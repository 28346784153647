import LazyCollection from './LazyCollection';
import ContinuousScroll from './ContinuousScroll';
export default class GroupedContinuousScroll extends LazyCollection {
    constructor(obj = {}, index = 0) {
        var _a;
        obj.items = obj.continuousScroll.data.items;
        super(obj, index);
        this._continuousScroll = new ContinuousScroll((_a = obj.continuousScroll) === null || _a === void 0 ? void 0 : _a.data);
    }
    get continuousScroll() {
        return this._continuousScroll;
    }
    get items() {
        return this._continuousScroll.items;
    }
    get itemsComponentType() {
        return this.obj.continuousScroll.component;
    }
    get listTitle() {
        return this.obj.title;
    }
    get groups() {
        return this.obj.groups;
    }
    get groupId() {
        var _a, _b;
        return (_b = (_a = this.obj.groups) === null || _a === void 0 ? void 0 : _a[this.index]) === null || _b === void 0 ? void 0 : _b.id;
    }
    get shelfData() {
        return {
            playlistMachineName: this.playlistMachineName,
            listTitle: this.listTitle,
            shelfIndex: this.index,
            machineName: this.machineName,
        };
    }
    get itemLabels() {
        return this.obj.itemLabels;
    }
}
