import { LiveGuideV2States } from '../../../constants';
export const AssetInfoStateFactory = (base) => class AssetInfo extends base {
    $enter() {
        this.tag('Focus').alpha = 1;
    }
    $exit() {
        this.tag('Focus').alpha = 0;
    }
    _focus() {
        this.tag('Focus').alpha = 1;
    }
    _unfocus() {
        this.tag('Focus').alpha = 0;
    }
    _handleDown() {
        this._setState(LiveGuideV2States.Tabs);
    }
    _handleEnter() {
        this.fireAncestors('$showPlayer');
    }
    _getFocused() {
        return this;
    }
};
