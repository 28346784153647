import { PLAYER_TYPE } from '../core/PlayerFactory';
import { PlayerFactorySingleton } from '../core/PlayerFactory';
export class CoreVideoLightningMediaPlayerAdapter {
    get watchId() {
        return '';
    }
    close() {
        var _a;
        (_a = PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)) === null || _a === void 0 ? void 0 : _a.close();
    }
    _setVideoArea([x, y, w, h]) {
        var _a;
        (_a = PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)) === null || _a === void 0 ? void 0 : _a.setVideoSize(x, y, w, h);
    }
}
