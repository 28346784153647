import { LiveGuideV2States } from '../../../constants';
export const FiltersStateFactory = (base) => class Filters extends base {
    $enter() {
        this._filtersGuide.visible = true;
        this._channelsGuide.visible = false;
    }
    _getFocused() {
        return this._filtersGuide;
    }
    _handleUp() {
        this._setState(LiveGuideV2States.Tabs);
    }
};
