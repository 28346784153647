import { COLORS, PROGRAMMING_TYPES } from '../../../../constants';
import { addSponsorsBadge, setBadge } from './helpers';
import { pipeSeparate } from '../../../../helpers';
export const fullEventReplayDecorator = (item) => {
    if (item._item.programmingType === PROGRAMMING_TYPES.FER) {
        item.headerTitle = item._item.slideHeader || item._item.videoTitle || item._item.title;
        item.tag('LogoArea.FallbackText').patch({ text: { textColor: COLORS.white } });
        item.secondaryTitle = pipeSeparate(item._item.description || item._item.secondaryTitle || '');
        setBadge(item);
        addSponsorsBadge(item);
    }
};
