let styles;
let compiled = false;
const compile = (style) => {
    if (!compiled) {
        const tags = Object.keys(style.tags);
        compiled = true;
        styles = tags.reduce((a, v) => {
            a.set(v, style.tags[v]);
            return a;
        }, new Map());
    }
    return styles;
};
const getTag = (tag) => {
    if (styles.has(tag)) {
        return styles.get(tag);
    }
    return null;
};
export default {
    compile,
    getTag,
};
