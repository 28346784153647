import { Language } from '@lightningjs/sdk';
import { PAGE_NAME, PAGE_TYPE, ROUTE } from '../../../../constants';
import { ModalTypes } from '../../../ModalManager';
const getAttributesWithAllVariations = (attributes) => {
    for (const route in attributes) {
        if (route.includes('?')) {
            const exploded = route.split('/');
            const routeWithoutOptionals = exploded.filter((part) => !part.includes('?')).join('/');
            attributes[routeWithoutOptionals] = attributes[route];
        }
    }
    return attributes;
};
const pageAttributes = Object.freeze(getAttributesWithAllVariations({
    [ROUTE.home]: {
        nbcsports: { name: PAGE_NAME.sports, type: PAGE_TYPE.sports },
        default: { name: PAGE_NAME.main, type: PAGE_TYPE.main },
    },
    [ROUTE.shows]: {
        default: { name: PAGE_NAME.shows, type: PAGE_TYPE.shows },
    },
    [ROUTE.showHome]: {
        default: { name: PAGE_NAME.showHome, type: PAGE_TYPE.showHome },
    },
    [ROUTE.movieHome]: {
        default: { name: PAGE_NAME.movieHome, type: PAGE_TYPE.movieHome },
    },
    [ROUTE.video]: {
        default: { name: PAGE_NAME.videoPlayer, type: PAGE_TYPE.videoPlayer },
    },
    'video/:videoId/end': {
        default: { name: PAGE_NAME.videoEndCard, type: PAGE_TYPE.videoPlayer },
    },
    [ROUTE.settings]: {
        default: { name: PAGE_NAME.settings, type: PAGE_TYPE.settings },
    },
    [ROUTE.appSettings]: {
        default: { name: PAGE_NAME.settings, type: PAGE_TYPE.settings },
    },
    [ROUTE.about]: {
        default: { name: PAGE_NAME.about, type: PAGE_TYPE.settings },
    },
    [ROUTE.closedCaptioning]: {
        default: { name: PAGE_NAME.closedCaptions, type: PAGE_TYPE.settings },
    },
    [ROUTE.contactUs]: {
        default: { name: PAGE_NAME.contactUs, type: PAGE_TYPE.settings },
    },
    [ROUTE.faq]: {
        default: { name: PAGE_NAME.faq, type: PAGE_TYPE.settings },
    },
    [ROUTE.language]: {
        default: { name: PAGE_NAME.language, type: PAGE_TYPE.settings },
    },
    [ROUTE.profile]: {
        default: { name: PAGE_NAME.myProfile, type: PAGE_TYPE.settings },
    },
    [ModalTypes.ACTIVATION]: {
        default: { name: PAGE_NAME.activation, type: PAGE_TYPE.authFunnel },
    },
    [ModalTypes.ACTIVATION_TEMPPASS_MVPD]: {
        default: { name: PAGE_NAME.activation, type: PAGE_TYPE.authFunnel },
    },
    [ModalTypes.ACTIVATION_TEMPPASS_NBC]: {
        default: {
            name: () => Language.translate('tempPass-activation-header'),
            type: PAGE_TYPE.authFunnel,
        },
    },
    [ModalTypes.HARD_CONTENT_GATING]: {
        default: {
            name: PAGE_NAME.hardContentGating,
            type: PAGE_TYPE.authFunnel,
        },
    },
    [ModalTypes.SOFT_CONTENT_GATING]: {
        default: {
            name: PAGE_NAME.softContentGating,
            type: PAGE_TYPE.authFunnel,
        },
    },
    [ROUTE.error]: {
        default: { name: PAGE_NAME.error, type: PAGE_TYPE.error },
    },
    [ROUTE.boot]: {
        default: { name: PAGE_NAME.main, type: PAGE_TYPE.main },
    },
    [ROUTE.notFound]: {
        default: { name: PAGE_NAME.error, type: PAGE_TYPE.error },
    },
    [ROUTE.credits]: {
        default: {
            name: PAGE_NAME.creditConfirmation,
            type: PAGE_TYPE.authFunnel,
        },
    },
    [ROUTE.search]: {
        default: { name: PAGE_NAME.search, type: PAGE_TYPE.search },
    },
    [ROUTE.live]: {
        default: { name: PAGE_NAME.linearVideoPlayer, type: PAGE_TYPE.live },
    },
    [ROUTE.networks]: {
        default: { name: PAGE_NAME.allBrands, type: PAGE_TYPE.brands },
    },
    [ROUTE.networkLanding]: {
        default: { name: PAGE_NAME.brandLanding, type: PAGE_TYPE.brandLanding },
    },
    [ROUTE.liveUpcoming]: {
        default: { name: PAGE_NAME.liveAndUpcoming, type: PAGE_TYPE.viewMore },
    },
    [ROUTE.sports]: {
        default: { name: PAGE_NAME.sports, type: PAGE_TYPE.sports },
    },
    [ROUTE.replays]: {
        default: { name: PAGE_NAME.replays, type: PAGE_TYPE.viewMore },
    },
    [ROUTE.olympics]: {
        default: { name: PAGE_NAME.olympics, type: PAGE_TYPE.olympics },
    },
    [ROUTE.olympicsReplays]: {
        default: { name: PAGE_NAME.olympicsReplays, type: PAGE_TYPE.viewMore },
    },
    [ROUTE.olympicsHighlights]: {
        default: { name: PAGE_NAME.olympicsHighlights, type: PAGE_TYPE.viewMore },
    },
}));
export default pageAttributes;
