import { AdBreakMarkersDelegate } from '../delegates/AdBreakMarkersDelegate';
export const WithAdBreaks = (base) => class extends base {
    constructor() {
        super(...arguments);
        this._adbreakMarkersDelegate = new AdBreakMarkersDelegate(this);
    }
    addAdBreakPoints(adbreaks) {
        this._adbreakMarkersDelegate.draw(adbreaks, this._strategy._totalTime);
    }
};
