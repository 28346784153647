import { Lightning } from '@lightningjs/sdk';
import SelectButton from './SelectButton';
import { ClosedCaptionsUtils } from '../../lib/ClosedCaptions/ClosedCaptionsUtils';
import { COLORS } from '../../constants';
export default class CCColorSelectButton extends SelectButton {
    static _template() {
        return {
            ...super._template(),
            ShadowBG: {
                x: 15,
                y: (h) => h / 2 + 5,
                color: COLORS.black9,
                mountY: 0.5,
                texture: Lightning.Tools.getShadowRect(38, 38, 19, 5, 5),
            },
            Circle: {
                x: 10,
                y: (h) => h / 2,
                mountY: 0.5,
            },
            Label: {
                x: 68,
                y: (h) => h / 2 + 3,
                mountY: 0.5,
            },
        };
    }
    set optionValue(v) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        super.optionValue = v;
        this.tag('Circle').texture = Lightning.Tools.getRoundRect(38, 38, 19, 0, 0, true, ClosedCaptionsUtils.getColorHex(v));
    }
    get optionValue() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        return super.optionValue;
    }
}
