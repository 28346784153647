import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { DebugControllerSingleton } from '../../util/debug/DebugController';
import { CONTENT_TYPE, WEB_PLATFORM } from '../../constants';
import { getBrowser } from '../../helpers';
import TvPlatform from '../tv-platform';
import TVPlatform from '../tv-platform';
import { ErrorType } from '../tv-platform/types';
const LEMONADE_TAG = 'Lemonade';
export const fetchLemonadeData = async (sessionItem) => {
    const headers = new Headers({
        Accept: 'application/json',
    });
    const { contentType } = sessionItem !== null && sessionItem !== void 0 ? sessionItem : {};
    if (contentType === CONTENT_TYPE.PLAYMAKER) {
        return sessionItem.streamUrl;
    }
    const url = getLemonadeUrl(sessionItem);
    try {
        // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        const response = await fetch(url, { headers });
        if (!response.ok) {
            return {
                errors: Error(`Request failed with status ${response.status}: ${response.statusText}`),
            };
        }
        return await response.json();
    }
    catch (errors) {
        TVPlatform.reportError({
            type: ErrorType.NETWORK,
            code: LEMONADE_TAG,
            description: 'Error reporting to lemonade',
            payload: errors,
        });
        return { errors };
    }
};
const getLemonadeUrl = (sessionItem) => {
    var _a;
    const { host, endpoint } = ((_a = AppConfigFactorySingleton === null || AppConfigFactorySingleton === void 0 ? void 0 : AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.lemonade) || {};
    if (!host || !endpoint) {
        TVPlatform.reportError({
            type: ErrorType.NETWORK,
            code: LEMONADE_TAG,
            description: 'Error generating lemonade url. Host or endpoint not found',
            payload: {
                host,
                endpoint,
            },
        });
        return '';
    }
    const { pid, stationId, mpxGuid, mpxAccountId, programmingType } = sessionItem !== null && sessionItem !== void 0 ? sessionItem : {};
    const platform = DebugControllerSingleton.videoProtocol
        ? WEB_PLATFORM
        : TvPlatform.lemonadePlatform;
    if (pid) {
        return `${host}${endpoint.sle}${pid}?platform=${platform}`;
    }
    else if (stationId) {
        return `${host}${endpoint.linear}${stationId}?platform=${platform}`;
    }
    else if (mpxGuid) {
        return `${host}${endpoint.vod}${mpxAccountId}/${mpxGuid}?programmingType=${programmingType}&platform=${platform}${platform === WEB_PLATFORM ? `&browser=${getBrowser()}` : ''}`;
    }
};
