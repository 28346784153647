import { Observable, filter, merge } from 'rxjs';
import { IPlayerEvent } from '../player/model/event/IPlayerEvent';
import PlayerStoreSingleton from '../store/PlayerStore/PlayerStore';
import { PLAYER_TYPE, PlayerFactorySingleton } from '../player/core/PlayerFactory';
import { SecondaryPlayerSingleton } from '../lib/SecondaryPlayer';
import TVPlatform from '../lib/tv-platform';
import ModalManager from '../lib/ModalManager';
import Announcer from '../lib/tts/Announcer';
import { AuthenticationEvents } from '../authentication/Authentication';
import { EpgGuideController, EpgGuideEvents } from '../components/EpgGuideV2/EpgGuideController';
export var SubscriptionSources;
(function (SubscriptionSources) {
    SubscriptionSources["PLAYER_STORE"] = "playerStore";
    SubscriptionSources["PLAYER"] = "player";
    SubscriptionSources["SECONDARY_PLAYER"] = "secondaryPlayer";
    SubscriptionSources["TV_PLATFORM"] = "tvPlatform";
    SubscriptionSources["MODAL"] = "modal";
    SubscriptionSources["TTS_ANNOUNCER"] = "tts";
    SubscriptionSources["AUTHENTICATION"] = "authentication";
    SubscriptionSources["LIVE_GUIDE_VIEW"] = "liveGuideView";
    SubscriptionSources["LIVE_GUIDE_TAB_DATA"] = "liveGuideTabData";
    SubscriptionSources["LIVE_GUIDE_PROGRESS"] = "liveGuideProgress";
})(SubscriptionSources || (SubscriptionSources = {}));
const isSubscriptionConfig = (input) => typeof input === 'object' && 'type' in input;
export class SubscriptionBuilder {
    constructor() {
        this._subjects = [];
        this._subscriptions = [];
    }
    with(...sources) {
        sources.forEach((sub) => {
            var _a, _b, _c, _d;
            if (isSubscriptionConfig(sub)) {
                // TV Platform events have to be handled separately since
                // it doesn't use RxJS under the hood
                if (sub.type === SubscriptionSources.TV_PLATFORM) {
                    (_a = this._subscriptions) === null || _a === void 0 ? void 0 : _a.push(new Observable((observer) => {
                        const subscription = TVPlatform.subscribe(sub.event, (...args) => observer.next(...args));
                        return function unsubscribe() {
                            subscription === null || subscription === void 0 ? void 0 : subscription.unsubscribe();
                        };
                    }).subscribe(sub.handler));
                }
                let subject = this._getSubscriptionSubject(sub.type);
                if (!subject)
                    return;
                if ('events' in sub) {
                    subject = subject.pipe(this._createFilterCallback(sub.events));
                }
                if (sub.handler) {
                    (_b = this._subscriptions) === null || _b === void 0 ? void 0 : _b.push(subject.subscribe(sub.handler));
                }
                else {
                    (_c = this._subjects) === null || _c === void 0 ? void 0 : _c.push(subject);
                }
            }
            else {
                const subject = this._getSubscriptionSubject(sub);
                if (subject)
                    (_d = this._subjects) === null || _d === void 0 ? void 0 : _d.push(subject);
            }
        });
        return this;
    }
    subscribe(callback) {
        var _a, _b, _c, _d, _e;
        if (!((_a = this._subjects) === null || _a === void 0 ? void 0 : _a.length) && !((_b = this._subscriptions) === null || _b === void 0 ? void 0 : _b.length)) {
            throw new Error('SubscriptionBuilder:: No subscription sources in builder. Please call with() method first.');
        }
        if (callback && !((_c = this._subjects) === null || _c === void 0 ? void 0 : _c.length)) {
            throw new Error('SubscriptionBuilder:: Received a callback but subscription sources already have a defined handler.');
        }
        let finalSubscription;
        if ((_d = this._subjects) === null || _d === void 0 ? void 0 : _d.length) {
            finalSubscription = merge(...this._subjects).subscribe(callback);
        }
        if ((_e = this._subscriptions) === null || _e === void 0 ? void 0 : _e.length) {
            let rest = [];
            if (finalSubscription) {
                rest = this._subscriptions;
            }
            else {
                finalSubscription = this._subscriptions[0];
                rest = this._subscriptions.slice(1);
            }
            rest === null || rest === void 0 ? void 0 : rest.forEach((sub) => {
                finalSubscription === null || finalSubscription === void 0 ? void 0 : finalSubscription.add(sub);
            });
        }
        this._subjects = [];
        this._subscriptions = [];
        return finalSubscription;
    }
    _getSubscriptionSubject(source) {
        var _a;
        switch (source) {
            case SubscriptionSources.PLAYER_STORE:
                return PlayerStoreSingleton.events;
            case SubscriptionSources.PLAYER:
                return (_a = PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)) === null || _a === void 0 ? void 0 : _a.events;
            case SubscriptionSources.SECONDARY_PLAYER:
                return SecondaryPlayerSingleton.events;
            case SubscriptionSources.MODAL:
                return ModalManager._subject;
            case SubscriptionSources.TTS_ANNOUNCER:
                return Announcer.events;
            case SubscriptionSources.AUTHENTICATION:
                return AuthenticationEvents._subject;
            case SubscriptionSources.LIVE_GUIDE_PROGRESS:
                return EpgGuideController.getSubject(EpgGuideEvents.PROGRESS);
            case SubscriptionSources.LIVE_GUIDE_VIEW:
                return EpgGuideController.getSubject(EpgGuideEvents.VIEW_CHANGE);
            case SubscriptionSources.LIVE_GUIDE_TAB_DATA:
                return EpgGuideController.getSubject(EpgGuideEvents.TAB_DATA);
            default:
                return undefined;
        }
    }
    _createFilterCallback(events) {
        if ((events === null || events === void 0 ? void 0 : events[0]) instanceof IPlayerEvent) {
            return filter((value) => events.find((evt) => value instanceof evt));
        }
        return filter((value) => events.includes(value.type));
    }
}
