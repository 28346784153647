import SeekablePlayerControlsV1 from './SeekablePlayerControlsV1';
import FERStrategy from './strategies/FERStrategy';
import { formatSecondsAsHhMmSs, setSmooth } from '../../../helpers';
import { COLORS, FONT_FACE, PlayerControlsStates, VERTICAL_ALIGN } from '../../../constants';
import { WithPauseAds } from './hoc/WithPauseAds';
import { WithTrickPlay } from './hoc/WithTrickPlay';
import { WithAdBreaks } from './hoc/WithAdBreaks';
import TempPassCTA from './TempPassCTA';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { EVENTS } from '../../../lib/analytics/types';
import { activateTempPassRedirect } from '../../../pages/Player/StreamLoader/actions/redirect';
import { TEMP_PASS_REDIRECT } from '../../../pages/Player/BasePlayer';
import { Registry } from '@lightningjs/sdk';
import { useRouterBackDisabled } from '../../../widgets/Modals/activation/hooks/useRouterBackDisabled';
const TIMEOUT_HIDE_TEMPPASS_TIMER = 5000;
class FERPlayerControls extends SeekablePlayerControlsV1 {
    constructor() {
        super(...arguments);
        this._strategy = new FERStrategy(this);
    }
    static _template() {
        return super._template({
            PlayerControls: {
                TitleContainer: {
                    HeadingContainer: {
                        Title: {
                            x: 18,
                        },
                    },
                    TertiaryTitle: {
                        y: 150,
                        x: 82,
                        text: {
                            textColor: COLORS.white,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 28,
                            fontFace: FONT_FACE.light,
                            maxLines: 1,
                            wordWrapWidth: this.PROGRESSBAR_TOTAL_WIDTH,
                        },
                    },
                },
            },
        });
    }
    _updateProgress([currentTime, duration]) {
        if (!duration)
            return;
        const position = (currentTime * this.constructor.PROGRESSBAR_TOTAL_WIDTH) / duration;
        this._timer.aTime = formatSecondsAsHhMmSs(currentTime);
        setSmooth(this.tag('CurrentBar'), 'w', position, { duration: 0.2 });
        setSmooth(this.tag('SeekBar'), 'x', position, { duration: 0.2 });
    }
    getTitleContainerY() {
        return this.constructor.TITLE_CONTAINER_Y;
    }
    patchTempPassTemplate() {
        this.patch({
            TempPass: {
                type: TempPassCTA,
                alpha: 1,
            },
        });
        this._tempPassControlsOverlay = this.tag('TempPass');
        this.tempPassCtaClicked = false;
    }
    createTimeOut() {
        Registry.setTimeout(() => {
            this.fireAncestors('$hideTempPassTimer');
        }, TIMEOUT_HIDE_TEMPPASS_TIMER);
    }
    updateTempPassCountdown(secondsLeft) {
        if (secondsLeft <= 30 && this._getState() !== PlayerControlsStates.TempPass) {
            this.fireAncestors('$showTempPassTimer', { pin: true });
        }
        this._tempPassControlsOverlay.update(secondsLeft);
    }
    static _states() {
        return [
            ...super._states(),
            class TempPass extends this {
                _handleEnter() {
                    sendMetric(EVENTS.CLICK, {
                        name: 'TempPass - Link TV Provider',
                    });
                    this.tempPassCtaClicked = true;
                    useRouterBackDisabled().set(true);
                    activateTempPassRedirect(TEMP_PASS_REDIRECT.TEMP_PASS_MVPD);
                }
                _getFocused() {
                    return this.tag('TempPass') || this;
                }
            },
        ];
    }
}
FERPlayerControls.TITLE_CONTAINER_Y = 700;
// Workaround for TS types not being correctly asserted with HOCs
export default class extends WithAdBreaks(WithPauseAds(WithTrickPlay(FERPlayerControls))) {
}
