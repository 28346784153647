export default class UserProfile {
    constructor(data) {
        this._data = data;
    }
    get userName() {
        return this._data.userName;
    }
    get mail() {
        return this._data.mail;
    }
    get rev() {
        return this._data._rev;
    }
    get brandSource() {
        return this._data.brandSource;
    }
    get episodeCount() {
        return this._data.episodeCount;
    }
    get episodeArray() {
        return this._data.episodeArray || [];
    }
    get id() {
        return this._data._id;
    }
    isVideoAlreadyPlayed(videoId) {
        return this.episodeArray.indexOf(videoId) >= 0;
    }
    get googleId() {
        return this._data.googleId;
    }
    get facebookId() {
        return this._data.facebookId;
    }
    get appleId() {
        return this._data.appleId;
    }
}
