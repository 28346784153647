import Tile from './Tile';
export default class MovieTile extends Tile {
    get title() {
        return this.data.title || null;
    }
    get posterImage() {
        return this.data.posterImage || null;
    }
    get image() {
        return this.data.image || null;
    }
    get logo() {
        return this.data.whiteBrandLogo || null;
    }
    get urlAlias() {
        return this.data.urlAlias || '';
    }
    get labelBadge() {
        return this.data.labelBadge;
    }
    get gradientStart() {
        return this.data.gradientStart ? this.data.gradientStart.replace('#', '') : 'b78623';
    }
    get gradientEnd() {
        return this.data.gradientEnd ? this.data.gradientEnd.replace('#', '') : 'b78623';
    }
}
