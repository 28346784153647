//Launch Darkly
import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags';
export const ALGOLIA_ENTITY_TYPES = {
    MOVIES: 'movies',
    SERIES: 'series',
    EPISODES: 'episodes',
    SEGMENTS: 'segments',
    SLE: 'singleLiveEvents',
    FER: 'fer',
    REPLAYS: 'fullEventReplays',
    SPORTS_SERIES: 'sportsSeries',
    SPORTS_VIDEOS: 'sportsVideos',
};
const ALLOWED_TYPES_SEARCH = new Set([
    ALGOLIA_ENTITY_TYPES.MOVIES,
    ALGOLIA_ENTITY_TYPES.SERIES,
    ALGOLIA_ENTITY_TYPES.SEGMENTS,
    ALGOLIA_ENTITY_TYPES.EPISODES,
    ALGOLIA_ENTITY_TYPES.SLE,
    ALGOLIA_ENTITY_TYPES.REPLAYS,
    ALGOLIA_ENTITY_TYPES.SPORTS_SERIES,
    ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS,
]);
export const removeNotMappedTypeContents = (results) => {
    const ferSearch = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.enableFERSearch);
    //See if we should remove FER results or not. Default to them not being enabled
    if (ferSearch && !ALLOWED_TYPES_SEARCH.has(ALGOLIA_ENTITY_TYPES.FER)) {
        ALLOWED_TYPES_SEARCH.add(ALGOLIA_ENTITY_TYPES.FER);
    }
    return results.filter((result) => { var _a; return ALLOWED_TYPES_SEARCH.has((_a = result.algoliaProperties) === null || _a === void 0 ? void 0 : _a.entityType); });
};
