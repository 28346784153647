import { Lightning } from '@lightningjs/sdk';
import { FONT_FACE, TEXT_ALIGN } from '../../constants';
// @ts-expect-error TS(2417): Class static side 'typeof LabelBadge' incorrectly ... Remove this comment to see the full error message
export default class LabelBadge extends Lightning.Component {
    constructor() {
        super(...arguments);
        this.widthSize = 68;
        this.heightSize = 36;
        this.heightSizeContainer = 40;
        this.backgroundColor = '';
    }
    static _template() {
        return {
            flex: {},
            rect: true,
            x: this.bindProp('positionX'),
            y: this.bindProp('positionY'),
            Label: {
                flexItem: { marginTop: 3, marginBottom: -5 },
                color: this.bindProp('fontColor'),
                text: {
                    textAlign: TEXT_ALIGN.center,
                    fontFace: FONT_FACE.regular,
                    fontSize: this.bindProp('fontSize'),
                    text: this.bindProp('label'),
                },
            },
        };
    }
    _init() {
        this.patch({
            texture: Lightning.Tools.getRoundRect(this.widthSize || 0, this.heightSize || 0, 4, 0, 
            // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
            null, true, this.backgroundColor),
            h: this.heightSizeContainer,
            Label: {
                w: this.widthSize,
                h: this.heightSize,
                text: {
                    w: this.widthSize,
                    h: this.heightSize,
                },
            },
        });
    }
}
