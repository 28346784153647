import { GraphQlClient } from '../../graphql/client';
import { getMpid } from '../../helpers';
import bffPageMapper from '../../graphql/mappers/bffPage';
import { QueryName } from './bffQuery';
import rsnFilter from '../../graphql/filter/rsnFilter';
const mainRequest = async (variables, options) => {
    if (!variables.userId)
        variables.userId = getMpid();
    return rsnFilter(bffPageMapper(await GraphQlClient.query({
        query: QueryName.bonanzaPage,
        variables,
    }, options)));
};
export default mainRequest;
