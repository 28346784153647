import { ALGOLIA_ENTITY_TYPES, formatSecondsAsHhMmSs } from '../../../helpers';
import LabelBadge from '../../badges/LabelBadge';
import { COLORS } from '../../../constants';
export default (tile) => {
    var _a, _b;
    if (ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS !== tile.entityType || !((_a = tile.item.video) === null || _a === void 0 ? void 0 : _a.duration))
        return;
    const label = formatSecondsAsHhMmSs((_b = tile.item.video) === null || _b === void 0 ? void 0 : _b.duration);
    tile.patch({
        Image: {
            VideoDuration: {
                type: LabelBadge,
                label,
                positionX: 10,
                positionY: (h) => h - 50,
                backgroundColor: COLORS.black5,
                fontColor: COLORS.white,
                fontSize: 24,
                width: 68,
                height: 36,
            },
        },
    });
};
