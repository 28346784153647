import { IPlayerEvent } from './IPlayerEvent';
export class AdBreakDataEvent extends IPlayerEvent {
    constructor(adBreaks) {
        super();
        this._adBreaks = adBreaks;
    }
    get adBreaks() {
        return this._adBreaks;
    }
}
