import get from 'lodash/get';
import SearchOutcomeType from '../model/SearchOutcomeType';
import { ALGOLIA_ENTITY_TYPES, setDefaultValue } from '../../../../helpers';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
const getResultData = (result, entityType) => {
    switch (entityType) {
        case ALGOLIA_ENTITY_TYPES.SERIES:
        case ALGOLIA_ENTITY_TYPES.SPORTS_SERIES:
            return {
                showName: get(result, 'series.title', ''),
                brand: get(result, 'series.brands[0].title', 'None'),
            };
        case ALGOLIA_ENTITY_TYPES.MOVIES:
            return {
                showName: get(result, 'movie.sortTitle', ''),
                brand: get(result, 'movie.brands[0].title', 'None'),
            };
        case ALGOLIA_ENTITY_TYPES.EPISODES:
        case ALGOLIA_ENTITY_TYPES.SEGMENTS:
        case ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS:
            return {
                showName: get(result, 'series.title', ''),
                brand: get(result, 'video.brand.title', 'None'),
            };
        default:
            return {};
    }
};
const getSelectionType = (entityType, item) => {
    var _a;
    // Video selected
    if (entityType !== ALGOLIA_ENTITY_TYPES.SERIES && entityType !== ALGOLIA_ENTITY_TYPES.MOVIES) {
        const programmingType = (_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.programmingType;
        return ((Array.isArray(programmingType) ? programmingType[0] : programmingType) ||
            MPARTICLE_DEFAULT_ATTR_VALUE);
    }
    return 'Show';
};
const getSearchResultsAttributes = (params = {}) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const { item } = params;
    const entityType = (_a = item === null || item === void 0 ? void 0 : item.algoliaProperties) === null || _a === void 0 ? void 0 : _a.entityType;
    const resultData = getResultData(item, entityType);
    const lockup = (_b = item === null || item === void 0 ? void 0 : item.algoliaProperties) === null || _b === void 0 ? void 0 : _b.isEpisode;
    const selIndex = params.index || 0;
    return setDefaultValue({
        Brand: resultData.brand,
        Show: resultData.showName,
        Season: params.seasonNumber,
        'Video ID': ((_c = item === null || item === void 0 ? void 0 : item.video) === null || _c === void 0 ? void 0 : _c.mpxGuid) || ((_d = item === null || item === void 0 ? void 0 : item.data) === null || _d === void 0 ? void 0 : _d.pid),
        'Content Position': selIndex + 1,
        'Custom Shelf Position': 1,
        'Custom Shelf Title': 'Search Results',
        Lockup: lockup ? 'True' : 'False',
        'Lockup Content Position': lockup ? selIndex + 1 : MPARTICLE_DEFAULT_ATTR_VALUE,
        'Selection Type': getSelectionType(entityType, item),
        'Search Term': params.searchTerm,
        'Search Outcome': params.type || SearchOutcomeType.browse,
        'Result Count': params.results,
        Sport: ((_f = (_e = item === null || item === void 0 ? void 0 : item.data) === null || _e === void 0 ? void 0 : _e.sport) === null || _f === void 0 ? void 0 : _f.title) || MPARTICLE_DEFAULT_ATTR_VALUE,
        League: ((_h = (_g = item === null || item === void 0 ? void 0 : item.data) === null || _g === void 0 ? void 0 : _g.league) === null || _h === void 0 ? void 0 : _h.title) || MPARTICLE_DEFAULT_ATTR_VALUE,
    }, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getSearchResultsAttributes;
