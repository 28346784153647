import { Log } from '@lightningjs/sdk';
import VideoTile from './VideoTile/VideoTile';
import { audioLanguageDecorator, labelBadgeDecorator, lockDecorator, olympicsMedalDecorator, } from './decorators';
import { FastImg } from '../../helpers';
import { COLORS, ENTITY_TYPES, FLEX_DIRECTION, FONT_FACE, MAX_LINES_SUFFIX, ROUTE, ROUTE_ENTITY, OLY_IMPOLICY, } from '../../constants';
import RouterUtil from '../../util/RouterUtil';
import { DebugControllerSingleton } from '../../util/debug/DebugController';
export default class ReplayTile extends VideoTile {
    set item(v) {
        var _a, _b, _c, _d, _e;
        this._item = v;
        this._item.obj.analytics.pid = (_d = (_c = (_b = (_a = v.obj) === null || _a === void 0 ? void 0 : _a.replayTileData) === null || _b === void 0 ? void 0 : _b.pid) === null || _c === void 0 ? void 0 : _c.toString) === null || _d === void 0 ? void 0 : _d.call(_c);
        this.patch({
            ImageHolder: {
                w: 420,
                h: 235,
                Image: { texture: FastImg(v.image).contain(420, 235, OLY_IMPOLICY.RECT_SMEDIUM_640_360) },
            },
            Content: {
                TitleCard: {
                    ShortTitleContainer: {
                        ShortTitle: {
                            text: {
                                text: this._getMainTitle(),
                                maxLines: 1,
                                maxLinesSuffix: MAX_LINES_SUFFIX,
                            },
                        },
                    },
                    Title: {
                        text: {
                            text: this._getSubTitle(),
                            maxLines: 1,
                            maxLinesSuffix: MAX_LINES_SUFFIX,
                        },
                    },
                    SmartTimeStamp: {
                        flex: {},
                        flexItem: { marginTop: -8 },
                        ShortTitle: {
                            flexItem: { marginRight: 8 },
                            text: {
                                text: (_e = this._item) === null || _e === void 0 ? void 0 : _e.tertiaryTitle,
                                fontSize: 24,
                                textColor: COLORS.white,
                                fontFace: FONT_FACE.light,
                                lineHeight: 24,
                            },
                        },
                        AudioLanguageContainer: {
                            flex: { direction: FLEX_DIRECTION.row },
                        },
                    },
                },
                Logo: { texture: FastImg(v.logo).contain(60, 40) },
            },
        });
    }
    _init() {
        var _a;
        super._init();
        this._addDecorators([
            labelBadgeDecorator((_a = this._item) === null || _a === void 0 ? void 0 : _a.labelBadge),
            lockDecorator,
            olympicsMedalDecorator(5),
            audioLanguageDecorator,
        ]);
    }
    _getMainTitle() {
        return this._item.title;
    }
    _getSubTitle() {
        return this._item.secondaryTitle;
    }
    _handleEnter() {
        Log.info('ReplayTileItem', this._item);
        this.trackContentPosition();
        this.trackContentClick(ENTITY_TYPES.REPLAY);
        DebugControllerSingleton.streamUrl = this._item.HARDCODEDSTREAM;
        RouterUtil.navigateToRoute(ROUTE.watch, {
            entity: ROUTE_ENTITY.pid,
            value: this._item.pid,
        });
    }
    get olympicsMedalContainer() {
        return this.tag('ShortTitleContainer');
    }
    static get tag() {
        return 'ReplayTile';
    }
}
