import { Router } from '@lightningjs/sdk';
import ModalManager, { ModalTypes } from '../../lib/ModalManager';
import QrModal from './qrModal';
import UpcomingModal from './upcomingModal';
import ErrorModal from './errorModal/ErrorModal';
import PlayerLoader from './playerLoader/PlayerLoader';
import Activation from './activation/Activation';
import TempPassActivation from './activation/TempPassActivation';
import TempPassConcluded from './activation/TempPassConcluded';
import OnboardingModal from './onboardingModal';
import OnboardingActivation from './activation/OnboardingActivation';
import ContentGatingModal from './contentGating/ContentGatingModal';
import ContentGatingActivation from './activation/ContentGatingActivation';
export const ModalTypeTagMap = {
    [ModalTypes.QR_MODAL]: { key: 'QrModal', component: QrModal },
    [ModalTypes.UPCOMING]: { key: 'UpcomingLiveModal', component: UpcomingModal },
    [ModalTypes.PLAYER_ERROR]: { key: 'PlayerErrorModal', component: ErrorModal },
    [ModalTypes.PLAYER_LOADER]: { key: 'PlayerLoaderModal', component: PlayerLoader },
    [ModalTypes.ACTIVATION]: { key: 'Activation', component: Activation },
    [ModalTypes.ACTIVATION_TEMPPASS_MVPD]: { key: 'ActivationTempPassMvpd', component: Activation },
    [ModalTypes.ACTIVATION_TEMPPASS_NBC]: {
        key: 'ActivationTempPassNbc',
        component: TempPassActivation,
    },
    [ModalTypes.TEMPPASS_CONCLUDED]: {
        key: 'TempPassConcluded',
        component: TempPassConcluded,
    },
    [ModalTypes.ONBOARDING_MODAL]: { key: 'OnboardingModal', component: OnboardingModal },
    [ModalTypes.ONBOARDING_ACTIVATION]: {
        key: 'OnboardingActivation',
        component: OnboardingActivation,
    },
    [ModalTypes.SOFT_CONTENT_GATING]: {
        key: 'SoftContentGating',
        component: ContentGatingModal,
    },
    [ModalTypes.HARD_CONTENT_GATING]: {
        key: 'HardContentGating',
        component: ContentGatingModal,
    },
    [ModalTypes.CONTENT_GATING_ACTIVATION]: {
        key: 'ContentGatingActivation',
        component: ContentGatingActivation,
    },
};
export const loadModals = () => Object.values(ModalTypeTagMap).reduce((acc, item, index) => ({
    ...acc,
    [item.key]: {
        type: item.component,
        visible: false,
        zIndex: 200 + index,
    },
}), {});
export const createModal = (ctx, type, payload) => {
    const { key } = ModalTypeTagMap[type];
    const modal = ctx.tag(key);
    if (modal) {
        modal.modalData = payload;
        modal.visible = true;
    }
    Router.focusWidget(key);
    return {
        destroy(focusPage = true) {
            modal.visible = false;
            if (focusPage)
                Router.focusPage();
        },
    };
};
export const updateModal = (ctx, type, payload) => {
    const { key } = ModalTypeTagMap[type];
    const modal = ctx.tag(key);
    modal.modalData = payload;
};
export const getCurrentModalInstance = () => {
    const page = Router.getActivePage();
    const activeModal = ModalManager._activeModal && ModalTypeTagMap[ModalManager._activeModal];
    if (page && activeModal)
        return page.fireAncestors('$getWidget', activeModal.key);
    return null;
};
