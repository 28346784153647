import { Subject } from 'rxjs';
export default class BaseStore {
    constructor() {
        this.events = new Subject();
        this.state = {};
    }
    subscribe(handler) {
        return this.events.subscribe(handler);
    }
    async _getActionResult(action) {
        // If the action is a function pass a copy of the state so we make sure
        // actions don't mutate state, only use it for calculations
        return await (typeof action === 'function'
            ? action({ ...this.state })
            : Promise.resolve(action));
    }
}
