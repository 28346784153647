import qs from 'qs';
import { get } from 'lodash';
import { CLIENT_NAME } from '../constants';
import AppConfigFactorySingleton from '../config/AppConfigFactory';
import { DebugControllerSingleton } from '../util/debug/DebugController';
import AuthenticationSingleton from '../authentication/Authentication';
import TVPlatform from '../lib/tv-platform';
import { ErrorType } from '../lib/tv-platform/types';
export var CHANNEL_IDS;
(function (CHANNEL_IDS) {
    CHANNEL_IDS["nbc"] = "nbc";
    CHANNEL_IDS["nbcnews"] = "nbcnews";
    CHANNEL_IDS["telemundo"] = "telemundo";
    CHANNEL_IDS["usa"] = "usa";
    CHANNEL_IDS["e"] = "e!";
    CHANNEL_IDS["nbcsportspeacock"] = "nbcsportspeacock";
})(CHANNEL_IDS || (CHANNEL_IDS = {}));
let coast, nbcGeoStation, telemundoAffiliate;
const getZip = () => DebugControllerSingleton.geoZip || AuthenticationSingleton.getMvpdDataZip();
export const isGeoCheckError = (e) => e.code && e.description;
export const geoCheck = async (channelId, channelName, options = {
    contentType: 'live',
    failOnRestrictionError: false,
    entitlementId: null,
}) => {
    var _a;
    if (DebugControllerSingleton.geo === 'Disabled') {
        return {};
    }
    const { host, endpoint } = AppConfigFactorySingleton.config.access_vod.geoLocationService;
    const lowerContentType = ((_a = options.contentType) === null || _a === void 0 ? void 0 : _a.toLowerCase()) || 'live';
    const url = `${host}${endpoint}/${lowerContentType}/${channelId}`;
    const timestamp = new Date().getTime().toString();
    const headers = new Headers({
        'Content-Type': 'application/json',
        Accept: 'application/media.geo-v2+json',
        Client: CLIENT_NAME,
    });
    let serviceZip = getZip();
    // Make last attempt to get zipcode - else fail
    if (!serviceZip)
        serviceZip = await AuthenticationSingleton.checkStatus().then(getZip);
    const body = {
        adobeMvpdId: AuthenticationSingleton.getMvpdDataMvpdId(),
        device: AppConfigFactorySingleton.config.access_vod.device,
        serviceZip,
        channelName,
    };
    const params = {};
    // Pass the entitlement parameter only for SLE and FER
    if (options.entitlementId) {
        // @ts-expect-error TS(2339): Property 'entitlementId' does not exist on type '{... Remove this comment to see the full error message
        params.entitlementId = options.entitlementId;
    }
    else {
        // @ts-expect-error adding it here
        params.time = timestamp;
    }
    try {
        const response = await fetch(`${url}?${qs.stringify(params)}`, {
            referrerPolicy: 'no-referrer',
            headers,
            method: 'POST',
            body: JSON.stringify(body),
        });
        const geoData = await (response === null || response === void 0 ? void 0 : response.json());
        if (options.failOnRestrictionError && geoData.restricted) {
            throw geoData.restrictionDetails;
        }
        if (geoData.code === 1200) {
            throw geoData.message;
        }
        if (get(geoData, 'localizedChannelInfo')) {
            // Save user coast and affiliate data.
            saveGeoData(channelId, geoData);
        }
        if ((response === null || response === void 0 ? void 0 : response.status) !== 200)
            return Promise.reject(geoData);
        return { ...geoData };
    }
    catch (err) {
        TVPlatform.reportError({
            type: ErrorType.RESTRICTION,
            description: 'geo error',
            payload: err,
        });
        return Promise.reject(err);
    }
};
export const nationalBroadcastType = () => {
    if (DebugControllerSingleton.geo === 'Disabled' && !coast)
        return 'eastCoast';
    return coast;
};
export const nbcAffiliateName = () => {
    if (DebugControllerSingleton.geo === 'Disabled' && !nbcGeoStation)
        return 'wtvj';
    return nbcGeoStation;
};
export const telemundoAffiliateName = () => telemundoAffiliate;
export const isBionicStream = (channelId) => channelId !== 'nbcnews';
const saveGeoDataStream = (geoData) => {
    coast =
        (get(geoData, 'localizedChannelInfo.channel') || '').indexOf('west') > -1
            ? 'westCoast'
            : 'eastCoast';
};
const saveGeoDataChannel = (geoData) => {
    nbcGeoStation = get(geoData.localizedChannelInfo, 'geoChannel', '');
    // nbcHomeStation = get(geoData.localizedChannelInfo, 'homeChannel', '')
    // geoZip = get(geoData.requestInfo, 'geoZip', '')
};
const saveTelemundo = (geoData) => {
    telemundoAffiliate = get(geoData, 'localizedChannelInfo.geoChannel', '');
};
const saveGeoData = (channelId, geoData) => {
    switch (channelId) {
        case CHANNEL_IDS.nbc:
            if (!nbcGeoStation)
                saveGeoDataChannel(geoData);
            break;
        case CHANNEL_IDS.telemundo:
            if (!telemundoAffiliate)
                saveTelemundo(geoData);
            break;
        default:
            if (!coast)
                saveGeoDataStream(geoData);
            break;
    }
};
