import { Lightning, Router, Language } from '@lightningjs/sdk';
import BaseComponent from '../base';
import { tertiaryTitleDecorator, clipCardDecorator, badgeDecorator, liveProgressBarDecorator, durationDecorator, } from './decorators';
import { lockDecorator } from '../items/decorators';
import { ALGOLIA_ENTITY_TYPES, FastImg, formatSecondsAsHhMmSSForTTS } from '../../helpers';
import { navigateUpcomingLiveItems } from '../items/helpers/upcomingItems';
import { BADGE_LABELS, COLORS, FONT_FACE } from '../../constants';
import { OLY_IMPOLICY } from '../../constants/images';
// @ts-expect-error TS(2417): Class static side 'typeof PagingListTile' incorrec... Remove this comment to see the full error message
export default class PagingListTile extends BaseComponent {
    static _template() {
        return {
            color: 'transparent',
            rect: true,
            clipping: true,
            Focus: {
                alpha: 0,
                rect: true,
                color: 'transparent',
                w: (w) => w,
                h: (h) => h,
                shader: {
                    type: Lightning.shaders.Outline,
                    width: 6,
                },
            },
            LazyImageFallback: {
                x: 13,
                y: 13,
                w: 460,
                h: 259,
                rect: true,
                colorLeft: COLORS.brightGrey,
                colorRight: COLORS.thunder2,
            },
            Image: {
                x: 13,
                y: 13,
                w: 460,
                h: 259,
            },
            Title: {
                x: 508,
                y: 17,
                text: {
                    fontFace: FONT_FACE.regular,
                    fontSize: 36,
                    maxLines: 1,
                },
            },
            SubTitle: {
                w: 850,
                x: 508,
                color: COLORS.white,
                text: {
                    fontFace: FONT_FACE.light,
                    fontSize: 30,
                    maxLines: 3,
                    fontWeight: 300,
                    lineHeight: 40,
                },
            },
            TertiaryTitle: {
                y: 116,
                x: 508,
                text: {
                    fontFace: FONT_FACE.light,
                    fontSize: 30,
                    maxLines: 3,
                    fontWeight: 300,
                    lineHeight: 40,
                },
            },
            Description: {
                w: 850,
                x: 508,
                y: 165,
                color: COLORS.white,
                text: {
                    fontFace: FONT_FACE.light,
                    fontSize: 30,
                    maxLines: 2,
                    fontWeight: 300,
                    lineHeight: 40,
                },
            },
            BrandLogo: {
                mount: 1,
                x: 1426 + 50,
                y: 195 + 40,
            },
        };
    }
    _init() {
        var _a, _b, _c, _d;
        super._init();
        this._addDecorators([
            tertiaryTitleDecorator,
            clipCardDecorator,
            badgeDecorator(((_b = (_a = this._item) === null || _a === void 0 ? void 0 : _a.video) === null || _b === void 0 ? void 0 : _b.labelBadge) || ((_d = (_c = this.item) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.labelBadge)),
            lockDecorator,
            liveProgressBarDecorator,
            durationDecorator,
        ]);
    }
    set item(v) {
        this._item = v;
        this.patch({
            Image: {
                texture: FastImg(this.image).contain(460, 259, OLY_IMPOLICY.RECT_SMEDIUM_640_360),
            },
            Title: {
                text: {
                    text: this.title,
                },
            },
            SubTitle: {
                y: 92,
                text: {
                    text: this.subTitle,
                },
            },
            BrandLogo: {
                texture: FastImg(this.brandLogo).contain(52, 30, OLY_IMPOLICY.RECT_SMALL_384_216),
            },
        });
    }
    get item() {
        return this._item;
    }
    get showTertiaryTitle() {
        return (this.entityType === ALGOLIA_ENTITY_TYPES.EPISODES ||
            this.entityType === ALGOLIA_ENTITY_TYPES.REPLAYS ||
            this.entityType === ALGOLIA_ENTITY_TYPES.SLE ||
            this.entityType === ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS);
    }
    get showClipData() {
        var _a, _b;
        return ((_a = this.item.algoliaProperties) === null || _a === void 0 ? void 0 : _a.isSegment) || ((_b = this.item.algoliaProperties) === null || _b === void 0 ? void 0 : _b.isMovieSegment);
    }
    get image() {
        var _a, _b, _c;
        return (_c = (_b = (_a = this.item) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.image) === null || _c === void 0 ? void 0 : _c.path;
    }
    get title() {
        var _a;
        const { data } = this.item;
        let title = '';
        switch (this.entityType) {
            case ALGOLIA_ENTITY_TYPES.SEGMENTS:
            case ALGOLIA_ENTITY_TYPES.EPISODES:
                title = data.series.title;
                break;
            case ALGOLIA_ENTITY_TYPES.MOVIES:
                title = data.shortDisplayTitle;
                break;
            case ALGOLIA_ENTITY_TYPES.SERIES:
            case ALGOLIA_ENTITY_TYPES.SPORTS_SERIES:
                title = data.title;
                break;
            case ALGOLIA_ENTITY_TYPES.REPLAYS:
            case ALGOLIA_ENTITY_TYPES.SLE:
            case ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS:
                title = ((_a = data.series) === null || _a === void 0 ? void 0 : _a.title) || data.league.title || data.sport.title;
                break;
            default:
        }
        return title;
    }
    get subTitle() {
        const { data } = this.item;
        let description = '';
        switch (this.entityType) {
            case ALGOLIA_ENTITY_TYPES.EPISODES:
                description = data.tertiaryTitle;
                break;
            case ALGOLIA_ENTITY_TYPES.SERIES:
            case ALGOLIA_ENTITY_TYPES.MOVIES:
            case ALGOLIA_ENTITY_TYPES.SPORTS_SERIES:
                description = data.longDescription;
                break;
            case ALGOLIA_ENTITY_TYPES.REPLAYS:
            case ALGOLIA_ENTITY_TYPES.SLE:
            case ALGOLIA_ENTITY_TYPES.SEGMENTS:
                description = data.title;
                break;
            case ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS:
                description = data.subTitle;
                break;
            default:
        }
        return description;
    }
    get announce() {
        var _a, _b, _c, _d;
        const announcements = [];
        // Line 1
        if ((_b = (_a = this.item) === null || _a === void 0 ? void 0 : _a.video) === null || _b === void 0 ? void 0 : _b.labelBadge) {
            announcements.push(this.item.video.labelBadge);
        }
        announcements.push(this.title);
        // Line 2
        if ([
            ALGOLIA_ENTITY_TYPES.SLE,
            ALGOLIA_ENTITY_TYPES.REPLAYS,
            ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS,
        ].includes(this.entityType)) {
            announcements.push(this.subTitle);
        }
        // line 3
        if ([ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS].includes(this.entityType)) {
            announcements.push(`${Language.translate('aired_on')} ${this.tertiaryTitle}`);
        }
        // line 4
        if ([ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS].includes(this.entityType) &&
            ((_c = this.item.video) === null || _c === void 0 ? void 0 : _c.duration)) {
            announcements.push(`${formatSecondsAsHhMmSSForTTS((_d = this.item.video) === null || _d === void 0 ? void 0 : _d.duration)}`);
        }
        return announcements;
    }
    get description() {
        const { data } = this.item;
        return data.shortDescription || data.description || '';
    }
    get brandLogo() {
        const { data } = this.item;
        return data.brand.whiteBrandLogo.path;
    }
    get entityType() {
        var _a, _b;
        return (_b = (_a = this.item) === null || _a === void 0 ? void 0 : _a.algoliaProperties) === null || _b === void 0 ? void 0 : _b.entityType;
    }
    get tertiaryTitle() {
        const { data } = this.item;
        if (this.entityType === ALGOLIA_ENTITY_TYPES.EPISODES)
            return data.title;
        if (this.entityType === ALGOLIA_ENTITY_TYPES.REPLAYS)
            return data.tertiaryTitle;
        return data.tertiaryTitle;
    }
    _getBadgeLabel(label) {
        // Only return translations we have a known value for.
        // Otherwise the translate function would return labelBadge-UNKNOWN_LABEL
        // With this protection we just return the label in English which is still better.
        if (!BADGE_LABELS.includes(label))
            return label;
        return Language.translate(`labelBadge-${label.toLowerCase()}`);
    }
    _focus() {
        this.fire('toggleFocus', { v: true });
    }
    _unfocus() {
        this.fire('toggleFocus', { v: false });
    }
    toggleFocus({ v }) {
        this.tag('Focus').patch({
            alpha: v ? 1 : 0,
        });
    }
    _getUpcomingItemObject() {
        return {
            startTime: this.item.data.startDate,
            data: {
                pid: this.item.data.pid,
            },
            isLive: this.item.data.isLive,
            channelId: this.item.data.channelId,
            customerPlayableDate: this.item.data.customerPlayableDate,
            upcomingModal: this.item.data.upcomingModal,
        };
    }
    _handleEnter() {
        let route;
        // @ts-expect-error TODO type this signal adding a LightningJS typeconfig
        this.signal('onClickItem', this.item);
        switch (this.entityType) {
            case ALGOLIA_ENTITY_TYPES.EPISODES:
            case ALGOLIA_ENTITY_TYPES.SEGMENTS:
            case ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS:
                route = `video/${this.item.video.mpxGuid}`;
                break;
            case ALGOLIA_ENTITY_TYPES.SERIES:
            case ALGOLIA_ENTITY_TYPES.SPORTS_SERIES:
                route = `show/${this.item.data.urlAlias}`;
                break;
            case ALGOLIA_ENTITY_TYPES.MOVIES:
                route = `movie/${this.item.data.urlAlias}`;
                break;
            case ALGOLIA_ENTITY_TYPES.REPLAYS:
                route = `watch/${this.item.data.pid}`;
                break;
            case ALGOLIA_ENTITY_TYPES.SLE:
                if (this.item.data.isUpcoming) {
                    navigateUpcomingLiveItems({
                        item: this._getUpcomingItemObject(),
                        fireAncestors: this.fireAncestors.bind(this),
                        preventClickMetric: true,
                    });
                }
                else {
                    route = this.item.data.isLive ? `watch/${this.item.data.pid}` : '';
                }
                break;
            default:
                route = '';
        }
        if (route) {
            Router.navigate(route, { keepAlive: true, preventClickMetric: true });
        }
    }
    get isLocked() {
        var _a, _b, _c;
        return ((_c = (_b = (_a = this.item) === null || _a === void 0 ? void 0 : _a.video) === null || _b === void 0 ? void 0 : _b.authAccess) === null || _c === void 0 ? void 0 : _c.ConnectedTvDevice) || this.item.data.locked;
    }
    get lockContainer() {
        return this.tag('Image');
    }
    get keyBadgePosition() {
        return 460;
    }
}
