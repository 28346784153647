import moment from 'moment-timezone';
import { Language } from '@lightningjs/sdk';
import BaseAllEvents from './BaseAllEvents';
import algolia from '../../api/algolia/algolia';
import assets from '../../api/algolia/spawners/assets';
import { setSmooth } from '../../helpers';
import { COLORS, FONT_FACE, ROUTE, TEXT_ALIGN } from '../../constants';
import { getFERDates } from '../../api/requests/allReplays';
import TVPlatform from '../../lib/tv-platform';
import { ErrorType } from '../../lib/tv-platform/types';
export default class Replays extends BaseAllEvents {
    constructor() {
        super(...arguments);
        this._parentRoute = ROUTE.sports;
        this._pageTitle = Language.translate('replays');
        // @ts-expect-error TS(2416): Property '_assetsFetchFunction' in type 'Replays' ... Remove this comment to see the full error message
        this._assetsFetchFunction = (timeParams = null, page = 0) => {
            this._timeParams = timeParams;
            return algolia.replays
                .getAssets({ timeParams, page })
                .then((v) => {
                this.tag('NotAvailable').visible = !v.assets.length && page === 0;
                return v;
            })
                .catch((err) => {
                TVPlatform.reportError({
                    type: ErrorType.NETWORK,
                    code: 'Replays Page',
                    description: 'Error fetching assets',
                    payload: err,
                });
            });
        };
        // @ts-expect-error TS(2416): Property '_assetsFetchByIndexFunction' in type 'Replays' ... Remove this comment to see the full error message
        this._assetsFetchByIndexFunction = (index, timeParams = null, page = 0) => {
            const pageNumber = Math.ceil((index + 5) / 20);
            const hitsPerPage = pageNumber * 20;
            // Fetch enogh data so previous saved asset will load correctly
            return algolia.replays
                .getAssetsByIndex({ timeParams, page, hitsPerPage })
                .then((v) => {
                this.tag('NotAvailable').visible = !v.assets.length && page === 0;
                return v;
            })
                .catch((err) => {
                TVPlatform.reportError({
                    type: ErrorType.NETWORK,
                    code: 'Replays Page',
                    description: 'Error fetching assets by index',
                    payload: err,
                });
            });
        };
        // @ts-expect-error TS(2416): Property '_datesFetchFunction' in type 'Replays' i... Remove this comment to see the full error message
        this._datesFetchFunction = getFERDates;
    }
    static _template() {
        return super._template({
            NotAvailable: {
                visible: false,
                x: 960,
                y: 600,
                mount: 0.5,
                text: {
                    textColor: COLORS.warmGray,
                    fontFace: FONT_FACE.light,
                    textAlign: TEXT_ALIGN.center,
                    fontSize: 64,
                    lineHeight: 80,
                    text: '',
                },
            },
        });
    }
    _active() {
        this.tag('NotAvailable').text.text = Language.translate('replays_not_available');
    }
    // @ts-expect-error TS(2611): 'dates' is defined as a property in class 'BaseAll... Remove this comment to see the full error message
    set dates(v) {
        var _a, _b, _c;
        if (!((_a = v === null || v === void 0 ? void 0 : v.dates) === null || _a === void 0 ? void 0 : _a.length))
            return;
        this._dates = v === null || v === void 0 ? void 0 : v.dates;
        this._setDatesPage(v);
        const now = moment();
        const today = now.format('YYYY-MM-DD');
        const hasToday = ((_c = (_b = v === null || v === void 0 ? void 0 : v.dates) === null || _b === void 0 ? void 0 : _b[1]) === null || _c === void 0 ? void 0 : _c.value) === today;
        let activeIndex = 0;
        if (!hasToday) {
            this._dates.splice(1, 0, {
                label: Language.translate('today'),
                value: today,
                localStartTimestamp: now.startOf('day').valueOf(),
                localEndTimestamp: now.endOf('day').valueOf(),
            });
        }
        if (this._initialDate)
            activeIndex = this._fillInitialDate();
        this._filters.items = this._createDatesPatchObject(this._dates, activeIndex);
        if (activeIndex !== 0)
            this._filters.selectItem(activeIndex);
        setSmooth(this.widgets.loader, 'visible', 0);
        this._setState('Filters');
    }
    // @ts-expect-error TS(2611): 'apiData' is defined as a property in class 'BaseA... Remove this comment to see the full error message
    set apiData(v) {
        if (!v.assets.length)
            return;
        assets(this.stage, v.assets).then((assets) => {
            this.results = assets;
            this._setResultsPage(v);
        });
    }
    // @ts-expect-error TS(2611): 'indexApiData' is defined as a property in class 'BaseA... Remove this comment to see the full error message
    set indexApiData(v) {
        if (!v.assets.length)
            return;
        assets(this.stage, v.assets).then((assets) => {
            const page = v.assets.length / 20 - 1;
            const pageResults = {
                assets: v.assets,
                page,
                totalAssets: v.totalAssets,
                totalPages: Math.ceil(v.totalAssets / 20),
            };
            this.results = assets;
            // pass new result data based on a normal 20 hits per page call so next call will
            // pull the correct page number
            this._setResultsPage(pageResults);
        });
    }
}
