import { Language, Lightning } from '@lightningjs/sdk';
import ModalManager, { ModalTypes } from '../../../../../src/lib/ModalManager';
import GuideShimmerLoader from '../ChannelsGuide/GuideShimmerLoader';
import Filters from './Filters';
import Content from './Content';
import { COMPONENT_TYPES, FiltersStates } from '../../../../constants';
import { createViewAllCta } from './filtersViewAllCta';
import { RejectedResponse, useRequest } from '../../../../lib/useRequest';
import { SectionsSpawner } from '../../../../api/spawners';
import { FiltersGuideRequestConfig } from './request';
export const FilterDelegateTypes = {
    replayTile: 'replayTileLiveGuide',
};
export default class FiltersGuide extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._goToFullScheduleQrModal = (ctaData) => {
            const { qrModal } = ctaData;
            return () => ModalManager.open(ModalTypes.QR_MODAL, qrModal);
        };
    }
    static _template() {
        return {
            clipping: true,
            w: (w) => w,
            h: 868,
            ShimmerLoader: {
                type: GuideShimmerLoader,
                x: 80,
            },
            FiltersHolder: {
                y: 20,
                w: 376,
                type: Filters,
            },
            ContentHolder: {
                visible: false,
                x: 520,
                y: 20,
                type: Content,
            },
        };
    }
    _init() {
        this._shimmerLoader = this.tag('ShimmerLoader');
        this._filters = this.tag('FiltersHolder');
        this._content = this.tag('ContentHolder');
    }
    set items(items) {
        this._items = items;
        this.loadFilterData();
    }
    get items() {
        return this._items;
    }
    set filters(filters) {
        var _a;
        if (!filters)
            return;
        this._filters.filters = filters;
        (_a = this._filters) === null || _a === void 0 ? void 0 : _a.selectFocused();
        this._shimmerLoader.visible = false;
        this._setState(FiltersStates.Content);
    }
    _getFocused() {
        return this._filters || this;
    }
    resetFilterGuide() {
        var _a, _b;
        (_b = (_a = this._content) === null || _a === void 0 ? void 0 : _a._container) === null || _b === void 0 ? void 0 : _b.setIndex(0);
        this._shimmerLoader.visible = true;
        this._content.visible = false;
    }
    async loadFilterData() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        this.resetFilterGuide();
        try {
            const currentItem = this.items[this._filters.currentItem];
            const currentItemData = currentItem.data;
            const variables = currentItemData.queryVariables;
            const data = await useRequest(FiltersGuideRequestConfig(variables)).fetch();
            const component = data === null || data === void 0 ? void 0 : data.components[0];
            if (component && (component === null || component === void 0 ? void 0 : component.component) === COMPONENT_TYPES.STACK_GROUP) {
                const items = 'items' in component.data && component.data.items;
                const viewAllCtaData = 'viewAllCta' in component.data && ((_b = (_a = component === null || component === void 0 ? void 0 : component.data) === null || _a === void 0 ? void 0 : _a.viewAllCta) === null || _b === void 0 ? void 0 : _b.data);
                if (!items)
                    return;
                const sections = await SectionsSpawner(this.stage, items, FilterDelegateTypes);
                if (!(sections === null || sections === void 0 ? void 0 : sections.length))
                    throw data;
                if (!!viewAllCtaData) {
                    const cta = this.stage.c(this._createViewAllCta(viewAllCtaData));
                    sections.push(cta);
                }
                this._content.sections = sections;
            }
        }
        catch (e) {
            if (e instanceof RejectedResponse) {
                const d = (_d = (_c = e.data) === null || _c === void 0 ? void 0 : _c.components[0]) === null || _d === void 0 ? void 0 : _d.data;
                const title = d ? d.textRow1 : Language.translate('error_filters_title');
                const message = d ? d.textRow2 : Language.translate('error_filters_subtitle');
                const buttonText = d ? (_f = (_e = d.cta) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.text : Language.translate('error_filters_subtitle');
                if (d === null || d === void 0 ? void 0 : d.cta) {
                    this._content._nullStateCallback =
                        ((_h = (_g = e.data) === null || _g === void 0 ? void 0 : _g.shelfName) === null || _h === void 0 ? void 0 : _h.includes('sports')) || ((_k = (_j = d.cta.data) === null || _j === void 0 ? void 0 : _j.destination) === null || _k === void 0 ? void 0 : _k.includes('sports'))
                            ? this.selectFirstFilter.bind(this)
                            : this._goToFullScheduleQrModal(d.cta.data);
                }
                this._content.error = {
                    title: title || '',
                    message: message || '',
                    buttonText: buttonText || '',
                };
            }
            else {
                this._content.error = {
                    title: Language.translate('error_filters_title'),
                    message: Language.translate('error_filters_subtitle'),
                    buttonText: '',
                    hideButton: true,
                };
            }
            this._content.showErrorState();
        }
        this._shimmerLoader.visible = false;
        this._content.visible = true;
    }
    selectFirstFilter() {
        var _a, _b, _c;
        (_a = this._filters) === null || _a === void 0 ? void 0 : _a.selectItem((_b = this._filters) === null || _b === void 0 ? void 0 : _b.initiallySelected);
        (_c = this._filters) === null || _c === void 0 ? void 0 : _c.selectFocused();
        this.loadFilterData();
    }
    _createViewAllCta(ctaData) {
        return createViewAllCta({
            ctaData,
            cb: this._goToFullScheduleQrModal(ctaData),
        });
    }
    static _states() {
        return [
            class FiltersList extends this {
                _getFocused() {
                    return this._filters || this;
                }
                _handleRight() {
                    if (!this._content._errorContainer.hideButton)
                        this._setState(FiltersStates.Content);
                }
                _handleEnter() {
                    this._content._container.setIndex(0);
                    this.loadFilterData();
                }
            },
            class Content extends this {
                _getFocused() {
                    return this.tag('ContentHolder') || this;
                }
                _handleLeft() {
                    this._setState(FiltersStates.FiltersList);
                }
            },
        ];
    }
}
