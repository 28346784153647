import BasePlayer from './BasePlayer';
export default class BaseEventsPlayer extends BasePlayer {
    set params(params) {
        this.pid = params.pid;
    }
    get widgets() {
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        return this.parent.widgets;
    }
    _attach() {
        var _a;
        super._attach();
        this.fireAncestors('$onAttached');
        (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.firePageLoad();
        this._load();
    }
    _setPlayerInActiveTimeout() { }
}
