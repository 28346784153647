import { IPlayerEvent } from './IPlayerEvent';
export class AudioTrackEvent extends IPlayerEvent {
    constructor(trackId) {
        super();
        this._trackId = trackId;
    }
    get trackId() {
        return this._trackId;
    }
}
