import Collection from './Collection';
export default class MarketingModuleSection extends Collection {
    constructor(obj) {
        super(obj);
        this.obj.items = obj;
    }
    get instanceID() {
        return this.obj.marketingModuleData.v4ID;
    }
}
