import { Lightning } from '@lightningjs/sdk';
import { ALIGN_CONTENT, ALIGN_SELF, COLORS, FLEX_DIRECTION, FONT_FACE, TEXT_ALIGN, } from '../../constants';
import TextBorderButton from '../buttons/TextBorderButton';
export default class MessageContainer extends Lightning.Component {
    static _template() {
        return {
            ErrorContainer: {
                y: 0,
                w: 1454,
                flex: { direction: FLEX_DIRECTION.column, alignItems: ALIGN_CONTENT.center },
                Title: {
                    text: {
                        text: this.bindProp('title'),
                        wordWrapWidth: 1080,
                        fontFace: FONT_FACE.light,
                        textAlign: TEXT_ALIGN.center,
                        lineHeight: 72,
                        fontSize: 48,
                        textColor: COLORS.white,
                    },
                },
                Message: {
                    text: {
                        text: this.bindProp('message'),
                        wordWrapWidth: 1080,
                        fontFace: FONT_FACE.light,
                        textAlign: TEXT_ALIGN.center,
                        lineHeight: 72,
                        fontSize: 48,
                        textColor: COLORS.white,
                    },
                },
                Button: {
                    flexItem: { margin: 10, alignSelf: ALIGN_SELF.center, grow: 1 },
                    type: TextBorderButton,
                    label: this.bindProp('buttonText'),
                    radius: 31,
                    fontSize: 32,
                    fontFace: FONT_FACE.regular,
                    focusFontColor: COLORS.black,
                    unfocusFontColor: COLORS.white,
                    focusBackGroundColor: COLORS.white,
                    unfocusBackgroundColor: COLORS.transparent,
                    focusBorderColor: COLORS.white,
                    unfocusBorderColor: COLORS.white,
                    autoWidth: true,
                    h: 63,
                },
            },
        };
    }
    set hideButton(_hideButton) {
        this._hideButton = _hideButton;
        this.tag('Button').visible = !_hideButton;
    }
    get hideButton() {
        return this._hideButton;
    }
    _getFocused() {
        return this.tag('Button');
    }
}
