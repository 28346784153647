import { Lightning } from '@lightningjs/sdk';
import { sendMetric } from '../../lib/analytics/Analytics';
import { EVENTS } from '../../lib/analytics/types';
import { COLORS } from '../../constants';
import LabelsListHorizontal from './strategies/horizontal';
import LabelsListVertical from './strategies/vertical';
import FilterPosition from '../../util/filterPosition/filterPosition';
export var LabelsListRenderingType;
(function (LabelsListRenderingType) {
    LabelsListRenderingType["HORIZONTAL"] = "horizontal";
    LabelsListRenderingType["VERTICAL"] = "vertical";
})(LabelsListRenderingType || (LabelsListRenderingType = {}));
export default class LabelsList extends Lightning.Component {
    constructor() {
        super(...arguments);
        this.radius = 6;
        this._sectionSize = 0;
    }
    static _template() {
        return {
            Holder: {
                y: 0,
                Focus: {
                    rect: true,
                    color: COLORS.white1,
                    alpha: 0,
                    h: 72,
                    shader: {
                        type: Lightning.shaders.RoundedRectangle,
                        radius: this.bindProp('radius'),
                    },
                },
                Items: {},
            },
        };
    }
    set mode(mode) {
        this._strategy =
            mode === LabelsListRenderingType.HORIZONTAL
                ? new LabelsListHorizontal(this, this.sectionSize)
                : new LabelsListVertical(this, this.sectionSize);
    }
    set items(v) {
        var _a, _b, _c;
        this._items = v;
        (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.resetItems();
        this.stage.gc();
        (_b = this._strategy) === null || _b === void 0 ? void 0 : _b.createItems(v);
        if (this._items.findIndex((item) => (item === null || item === void 0 ? void 0 : item.active) === true) ===
            FilterPosition.getPositionForCurrentPage()) {
            (_c = this._strategy) === null || _c === void 0 ? void 0 : _c.moveToIndex(FilterPosition.getPositionForCurrentPage());
        }
    }
    get items() {
        return this._items;
    }
    set sectionSize(sectionSize) {
        this._sectionSize = sectionSize;
    }
    get sectionSize() {
        return this._sectionSize;
    }
    set focusFontFace(font) {
        if (this._strategy)
            this._strategy.fontFace = font;
    }
    set activeIndex(index) {
        if (this._strategy)
            this._strategy.activeIndex = index;
    }
    get activeIndex() {
        var _a;
        return ((_a = this._strategy) === null || _a === void 0 ? void 0 : _a._activeIndex) || 0;
    }
    get focusIndex() {
        var _a;
        return ((_a = this._strategy) === null || _a === void 0 ? void 0 : _a._index) || 0;
    }
    _init() {
        var _a;
        (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.init();
    }
    _focus() {
        var _a;
        (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.enter();
    }
    _unfocus() {
        var _a;
        (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.exit();
    }
    _handleUp() {
        var _a;
        return (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.handleUp();
    }
    _handleDown() {
        var _a;
        return (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.handleDown();
    }
    _handleLeft() {
        var _a;
        return (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.handleLeft();
    }
    _handleRight() {
        var _a;
        return (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.handleRight();
    }
    _handleEnter() {
        this.selectFocused();
        this.trackContentClick();
        return false;
    }
    addItems(items) {
        var _a;
        const offset = this.childList.length;
        (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.createItems(items, offset);
    }
    selectItem(index) {
        var _a;
        (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.moveToIndex(index);
    }
    selectFocused() {
        var _a;
        (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.moveToFocused();
    }
    trackContentClick() {
        var _a, _b;
        const name = (_b = (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.activeItem) === null || _b === void 0 ? void 0 : _b.label;
        sendMetric(EVENTS.CLICK, {
            name,
            shelf: { listTitle: 'None' },
        });
    }
    _getFocused() {
        var _a;
        return ((_a = this._strategy) === null || _a === void 0 ? void 0 : _a.focusItem) || this;
    }
}
