import Item from '../../../items/Item';
import { COLORS, FONT_FACE, VERTICAL_ALIGN } from '../../../../constants';
import { Lightning } from '@lightningjs/sdk';
import { setSmooth } from '../../../../helpers';
const extraMargin = 158;
var Visibility;
(function (Visibility) {
    Visibility[Visibility["INVISIBLE"] = 0] = "INVISIBLE";
    Visibility[Visibility["COMPACTED"] = 1] = "COMPACTED";
})(Visibility || (Visibility = {}));
const VisibilityWidthMap = {
    [Visibility.COMPACTED]: 154,
    [Visibility.INVISIBLE]: 0,
};
const isVisibility = (input) => typeof input === 'number';
export default class GuideTimeSlotHolder extends Item {
    constructor() {
        super(...arguments);
        this._page = 0;
        // #endregion
        this._renderInitialSlots = () => {
            this.children = Array(4)
                .fill(null)
                .map((_, index) => ({
                rect: true,
                w: 200,
                x: 200 * index,
                h: 55,
                color: COLORS.dark7,
                shader: {
                    type: Lightning.shaders.RoundedRectangle,
                    radius: 6,
                },
                Text: {
                    y: 2,
                    text: {
                        text: '',
                        fontFace: FONT_FACE.light,
                        fontSize: 30,
                        lineHeight: 38,
                        verticalAlign: VERTICAL_ALIGN.middle,
                    },
                },
            }));
        };
        this._getSlots = (startIndex) => {
            const slotsPerPage = 3;
            const endIndex = startIndex + slotsPerPage;
            const slots = this._slots.slice(startIndex, endIndex);
            return startIndex === 0 ? [...slots, Visibility.INVISIBLE] : [Visibility.COMPACTED, ...slots];
        };
        this._getWidth = (slot, index) => {
            return slot === 0 ? (index > 0 ? this.itemWidth : this.itemWidth + extraMargin) : this.itemWidth;
        };
        this._renderPage = (slotIndex) => {
            let x = 0;
            const slots = this._getSlots(slotIndex);
            this.children.forEach((child, i) => {
                const current = slots[i] || Visibility.INVISIBLE;
                const width = isVisibility(current)
                    ? VisibilityWidthMap[current]
                    : this._getWidth(slotIndex, i);
                setSmooth(child, 'w', width);
                setSmooth(child, 'x', x);
                child.tag('Text').patch({
                    x: slotIndex === 0 && i === 0 ? 39 : 30,
                    text: {
                        text: typeof current === 'string' ? current : '',
                    },
                });
                x += width + 3;
            });
        };
    }
    static _template() {
        const template = super._template();
        return {
            ...template,
            x: -extraMargin,
            w: 1840,
            h: 212,
            clipping: true,
            Slots: {
                boundsMargin: [500, 100, 500, 100],
            },
        };
    }
    // #region Lifecycle
    _init() {
        this._renderInitialSlots();
    }
    // #endregion
    // #region Getters/setters
    set index(index) {
        this._renderPage(index);
    }
    set slots(v) {
        this._slots = (v || []).map((slot) => slot.toUpperCase());
        this._renderPage(0);
    }
}
