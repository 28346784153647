import { Language, Lightning, Registry, Router, Log } from '@lightningjs/sdk';
import SettingsButton from '../../components/buttons/SettingsButton';
import { COLORS, FLEX_DIRECTION, FONT_FACE } from '../../constants';
import RouterUtil from '../../util/RouterUtil';
import Announcer from '../../lib/tts/Announcer';
const WRAPPER_HEIGHT = 810;
const CONTENT_OFFSET = 80;
const SCROLL_STEP = 200;
class LongText extends Lightning.Component {
    static _template() {
        return {
            x: 80,
            y: 200,
            w: 1760,
            h: WRAPPER_HEIGHT,
            rect: true,
            color: COLORS.dark5,
            clipping: true,
            TextWrapper: {
                x: 120,
                y: CONTENT_OFFSET,
                w: 1400,
                Terms: {
                    w: (w) => w,
                    flex: { direction: FLEX_DIRECTION.column },
                },
            },
        };
    }
    _detach() {
        super._detach();
        if (this._leftOverTimeout)
            Registry.clearTimeout(this._leftOverTimeout);
    }
    _onDataProvided() {
        RouterUtil.setAppState('');
    }
    _firstActive() {
        RouterUtil.setAppState('Loading');
    }
    _init() {
        this.stage.setClearColor(COLORS.dark);
        this._initStartTime = performance.now();
    }
    _focus() {
        var _a;
        this.widgets.menu.collapse({
            subCategoryItem: (_a = this._params) === null || _a === void 0 ? void 0 : _a.subcat,
            hideProfile: true,
        });
    }
    _inactive() {
        this.widgets.menu.expand();
    }
    set apiData(text) {
        this.tag('Terms').children = [];
        this.stage.gc();
        const initText = this._params.initText || '';
        const content = this._translateContentHeading(text);
        this._text = content.split(/[\r\n]/gm).filter(Boolean);
        const CHUNK_SIZE = 200;
        if (this._text.length > CHUNK_SIZE) {
            const halfPage = Math.floor(this._text.length / 2);
            const attachText = this._text.slice(0, halfPage);
            this._leftOverText = this._text.slice(halfPage);
            if (this._leftOverTimeout) {
                Registry.clearTimeout(this._leftOverTimeout);
            }
            this._leftOverTimeout = Registry.setTimeout(() => {
                this._addLeftOverText();
            }, 1000);
            this._renderContent(attachText, initText);
        }
        else {
            this._renderContent(this._text, initText);
            this._setBottomTerms();
        }
        Registry.setTimeout(() => {
            Announcer.announce(this._text);
            if (this._isMaxScroll()) {
                this._setState('BackButton');
            }
        });
    }
    _generateTermsText(text) {
        return text.map((txt) => {
            return {
                w: 1400,
                color: COLORS.white,
                text: {
                    text: txt.replace(/(<([^>]+)>)/gi, '\r \n'),
                    fontSize: 30,
                    lineHeight: 35,
                    textBaseline: 'Top',
                    fontFace: FONT_FACE.light,
                },
            };
        });
    }
    _setBottomTerms() {
        this.tag('Terms').patch({
            BackButton: {
                flexItem: { marginTop: 40, marginBottom: 60 },
                w: 740,
                h: 70,
                type: SettingsButton,
                radius: 0,
                fontSize: 30,
                fontFace: FONT_FACE.light,
                focusFontColor: COLORS.dark,
                unfocusFontColor: COLORS.white,
                focusBackGroundColor: COLORS.lightGray3,
                unfocusBackgroundColor: COLORS.black3,
                label: Language.translate('back'),
                padding: 0,
            },
        });
    }
    _addLeftOverText() {
        const addOnText = this._generateTermsText(this._leftOverText);
        this.tag('Terms').children = [...this.tag('Terms').children, ...addOnText];
        if (this._leftOverTimeout)
            Registry.clearTimeout(this._leftOverTimeout);
    }
    _renderContent(textContent, initText) {
        var _a;
        const initPosition = textContent.indexOf(initText);
        this.tag('Terms').children = this._generateTermsText(textContent);
        this.stage.update();
        const endTime = performance.now();
        const startTime = this._initStartTime || endTime;
        const timeTaken = endTime - startTime;
        Log.info('Performance: Text rendering completed in', timeTaken.toFixed(2), 'ms');
        Log.info('Text elements rendered:', this.tag('Terms').children.length);
        if (initPosition >= 0) {
            const scrollToPosition = (_a = this.tag('Terms').children[initPosition]) === null || _a === void 0 ? void 0 : _a.finalY;
            if (scrollToPosition) {
                this._scrollTo(-scrollToPosition - CONTENT_OFFSET + 20);
            }
        }
    }
    _translateContentHeading(content) {
        if (Language.get() !== 'es') {
            return content;
        }
        return LongText.translatedHeadings.reduce((translatedContent, heading) => {
            if (translatedContent.includes(heading.en)) {
                const translation = Language.translate(heading.es);
                return translatedContent.replace(heading.en, translation);
            }
            return translatedContent;
        }, content);
    }
    _onUrlParams(args) {
        this._params = args;
    }
    _scrollTo(y) {
        if (y !== this.tag('Terms').finalY)
            this.tag('Terms').patch({
                y,
            });
    }
    _handleDown() {
        if (this.tag('Terms').finalH < WRAPPER_HEIGHT + CONTENT_OFFSET)
            return;
        const maxScrollY = this.tag('Terms').finalH + CONTENT_OFFSET - WRAPPER_HEIGHT;
        const isMaxScroll = this._isMaxScroll();
        if (isMaxScroll)
            this._setState('BackButton');
        else
            this._setState('');
        this._scrollTo(Math.floor(isMaxScroll ? -maxScrollY : this.tag('Terms').y - SCROLL_STEP));
    }
    _handleUp() {
        if (Math.floor(this.tag('Terms').finalY) === 0)
            return;
        this._setState('');
        this._scrollTo(this.tag('Terms').y + SCROLL_STEP >= 0 ? 0 : this.tag('Terms').y + SCROLL_STEP);
    }
    _isMaxScroll() {
        return (this.tag('Terms').y - SCROLL_STEP <=
            -(this.tag('Terms').finalH + CONTENT_OFFSET - WRAPPER_HEIGHT));
    }
    static _states() {
        return [
            class BackButton extends this {
                _getFocused() {
                    return this.tag('BackButton') || this;
                }
                _handleEnter() {
                    Router.step(-1);
                }
            }
        ];
    }
}
LongText.translatedHeadings = [
    {
        en: 'Last Updated',
        es: Language.translate('settingsPage-longText-lastUpdated'),
    },
    {
        en: 'Do Not Sell My Personal Information',
        es: Language.translate('settingsPage-longText-doNotSellTitle'),
    },
    {
        en: 'Privacy Center',
        es: Language.translate('settingsPage-longText-privacyCenter'),
    },
];
export default LongText;
