import { Language, Registry } from '@lightningjs/sdk';
import TempPassCTA from '../../../components/player/PlayerControls/TempPassCTA';
import { TempPassSingleton } from '../../../lib/TempPass';
import AuthenticationSingleton, { AuthenticationEvents, } from '../../../authentication/Authentication';
import { activateTempPassRedirect } from '../StreamLoader/actions/redirect';
import { TEMP_PASS_REDIRECT } from '../BasePlayer';
import { StreamAuthorizationType } from '../StreamLoader/request';
import LaunchDarklySingleton from '../../../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import { SCREEN_SIZE, COLORS } from '../../../constants';
import { checkSetMvpdLogo, getRemainingAnnounce, isNBCProfileLinked } from '../../../helpers';
import LinearGradientShader from '../../../shaders/LinearGradientShader';
import { ModalTypes, ModalManagerEventTypes, CloseReason, } from '../../../lib/ModalManager';
import Announcer from '../../../lib/tts/Announcer';
import { ACTIVATION_TYPE } from '../../../widgets/Modals/activation/constants';
import TVPlatform from '../../../lib/tv-platform';
const MVPD_SUCCESS_TIMELIMIT = 5 * 60 * 1000; // 5 min limit
export const WithTempPass = (component) => class extends component {
    constructor() {
        super(...arguments);
        this._startTempPass = () => {
            var _a;
            const { initialTempPassName } = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass);
            (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.updateUserDefaultAttributes(initialTempPassName);
            this._patchTempPassTemplate();
            TempPassSingleton.initSession(AuthenticationSingleton.getMvpdTokenExpiration());
            this._announceTempPass(TempPassSingleton.getDelta(), true);
            this._tempPassPlayerOverlay.update(TempPassSingleton.getDelta());
            this._tempPassSubscription = TempPassSingleton.subscribe(this._onTempPassTick.bind(this));
        };
        this._onTempPassTick = (secondsLeft) => {
            var _a;
            this._tempPassPlayerOverlay.update(secondsLeft);
            // @ts-expect-error - still need a better way to handle the separate controls for sle and fer
            this._controls.updateTempPassCountdown(secondsLeft);
            if (secondsLeft <= 0) {
                (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.updateUserDefaultAttributes('Unauthenticated');
                this.resolveTempPass().then(() => {
                    activateTempPassRedirect((this.identityRequiredForSecondary && isNBCProfileLinked()) ||
                        !this.identityRequiredForSecondary
                        ? TEMP_PASS_REDIRECT.TEMP_PASS_CONCLUDED
                        : TEMP_PASS_REDIRECT.TEMP_PASS_NBC_ACCOUNT);
                });
            }
            this._announceTempPass(secondsLeft);
        };
    }
    _construct() {
        super._construct();
        const { initialTempPassName = '', identityRequiredForSecondary = false } = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass) || {};
        this.initialTempPassName = initialTempPassName;
        this.identityRequiredForSecondary = identityRequiredForSecondary;
    }
    async _detach() {
        super._detach();
        if (this._tempPassSubscription) {
            this._tempPassSubscription.unsubscribe();
            TempPassSingleton.endSession();
        }
        if (this === null || this === void 0 ? void 0 : this._authnTimeout)
            Registry.clearTimeout(this._authnTimeout);
    }
    _handleBack(e) {
        this.resolveTempPass().then(() => {
            super._handleBack(e);
        });
    }
    _closeMediaPlayer(forceExit = false) {
        this.resolveTempPass().then(() => {
            super._closeMediaPlayer(forceExit);
        });
    }
    async resolveTempPass() {
        // If we have a temp pass overlay, logout
        return AuthenticationSingleton.isMvpdTempPass()
            ? await AuthenticationSingleton.logout()
            : Promise.resolve({});
    }
    async _startStream() {
        if (this.authtype === StreamAuthorizationType.TEMP_PASS) {
            this._startTempPass();
        }
        await super._startStream();
    }
    _patchTempPassTemplate() {
        if (TVPlatform.getAllowLinearGradient()) {
            this.patch({
                TempPassBg: {
                    w: SCREEN_SIZE.width,
                    h: 300,
                    rect: true,
                    shader: {
                        type: LinearGradientShader,
                        colors: [COLORS.black2, COLORS.transparent],
                        stops: [1.0, 0.0],
                        angle: 0,
                    },
                },
            });
        }
        this.patch({
            TempPass: {
                type: TempPassCTA,
            },
        });
        this._tempPassPlayerOverlay = this.tag('TempPass');
        this._tempPassPlayerOverlayBg = this.tag('TempPassBg');
        // @ts-expect-error - still need a better way to handle the separate controls for sle and fer
        this._controls.patchTempPassTemplate();
    }
    _announceTempPass(secondsLeft, force = false) {
        // Announce TempPass remaining time on 5min/30sec or force when player loading
        if ([300, 30].includes(secondsLeft) || force) {
            Announcer.announce([
                Language.translate('tempPass-expires-in'),
                getRemainingAnnounce(secondsLeft),
            ]);
        }
    }
    _hideTempPass() {
        var _a, _b, _c;
        (_a = this._tempPassPlayerOverlay) === null || _a === void 0 ? void 0 : _a.patch({ alpha: 0 });
        (_b = this._tempPassPlayerOverlayBg) === null || _b === void 0 ? void 0 : _b.patch({ alpha: 0 });
        (_c = this._controls.tag('TempPass')) === null || _c === void 0 ? void 0 : _c.patch({ alpha: 0 });
    }
    _checkMvpdSuccess() {
        var _a;
        (_a = this._authnSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._authnSubscription = AuthenticationEvents.subscribe((type) => {
            var _a;
            if (type === ACTIVATION_TYPE.MVPD && this) {
                (_a = this._tempPassSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
                this._hideTempPass();
                // Update the mvpd logo inside the control component when mvpd is connected
                checkSetMvpdLogo(this._controls);
                this._authnSubscription.unsubscribe();
                this.resolveTempPass();
                Registry.clearTimeout(this._authnTimeout);
            }
            else {
                this._checkMvpdSuccess();
            }
        });
        AuthenticationEvents.pollAdobe();
    }
    _modalOpen() {
        var _a, _b;
        (_a = this._tempPassSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        (_b = this._player) === null || _b === void 0 ? void 0 : _b.clearPreviousSession();
        this._endMediaSession();
        this._checkMvpdSuccess();
        this._authnTimeout = Registry.setTimeout(() => {
            this === null || this === void 0 ? void 0 : this._authnSubscription.unsubscribe();
        }, MVPD_SUCCESS_TIMELIMIT);
    }
    _modalClose(e) {
        var _a, _b, _c, _d;
        if (e.payload === CloseReason.DEFAULT && e.type === ModalTypes.TEMPPASS_CONCLUDED) {
            this._handleBack({});
        }
        else if (e.payload === CloseReason.DEFAULT ||
            (e.payload === CloseReason.CANCELLED && ((_a = this._controls) === null || _a === void 0 ? void 0 : _a.tempPassCtaClicked))) {
            if ((_b = this._params) === null || _b === void 0 ? void 0 : _b.preauth)
                this._params.preauth = false;
            (_c = this._controls.tag('TempPass')) === null || _c === void 0 ? void 0 : _c.patch({ alpha: 0 });
            this._load();
            if ((_d = this._controls) === null || _d === void 0 ? void 0 : _d.tempPassCtaClicked)
                this._controls.tempPassCtaClicked = false;
        }
    }
    _onModalEvent(e) {
        if ([
            ModalTypes.ACTIVATION,
            ModalTypes.ACTIVATION_TEMPPASS_MVPD,
            ModalTypes.ACTIVATION_TEMPPASS_NBC,
            ModalTypes.ACTIVATION_TEMPPASS_NBC,
            ModalTypes.TEMPPASS_CONCLUDED,
        ].includes(e.type)) {
            if (e.event === ModalManagerEventTypes.OPEN) {
                this._modalOpen();
            }
            else if (e.event === ModalManagerEventTypes.CLOSE) {
                this._modalClose(e);
            }
        }
        super._onModalEvent(e);
    }
};
