import { Router } from '@lightningjs/sdk';
import { CONTENT_TYPE, ROUTE } from '../../../constants';
import { pipeSeparate } from '../../../helpers';
export default (tile) => {
    var _a;
    if (tile._item.contentType === CONTENT_TYPE.SLE) {
        const isOlympicsRoute = [ROUTE.olympics, ROUTE.paralympics].includes(Router.getActiveRoute());
        if (tile._item.isOlympics && isOlympicsRoute) {
            tile.videoTitle = tile._item.title;
            if (tile._item.secondaryTitle)
                tile.secondaryTitle = pipeSeparate(tile._item.secondaryTitle);
        }
        else {
            tile.videoTitle = (_a = tile._item.secondaryTitle) !== null && _a !== void 0 ? _a : '';
            if (tile._item.description)
                tile.secondaryTitle = pipeSeparate(tile._item.description);
        }
    }
    else {
        tile.videoTitle = tile._item.videoTitle || tile._item.title || '';
        if (tile._item.secondaryTitle)
            tile.secondaryTitle = pipeSeparate(tile._item.secondaryTitle);
    }
};
