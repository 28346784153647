import { ENV, getBrandName, isDevelopmentMode } from '../../../helpers';
const mParticleConfig = (callback) => {
    const setup = {
        eCommerce: { Cart: {} },
        Identity: {},
        config: {
            rq: [],
            isDevelopmentMode: NODE_ENV === ENV.STAGE,
            forceHttps: true,
            identityRequest: {
                userIdentities: {},
            },
            identityCallback: callback,
            dataPlan: {
                planId: 'nbcu_data_plan',
                planVersion: 17,
            },
            appName: `${getBrandName().toUpperCase()} ${isDevelopmentMode() ? 'QA' : 'Prod'}`,
            useCookieStorage: false,
        },
        // @ts-expect-error TS(2345): Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
        ready: (f) => setup.config.rq.push(f),
    };
    return setup;
};
export default mParticleConfig;
