import { CoreVideoPlayer } from '../CoreVideoPlayer/CoreVideoPlayer';
import { PlayerMode } from './PlayerInterface';
export var PLAYER_TYPE;
(function (PLAYER_TYPE) {
    PLAYER_TYPE["MAIN"] = "main";
    PLAYER_TYPE["PREVIEW"] = "preview";
    PLAYER_TYPE["BACKGROUND"] = "background";
})(PLAYER_TYPE || (PLAYER_TYPE = {}));
export class PlayerFactory {
    constructor() {
        this._players = {};
    }
    player(type, options) {
        if (!type) {
            throw new Error('Player Factory:: Player type must be specified');
        }
        if (!Object.values(PLAYER_TYPE).includes(type)) {
            throw new Error(`Player Factory:: Invalid player type ${type}`);
        }
        if (this._players[type]) {
            return this._players[type];
        }
        //We don't have it so create it
        this._players[type] = new CoreVideoPlayer(type === PLAYER_TYPE.MAIN ? PlayerMode.FULL : PlayerMode.MINIMAL, options);
        return this._players[type];
    }
    getPlayerIfActive(type) {
        return this._players[type];
    }
    clearPlayer(type) {
        var _a;
        if (this._players[type]) {
            (_a = this._players[type]) === null || _a === void 0 ? void 0 : _a._detach();
            this._players[type] = null;
        }
    }
}
export const PlayerFactorySingleton = new PlayerFactory();
