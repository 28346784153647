import { Registry } from '@lightningjs/sdk';
import { toEnterPauseAdsPatch, toExitPauseAdsPatch, toPauseAdOverlay, getPauseAdImageTexture, } from './pauseAdHelpers';
import { PlayerControlsStates } from '../../../../constants';
import TVPlatform from '../../../../lib/tv-platform';
import { ErrorType } from '../../../../lib/tv-platform/types';
export const PauseAdControlsStateFactory = (base) => class PauseAdControls extends base {
    constructor() {
        super(...arguments);
        this._adPlayedCount = 0;
    }
    $enter() {
        try {
            // Adjust UI to handle Pause Ads layout
            this.patch(toEnterPauseAdsPatch());
            this._pauseAdImage = this.tag('PauseAdImage');
            this._pauseAdImage.once('txLoaded', () => this.patch(toPauseAdOverlay()));
            // begin the process of setting up ad placement and timer for rotating ads
            this.setupAdPlacement();
        }
        catch (e) {
            TVPlatform.reportError({
                type: ErrorType.OTHER,
                description: `PauseAdControls error $enter: ${e}`,
            });
        }
    }
    $exit() {
        try {
            // This triggers nothing yet but we may end up needing it
            this.fireAncestors('$PauseAdsEnd');
            this.destroy();
        }
        catch (e) {
            TVPlatform.reportError({
                type: ErrorType.OTHER,
                description: `PauseAdControls error $exit: ${e}`,
            });
        }
    }
    // listen for any key press
    _handleKey() {
        var _a, _b;
        // Make sure we fire the ended event
        (_a = this._currentAd) === null || _a === void 0 ? void 0 : _a.ended();
        (_b = this.clearPauseAdsEventData) === null || _b === void 0 ? void 0 : _b.call(this);
        // Return ui to non pause ad ui
        this.patch(toExitPauseAdsPatch());
        this._setState(PlayerControlsStates.PlayOrPause);
    }
    _captureBack() {
        this._handleKey();
    }
    setupAdPlacement() {
        // Make sure we start at 0 played count
        this._adPlayedCount = 0;
        // begin updating ad placemen
        this.updatePlacement();
    }
    updatePlacement() {
        var _a, _b, _c, _d, _e, _f, _g;
        const newAd = (_b = (_a = this.pauseAdEventData) === null || _a === void 0 ? void 0 : _a.consume) === null || _b === void 0 ? void 0 : _b.call(_a);
        // Early return if ad is undefined
        if (!newAd)
            return this._exitIfEmpty();
        // If ad is not undefined set it to current
        this._currentAd = newAd;
        // comes from LD flag, how many times should we show a new ad
        const { refreshMax } = this.pauseAdRefreshData;
        // update count
        this._adPlayedCount += 1;
        const limitIsNotReached = this._adPlayedCount <= refreshMax;
        if ((_d = (_c = this._currentAd) === null || _c === void 0 ? void 0 : _c.variants) === null || _d === void 0 ? void 0 : _d.length) {
            this._renderAd((_f = (_e = this._currentAd.variants) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.resourceUri);
            // If we haven't hit the max refresh count
            if (limitIsNotReached)
                this.startRefresh();
        }
        else {
            // Call ended(), even if the variants are empty, and then update the ad
            (_g = this._currentAd) === null || _g === void 0 ? void 0 : _g.ended();
            // If the limit is reached, try to exit the state
            if (!limitIsNotReached)
                return this._exitIfEmpty();
            this.updatePlacement();
        }
    }
    _renderAd(resourceUri) {
        var _a, _b;
        if (resourceUri) {
            // Patch with latest img from current variant
            this._pauseAdImage.patch(getPauseAdImageTexture(resourceUri));
        }
        // Call the shown method when the ad is rendered
        (_b = (_a = this._currentAd) === null || _a === void 0 ? void 0 : _a.shown) === null || _b === void 0 ? void 0 : _b.call(_a);
    }
    _exitIfEmpty() {
        if (!this._pauseAdImage.texture)
            this._handleKey();
    }
    startRefresh() {
        const { refreshRate } = this.pauseAdRefreshData;
        // Set timeout with LD refreshRate for pause ad,
        this._timeout = Registry.setTimeout(() => {
            var _a;
            /**
             * Calling the ended() function before switching to the next ad
             */
            (_a = this._currentAd) === null || _a === void 0 ? void 0 : _a.ended();
            this.updatePlacement();
        }, refreshRate * 1000);
    }
    destroy() {
        var _a;
        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
        }
        (_a = this._currentAd) === null || _a === void 0 ? void 0 : _a.ended();
        this._timeout = null;
        this._adPlayedCount = 0;
    }
};
