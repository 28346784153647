import { Lightning } from '@lightningjs/sdk';
import Item from './Item';
import { COLORS } from '../../constants';
// @ts-expect-error TS(2417): Class static side 'typeof TrickPlayImageTile' inco... Remove this comment to see the full error message
export default class TrickPlayImageTile extends Lightning.Component {
    static _template() {
        const duration = Item.duration;
        const timingFunction = Item.timingFunction;
        return {
            ThumbNailHolder: {
                w: 275,
                h: 141,
                rect: true,
                colorLeft: COLORS.brightGrey,
                colorRight: COLORS.thunder2,
                ThumbNail: {
                    w: (w) => w,
                    h: (h) => h,
                    src: '',
                },
            },
            FocusBorder: {
                x: -1,
                y: -1,
                texture: Lightning.Tools.getRoundRect(275, 141, 0, 2, COLORS.lightGray9, false),
                alpha: 0,
                transitions: {
                    alpha: { duration, timingFunction },
                },
            },
            transitions: {
                x: { duration, timingFunction },
                y: { duration, timingFunction },
                scale: { duration, timingFunction },
                scaleX: { duration, timingFunction },
                scaleY: { duration, timingFunction },
                zIndex: { duration, timingFunction },
                alpha: { duration, timingFunction },
            },
        };
    }
    set item(v) {
        if (v && v.src) {
            this.patch({
                ThumbNailHolder: {
                    ThumbNail: {
                        src: v.src,
                    },
                },
            });
        }
        else {
            this.patch({
                ThumbNailHolder: {
                    alpha: 0,
                },
                FocusBorder: {
                    alpha: 0,
                },
            });
        }
    }
    _focus() {
        this.setSmooth('scaleX', 1.15);
        this.setSmooth('scaleY', 1.3);
        this.setSmooth('x', -20);
        this.setSmooth('y', -21);
        this.setSmooth('zIndex', 100);
        this.tag('FocusBorder').setSmooth('alpha', 1);
    }
    _unfocus() {
        this.setSmooth('scale', 1);
        this.setSmooth('x', 0);
        this.setSmooth('y', 0);
        this.setSmooth('zIndex', 1);
        this.tag('FocusBorder').setSmooth('alpha', 0);
    }
    static get tag() {
        return 'TrickPlayImageTile';
    }
}
