import { GraphQlClient } from '../../graphql/client';
import paginatedShelf from '../../graphql/mappers/paginatedShelf';
import rsnFilter from '../../graphql/filter/rsnFilter';
import { QueryName } from './bffQuery';
export default async (variables) => {
    return await GraphQlClient.query({
        query: QueryName.paginatedShelf,
        variables,
    })
        .then(paginatedShelf)
        .then(rsnFilter);
};
