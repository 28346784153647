import ShimmerLoader from '../../../items/ShimmerLoader';
export default class GuideShimmerLoader extends ShimmerLoader {
    _setup() {
        super._setup();
        const headerHeight = 55;
        const rowHeight = 190;
        const module1Width = 558;
        const header = [
            { w: 720, h: headerHeight },
            { w: module1Width, h: headerHeight },
            { w: module1Width, h: headerHeight },
        ];
        const channelBlock = { w: 154, h: rowHeight };
        const rowVariation1 = [
            channelBlock,
            { w: 564, h: rowHeight },
            { w: 1118, h: rowHeight },
        ];
        const rowVariation2 = [
            channelBlock,
            { w: 1124, h: rowHeight },
            { w: module1Width, h: rowHeight },
        ];
        this.item = [header, rowVariation1, rowVariation1, rowVariation2, rowVariation2];
    }
}
