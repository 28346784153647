import { Lightning, Router } from '@lightningjs/sdk';
import { setSmooth } from '../../helpers';
import { SectionsSpawner } from '../../api/spawners';
import { COLORS } from '../../constants';
import { useRequest } from '../../lib/useRequest';
import { NetworksPageRequestConfig } from './request';
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError';
class Networks extends Lightning.Component {
    static _template() {
        return {
            Networks: {},
        };
    }
    _init() {
        this.stage.setClearColor(COLORS.dark);
        this.widgets.loader.alpha = 1;
    }
    set networksData(v) {
        v.x = 75;
        v.y = 180;
        this.patch({
            Networks: v,
        });
    }
    async load() {
        try {
            const data = (await useRequest(NetworksPageRequestConfig()).fetch());
            const networksSections = await SectionsSpawner(this.stage, data.sections);
            this.networksData = networksSections === null || networksSections === void 0 ? void 0 : networksSections[0];
            setSmooth(this.widgets.loader, 'visible', 0);
            this._setState('NetworksList');
        }
        catch (e) {
            this._setState(WithRequestErrorState);
        }
    }
    static _states() {
        return [
            class NetworksList extends this {
                _handleUp() {
                    Router.focusWidget('Menu');
                }
                _captureBack(e) {
                    if (this.tag('Networks').index === 0) {
                        this.widgets.menu.setSmooth('alpha', 1);
                        Router.focusWidget('Menu');
                    }
                    else {
                        this.tag('Networks').setIndex(0);
                    }
                    e.preventDefault();
                    e.stopPropagation();
                }
                _getFocused() {
                    return this.tag('Networks') || this;
                }
                $scrolledRow(rowId) {
                    this.widgets.menu.visible = rowId < 2;
                }
            },
        ];
    }
}
export default WithRequestError(Networks);
