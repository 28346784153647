import { liveBadgeDecorator, liveProgressDecorator } from '../../decorators';
export const getLiveStateClass = (context) => class LiveState extends context {
    $enter() {
        this._removeBadge();
        if (this._item.liveCtaText)
            this.buttonText = this._item.liveCtaText;
        this._addDecorators([
            liveProgressDecorator(420),
            liveBadgeDecorator(this._item.liveBadge, 357),
        ]);
    }
};
