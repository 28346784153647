import { Lightning } from '@lightningjs/sdk';
import Styler from '../lib/Styler';
export default class BaseCollection extends Lightning.Component {
    create(items, index) { }
    _getFocused() {
        return this.activeItemWrapper || this;
    }
    get activeItemWrapper() {
        return this._getItem(this._index);
    }
    get items() {
        return this._items;
    }
    get index() {
        return this._index;
    }
    get noOfItems() {
        return this.items.length;
    }
    set itemType(v) {
        this._itemType = v;
    }
    set ariaLabel(ariaLabel) {
        this.announce = ariaLabel;
    }
    _getItem(index = 0) {
        return this.tag('Items').children[index];
    }
    getChildItem(index) {
        var _a;
        return (_a = this._getItem(index)) === null || _a === void 0 ? void 0 : _a.child;
    }
    setChildPropsForIndex(index, props) {
        var _a, _b;
        return (_b = (_a = this._getItem(index)) === null || _a === void 0 ? void 0 : _a.setChildProps) === null || _b === void 0 ? void 0 : _b.call(_a, props);
    }
    getTag(child) {
        const tags = child.getTags();
        if (!tags.length) {
            throw new Error(`Specify a 'tag' for: ${child}`);
        }
        return tags[0];
    }
    getStyle() {
        const tag = this.getTag(this);
        return Styler.getTag(tag);
    }
    addItems(items) {
        const initialIndex = this._items.length;
        this._items = [...this._items, ...items];
        this.tag('Items').childList.a(this.create({ items }, initialIndex));
    }
}
