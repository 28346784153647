import componentTypes from '../../types/componentTypes';
import Tiles from '../tiles';
import Collection from './Collection';
export default class Grid extends Collection {
    mapItems(items) {
        return (items
            .filter((item) => item != null && Object.values(componentTypes).indexOf(item.component) > -1)
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            .map((item) => new Tiles[item.component](item)));
    }
}
