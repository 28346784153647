export default class UpcomingModal {
    constructor(obj, isOlympics) {
        this.isOlympics = false;
        this._analytics = (obj === null || obj === void 0 ? void 0 : obj.analytics) || {};
        this.data = (obj === null || obj === void 0 ? void 0 : obj.data) || {};
        this.isOlympics = isOlympics;
    }
    get backgroundImage() {
        return this.data.backgroundImage;
    }
    get channelId() {
        return this.data.channelId;
    }
    get ctaText() {
        return this.data.ctaText;
    }
    get description() {
        return this.data.description;
    }
    get dismissText() {
        return this.data.dismissText;
    }
    get title() {
        return this.data.title;
    }
    get startTime() {
        return this.data.startTime;
    }
    get modalName() {
        return this._analytics.modalName;
    }
    get modalType() {
        return this._analytics.modalType;
    }
    get countdownDayLabel() {
        return this.data.countdownDayLabel;
    }
    get countdownHourLabel() {
        return this.data.countdownHourLabel;
    }
    get countdownMinLabel() {
        return this.data.countdownMinLabel;
    }
    get customerPlayableDate() {
        return this.data.customerPlayableDate;
    }
    get pid() {
        return this.data.pid;
    }
}
