import { PlayerError } from '../../../components/error/PlayerError';
export var PlayerStoreActions;
(function (PlayerStoreActions) {
    PlayerStoreActions["SET_GEO"] = "setGeo";
    PlayerStoreActions["SET_EPG"] = "setEpg";
    PlayerStoreActions["SET_SINGLE_STREAM"] = "setSingleStream";
    PlayerStoreActions["UPDATE_EPG_CHANNEL_INDEX"] = "updateEpgChannel";
    PlayerStoreActions["NEXT_EPG_SCHEDULE_INDEX"] = "nextEpgScheduleIndex";
    PlayerStoreActions["UPDATE_STREAM"] = "updateStream";
    PlayerStoreActions["UPDATE_PROGRAM"] = "updateProgram";
    PlayerStoreActions["ERROR"] = "error";
    PlayerStoreActions["STREAM_ERROR"] = "streamError";
    PlayerStoreActions["SET_LEMONADE"] = "setLemonade";
    PlayerStoreActions["CLEAR_LEMONADE"] = "clearLemonade";
    PlayerStoreActions["SET_PRE_CHECK_DATA"] = "setPreCheckData";
    PlayerStoreActions["CLEAR_PRE_CHECK_DATA"] = "clearPreCheckData";
    PlayerStoreActions["CLEAR_STATE"] = "clearState";
    PlayerStoreActions["SET_PRE_CHECK_LEMONADE"] = "setPreCheckLemonade";
    PlayerStoreActions["CLEAR_PRE_CHECK_LEMONADE"] = "clearPreCheckLemonade";
})(PlayerStoreActions || (PlayerStoreActions = {}));
export const findCoreSDKPlayerComponent = (sections) => {
    const coreSdkSection = sections === null || sections === void 0 ? void 0 : sections.find((e) => e && 'component' in e && e.component === 'CoreSDKPlayer');
    return coreSdkSection || undefined;
};
export const findLinkSelectableComponent = (sections) => {
    return sections === null || sections === void 0 ? void 0 : sections.find((e) => e && 'component' in e && (e === null || e === void 0 ? void 0 : e.component) === 'LinksSelectableGroup');
};
export const findGridComponent = (sections) => {
    return sections === null || sections === void 0 ? void 0 : sections.find((e) => e && 'component' in e && (e === null || e === void 0 ? void 0 : e.component) === 'Grid');
};
export const findMessageComponent = (sections) => {
    return sections === null || sections === void 0 ? void 0 : sections.find((e) => e && 'component' in e && (e === null || e === void 0 ? void 0 : e.component) === 'Message');
};
export const findPlaceholderInLinkSelectable = (sections) => {
    var _a;
    const LinkSelectableGroup = findLinkSelectableComponent(sections);
    if (LinkSelectableGroup) {
        return (_a = LinkSelectableGroup.data.items.find((e) => (e === null || e === void 0 ? void 0 : e.component) === 'Placeholder').data) === null || _a === void 0 ? void 0 : _a.queryVariables;
    }
};
export const isSingleProgram = (input) => input && ('pid' in input || 'callSign' in input);
export const isSingleStream = (input) => input && 'airDate' in input;
export const setStream = (stream, program) => ({
    type: PlayerStoreActions.SET_SINGLE_STREAM,
    payload: { stream, program },
});
export const clearPreCheckData = () => ({
    type: PlayerStoreActions.CLEAR_PRE_CHECK_DATA,
    payload: {},
});
export const clearPreCheckLemonade = () => ({
    type: PlayerStoreActions.CLEAR_PRE_CHECK_LEMONADE,
    payload: {},
});
export const setPreCheckData = (payload) => {
    const { data } = payload;
    const streamData = data && findCoreSDKPlayerComponent(data.sections);
    const stream = streamData.CoreSDKPlayerData.player;
    const program = streamData.analytics;
    return {
        type: PlayerStoreActions.SET_PRE_CHECK_DATA,
        payload: { stream, program },
    };
};
export const setStreamData = (data) => async () => {
    var _a;
    const stream = data && findCoreSDKPlayerComponent(data.sections);
    if (((_a = stream === null || stream === void 0 ? void 0 : stream.analytics) === null || _a === void 0 ? void 0 : _a.pid) === 0) {
        stream === null || stream === void 0 ? true : delete stream.analytics.pid;
    }
    return stream ? setStream(stream.CoreSDKPlayerData.player, stream.analytics) : setUnknownError();
};
export const clearState = () => ({ type: PlayerStoreActions.CLEAR_STATE });
export const clearLemonade = () => ({ type: PlayerStoreActions.CLEAR_LEMONADE });
export const updateStream = (payload) => ({
    type: PlayerStoreActions.UPDATE_STREAM,
    payload,
});
export const updateProgram = (payload) => ({
    type: PlayerStoreActions.UPDATE_PROGRAM,
    payload,
});
export const setUnknownError = () => ({
    type: PlayerStoreActions.ERROR,
    payload: {
        type: PlayerError.UNKNOWN,
        description: 'Unable to find stream',
        code: '404',
    },
});
export const setStreamError = () => ({
    type: PlayerStoreActions.STREAM_ERROR,
    payload: {
        type: PlayerError.UNKNOWN,
        description: 'Unable to find stream',
        code: '404',
    },
});
export const throwStoreError = (action) => ({
    type: PlayerStoreActions.ERROR,
    payload: action,
});
