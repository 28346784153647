var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
import { Router, Log } from '@lightningjs/sdk';
import mParticle from '@mparticle/web-sdk';
import { get } from 'lodash';
import moment from 'moment-timezone';
import { events } from './events';
import trackEvent from './trackEvent';
import { EVENTS } from '../types';
import getLiveStartupAttributes from './attributes/getLiveStartupAttributes';
import { StopWatch } from '../../../util/StopWatch';
import getSearchResultsAttributes from './attributes/getSearchResultsAttributes';
import { getFirstRun, saveFirstRun } from '../firstRun';
import getDefaultUserAttributes from './attributes/getDefaultUserAttributes';
import AppConfigFactorySingleton from '../../../config/AppConfigFactory';
import { getCookieNamesByPrefix, removeCookie } from '../../../helpers';
import mParticleConfig from './mParticleConfig';
const USER_READY_TIMEOUT = 1000;
const USER_READY_MAX_ATTEMPTS = 10;
const MPARTICLE_COOKIE_PREFIX = 'mprtcl-';
export const MPARTICLE_TAG = '[mParticle]';
class mParticleInterface {
    constructor() {
        this.videoID = null;
        this.videoType = null;
        this.firstVisit = 'False';
        this._previousVideo = '';
        this._previousVideoType = '';
        this._adStartTime = 0;
        this.removeBrandCookies = () => {
            var _10;
            // mParticle stores a lot of data, apps crash when more than 3 mParticle cookies exist.
            // remove any cookies associated with other brands.
            // @ts-expect-error TODO: Store is not a valid property in mParticle object according to type definition
            const storageName = (_10 = mParticle === null || mParticle === void 0 ? void 0 : mParticle.Store) === null || _10 === void 0 ? void 0 : _10.storageName;
            const mParticleCookies = getCookieNamesByPrefix(MPARTICLE_COOKIE_PREFIX);
            if (mParticleCookies.length) {
                mParticleCookies.forEach((ck) => {
                    if (ck !== storageName) {
                        Log.info(MPARTICLE_TAG, `removing cookie associated with another brand: ${ck}`);
                        removeCookie(ck, { domain: this.getMparticleCookieDomain() });
                    }
                });
            }
        };
        this.addProps = (add, source) => (prop) => (o) => {
            if (prop) {
                source[prop];
            }
            else {
                source[o] = add(o, prop);
            }
        };
        this.addEvent = (event, params = {}, type) => {
            trackEvent(events[event](type), {
                ...params,
                firstVisit: (params === null || params === void 0 ? void 0 : params.firstVisit) || this.firstVisit,
            });
            Log.info(`mParticle event ${event}`, params);
        };
        this.getMparticleDomain = (n, t) => {
            for (let r, u = 'mptest=cookie', f = t.split('.'), i = f.length - 1; i >= 0; i--)
                if (((r = f.slice(i).join('.')),
                    (n.cookie = u + ';domain=.' + r + ';'),
                    n.cookie.indexOf(u) > -1))
                    return ((n.cookie =
                        u.split('=')[0] + '=;domain=.' + r + ';expires=Thu, 01 Jan 1970 00:00:01 GMT;'),
                        r);
            return '';
        };
        this.getMparticleCookieDomain = () => {
            const i = this.getMparticleDomain(document, location.hostname);
            return i === '' ? '' : '.' + i;
        };
        // #region Events
        // General
        this[_a] = () => {
            this.setDefaultUserAttributes();
            this.addEvent(EVENTS.LAUNCH);
        };
        this[_b] = (metadata) => {
            this.addEvent(EVENTS.ERROR, metadata);
        };
        this[_c] = (params) => {
            this.addEvent(EVENTS.EXIT, params);
        };
        // Ads
        this[_d] = (video, adBreak, isLive) => {
            const type = isLive ? 'Linear Ad Pod Start' : 'Ad Pod Start';
            this.addEvent(EVENTS.AD_BREAK_START, {
                video,
                adBreak,
                previousVideo: this._previousVideo,
                previousVideoType: this._previousVideoType,
                isLive,
            }, type);
        };
        this[_e] = (video, adBreak, isLive) => {
            const type = isLive ? 'Linear Ad Pod End' : 'Ad Pod End';
            this.addEvent(EVENTS.AD_BREAK_END, {
                video,
                adBreak,
                previousVideo: this._previousVideo,
                previousVideoType: this._previousVideoType,
                isLive,
            }, type);
        };
        this[_f] = (video, ad, isLive) => {
            const type = isLive ? 'Linear Ad Start' : 'Ad Start';
            this._adStartTime = Date.now();
            this.addEvent(EVENTS.AD_START, {
                video,
                ad,
                previousVideo: this._previousVideo,
                previousVideoType: this._previousVideoType,
                isLive,
            }, type);
        };
        this[_g] = (video, ad, isLive) => {
            const type = isLive ? 'Linear Ad End' : 'Ad End';
            const adSecondsWatched = Math.round(Date.now() / 1000 - this._adStartTime / 1000);
            this._adStartTime = 0;
            this.addEvent(EVENTS.AD_END, {
                video,
                ad,
                previousVideo: this._previousVideo,
                previousVideoType: this._previousVideoType,
                isLive,
                adSecondsWatched,
            }, type);
        };
        // Live
        this[_h] = (metadata, previousMetadata) => {
            this._previousVideo =
                get(previousMetadata, 'tmsId') ||
                    get(previousMetadata, 'mpxGuid') ||
                    get(previousMetadata, 'pid', 'None');
            this._previousVideoType = 'Live';
            this.addEvent(EVENTS.PROGRAM_END, previousMetadata);
            this.addEvent(EVENTS.PROGRAM_START, metadata);
        };
        this[_j] = () => {
            // For measuring video start time performance
            this._playerLoadStopWatch = new StopWatch();
            this._playerLoadStopWatch.start();
        };
        this[_k] = (metadata) => {
            if (this._previousVideoType && this._previousVideoType !== 'Live') {
                // Clear previous VOD asset.
                this._previousVideo = '';
                this._previousVideoType = '';
            }
            this.addEvent(EVENTS.LIVE_SESSION_START, metadata);
            this.addEvent(EVENTS.PROGRAM_START, metadata);
        };
        this[_l] = (metadata) => {
            this._previousVideo =
                get(metadata, 'tmsId') || get(metadata, 'mpxGuid') || get(metadata, 'pid', 'None');
            this._previousVideoType = 'Live';
            this.addEvent(EVENTS.PROGRAM_END, metadata);
            this.addEvent(EVENTS.LIVE_SESSION_END, metadata);
        };
        // VOD
        this[_m] = (metadata) => {
            if (this._previousVideoType === 'Live') {
                // Clear previous Live asset.
                this._previousVideo = '';
                this._previousVideoType = '';
            }
            this.addEvent(EVENTS.VOD_SESSION_START, {
                ...metadata,
                previousVideo: this._previousVideo,
                previousVideoType: this._previousVideoType,
            });
        };
        this[_o] = (metadata) => {
            var _10, _11;
            this.addEvent(EVENTS.VOD_SESSION_END, {
                ...metadata,
                previousVideo: this._previousVideo,
                previousVideoType: this._previousVideoType,
            });
            this._previousVideo = (metadata === null || metadata === void 0 ? void 0 : metadata.mpxGuid) || ((_10 = metadata.video) === null || _10 === void 0 ? void 0 : _10.mpxGuid);
            this._previousVideoType = (metadata === null || metadata === void 0 ? void 0 : metadata.programmingType) || ((_11 = metadata.video) === null || _11 === void 0 ? void 0 : _11.programmingType);
        };
        // Player
        this[_p] = (metadata) => {
            if (this._playerLoadStopWatch)
                this._playerLoadStopWatch.stop();
            if (this._initialBufferStopWatch) {
                this._initialBufferStopWatch.stop();
            }
            if (this._playerLoadStopWatch) {
                this.addEvent(EVENTS.PLAYER_LOAD_TIME, getLiveStartupAttributes({
                    ...metadata,
                    startUpTime: this._playerLoadStopWatch ? this._playerLoadStopWatch.duration() : null,
                    authorizationDuration: this._authZStopWatch ? this._authZStopWatch.duration() : null,
                    initialBufferDuration: this._initialBufferStopWatch
                        ? this._initialBufferStopWatch.duration()
                        : null,
                    previousVideo: this._previousVideo,
                    previousVideoType: this._previousVideoType,
                }));
            }
        };
        // Auth
        this[_q] = () => {
            this._authZStopWatch = new StopWatch();
            this._authZStopWatch.start();
        };
        this[_r] = () => {
            this._authZStopWatch.stop();
            this._initialBufferStopWatch = new StopWatch();
            this._initialBufferStopWatch.start();
        };
        this[_s] = (metadata) => {
            this.addEvent(EVENTS.AUTH_SUCCESS_MVPD, metadata);
        };
        this[_t] = (metadata) => {
            this.addEvent(EVENTS.MVPD_PAGE_ABANDONED, metadata);
        };
        this[_u] = (params) => {
            this.addEvent(EVENTS.AUTH_SUCCESS_NBC, params);
        };
        this[_v] = () => {
            this.addEvent(EVENTS.NBC_PROFILE_SIGN_OUT);
        };
        this[_w] = () => {
            this.addEvent(EVENTS.IDM_CONVERSION);
        };
        // User actions
        this[_x] = (metadata) => {
            // Pages with async data live movie, show, live don't have a
            // path yet because they don't come straight from the router.
            if (!metadata.path) {
                // Get event on route change
                const handle = () => {
                    if (metadata.name) {
                        metadata.path = Router.getActiveRoute();
                        this.addEvent(metadata.name, metadata);
                    }
                    window.removeEventListener('hashchange', handle);
                };
                window.addEventListener('hashchange', handle);
            }
            else {
                this.addEvent(EVENTS.PAGE_LOAD, metadata);
            }
        };
        this[_y] = (metadata) => {
            this.addEvent(EVENTS.CLICK, metadata);
        };
        this[_z] = (metadata) => {
            this.addEvent(EVENTS.CONTENT_CLICK, metadata);
        };
        this[_0] = (metadata) => {
            this.addEvent(EVENTS.SEARCH_RESULT, getSearchResultsAttributes(metadata));
        };
        this[_1] = (params) => {
            this.addEvent(EVENTS.SHELF_IMPRESSION, params);
        };
        this[_2] = (params) => {
            this.addEvent(EVENTS.DYNAMIC_LEAD_IMPRESSION, params);
        };
        this[_3] = (params) => {
            this.addEvent(EVENTS.SHELVES_LOAD, params);
        };
        this[_4] = (params) => {
            this.addEvent(EVENTS.MODAL_LOAD, params);
        };
        this[_5] = (params) => {
            this.addEvent(EVENTS.END_CARD_IMPRESSION, {
                ...params,
                previousVideo: this._previousVideo,
                previousVideoType: this._previousVideoType,
            });
        };
        this[_6] = (params) => {
            this.addEvent(EVENTS.END_CARD, {
                ...params,
                previousVideo: this._previousVideo,
                previousVideoType: this._previousVideoType,
            });
        };
        this[_7] = (params) => {
            this.addEvent(EVENTS.LIVE_TO_VOD_IMPRESSION, params);
        };
        this[_8] = (params) => {
            this.addEvent(EVENTS.MARKETING_MODULE_IMPRESSION, params);
        };
        this[_9] = (params) => {
            this.addEvent(EVENTS.MARKETING_MODULE_VIDEO_PREVIEW, params);
        };
        // #endregion
    }
    async initialize(callback) {
        Log.info(MPARTICLE_TAG, 'initialize');
        // this is set for compatibility with library references
        const particleSetup = mParticleConfig(callback);
        const { apikey } = AppConfigFactorySingleton.config.mparticle;
        mParticle.init(apikey, particleSetup.config);
        // Send track launch since we've obviously launched at this point,
        // but may not be initialized when the normal launch event fires
        if (mParticleInterface.getMpid()) {
            this.trackLaunch();
        }
        else {
            // User info not loaded into the mParticle SDK, set listener.
            await new Promise((resolve) => {
                let attempts = 0;
                const checkIsUserReady = setInterval(() => {
                    attempts += 1;
                    const mpid = mParticleInterface.getMpid();
                    if (mpid || attempts === USER_READY_MAX_ATTEMPTS) {
                        clearInterval(checkIsUserReady);
                        this.trackLaunch();
                        resolve();
                    }
                }, USER_READY_TIMEOUT);
            });
        }
        this.removeBrandCookies();
    }
    getFirstVisitDate() {
        let runDate = getFirstRun();
        if (!moment(runDate).isValid()) {
            runDate = saveFirstRun();
            this.firstVisit = 'True';
        }
        return runDate;
    }
    setDefaultUserAttributes() {
        const firstVisitDate = this.getFirstVisitDate();
        const defaultUserAttributes = getDefaultUserAttributes({ firstVisitDate });
        const user = mParticleInterface.getCurrentUser();
        if (!user)
            return;
        for (const [key, value] of Object.entries(defaultUserAttributes)) {
            user.setUserAttribute(key, value);
        }
    }
    setUserAttribute(key, value) {
        const user = mParticleInterface.getCurrentUser();
        if (Array.isArray(value)) {
            user.setUserAttributeList(key, value);
        }
        else {
            user.setUserAttribute(key, value);
        }
    }
    updateDefaultUserAttributes(tempPassTokenName) {
        const defaultUserAttributes = getDefaultUserAttributes({
            tempPassTokenName,
        });
        const user = mParticleInterface.getCurrentUser();
        for (const [key, value] of Object.entries(defaultUserAttributes)) {
            user.setUserAttribute(key, value);
        }
    }
}
_a = EVENTS.LAUNCH, _b = EVENTS.ERROR, _c = EVENTS.EXIT, _d = EVENTS.AD_BREAK_START, _e = EVENTS.AD_BREAK_END, _f = EVENTS.AD_START, _g = EVENTS.AD_END, _h = EVENTS.LIVE_PROGRAM_CHANGE, _j = EVENTS.LIVE_PLAYER_LOAD, _k = EVENTS.LIVE_SESSION_START, _l = EVENTS.LIVE_SESSION_END, _m = EVENTS.VOD_SESSION_START, _o = EVENTS.VOD_SESSION_END, _p = EVENTS.PLAYER_LOAD_TIME, _q = EVENTS.AUTHZ_START, _r = EVENTS.AUTHZ_COMPLETE, _s = EVENTS.AUTH_SUCCESS_MVPD, _t = EVENTS.MVPD_PAGE_ABANDONED, _u = EVENTS.AUTH_SUCCESS_NBC, _v = EVENTS.NBC_PROFILE_SIGN_OUT, _w = EVENTS.IDM_CONVERSION, _x = EVENTS.PAGE_LOAD, _y = EVENTS.CLICK, _z = EVENTS.CONTENT_CLICK, _0 = EVENTS.SEARCH_RESULT, _1 = EVENTS.SHELF_IMPRESSION, _2 = EVENTS.DYNAMIC_LEAD_IMPRESSION, _3 = EVENTS.SHELVES_LOAD, _4 = EVENTS.MODAL_LOAD, _5 = EVENTS.END_CARD_IMPRESSION, _6 = EVENTS.END_CARD, _7 = EVENTS.LIVE_TO_VOD_IMPRESSION, _8 = EVENTS.MARKETING_MODULE_IMPRESSION, _9 = EVENTS.MARKETING_MODULE_VIDEO_PREVIEW;
mParticleInterface.getCurrentUser = mParticle.Identity.getCurrentUser;
mParticleInterface.getMpid = () => { var _10; return ((_10 = mParticleInterface.getCurrentUser()) === null || _10 === void 0 ? void 0 : _10.getMPID()) || ''; };
mParticleInterface.setAuthAttributes = (mvpd = '', profile = '') => {
    const user = mParticleInterface.getCurrentUser();
    user.setUserAttribute('User MVPD', mvpd);
    user.setUserAttribute('User Profile', profile);
};
mParticleInterface.setUserLanguageAttribute = (lang = '') => {
    const user = mParticleInterface.getCurrentUser();
    user.setUserAttribute('User Language', lang);
};
mParticleInterface.setConversionAttribute = (converted = false) => {
    const user = mParticleInterface.getCurrentUser();
    user.setUserAttribute('User Converted', `${converted}`);
};
export default mParticleInterface;
