export default class TrickPlayImageTile {
    constructor(src, time) {
        this._src = src;
        this._time = time;
    }
    get src() {
        return this._src;
    }
    get time() {
        return this._time;
    }
}
