import getNbcAuthSuccessAttributes from '../attributes/getNbcAuthSuccessAttributes';
import MParticleInterface from '../mParticleInterface';
import mParticle from '@mparticle/web-sdk';
export const preTrackEvent = () => (properties) => MParticleInterface.setAuthAttributes(properties);
const getEvent = () => ({
    getAttributes: getNbcAuthSuccessAttributes,
    getCategory: () => mParticle.EventType.UserPreference,
    type: 'NBC Authentication Success',
    injectGlobals: true,
    preTrackEvent: preTrackEvent(),
});
export default getEvent;
