import { COLORS } from '../../../constants';
import UserInteractionsStoreSingleton from '../../../store/UserInteractions';
export default (initialPositionY = 0, hideIfWatched = false) => {
    return (tile) => {
        var _a;
        const percentViewed = UserInteractionsStoreSingleton.getPercentViewed(tile._item.v4ID);
        const hasProgress = percentViewed < 0.95 && percentViewed > 0.0;
        const isWatched = !hideIfWatched && percentViewed >= 0.95;
        const width = (_a = tile.constructor) === null || _a === void 0 ? void 0 : _a.width;
        if (hasProgress || isWatched) {
            const currentProgressWidth = isWatched ? width : width * percentViewed;
            tile._addProgress({
                y: initialPositionY - 12,
                totalProgressWidth: width,
                totalProgressColor: COLORS.charcoalGrey,
                currentProgressWidth,
                currentProgressColor: COLORS.white,
            });
        }
    };
};
