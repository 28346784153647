export var PlayerStatus;
(function (PlayerStatus) {
    PlayerStatus[PlayerStatus["IDLE"] = 0] = "IDLE";
    PlayerStatus[PlayerStatus["INITIALIZING"] = 1] = "INITIALIZING";
    PlayerStatus[PlayerStatus["INITIALIZED"] = 2] = "INITIALIZED";
    PlayerStatus[PlayerStatus["READY"] = 3] = "READY";
    PlayerStatus[PlayerStatus["PLAYING"] = 4] = "PLAYING";
    PlayerStatus[PlayerStatus["PAUSED"] = 5] = "PAUSED";
    PlayerStatus[PlayerStatus["BUFFERING"] = 6] = "BUFFERING";
    PlayerStatus[PlayerStatus["SEEKING"] = 7] = "SEEKING";
    PlayerStatus[PlayerStatus["COMPLETE"] = 8] = "COMPLETE";
    PlayerStatus[PlayerStatus["ERROR"] = 9] = "ERROR";
    PlayerStatus[PlayerStatus["RELEASED"] = 10] = "RELEASED";
    PlayerStatus[PlayerStatus["SUSPENDED"] = 11] = "SUSPENDED";
    PlayerStatus[PlayerStatus["EXPIRED"] = 12] = "EXPIRED";
    PlayerStatus[PlayerStatus["UNKNOWN"] = 13] = "UNKNOWN";
    PlayerStatus[PlayerStatus["FINISHED"] = 14] = "FINISHED";
    PlayerStatus[PlayerStatus["LOADING"] = 15] = "LOADING";
})(PlayerStatus || (PlayerStatus = {}));
