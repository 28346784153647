import { FONT_FACE } from '../../../constants';
import { getProxyPath, isXclass } from '../../../helpers';
const STATIC_PATH = getProxyPath();
// Lightning static getFonts method dont load the fallback fonts. PeacockTV has a similar implementation.
export const fontSpecs = [
    {
        family: FONT_FACE.light,
        src: [
            {
                format: 'woff2',
                url: `${STATIC_PATH}/fonts/SourceSansPro-Light.woff2`,
            },
            {
                format: 'woff',
                url: `${STATIC_PATH}/fonts/SourceSansPro-Light.woff`,
            },
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/SourceSansPro-Light.ttf`,
            },
        ],
        descriptors: {
            weight: '300',
        },
    },
    {
        family: FONT_FACE.regular,
        src: [
            {
                format: 'woff2',
                url: `${STATIC_PATH}/fonts/SourceSansPro-Regular.woff2`,
            },
            {
                format: 'woff',
                url: `${STATIC_PATH}/fonts/SourceSansPro-Regular.woff`,
            },
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/SourceSansPro-Regular.ttf`,
            },
        ],
        descriptors: {
            weight: 'normal',
        },
    },
    {
        family: FONT_FACE.semiBold,
        src: [
            {
                format: 'woff2',
                url: `${STATIC_PATH}/fonts/SourceSansPro-SemiBold.woff2`,
            },
            {
                format: 'woff',
                url: `${STATIC_PATH}/fonts/SourceSansPro-SemiBold.woff`,
            },
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/SourceSansPro-SemiBold.ttf`,
            },
        ],
        descriptors: {
            weight: '600',
        },
    },
    {
        family: FONT_FACE.bold,
        src: [
            {
                format: 'woff2',
                url: `${STATIC_PATH}/fonts/SourceSansPro-Bold.woff2`,
            },
            {
                format: 'woff',
                url: `${STATIC_PATH}/fonts/SourceSansPro-Bold.woff`,
            },
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/SourceSansPro-Bold.ttf`,
            },
        ],
        descriptors: {
            weight: 'bold',
        },
    },
    // Used in search page
    {
        family: FONT_FACE.roboto,
        src: [
            {
                format: 'woff2',
                url: `${STATIC_PATH}/fonts/Roboto-Regular.woff2`,
            },
            {
                format: 'woff',
                url: `${STATIC_PATH}/fonts/Roboto-Regular.woff`,
            },
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/Roboto-Regular.ttf`,
            },
        ],
        descriptors: {
            weight: 'normal',
        },
    },
    // Used in player control page
    {
        family: FONT_FACE.helvetica,
        src: [
            {
                format: 'woff2',
                url: `${STATIC_PATH}/fonts/Helvetica.woff2`,
            },
            {
                format: 'woff',
                url: `${STATIC_PATH}/fonts/Helvetica.woff`,
            },
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/Helvetica.ttf`,
            },
        ],
        descriptors: {
            weight: 'normal',
        },
    },
    // Used in player captions page
    {
        family: FONT_FACE.courier,
        src: [
            {
                format: 'woff2',
                url: `${STATIC_PATH}/fonts/Courier.woff2`,
            },
            {
                format: 'woff',
                url: `${STATIC_PATH}/fonts/Courier.woff`,
            },
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/Courier.ttf`,
            },
        ],
        descriptors: {
            weight: 'normal',
        },
    },
    {
        family: FONT_FACE.impress,
        src: [
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/Impress.ttf`,
            },
        ],
        descriptors: {
            weight: 'normal',
        },
    },
    {
        family: FONT_FACE.coronet,
        src: [
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/Coronet.ttf`,
            },
        ],
        descriptors: {
            weight: 'normal',
        },
    },
    {
        family: FONT_FACE.copperplate,
        src: [
            {
                format: 'truetype',
                url: `${STATIC_PATH}/fonts/Copperplate.ttf`,
            },
        ],
        descriptors: {
            weight: 'normal',
        },
    },
];
export const computeFontSource = (specification) => {
    const sourceSpecs = specification.src;
    const isComcast = isXclass();
    return sourceSpecs
        .filter((src) => (isComcast ? src.format === 'truetype' : true))
        .map((sourceSpec) => `url("${sourceSpec.url}") format("${sourceSpec.format}")`)
        .join(', ');
};
