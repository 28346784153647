import { componentsForPlaceholders } from '../../../../api/requests';
import { COMPONENT_TYPES } from '../../../../constants';
import Variables from '../../../../graphql/Variables';
import rsnFilter from '../../../../graphql/filter/rsnFilter';
import componentsForPlaceholdersMapper from '../../../../graphql/mappers/componentsForPlaceholders';
import { createRequestConfig } from '../../../../lib/useRequest';
export const FiltersGuideRequestConfig = (variables) => createRequestConfig({
    query: componentsForPlaceholders,
    variables: Variables.componentsForPlaceholdersVariables(variables),
    mapper: (data) => rsnFilter(componentsForPlaceholdersMapper(data)),
    reject: [(data) => { var _a; return ((_a = data === null || data === void 0 ? void 0 : data.components[0]) === null || _a === void 0 ? void 0 : _a.component) === COMPONENT_TYPES.MESSAGE; }],
});
