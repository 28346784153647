import { Lightning } from '@lightningjs/sdk';
import TextButton from './TextButton';
import { COLORS } from '../../constants';
export default class TextBorderButton extends TextButton {
    _construct() {
        super._construct();
        this._strokeWidth = 2;
        this._focusBorderColor = COLORS.lightGray3;
        this._unfocusBorderColor = COLORS.dark;
    }
    set focusBorderColor(focusBorderColor) {
        this._focusBorderColor = focusBorderColor;
    }
    set unfocusBorderColor(unfocusBorderColor) {
        this._unfocusBorderColor = unfocusBorderColor;
    }
    _updateButton() {
        super._updateButton();
        const borderColor = this._isFocus ? this._focusBorderColor : this._unfocusBorderColor;
        this.patch({
            Border: {
                x: this.w / 2,
                y: this.h / 2,
                mount: 0.5,
                texture: Lightning.Tools.getRoundRect(this.w, this.h, this._radius, this._strokeWidth, borderColor, false, borderColor),
            },
        });
    }
}
