import cloneDeep from 'lodash/cloneDeep';
import { BrandSelectableGroup, EndTiles, Grid, Guide, GuideSchedule, LazyLinksSelectableGroup, LazyOnAirNowShelf, LazyShelf, LinksSelectableGroup, NestedLinkSelectableGroup, OnAirNowShelf, Shelf, Slideshow, SmartTile, EventSchedule, Message, GroupedContinuousScroll, ContinuousScroll, PlaceholderSection, MarketingModuleSection, StackGroup, Stack, } from '.';
import { COMPONENT_TYPES } from '../../constants';
export default class Container {
    constructor(obj, index) {
        if (!obj) {
            return;
        }
        this.obj = cloneDeep(obj);
        this._index = index;
        if (this.obj.data)
            this.obj.data.component = this.obj.component;
        this.obj.data = this.getComponent(this.obj.component, this.obj.data);
    }
    getComponent(name, data) {
        switch (name) {
            case COMPONENT_TYPES.SLIDE_SHOW:
                return new Slideshow(data);
            case COMPONENT_TYPES.STACK:
                return new Stack(data, this.index);
            case COMPONENT_TYPES.STACK_GROUP:
                return new StackGroup(data, this.index);
            case COMPONENT_TYPES.SHELF:
                return new Shelf(data, this.index);
            case COMPONENT_TYPES.ON_AIR_NOW_SHELF:
                return new OnAirNowShelf(data, this.index);
            case COMPONENT_TYPES.LAZY_SHELF:
                return new LazyShelf(data);
            case COMPONENT_TYPES.BRAND_SELECTABLE_GROUP:
                return new BrandSelectableGroup(data);
            case COMPONENT_TYPES.LINK_SELECTABLE_GROUP:
                return new LinksSelectableGroup(data, this.index);
            case COMPONENT_TYPES.NESTED_LINK_SELECTABLE_GROUP:
                return new NestedLinkSelectableGroup(data, this.index);
            case COMPONENT_TYPES.LAZY_LINK_SELECTABLE_GROUP:
                return new LazyLinksSelectableGroup(data);
            case COMPONENT_TYPES.LAZY_ON_AIR_NOW_SHELF:
                return new LazyOnAirNowShelf(data);
            case COMPONENT_TYPES.GRID:
                return new Grid(data);
            case COMPONENT_TYPES.SMART_TILE:
                return new SmartTile(data, this.index);
            case COMPONENT_TYPES.END_TILES:
                return new EndTiles(data);
            case COMPONENT_TYPES.GUIDE:
                return new Guide(data);
            case COMPONENT_TYPES.GUIDE_SCHEDULE:
                return new GuideSchedule(data);
            case COMPONENT_TYPES.EVENT_SCHEDULE:
                return new EventSchedule(data);
            case COMPONENT_TYPES.MESSAGE:
                return new Message(data);
            case COMPONENT_TYPES.GROUPED_CONTINUOUS_SCROLL:
                return new GroupedContinuousScroll({ ...data, itemLabels: this.obj.analytics.itemLabels }, this.index);
            case COMPONENT_TYPES.CONTINUOUS_SCROLL:
                return new ContinuousScroll(data, this.index);
            case COMPONENT_TYPES.PLACEHOLDER:
            case COMPONENT_TYPES.PLACEHOLDER_ELEMENT:
                return new PlaceholderSection(data);
            case COMPONENT_TYPES.MARKETING_MODULE:
                return new MarketingModuleSection(this.obj);
            default:
                return null;
        }
    }
    get index() {
        return this._index;
    }
    get component() {
        return this.obj.component;
    }
    get data() {
        return this.obj.data;
    }
    get deeplinkHandle() {
        return this.obj.deeplinkHandle;
    }
    get logicName() {
        return this.obj.logicName;
    }
    get meta() {
        return this.obj.meta;
    }
    get treatments() {
        return this.obj.treatments;
    }
    get title() {
        return this.data.listTitle;
    }
    get sponsorName() {
        var _a, _b;
        return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.listTitle) && ((_b = this.data) === null || _b === void 0 ? void 0 : _b.sponsorName) ? this.data.sponsorName : '';
    }
    get ariaLabel() {
        return this.data.ariaLabel;
    }
}
