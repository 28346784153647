import { Lightning } from '@lightningjs/sdk';
import { COLORS, FONT_FACE, VERTICAL_ALIGN } from '../../../../constants';
export var TimerDesign;
(function (TimerDesign) {
    TimerDesign["V1"] = "v1";
    TimerDesign["V2"] = "v2";
})(TimerDesign || (TimerDesign = {}));
export class Timer extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._aTime = '00:00';
        this._bTime = '00:00';
        this._design = TimerDesign.V1;
    }
    static _template() {
        return {};
    }
    set design(design) {
        // Must set w before calling this setter
        this._design = design;
        const text = {
            textColor: COLORS.white,
            verticalAlign: VERTICAL_ALIGN.middle,
            fontSize: 28,
            fontFace: FONT_FACE.regular,
            maxLines: 1,
            text: '00:00',
        };
        if (design === TimerDesign.V1) {
            this.patch({
                ATime: {
                    text,
                },
                BTime: {
                    x: this.w,
                    mountX: 1,
                    text,
                },
            });
        }
        else {
            this.patch({
                x: this.w,
                mountX: 1,
                text: {
                    textColor: COLORS.white,
                    textAlign: 'right',
                    fontSize: 28,
                    fontFace: FONT_FACE.regular,
                    text: '00:00 / 00:00',
                },
            });
        }
    }
    set aTime(text) {
        var _a;
        this._aTime = text;
        if (this._design === TimerDesign.V1) {
            (_a = this.tag('ATime')) === null || _a === void 0 ? void 0 : _a.patch({
                text: {
                    text,
                },
            });
        }
        else {
            this.patch({
                text: { text: `${text} / ${this._bTime}` },
            });
        }
    }
    set bTime(text) {
        var _a;
        this._bTime = text;
        if (this._design === TimerDesign.V1) {
            (_a = this.tag('BTime')) === null || _a === void 0 ? void 0 : _a.patch({
                text: {
                    text,
                },
            });
        }
        else {
            this.patch({
                text: { text: `${this._aTime} / ${text}` },
            });
        }
    }
}
