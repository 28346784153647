import { liveBadgeDecorator, liveProgressDecorator } from '../../decorators';
export const getLiveStateClass = (context) => class LiveState extends context {
    $enter() {
        this._removeBadge();
        this._addLiveItems();
        this.announce = this._item.liveAriaLabel;
    }
    _addLiveItems() {
        this._addDecorators([
            liveProgressDecorator(260),
            liveBadgeDecorator(this._item.liveBadge, 184),
        ]);
    }
};
