export default class Tile {
    constructor(obj = {}, shelf = {}, tileIndex = 0) {
        this.obj = {
            ...obj,
            index: tileIndex,
        };
        this.shelf = {
            ...shelf,
            tileIndex,
        };
    }
    get shelfData() {
        return this.shelf;
    }
    get analytics() {
        var _a;
        return ((_a = this === null || this === void 0 ? void 0 : this.obj) === null || _a === void 0 ? void 0 : _a.analytics) || null;
    }
    get component() {
        return this.obj.component || null;
    }
    get data() {
        return this.obj.data || {};
    }
    get meta() {
        return this.obj.meta || null;
    }
    get treatments() {
        return this.obj.treatments || null;
    }
    get onAirNowTileData() {
        var _a, _b;
        return ((_a = this.obj) === null || _a === void 0 ? void 0 : _a.onAirNowTileData) || ((_b = this.obj) === null || _b === void 0 ? void 0 : _b.data) || {};
    }
    get upcomingLiveTileData() {
        var _a, _b;
        return ((_a = this.obj) === null || _a === void 0 ? void 0 : _a.upcomingLiveTileData) || ((_b = this.obj) === null || _b === void 0 ? void 0 : _b.data) || {};
    }
    get eventTileData() {
        var _a, _b;
        return ((_a = this.obj) === null || _a === void 0 ? void 0 : _a.eventTileData) || ((_b = this.obj) === null || _b === void 0 ? void 0 : _b.data) || {};
    }
    get ariaLabel() {
        var _a;
        return (_a = this.data) === null || _a === void 0 ? void 0 : _a.ariaLabel;
    }
    get title() {
        return this.data.title;
    }
    get v4ID() {
        return this.data.v4ID && this.data.v4ID !== 'N/A' ? this.data.v4ID : this.data.videoV4ID;
    }
    get slideHeader() {
        return this.data.slideHeader || this.data.title;
    }
}
