export class PlayerError {
    constructor(fatal, type, details) {
        this._fatal = fatal;
        this._type = type;
        this._details = details;
    }
    get fatal() {
        return this._fatal;
    }
    get type() {
        return this._type;
    }
    get details() {
        return this._details;
    }
}
