import { IPlayerEvent } from './IPlayerEvent';
export class ErrorEvent extends IPlayerEvent {
    constructor(error) {
        super();
        this._error = error;
    }
    get error() {
        return this._error;
    }
}
