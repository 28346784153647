import { Language } from '@lightningjs/sdk';
import UpcomingLiveTile from './UpcomingLiveTile';
import { CONTENT_TYPE } from '../../../constants';
export default class UpcomingLiveSlideTile extends UpcomingLiveTile {
    get videoTitle() {
        return this.data.title;
    }
    get secondaryTitle() {
        return this.data.secondaryTitle;
    }
    get liveTuneIn() {
        return this.data.liveTuneIn || (this.isEventStarted && Language.translate('tune-in-live'));
    }
    get liveCtaText() {
        return this.data.liveCtaText;
    }
    get liveCtaColor() {
        return this.data.liveCtaColor || '#D5260B';
    }
    get upcomingTuneIn() {
        return this.data.upcomingTuneIn;
    }
    get upcomingCtaText() {
        return this.data.upcomingCtaText;
    }
    get upcomingCtaColor() {
        return this.data.upcomingCtaColor || '#656C80';
    }
    get sponsorName() {
        return this.data.sponsorName;
    }
    get sponsorLogo() {
        return this.data.sponsorLogo;
    }
    get sponsorLogoAltText() {
        return this.data.sponsorLogoAltText;
    }
    get whiteBrandLogo() {
        return this.data.whiteBrandLogo;
    }
    get language() {
        return this.data.language;
    }
    get isEventStarted() {
        return this.contentType === CONTENT_TYPE.SLE && this.isLive;
    }
    get description() {
        return this.data.description;
    }
    get league() {
        return this.analytics.league;
    }
    get sport() {
        return this.analytics.sport;
    }
}
