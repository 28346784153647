import { liveBadgeDecorator, liveProgressDecorator } from '../../decorators';
export const getLiveStateClass = (context) => class LiveState extends context {
    $enter() {
        this._removeBadge();
        this._addLiveItems();
    }
    _addLiveItems() {
        this._addDecorators([
            liveProgressDecorator(239),
            liveBadgeDecorator(this._item.liveBadge, 184),
        ]);
    }
    get announce() {
        return `${this._item.liveAriaLabel}, press OK to select`;
    }
};
