import { Registry, Language } from '@lightningjs/sdk';
import { LiveBadgeV1 } from './LiveBadgeV1';
import { LiveControlsStateFactory } from './states/LiveControls';
import SLEStrategy from './strategies/SLEStrategy';
import TempPassCTA from './TempPassCTA';
import LiveControlsV1 from './LiveControlsV1';
import { EVENTS } from '../../../lib/analytics/types';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { TEMP_PASS_REDIRECT } from '../../../pages/Player/BasePlayer';
import { PlayerControlsStates, LANGUAGES, ALIGN_SELF } from '../../../constants';
import { KeyMap } from '../../../lib/KeyMap';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { TimeChangeEvent } from '../../../player/model/event';
import { activateTempPassRedirect } from '../../../pages/Player/StreamLoader/actions/redirect';
import { useRouterBackDisabled } from '../../../widgets/Modals/activation/hooks/useRouterBackDisabled';
import SeekablePlayerControlsV1 from './SeekablePlayerControlsV1';
const TIMEOUT_HIDE_TEMPPASS_TIMER = 5000;
class SLEPlayerControls extends SeekablePlayerControlsV1 {
    constructor() {
        super(...arguments);
        this._strategy = new SLEStrategy(this);
        this._keyMap = KeyMap(this, [
            [
                [PlayerControlsStates.QOSPanel, PlayerControlsStates.MoreInfo],
                [PlayerControlsStates.MediaOptions, PlayerControlsStates.MoreInfo],
                [PlayerControlsStates.TempPass, PlayerControlsStates.QOSPanel],
            ],
            [[], PlayerControlsStates.AdControls, []],
            [[], PlayerControlsStates.LiveControls, []],
            [[], PlayerControlsStates.PlayOrPause, []],
        ]);
    }
    static _template() {
        return super._template({
            PlayerControls: {
                TitleContainer: {
                    HeadingContainer: {
                        y: 80,
                        flex: undefined,
                        Title: {
                            x: 80,
                            y: 80,
                        },
                    },
                    SecondaryTitle: {
                        x: Language.get() === LANGUAGES.SPANISH ? 210 : 170,
                    },
                },
            },
        });
    }
    _init() {
        super._init();
        this.tag('PlayerControls.TitleContainer').childList.addAt({ ref: 'Live', x: 60, y: 96, type: LiveBadgeV1 }, 1);
        this.showLiveControls();
    }
    get seekingAllowed() {
        var _a;
        return ((_a = PlayerStoreSingleton.lemonade) === null || _a === void 0 ? void 0 : _a.pvrControls) || false;
    }
    getTitleContainerY() {
        return SLEPlayerControls.TITLE_CONTAINER_Y;
    }
    setTitle(text) {
        this._title.patch({
            flexItem: {
                alignSelf: ALIGN_SELF.center,
            },
            text: {
                text,
                fontSize: 28,
            },
        });
    }
    setSecondaryTitle(text) {
        this._secondaryTitle.patch({ text: { text, fontSize: 58 } });
    }
    setDescription(text) {
        this._description.patch({ y: 185, text: { text } });
    }
    showLiveControls() {
        this.tag('PlayerControls').patch({
            LiveControls: {
                type: LiveControlsV1,
            },
        });
        this._liveControls = this.tag('LiveControls');
    }
    patchTempPassTemplate() {
        this.patch({
            TempPass: {
                type: TempPassCTA,
                alpha: 1,
            },
        });
        this._tempPassControlsOverlay = this.tag('TempPass');
        this.tempPassCtaClicked = false;
    }
    createTimeOut() {
        Registry.setTimeout(() => {
            this.fireAncestors('$hideTempPassTimer');
        }, TIMEOUT_HIDE_TEMPPASS_TIMER);
    }
    updateTempPassCountdown(secondsLeft) {
        if (secondsLeft <= 30 && this._getState() !== PlayerControlsStates.TempPass) {
            this.fireAncestors('$showTempPassTimer', { pin: true });
        }
        this._tempPassControlsOverlay.update(secondsLeft);
    }
    _updateProgress([currentTime, duration, seekableRange]) {
        if (!duration)
            return;
        const totalWidth = this.tag('TotalBar').w;
        const lessThan1MinLeft = this._getState() !== PlayerControlsStates.Seeking && duration - currentTime <= 60;
        const position = lessThan1MinLeft ? totalWidth : (currentTime * totalWidth) / duration;
        const seekEnd = seekableRange && !lessThan1MinLeft ? ((seekableRange === null || seekableRange === void 0 ? void 0 : seekableRange[1]) * totalWidth) / duration : position;
        this.tag('Progress').patch({
            CurrentBarBg: { w: seekEnd },
            CurrentBar: { w: position },
            SeekBar: { x: position },
        });
    }
    _playerSubscriptionHandler(event) {
        super._playerSubscriptionHandler(event);
        if (event instanceof TimeChangeEvent) {
            //toggle "back to live" button based on isAtLiveEdge property from player
            this._liveControls.toggleBackToLive(!event._isAtLiveEdge);
        }
    }
    static _states() {
        return [
            ...super._states(),
            class TempPass extends this {
                _handleEnter() {
                    sendMetric(EVENTS.CLICK, {
                        name: 'TempPass - Link TV Provider',
                    });
                    this.tempPassCtaClicked = true;
                    useRouterBackDisabled().set(true);
                    activateTempPassRedirect(TEMP_PASS_REDIRECT.TEMP_PASS_MVPD);
                }
                _getFocused() {
                    return this.tag('TempPass') || this;
                }
            },
            LiveControlsStateFactory(this, 'PlayerControls.LiveControls'),
        ];
    }
}
SLEPlayerControls.TITLE_CONTAINER_Y = 650;
export default SLEPlayerControls;
