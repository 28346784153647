import moment from 'moment-timezone';
import Tile from './Tile';
import UpcomingModal from '../UpcomingModal';
import { isLive, isUpcoming } from '../../algolia/helpers';
import { getRemaining, getTimeDiff } from '../../../helpers';
import { COMPONENT_TYPES } from '../../../constants';
export default class UpcomingLiveTile extends Tile {
    constructor(obj = {}, shelf = {}, tileIndex = 0) {
        var _a, _b;
        super(obj, shelf, tileIndex);
        this._upcomingModal = new UpcomingModal(this.obj.data.upcomingModal, (_b = (_a = this.obj) === null || _a === void 0 ? void 0 : _a.analytics) === null || _b === void 0 ? void 0 : _b.isOlympics);
    }
    get title() {
        return this.data.title;
    }
    get secondaryTitle() {
        return this.data.secondaryTitle || this.data.shortDescription || '';
    }
    get logo() {
        return this.data.whiteBrandLogo;
    }
    get liveBadge() {
        return this.data.liveBadge;
    }
    get upcomingBadge() {
        return this.data.upcomingBadge;
    }
    get channelId() {
        return this.data.channelId;
    }
    get streamAccessName() {
        return this.data.streamAccessName;
    }
    get callSign() {
        return this.data.callSign;
    }
    get pid() {
        return this.data.pid;
    }
    get startTime() {
        return this.data.startTime;
    }
    get endTime() {
        return this.data.endTime;
    }
    get image() {
        return this.data.image;
    }
    get upcomingModal() {
        return this._upcomingModal;
    }
    get isUpcoming() {
        return isUpcoming(this.startTime);
    }
    get isLive() {
        return isLive(this.startTime, this.endTime);
    }
    get upcomingAriaLabel() {
        return this.data.upcomingAriaLabel;
    }
    get liveAriaLabel() {
        return `${this.data.liveAriaLabel} ${getRemaining(getTimeDiff(moment(), this.data.endTime))}`;
    }
    get customerPlayableDate() {
        return this.data.customerPlayableDate;
    }
    get isMedalSession() {
        return this.data.isMedalSession;
    }
    get tertiaryTitle() {
        return this.data.tertiaryTitle;
    }
    get component() {
        // TODO this will be changed in PL-1995/PL-1996
        return this.shelf.component === COMPONENT_TYPES.STACK
            ? COMPONENT_TYPES.SPORTS_LIVE_GUIDE_TILE
            : this.obj.component;
    }
    get contentType() {
        return this.data.contentType;
    }
    get isOlympics() {
        return this.analytics.isOlympics;
    }
    get language() {
        return this.data.audioLanguage;
    }
}
