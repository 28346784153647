import LongScrollPage from './LongScrollPage';
import { EpgGuideV2 } from '../components';
import { setSmooth } from '../helpers';
import { PlayerStoreEvents } from '../store/PlayerStore/PlayerStore';
import { EPG_SLOT_WIDTH, EPG_SLOT_PADDING } from '../constants';
import { SubscriptionBuilder, SubscriptionSources } from '../util/SubscriptionBuilder';
export default class EpisodeGuide extends LongScrollPage {
    constructor() {
        super(...arguments);
        this._fetchLiveGuide = () => {
            this._epg.fetchData();
        };
        this._onStoreEvent = (event) => {
            const { type } = event;
            switch (type) {
                case PlayerStoreEvents.EPG_OK:
                    setSmooth(this.widgets.loader, 'visible', 0);
                    break;
                default:
                    break;
            }
        };
    }
    static _template() {
        return {
            Epg: {
                type: EpgGuideV2,
                programImageWidth: 380,
                itemHeight: 190,
                itemWidth: EPG_SLOT_WIDTH,
                itemPadding: EPG_SLOT_PADDING,
                visible: false,
            },
        };
    }
    _init() {
        this._epg = this.tag('Epg');
        this._epg.visible = true;
        this._epg.load();
    }
    _attach() {
        this._subscription = new SubscriptionBuilder()
            .with(SubscriptionSources.PLAYER_STORE)
            .subscribe(this._onStoreEvent);
        this._fetchLiveGuide();
    }
    _detach() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    _inactive() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._subscription = undefined;
    }
    _handleDown() {
        this._epg._handleDown();
    }
    _handleUp() {
        this._epg._handleUp();
    }
    _handleRight() {
        this._epg._handleRight();
    }
    _handleLeft() {
        this._epg._handleLeft();
    }
    _handleEnter() {
        this._epg._handleEnter();
    }
    static _states() {
        return [
            class Epg extends this {
                _getFocused() {
                    return this._epg || this;
                }
                _handleDown() {
                    this._epg._handleDown();
                }
            },
        ];
    }
}
