import VideoTile from '../VideoTile/VideoTile';
import { getLiveStateClass, getUpcomingStateClass } from './states';
import { audioLanguageDecorator, freeBadgeDecorator, liveBadgeDecorator, lockDecorator, olympicsMedalDecorator, } from '../decorators';
import { COLORS, FONT_FACE, ENTITY_TYPES, MAX_LINES_SUFFIX, FLEX_DIRECTION, OLY_IMPOLICY, } from '../../../constants';
import { navigateUpcomingLiveItems } from '../helpers/upcomingItems';
import { FastImg } from '../../../helpers';
import TVPlatform from '../../../lib/tv-platform';
// @ts-expect-error TS(2417): Class static side 'typeof UpcomingLiveTile' incorr... Remove this comment to see the full error message
export default class UpcomingLiveTile extends VideoTile {
    set item(v) {
        var _a;
        this._item = v;
        this.patch({
            ImageHolder: {
                w: 420,
                h: 235,
                Image: { texture: FastImg(v.image).contain(420, 235, OLY_IMPOLICY.RECT_SMEDIUM_640_360) },
            },
            Content: {
                TitleCard: {
                    ShortTitleContainer: {
                        ShortTitle: {
                            text: {
                                text: this._getMainTitle(),
                                maxLines: 1,
                                maxLinesSuffix: MAX_LINES_SUFFIX,
                            },
                        },
                    },
                    Title: {
                        text: {
                            text: this._getSubTitle(),
                            maxLines: 2,
                            maxLinesSuffix: MAX_LINES_SUFFIX,
                        },
                    },
                    SmartTimeStamp: {
                        flex: {},
                        flexItem: { marginTop: -8 },
                        ShortTitle: {
                            flexItem: { marginRight: 8 },
                            text: {
                                text: ((_a = this._item) === null || _a === void 0 ? void 0 : _a.tertiaryTitle) || '',
                                fontSize: 24,
                                textColor: COLORS.white,
                                fontFace: FONT_FACE.light,
                                maxLines: 1,
                                wordWrapWidth: 280,
                                maxLinesSuffix: MAX_LINES_SUFFIX,
                                lineHeight: 24,
                            },
                        },
                        AudioLanguageContainer: {
                            flex: { direction: FLEX_DIRECTION.row },
                        },
                    },
                },
                Logo: { texture: FastImg(v.logo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216) },
            },
        });
        if (v.isUpcoming)
            this._setState('UpcomingState');
        // The expired tiles should remain with the live bar and badges, so the items that are not upcoming are Live and Expired items
        if (!v.isUpcoming)
            this._setState('LiveState');
    }
    _init() {
        var _a;
        super._init();
        this._addDecorators([
            lockDecorator,
            olympicsMedalDecorator(5),
            liveBadgeDecorator((_a = this._item) === null || _a === void 0 ? void 0 : _a.liveBadge, 184),
            audioLanguageDecorator,
            freeBadgeDecorator(),
        ]);
    }
    _getMainTitle() {
        var _a;
        return (_a = this._item) === null || _a === void 0 ? void 0 : _a.title;
    }
    _getSubTitle() {
        var _a;
        return (_a = this._item) === null || _a === void 0 ? void 0 : _a.secondaryTitle;
    }
    _handleEnter() {
        this.trackContentPosition();
        this.trackContentClick(ENTITY_TYPES.UPCOMING_LIVE_TILE);
        navigateUpcomingLiveItems({
            item: this._item,
            fireAncestors: this.fireAncestors.bind(this),
            preventClickMetric: true,
        });
    }
    _inactive() {
        if (TVPlatform.getForceGC())
            this.stage.gc();
    }
    _getTrackParams() {
        return {
            isUpcoming: this._item.isUpcoming,
            isLive: this._item.isLive,
        };
    }
    get announce() {
        return `${this._item.liveAriaLabel} press OK to select`;
    }
    get olympicsMedalContainer() {
        return this.tag('ShortTitleContainer');
    }
    static _states() {
        return [getUpcomingStateClass(this), getLiveStateClass(this)];
    }
    static get tag() {
        return 'UpcomingLiveTile';
    }
}
