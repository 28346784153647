import { Log } from '@lightningjs/sdk';
import List from './List';
import PagingListTile from '../../components/search/PagingListTile';
import { removeNotMappedTypeContents } from '../../helpers/algolia';
import AuthenticationSingleton from '../../authentication/Authentication';
import { transformResources } from '../../helpers';
export default class PagingList extends List {
    _reset() {
        super._reset();
        this._fetchingMore = false;
    }
    _init() {
        this._autoResize = false;
        const isVertical = this._orientation === List.ORIENTATION.vertical;
        this._mainTransition = this.wrapper.transition(isVertical ? 'y' : 'x');
    }
    set availableItems(nr) {
        this._availableItems = nr;
    }
    onClickItem(item) {
        this.signal('onClickItem', { ...item, index: this.index });
    }
    async _indexChanged(obj) {
        var _a;
        super._indexChanged(obj);
        const { previousIndex, newIndex, dataLength } = obj;
        if (previousIndex === newIndex) {
            return;
        }
        const current = this.currentItem;
        const isVertical = this._orientation === List.ORIENTATION.vertical;
        const main = isVertical ? 'y' : 'x';
        const mainDim = isVertical ? 'h' : 'w';
        const marginEnd = current[mainDim];
        const marginStart = current[mainDim] + current.margin;
        const controlPoint = this._mainTransition.targetValue;
        const viewportSize = this[mainDim] - (marginEnd + marginStart);
        const viewportStart = marginStart - controlPoint;
        let m = controlPoint;
        if (current[main] < viewportStart && this._index > 0) {
            m = -(current[main] - marginStart);
        }
        else if (current[main] < viewportStart) {
            m = 0;
        }
        if (current[main] + current[mainDim] >= viewportStart + viewportSize &&
            this._index < dataLength - 1) {
            m = viewportSize + marginEnd - (current[main] + current[mainDim]);
        }
        if (this._mainTransition.isRunning()) {
            this._mainTransition.reset(m, 0.12);
        }
        else {
            this._mainTransition.start(m);
        }
        if (!this._fetchingMore && dataLength < this._availableItems && newIndex === dataLength - 1) {
            this._fetchingMore = true;
            const isMvpd = AuthenticationSingleton.isAuthenticated();
            const mvpdData = await AuthenticationSingleton.getMvpdData();
            let resources = [];
            let resourceAuthorization = {};
            if ((_a = mvpdData === null || mvpdData === void 0 ? void 0 : mvpdData.preAuthData) === null || _a === void 0 ? void 0 : _a.resources) {
                resources = mvpdData.preAuthData.resources;
                resourceAuthorization = transformResources(resources);
            }
            this.signal('requestMoreItems', { offset: dataLength + 1 }).then((response) => {
                if (!response.hits)
                    return false;
                //TODO: Need to find a better way to consolidate all logic in all places ]
                const filteredHits = removeNotMappedTypeContents(response.hits).filter(({ data }) => {
                    var _a, _b;
                    // Early exit from the function if mvpd is empty
                    if (!isMvpd)
                        return data;
                    const resourceId = (data === null || data === void 0 ? void 0 : data.resourceId) || ((_a = data === null || data === void 0 ? void 0 : data.event) === null || _a === void 0 ? void 0 : _a.resourceId) || ((_b = data === null || data === void 0 ? void 0 : data.brand) === null || _b === void 0 ? void 0 : _b.resourceId);
                    if (isMvpd && resourceId) {
                        return data;
                    }
                    else {
                        Log.info('Binge Hidden Resource Ids::', resourceId, 'Authorization:');
                    }
                });
                const items = filteredHits.map((item) => {
                    return {
                        type: PagingListTile,
                        w: 1516 + 12,
                        h: 275 + 12,
                        item: item,
                        signals: { onClickItem: true },
                    };
                });
                super.addItems(items);
                this._fetchingMore = false;
            });
        }
    }
}
