import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
const getExitAttributes = (params) => ({
    'Destination URL': params.destinationURL || MPARTICLE_DEFAULT_ATTR_VALUE,
    'Destination Type': params.destinationType || MPARTICLE_DEFAULT_ATTR_VALUE,
    'Notification Title': params.notificationTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
    'Custom Shelf Title': 'Peacock Live Stream Modal',
    'Custom Shelf Position': MPARTICLE_DEFAULT_ATTR_VALUE,
    'Content Position': 1,
    Brand: params.brand || MPARTICLE_DEFAULT_ATTR_VALUE,
    Show: params.show || MPARTICLE_DEFAULT_ATTR_VALUE,
    Season: params.season || MPARTICLE_DEFAULT_ATTR_VALUE,
});
export default getExitAttributes;
