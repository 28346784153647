class TVPlatformModel {
    constructor() {
        this._deepLink = null;
        this._deepLinkRedirected = false;
    }
    setDeepLink(route, params) {
        this._deepLink = {
            route,
            params,
        };
    }
    getDeepLink() {
        return this._deepLink;
    }
    setDeepLinkRedirected(value) {
        this._deepLinkRedirected = value;
    }
    getDeepLinkRedirected() {
        return this._deepLinkRedirected;
    }
}
export default TVPlatformModel;
