import { Lightning, Log } from '@lightningjs/sdk';
import { TrickPlayImagesSpawner } from '../../../../api/spawners';
import { setSmooth } from '../../../../helpers';
import { PLAYER_TYPE, PlayerFactorySingleton } from '../../../../player/core/PlayerFactory';
import TVPlatform from '../../../../lib/tv-platform';
import { ErrorType } from '../../../../lib/tv-platform/types';
export const WithTrickPlay = (base) => class extends base {
    constructor() {
        super(...arguments);
        this._hasThumbnailVariant = false;
    }
    set trickPlayImagesData(data) {
        this._trickPlayImagesSpawner = new TrickPlayImagesSpawner(data);
        this.trickPlayImagesList = this._trickPlayImagesSpawner.createTrickPlayImagesList(this.stage);
    }
    set trickPlayImagesList(v) {
        this._trickPlayImagesList = v;
        v.x = -160;
        v.y = 730;
        this.tag('PlayerControls').patch({
            TrickPlay: v,
        });
        this.tag('TrickPlay').alpha = 0;
    }
    _updateProgress(progress) {
        super._updateProgress(progress);
        this.updateTrickPlayThumbnail().then(() => this.updateTrickPlayImageIndex(progress[0]));
    }
    async updateTrickPlayThumbnail() {
        var _a;
        if (this._hasThumbnailVariant)
            return false;
        Log.info('corevideo isTrickPlayVariants', this._trickPlayImagesList);
        const player = PlayerFactorySingleton.player(PLAYER_TYPE.MAIN);
        if ((player === null || player === void 0 ? void 0 : player._sessionController) &&
            this._trickPlayImagesList &&
            this._trickPlayImagesList.length > 0 &&
            !this._hasThumbnailVariant) {
            this._hasThumbnailVariant = await player._sessionController.setThumbnailVariant((_a = this._trickPlayImagesList[0]) === null || _a === void 0 ? void 0 : _a.id);
            Log.info('corevideo updateTrickPlayThumbnail', this._hasThumbnailVariant);
            this.tag('TrickPlay').alpha = 0;
            return this._hasThumbnailVariant;
        }
        else {
            return false;
        }
    }
    async updateTrickPlayImageIndex(seekTime) {
        if (!this._hasThumbnailVariant)
            return;
        const player = PlayerFactorySingleton.player(PLAYER_TYPE.MAIN);
        const sessionController = player === null || player === void 0 ? void 0 : player._sessionController;
        if (!sessionController) {
            TVPlatform.reportError({
                type: ErrorType.OTHER,
                description: 'updateTrickPlayImageIndex:: Session controller is not available',
            });
            return;
        }
        try {
            const img = await sessionController.getThumbnailForTime(seekTime);
            const { currentThumbnail } = img || {};
            const { url, boundingRect } = currentThumbnail || {};
            if (!url || !boundingRect) {
                TVPlatform.reportError({
                    type: ErrorType.NETWORK,
                    description: 'Thumbnail data is incomplete sessionController.getThumbnailForTime is returning',
                    payload: img,
                });
                return;
            }
            const trickPlayImageTag = this.tag('TrickPlay.Image');
            trickPlayImageTag.patch({
                texture: {
                    type: Lightning.textures.ImageTexture,
                    src: url,
                },
            });
            // PL-2777 xbox crashes with trick play scrolling, force gc
            if (TVPlatform.getForceGC())
                this.stage.gc();
            const { left, top } = boundingRect;
            Log.info(`corevideo updateTrickPlayImageIndex ${seekTime}`, img);
            trickPlayImageTag.x = -left;
            trickPlayImageTag.y = -top;
        }
        catch (error) {
            TVPlatform.reportError({
                type: ErrorType.OTHER,
                description: 'Error updating trick play image index',
                payload: error,
            });
        }
    }
    addThumbnailVariants(data) {
        this._trickPlayImagesList = data._thumbnailVariants;
    }
    _toggleTrickPlay(visible) {
        if (this._hasThumbnailVariant) {
            setSmooth(this.tag('TitleContainer'), 'alpha', Number(!visible));
            setSmooth(this.tag('TrickPlay'), 'alpha', Number(visible));
        }
    }
    _onSeekingStarted() {
        this._toggleTrickPlay(true);
    }
    _onSeekingEnded() {
        this._toggleTrickPlay(false);
    }
};
