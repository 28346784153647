import { Storage } from '@lightningjs/sdk';
import Preferences from '../lib/Preferences';
import { STORAGE_KEYS, LegacyStoreKey } from '../constants';
import { DebugControllerSingleton } from '../util/debug/DebugController';
import { CCFontSizes } from '../lib/ClosedCaptions/ClosedCaptionsUtils';
const LegacyKeys = Object.freeze([
    LegacyStoreKey.deviceId,
    LegacyStoreKey.ccOptions,
    LegacyStoreKey.accessToken,
    LegacyStoreKey.language,
]);
var LegacyCCOptions;
(function (LegacyCCOptions) {
    LegacyCCOptions["audio"] = "audio";
    LegacyCCOptions["backgroundColor"] = "backgroundColor";
    LegacyCCOptions["backgroundOpacity"] = "backgroundOpacity";
    LegacyCCOptions["captions"] = "captions";
    LegacyCCOptions["edgeColor"] = "edgeColor";
    LegacyCCOptions["edgeOpacity"] = "edgeOpacity";
    LegacyCCOptions["font"] = "font";
    LegacyCCOptions["fontEdge"] = "fontEdge";
    LegacyCCOptions["fontSize"] = "fontSize";
    LegacyCCOptions["textColor"] = "textColor";
    LegacyCCOptions["textOpacity"] = "textOpacity";
    LegacyCCOptions["windowColor"] = "windowColor";
    LegacyCCOptions["windowOpacity"] = "windowOpacity";
})(LegacyCCOptions || (LegacyCCOptions = {}));
const CCOptionsMap = Object.freeze(new Map([
    [LegacyCCOptions.captions, Preferences.CLOSED_CAPTION],
    [LegacyCCOptions.fontSize, Preferences.CLOSED_CAPTION_FONT_SIZE],
    [LegacyCCOptions.textOpacity, Preferences.CLOSED_CAPTION_FONT_OPACITY],
    [LegacyCCOptions.fontSize, Preferences.CLOSED_CAPTION_FONT_SIZE],
    [LegacyCCOptions.textColor, Preferences.CLOSED_CAPTION_FONT_COLOR],
    [LegacyCCOptions.backgroundOpacity, Preferences.CLOSED_CAPTION_BACKGROUND_OPACITY],
    [LegacyCCOptions.backgroundColor, Preferences.CLOSED_CAPTION_BACKGROUND_COLOR],
    [LegacyCCOptions.windowOpacity, Preferences.CLOSED_CAPTION_WINDOW_OPACITY],
    [LegacyCCOptions.windowColor, Preferences.CLOSED_CAPTION_WINDOW_COLOR],
    [LegacyCCOptions.edgeOpacity, Preferences.CLOSED_CAPTION_EDGE_OPACITY],
    [LegacyCCOptions.edgeColor, Preferences.CLOSED_CAPTION_EDGE_COLOR],
]));
export const migrateLocalStorage = () => {
    LegacyKeys.forEach((key) => {
        var _a;
        //Using lightning storage here did not work and failed with app language because it was not a string value
        //So added a fallback to get it from window local storage directly
        const data = Storage.get(key) || ((_a = window === null || window === void 0 ? void 0 : window.localStorage) === null || _a === void 0 ? void 0 : _a.getItem(key));
        if (data) {
            switch (key) {
                case LegacyStoreKey.deviceId:
                    Preferences.store(Preferences.DEVICE_ID, data);
                    break;
                case LegacyStoreKey.accessToken:
                    Preferences.store(Preferences.ACCESS_TOKEN, data);
                    break;
                case LegacyStoreKey.language:
                    Storage.set(STORAGE_KEYS.APP_LANGUAGE, data);
                    break;
                case LegacyStoreKey.ccOptions:
                    if (typeof data === 'object') {
                        Object.keys(data).forEach((key) => {
                            if (CCOptionsMap.has(key)) {
                                // Map font sizes from behemoth
                                if (key === LegacyCCOptions.fontSize) {
                                    switch (data[key]) {
                                        case 2:
                                            data[key] = CCFontSizes.small;
                                            break;
                                        case 3:
                                            data[key] = CCFontSizes.medium;
                                            break;
                                        case 4:
                                            data[key] = CCFontSizes.large;
                                            break;
                                        default:
                                            data[key] = CCFontSizes.medium;
                                    }
                                }
                                Preferences.store(CCOptionsMap.get(key), data[key]);
                            }
                        });
                    }
                    break;
            }
            DebugControllerSingleton._setGenericValue(key, data);
            Storage.remove(key);
        }
    });
};
