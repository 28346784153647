import { IPlayerEvent } from './IPlayerEvent';
export class SeekEvent extends IPlayerEvent {
    constructor(metadata) {
        super();
        this._metadata = metadata;
    }
    get metadata() {
        var _a;
        return (_a = this._metadata) !== null && _a !== void 0 ? _a : JSON.parse('{}');
    }
}
