export var OLY_IMPOLICY;
(function (OLY_IMPOLICY) {
    OLY_IMPOLICY["HERO"] = "hero_large_1920_590";
    OLY_IMPOLICY["PLACEHOLDER"] = "placeholder_32_18";
    OLY_IMPOLICY["RECT_LARGE_1920_1080"] = "rect_large_1920_1080";
    OLY_IMPOLICY["RECT_MEDIUM_1024_576"] = "rect_medium_1024_576";
    OLY_IMPOLICY["RECT_SMEDIUM_640_360"] = "rect_smedium_640_360";
    OLY_IMPOLICY["RECT_SMALL_384_216"] = "rect_small_384_216";
    OLY_IMPOLICY["SQUARE"] = "square_medium_348";
    OLY_IMPOLICY["PORTRAIT"] = "vert_large_489_725";
    OLY_IMPOLICY["NONE"] = "none";
})(OLY_IMPOLICY || (OLY_IMPOLICY = {}));
export var IMAGE_RESIZE_MODE;
(function (IMAGE_RESIZE_MODE) {
    IMAGE_RESIZE_MODE["CONTAIN"] = "contain";
    IMAGE_RESIZE_MODE["COVER"] = "cover";
})(IMAGE_RESIZE_MODE || (IMAGE_RESIZE_MODE = {}));
