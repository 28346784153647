import { Lightning } from '@lightningjs/sdk';
import SubMenuItem from './SubMenuItem';
import { COLORS } from '../../constants';
export default class SubMenu extends Lightning.Component {
    static _template() {
        return {
            h: 68,
            rect: true,
            color: COLORS.thunder,
            Focus: {
                rtt: true,
                shader: { type: Lightning.shaders.RoundedRectangle, radius: 5 },
                rect: true,
                color: COLORS.lightGray1,
                alpha: 0,
                h: (h) => h,
            },
            Items: {
                rtt: true,
                h: 68,
            },
            DarkClipper: {
                clipping: true,
                h: (h) => h,
                Dark: {
                    color: COLORS.dark2,
                },
            },
        };
    }
    _init() {
        this._index = 0;
        this.tag('Dark').texture = this.tag('Items').getTexture();
    }
    set items(v) {
        this.tag('Items').patch({
            children: v.map((item, index) => {
                return { type: SubMenuItem, item, index, mountX: 1 };
            }),
        });
        this._positionItems();
    }
    _positionItems() {
        let x = 0;
        this.tag('Items').children.forEach((child) => {
            child.setSmooth('alpha', 1, {
                duration: 0.6,
                timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)',
            });
            child.setSmooth('x', x);
            x += child.width + 60;
        });
        if (this.tag('Items').w === 0) {
            this.tag('Items').w = x;
        }
    }
    $updateSubMenu() {
        this._updateElements();
    }
    _focus() {
        this._setFocus(true);
        this._animateToSelected(0, true);
    }
    _unfocus() {
        this._index = 0;
        this._setFocus(false);
        this.tag('Focus').setSmooth('alpha', 0);
    }
    _setFocus(v) {
        this.patch({
            Focus: { smooth: { alpha: v ? 1 : 0 } },
            DarkClipper: {
                smooth: { alpha: v ? 1 : 0 },
            },
        });
    }
    _handleLeft() {
        if (this._index > 0) {
            this._animateToSelected(-1);
        }
        else {
            return false;
        }
    }
    _handleRight() {
        if (this._index < this.tag('Items').children.length - 1) {
            this._animateToSelected(1);
        }
        else {
            return false;
        }
    }
    set activeIndex(index) {
        this._index = index;
    }
    get activeIndex() {
        return this._index;
    }
    get activeItem() {
        return this.tag('Items').children[this._index];
    }
    _animateToSelected(direction, immediate) {
        this._index += direction;
        const duration = immediate ? 0 : 0.3;
        this._updateElements(duration);
    }
    _updateElements(duration = 0) {
        this.patch({
            Focus: {
                smooth: {
                    x: [this.activeItem.x - 20, { duration }],
                    w: [this.activeItem.width + 40, { duration }],
                },
            },
            DarkClipper: {
                smooth: {
                    x: [this.activeItem.x, { duration }],
                    w: [this.activeItem.width, { duration }],
                },
                Dark: {
                    smooth: {
                        x: [-this.activeItem.x, { duration }],
                    },
                },
            },
        });
    }
    _getFocused() {
        return this.activeItem || this;
    }
}
