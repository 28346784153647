import Tiles from '../tiles';
import Tile from './Tile';
import { COMPONENT_TYPES } from '../../../constants';
export default class EndCardAlternate extends Tile {
    constructor(obj = {}, groupTagLine = null, isDefault = false) {
        super(obj);
        if (!this.tileComponent)
            return;
        this._groupTagLine = groupTagLine;
        this._isDefault = isDefault;
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        this._tile = new Tiles[this.tileComponent](this.data.tile);
    }
    get tagLine() {
        return this._groupTagLine || this.data.tagLine || '';
    }
    get tileComponent() {
        return this.data.tile.component || '';
    }
    get tile() {
        return this._tile;
    }
    get isDefault() {
        return this._isDefault;
    }
    get component() {
        return this.tileComponent === COMPONENT_TYPES.UPCOMING_LIVE_TILE
            ? COMPONENT_TYPES.END_CARD_ALTERNATE_SLE
            : this.obj.component;
    }
    get analytics() {
        var _a, _b, _c, _d;
        return ((_a = this === null || this === void 0 ? void 0 : this.obj) === null || _a === void 0 ? void 0 : _a.analytics) || ((_d = (_c = (_b = this === null || this === void 0 ? void 0 : this.obj) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.tile) === null || _d === void 0 ? void 0 : _d.analytics) || null;
    }
}
