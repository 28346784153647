import { Lightning, Router, Utils } from '@lightningjs/sdk';
import { Confirm } from './Confirm';
import { Wrapper } from './Wrapper';
import { COLORS } from '../../../constants';
import { AllowSaleHook, ConsentManagement } from '../../../lib/cmp/ConsentManagement';
import { CMPGroupIds } from './types';
var CMPPreferenceCenterComponents;
(function (CMPPreferenceCenterComponents) {
    CMPPreferenceCenterComponents["Wrapper"] = "Wrapper";
    CMPPreferenceCenterComponents["Confirm"] = "Confirm";
})(CMPPreferenceCenterComponents || (CMPPreferenceCenterComponents = {}));
export class CMPPreferenceCenter extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._focusedComponent = CMPPreferenceCenterComponents.Wrapper;
    }
    static _template() {
        return {
            w: 1920,
            h: 1080,
            rect: true,
            color: COLORS.white,
            Logo: {
                x: 1868,
                y: 58,
                mountX: 1,
                src: Utils.asset('images/logo.png'),
            },
            Wrapper: { type: Wrapper },
            Confirm: {
                y: 928,
                x: 0,
                type: Confirm,
            },
        };
    }
    _handleUp() {
        if (this._focusedComponent === CMPPreferenceCenterComponents.Confirm) {
            this._focusedComponent = CMPPreferenceCenterComponents.Wrapper;
        }
    }
    _handleDown() {
        if (this._focusedComponent !== CMPPreferenceCenterComponents.Confirm) {
            this._focusedComponent = CMPPreferenceCenterComponents.Confirm;
        }
    }
    _getFocused() {
        return this.tag(this._focusedComponent);
    }
    _handleEnter() {
        if (this._focusedComponent === CMPPreferenceCenterComponents.Confirm) {
            ConsentManagement.ctvOptOutConfirm({ [CMPGroupIds.ALLOW_SALE]: AllowSaleHook().value });
            Router.back();
        }
    }
}
