import { Lightning, Utils } from '@lightningjs/sdk';
import { COLORS, FLEX_DIRECTION, FONT_FACE } from '../../../constants';
import { AllowSaleHook } from '../../../lib/cmp/ConsentManagement';
import LinearGradientShader from '../../../shaders/LinearGradientShader';
import { generateTextElementsByHtml, normalizeTextBlocks } from './helpers';
const WRAPPER_HEIGHT = 776;
const CONTENT_OFFSET = 500;
const SCROLL_STEP = 175;
export class AllowForm extends Lightning.Component {
    static _template() {
        return {
            h: 776,
            w: 1246,
            Title: {
                x: 107,
                color: COLORS.black,
                text: {
                    fontFace: FONT_FACE.bold,
                    fontSize: 48,
                    wordWrapWidth: 1061,
                    text: this.bindProp('title'),
                },
            },
            Container: {
                h: 776,
                w: 1246,
                clipping: true,
                Content: {
                    w: 1246,
                    x: 107,
                    y: 0,
                    flex: {
                        direction: FLEX_DIRECTION.column,
                    },
                },
                GradientStart: {
                    x: 0,
                    y: 0,
                    w: 1246,
                    h: 30,
                    rect: true,
                    shader: {
                        type: LinearGradientShader,
                        colors: [COLORS.white, COLORS.transparent],
                        stops: [1.0, 0.0],
                        angle: 0,
                    },
                },
            },
            GradientEnd: {
                x: 0,
                y: 776,
                w: 1246,
                h: 30,
                mountY: 1,
                rect: true,
                shader: {
                    type: LinearGradientShader,
                    colors: [COLORS.transparent, COLORS.white],
                    stops: [1.0, 0.0],
                    angle: 0,
                },
            },
        };
    }
    _init() {
        this.tag('Title').on('txLoaded', () => {
            var _a;
            const h = this.tag('Title').renderHeight;
            (_a = this.tag('Container')) === null || _a === void 0 ? void 0 : _a.patch({
                y: h,
                h: 776 - h,
            });
        });
        this._hook = AllowSaleHook((v) => {
            var _a;
            (_a = this.tag('Tick.Img')) === null || _a === void 0 ? void 0 : _a.patch({
                visible: !!v,
            });
        });
    }
    _detach() {
        var _a;
        (_a = this._hook) === null || _a === void 0 ? void 0 : _a.unsubscribe(false);
    }
    get announce() {
        return this._announce;
    }
    set announce(announce) {
        this._announce = [this.title, ...announce];
    }
    set content(content) {
        const htmlBody = new DOMParser().parseFromString(content[1], 'text/html').body;
        const normalizedBody = htmlBody.getElementsByTagName('p').length > 0 ? htmlBody : normalizeTextBlocks(htmlBody);
        const paragraphs = Array.prototype.map
            .call([normalizedBody], generateTextElementsByHtml)
            .flat(Infinity);
        if (paragraphs === null || paragraphs === void 0 ? void 0 : paragraphs.length) {
            this.announce = paragraphs.reduce((acc, item) => {
                var _a;
                const i = ((_a = item === null || item === void 0 ? void 0 : item.text) === null || _a === void 0 ? void 0 : _a.text) &&
                    new DOMParser().parseFromString(item.text.text, 'text/html').body.innerText;
                return i ? [...acc, i] : acc;
            }, []);
            this.tag('Content').patch({
                Checkbox: {
                    w: 1050,
                    h: 350,
                    rect: true,
                    color: COLORS.lightGray14,
                    flexItem: {
                        marginTop: 30,
                        marginBottom: 30,
                    },
                    CheckboxContainer: {
                        shader: {
                            type: Lightning.shaders.Inversion,
                            amount: 1,
                        },
                        Tick: {
                            x: 1000,
                            y: 30,
                            mountX: 0.5,
                            rect: true,
                            h: 40,
                            w: 40,
                            texture: Lightning.Tools.getRoundRect(40, 40, 0, 4, COLORS.white, false),
                            Img: {
                                y: 20,
                                x: 20,
                                mount: 0.5,
                                src: Utils.asset('images/tick.png'),
                            },
                        },
                        Text: {
                            x: 30,
                            y: 20,
                            color: COLORS.white,
                            text: {
                                fontSize: 33,
                                fontFace: FONT_FACE.light,
                                wordWrapWidth: 1000,
                                text: content[0],
                            },
                        },
                    },
                },
                ...paragraphs.reduce((acc, p, i) => ({
                    ...acc,
                    [`Text${i}`]: p,
                }), {}),
            });
            this.tag('Checkbox.Text').on('txLoaded', (obj) => {
                this.tag('Checkbox').patch({
                    h: Math.ceil((Math.ceil(obj._source.renderInfo.h) + 40) / obj._source.renderInfo.precision),
                });
            });
            this.stage.update();
        }
    }
    get focusable() {
        return true;
    }
    _focus() {
        this._setFocused(true);
    }
    _unfocus() {
        this._setFocused();
    }
    _setFocused(focused = false) {
        var _a, _b;
        (_a = this.tag('Checkbox')) === null || _a === void 0 ? void 0 : _a.patch({
            color: focused ? COLORS.mediumGray6 : COLORS.lightGray14,
        });
        (_b = this.tag('CheckboxContainer')) === null || _b === void 0 ? void 0 : _b.patch({
            shader: {
                amount: focused ? 0 : 1,
            },
        });
    }
    _handleDown() {
        const el = this.tag('Content');
        if (el.y + WRAPPER_HEIGHT > CONTENT_OFFSET) {
            el.y -= SCROLL_STEP;
        }
        else {
            return false;
        }
    }
    _handleUp() {
        const el = this.tag('Content');
        if (el.y !== 0) {
            el.y += SCROLL_STEP;
        }
        else {
            return false;
        }
    }
    _handleEnter() {
        this._hook.set(!this._hook.value);
    }
}
