import { Storage } from '@lightningjs/sdk';
import { IStorage } from './IStorage';
export class LSStorage extends IStorage {
    set(key, value) {
        Storage.set(key, value);
    }
    get(key) {
        return Storage.get(key);
    }
    remove(key) {
        Storage.remove(key);
    }
    clear() {
        Storage.clear();
    }
}
