import { Log } from '@lightningjs/sdk';
import { get, merge } from 'lodash';
import { getAllCaches, modify } from '../../cache';
import { WATCH_PROGRESS_TAG, PAGE_TYPES } from './watchProgressConstants';
import { isSmartTile, isVideoHomeShelf, updateCTA, getContinueWatchingShelf, getContinueWatchingTile, } from './watchProgressHelpers';
import TVPlatform from '../../../lib/tv-platform';
import { ErrorType } from '../../../lib/tv-platform/types';
class WatchProgress {
    constructor() {
        this._updateSmartTileSection = (section, percentViewed) => merge({}, section, {
            data: {
                label: updateCTA(section.data.label),
                tile: { data: { percentViewed } },
            },
        });
        this._updateVideoTileSection = (section, percentViewed, linkSelectableIndex, tileIndex) => merge({}, section, {
            data: {
                items: {
                    [linkSelectableIndex]: {
                        data: { items: { [tileIndex]: { data: { percentViewed } } } },
                    },
                },
            },
        });
        this._updateVideoHomeData = (data, { video: { mpxGuid }, percentViewed }) => {
            const sections = (data.sections || []).map((section) => {
                if (isSmartTile(section, mpxGuid)) {
                    return this._updateSmartTileSection(section, percentViewed);
                }
                else if (isVideoHomeShelf(section)) {
                    let tileIndex = -1;
                    let linkSelectableIndex = -1;
                    section.data.items.forEach((shelf, shelfIndex) => {
                        var _a;
                        const items = ((_a = shelf.data) === null || _a === void 0 ? void 0 : _a.items) || [];
                        items.forEach((item, index) => {
                            var _a;
                            if (((_a = item === null || item === void 0 ? void 0 : item.data) === null || _a === void 0 ? void 0 : _a.mpxGuid) === mpxGuid) {
                                linkSelectableIndex = shelfIndex;
                                tileIndex = index;
                            }
                        });
                    });
                    if (linkSelectableIndex > -1 && tileIndex > -1)
                        return this._updateVideoTileSection(section, percentViewed, linkSelectableIndex, tileIndex);
                }
                return section;
            });
            return { sections };
        };
        this._updateContinueWatchingShelf = (sections, video, percentViewed) => {
            const cwShelfIndex = sections.findIndex((shelf) => shelf && shelf.logicName === 'continueWatchingShelf');
            if (cwShelfIndex === -1) {
                // Add continue watching shelf.
                const tile = getContinueWatchingTile(video, percentViewed);
                return [getContinueWatchingShelf(tile), ...sections];
            }
            else {
                // Add or update continue watching tile.
                const tileIndex = sections[cwShelfIndex].data.items.findIndex((item) => item && get(item, 'data.mpxGuid') === video.mpxGuid);
                let { items } = sections[cwShelfIndex].data;
                if (tileIndex === -1) {
                    items = merge({}, items, [...items, getContinueWatchingTile(video, percentViewed)]);
                }
                else {
                    items = merge({}, items, { [tileIndex]: { data: { percentViewed } } });
                }
                return merge([], sections, { [cwShelfIndex]: { data: { items } } });
            }
        };
        this._updateFeaturedItems = (featured, mpxGuid, percentViewed) => {
            const updatedItems = (get(featured, 'data.items') || []).map((item) => {
                if (item && get(item, 'data.cta.data.destination') === mpxGuid) {
                    const ctaText = updateCTA(item.data.cta.data.text);
                    return merge({}, item, {
                        data: { percentViewed, cta: { data: { text: ctaText } } },
                    });
                }
                return item;
            });
            return merge({}, featured, { data: { items: updatedItems } });
        };
        this._updatePageData = (data, { video, percentViewed, name }) => {
            const { mpxGuid } = video;
            const sections = (data.sections || []).map((section) => {
                if (get(section, 'data.items.length')) {
                    const tileIndex = section.data.items.findIndex((item) => item &&
                        (get(item, 'data.mpxGuid') === mpxGuid ||
                            (item.component === 'SlideTile' &&
                                get(item, 'data.cta.data.destination') === mpxGuid)));
                    if (tileIndex > -1) {
                        const tileData = section.data.items[tileIndex].data;
                        const mergeData = tileData.cta
                            ? {
                                percentViewed,
                                cta: { data: { text: updateCTA(tileData.cta.data.text) } },
                            }
                            : { percentViewed };
                        return merge({}, section, {
                            data: { items: { [tileIndex]: { data: mergeData } } },
                        });
                    }
                }
                return section;
            });
            return {
                sections: name === 'homepage'
                    ? this._updateContinueWatchingShelf(sections, video, percentViewed)
                    : sections,
                ...(data.featured
                    ? {
                        featured: this._updateFeaturedItems(data.featured, mpxGuid, percentViewed),
                    }
                    : {}),
            };
        };
        this.SECTIONS_MAP = {
            [PAGE_TYPES.TITLE]: this._updateVideoHomeData,
            [PAGE_TYPES.SERIES]: this._updateVideoHomeData,
            [PAGE_TYPES.PAGE]: this._updatePageData,
            [PAGE_TYPES.BRAND_LANDING]: this._updatePageData,
        };
        this.hasWatchedContent = false;
    }
    set hasWatchedContent(value) {
        this._hasWatchedContent = value;
    }
    get hasWatchedContent() {
        return this._hasWatchedContent;
    }
    logWatchProgress(video, percentViewed) {
        if (!video)
            return;
        Log.info(WATCH_PROGRESS_TAG, `log watch progress ${percentViewed}`);
        if (!this.hasWatchedContent) {
            this.hasWatchedContent = true;
        }
        const bffCache = getAllCaches(video);
        Array.prototype.map.call(bffCache, ({ data }) => {
            const bffData = get(data, 'bonanzaPage.data.sections', []);
            if (bffData.length && this.SECTIONS_MAP[get(data, 'bonanzaPage.pageType')]) {
                // Update tile progress in shelves and slides.
                this._modifyCache(data.bonanzaPage, video, percentViewed);
            }
        });
        return true;
    }
    _modifyCache({ id: cacheId, pageType, name }, video, percentViewed) {
        try {
            const modifier = this.SECTIONS_MAP[pageType];
            const params = { video, percentViewed, name };
            modify(cacheId, modifier, params);
        }
        catch (error) {
            TVPlatform.reportError({
                type: ErrorType.OTHER,
                code: WATCH_PROGRESS_TAG,
                description: 'error updating shelf watch progress',
                payload: error,
            });
        }
    }
}
export default WatchProgress;
