import { Lightning, Settings } from '@lightningjs/sdk';
import TVPlatform from '../../lib/tv-platform';
class ItemWrapper extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._lazyProps = null;
    }
    static _template() {
        return {
            clipbox: true,
            w: 320,
            h: 320,
        };
    }
    set item(obj) {
        this._item = obj;
    }
    get item() {
        return this._item;
    }
    set index(v) {
        this._index = v;
    }
    get child() {
        return this.children[0];
    }
    set itemType(v) {
        this._itemType = v;
    }
    setChildProps(props) {
        var _a, _b;
        if (this.child) {
            (_b = (_a = this.child).patch) === null || _b === void 0 ? void 0 : _b.call(_a, props);
        }
        else {
            this._lazyProps = props;
        }
    }
    $getCurrentScrollPosition() {
        const result = { ...this.fireAncestors('$getCurrentScrollPosition') };
        if (!result.content)
            result.content = this._index || 0;
        return result;
    }
    create() {
        const item = this._item;
        const index = this._index;
        this.children = [
            {
                type: this._itemType,
                item: item,
                index,
                ...(this._lazyProps || {}),
            },
        ];
        if (this._lazyProps)
            this._lazyProps = null;
        this._itemIsCreated = true;
        if (this._notifyOnItemCreation && this.hasFocus()) {
            this._refocus();
        }
    }
    _firstActive() {
        this.create();
        if (!ItemWrapper.FIRST_CREATED) {
            this.fireAncestors('$firstItemCreated');
            ItemWrapper.FIRST_CREATED = true;
        }
    }
    _inactive() {
        this.childList.clear();
        this._itemIsCreated = false;
        if (Settings.get('app', 'gcList') || TVPlatform.getForceGC()) {
            this.stage.gc();
        }
    }
    _active() {
        if (!this._itemIsCreated) {
            this.create();
        }
    }
    // @ts-expect-error TS(2416): Property '_getFocused' in type 'ItemWrapper' is no... Remove this comment to see the full error message
    _getFocused() {
        // due to lazy creation there is the possibility that
        // an component receives focus before the actual item
        // is created, therefore we set a flag
        if (!this.child) {
            this._notifyOnItemCreation = true;
        }
        return this.child || this;
    }
}
ItemWrapper.FIRST_CREATED = false;
export default ItemWrapper;
