import { Log } from '@lightningjs/sdk';
import qs from 'qs';
import AppConfigFactorySingleton from '../config/AppConfigFactory';
import Preferences from '../lib/Preferences';
import UserProfile from './models/UserProfile';
import MParticleApi from './MParticleApi';
import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly';
import TVPlatform from '../lib/tv-platform';
import { ErrorType } from '../lib/tv-platform/types';
import AuthenticationSingleton from '../authentication/Authentication';
import { ConsentManagement } from '../lib/cmp/ConsentManagement';
const IDENTITY_TAG = 'Identity';
let pollingTimeOut, pollingInterval, userProfile;
/**
 * Send request to get identity registration code to sign in or sign up
 * @return {Promise} IDM Response Object
 */
export const getIdentityRegCode = async () => {
    const url = getIdentityActivationUrl();
    const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        idmVersion: `v${AppConfigFactorySingleton.config.identity.version}`,
    };
    const body = {
        response_type: 'token',
        scope: 'email profile',
        client_id: getIdentityClientId(),
        nonce: '1234',
    };
    const response = await fetch(url, {
        method: 'POST',
        headers,
        body: qs.stringify(body),
    });
    return await response.json();
};
/**
 * @description Post necessary information to bouncer before beginning polling
 * @param {BouncerPayload} payload Credentials to pass to Bouncer which contains
 * idmcode, landing, and platform
 * @returns {Promise} Record created in Bouncer
 */
export async function pairCredentialsWithBouncer(payload) {
    const url = AppConfigFactorySingleton.config.identity.bouncer;
    const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return await response.json();
}
/**
 * @description Check if user has signed in or signed up with idm <code></code>
 * @param {string} deviceId Name of device, e.g. Vizio, Samsung, etc
 * @param {string} deviceCode Device code given when user requested idm code
 * @param {boolean} isDoingBothPolls Set true if there are two polls running
 * @returns {Promise}
 */
export const pollIDM = (deviceCode, isDoingBothPolls = false) => {
    clearPollingTimeOuts();
    const { identity } = AppConfigFactorySingleton.config;
    return new Promise((resolve, reject) => {
        const pollingTimeoutTime = (identity.polling_timeout || 600) * 1000;
        const pollingIntervalTime = (identity.polling || 10) * 1000;
        pollingTimeOut = setTimeout(() => {
            clearPollingTimeOuts();
            Log.info(`${IDENTITY_TAG} polling timeout executed`);
            reject('polling timeout executed');
        }, pollingTimeoutTime);
        pollingInterval = setInterval(async () => {
            if (isDoingBothPolls && AuthenticationSingleton.isAuthenticated()) {
                clearPollingTimeOuts();
                resolve();
            }
            const url = getIdentityPollingUrl();
            const headers = {
                idmVersion: `v${identity.version}`,
                'Content-Type': 'application/x-www-form-urlencoded',
            };
            const body = {
                grant_type: 'http://oauth.net/grant_type/device/1.0',
                client_id: getIdentityClientId(),
                client_secret: identity.secret,
                code: deviceCode,
            };
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers,
                    body: qs.stringify(body),
                });
                if (response.status !== 200)
                    return;
                const data = await response.json();
                clearPollingTimeOuts();
                saveAccessToken(data.access_token);
                const userProfile = await saveUserProfile();
                await MParticleApi.login(userProfile);
                resolve();
            }
            catch (error) {
                TVPlatform.reportError({
                    type: ErrorType.NETWORK,
                    description: `${IDENTITY_TAG} error polling: ${error}`,
                });
            }
        }, pollingIntervalTime);
    });
};
export async function saveUserProfile() {
    const accessToken = getAccessToken();
    if (!accessToken) {
        return true;
    }
    const { profileService, version } = AppConfigFactorySingleton.config.identity;
    const url = `${profileService}/api/device/validate`;
    const headers = {
        idmVersion: `v${version}`,
        access_token: accessToken,
        'API-Version': '2023.2',
        'X-IDM-Bypass-Vppa': 'true',
    };
    const response = await fetch(url, {
        method: 'GET',
        headers,
    }).catch((error) => Promise.resolve({ error }));
    // @ts-expect-error TS(2339): Property 'status' does not exist on type 'Response... Remove this comment to see the full error message
    if (response.status !== 200)
        return;
    // @ts-expect-error TS(2339): Property 'json' does not exist on type 'Response |... Remove this comment to see the full error message
    const data = await response.json();
    userProfile = new UserProfile(data);
    return userProfile;
}
export const getUserProfile = () => userProfile;
export const logoutUserProfile = async () => {
    deleteAccessToken();
    ConsentManagement.deleteOneTrustToken();
    await MParticleApi.logout();
    userProfile = null;
    // Update the LD context on logout
    LaunchDarklySingleton.updateUserAuthContext(false);
};
export const clearPollingTimeOuts = () => {
    if (pollingTimeOut) {
        clearTimeout(pollingTimeOut);
    }
    if (pollingInterval) {
        clearInterval(pollingInterval);
    }
};
/**
 * Get client_id by platform
 * @returns {string} client_id for Identity payload
 */
const getIdentityClientId = () => {
    const { identity } = AppConfigFactorySingleton.config || {};
    return (identity === null || identity === void 0 ? void 0 : identity.clientId) || 'PlatcoTV';
};
/**
 * Return the correctly formed identity activation url
 * @returns {string} Identity Activation url
 */
const getIdentityActivationUrl = () => {
    const { identity } = AppConfigFactorySingleton.config;
    const [service, realm] = [identity.service, identity.realm];
    return `${service}/oauth2/${realm}/device/code`;
};
/**
 * Return the correctly formed identity polling url
 * @returns {string} Identity Polling url
 */
const getIdentityPollingUrl = () => {
    const { identity } = AppConfigFactorySingleton.config;
    const [service, realm] = [identity.service, identity.realm];
    return `${service}/oauth2/${realm}/access_token`;
};
const saveAccessToken = (accessToken) => {
    Preferences.store(Preferences.ACCESS_TOKEN, accessToken);
};
const deleteAccessToken = () => {
    Preferences.remove(Preferences.ACCESS_TOKEN);
};
export const getAccessToken = () => Preferences.get(Preferences.ACCESS_TOKEN);
