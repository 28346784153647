import { getUserProfile } from '../../../../api/Identity';
import getLinearAttributes from './getLinearAttributes';
import { setDefaultValue } from '../../../../helpers/analytics';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
const getLiveStartupAttributes = (params = {}) => {
    const linearStart = getLinearAttributes(params);
    const userProfile = getUserProfile();
    return setDefaultValue({
        ...linearStart,
        // @ts-expect-error TS(2339): Property 'previousVideo' does not exist on type '{... Remove this comment to see the full error message
        'Previous Video': params.previousVideo,
        // @ts-expect-error TS(2339): Property 'previousVideoType' does not exist on typ... Remove this comment to see the full error message
        'Previous Video Type': params.previousVideoType,
        Resume: 'False',
        'Resume Time': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Episode Credits Left': (userProfile || {}).episodeCount,
        'Smart Tile Title': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Smart Tile Scenario': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Smart Tile Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Smart Tile Episode Title': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Content Position': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Custom Shelf Position': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Custom Shelf Title': MPARTICLE_DEFAULT_ATTR_VALUE,
        Sponsor: MPARTICLE_DEFAULT_ATTR_VALUE,
        'Playlist Name': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Playlist Position': MPARTICLE_DEFAULT_ATTR_VALUE,
        // @ts-expect-error TS(2339): Property 'startUpTime' does not exist on type '{}'... Remove this comment to see the full error message
        Duration: params.startUpTime,
        // @ts-expect-error TS(2339): Property 'initialBufferDuration' does not exist on... Remove this comment to see the full error message
        'Initial Buffer Duration': params.initialBufferDuration,
        // @ts-expect-error TS(2339): Property 'authorizationDuration' does not exist on... Remove this comment to see the full error message
        'Authorization Duration': params.authorizationDuration,
        'Preparation Duration': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Ad Parameter Duration': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Trail Access Duration': MPARTICLE_DEFAULT_ATTR_VALUE,
        'Retrans Duration': MPARTICLE_DEFAULT_ATTR_VALUE,
        Autoplay: 'True',
        // @ts-expect-error TS(2339): Property 'duration' does not exist on type '{}'.
        'Video Duration': params.duration,
    }, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getLiveStartupAttributes;
