import { BehaviorSubject } from 'rxjs';
import { PlayerStatus } from '../PlayerStatus';
import { PlayerStatusEvent } from './PlayerStatusEvent';
export class MutablePlayerEventStream {
    constructor() {
        this._eventRelay = new BehaviorSubject(new PlayerStatusEvent(PlayerStatus.UNKNOWN, null));
    }
    publish(value) {
        this._eventRelay.next(value);
    }
    events() {
        return this._eventRelay;
    }
}
