import { Lightning, Log } from '@lightningjs/sdk';
export const measureTextHeight = (stage, text, style, width) => {
    try {
        const textField = new Lightning.textures.TextTexture(stage);
        textField.text = text;
        textField.w = width;
        const canvas = stage.platform.getDrawingCanvas();
        // @ts-expect-error TS(2445): Property 'renderer' is protected and only accessib... Remove this comment to see the full error message
        const renderer = Lightning.textures.TextTexture.renderer(stage, canvas, textField);
        const info = renderer._calculateRenderInfo();
        return info.h / info.precision;
    }
    catch (e) {
        // Won't TVPlatform.reportError this one since it will send a lot of unneeded events
        Log.error('measureTextHeight error', e);
    }
    return 0;
};
