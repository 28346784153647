import { Lightning, Storage } from '@lightningjs/sdk';
import BaseComponent from '../base';
import ProgressBar from '../progressBar/progressBar';
import { sendMetric } from '../../lib/analytics/Analytics';
import { EVENTS } from '../../lib/analytics/types';
import ContentPosition from '../../util/contentPosition';
import RouterUtil from '../../util/RouterUtil';
import { STORAGE_KEYS, ENTITY_TYPES, COLORS } from '../../constants';
// @ts-expect-error TS(2417): Class static side 'typeof Item' incorrectly extend... Remove this comment to see the full error message
export default class Item extends BaseComponent {
    constructor() {
        super(...arguments);
        this.focusRingMargin = 0;
        this.containerWidth = 0;
        this.containerHeight = 0;
        this.useFocusRing = true;
    }
    _init() {
        super._init();
        this.focusRingMargin = 28;
        this.containerWidth = 420;
        this.containerHeight = 240;
    }
    static _template() {
        const duration = Item.duration;
        const timingFunction = Item.timingFunction;
        return {
            transitions: {
                alpha: { duration, timingFunction },
            },
            Focus: {
                color: COLORS.transparent,
                shader: { type: Lightning.shaders.Outline, width: 6, color: COLORS.white },
                x: this.bindProp('focusRingMargin', ({ focusRingMargin = 0 }) => -(focusRingMargin / 2)),
                y: this.bindProp('focusRingMargin', ({ focusRingMargin = 0 }) => -(focusRingMargin / 2)),
                h: this.bindProp('containerHeight', ({ useFocusRing = true, containerHeight = 0 }) => useFocusRing && containerHeight + 28),
                w: this.bindProp('containerWidth', ({ useFocusRing = true, containerWidth = 0 }) => useFocusRing && containerWidth + 28),
                rect: true,
                alpha: 0,
            },
        };
    }
    get announce() {
        var _a;
        return this.ariaLabel || ((_a = this._item) === null || _a === void 0 ? void 0 : _a.ariaLabel);
    }
    set announce(announce) {
        this.ariaLabel = announce;
    }
    _removeBadge() {
        this.patch({
            ImageHolder: {
                Badge: undefined,
            },
        });
    }
    _addProgress({ y, totalProgressWidth, totalProgressColor, currentProgressWidth, currentProgressColor, }) {
        this.patch({
            ImageHolder: {
                ProgressBar: {
                    y,
                    type: ProgressBar,
                    h: 10,
                    totalProgressWidth: totalProgressWidth,
                    totalProgressColor: totalProgressColor,
                    currentProgressColor: currentProgressColor,
                    currentProgressWidth: currentProgressWidth,
                },
            },
        });
    }
    _focusAnimation(v) {
        var _a;
        (_a = this.tag('Focus')) === null || _a === void 0 ? void 0 : _a.patch({ alpha: v ? 1 : 0 });
    }
    _focus() {
        this._focusAnimation(true);
    }
    _unfocus() {
        this._focusAnimation(false);
    }
    trackContentClick(entityType) {
        if (!this._item)
            return;
        const { analytics, shelf } = this._item;
        // Adjust shelf index for DLS / SmartTile, start from 1.
        const adjustedShelfIndex = (shelf === null || shelf === void 0 ? void 0 : shelf.shelfIndex) + (RouterUtil.isHomePage() ? 2 : 1);
        // Start tile index at 1.
        const adjustedTileIndex = (shelf === null || shelf === void 0 ? void 0 : shelf.tileIndex) + 1;
        const params = {
            entity: {
                entityType,
                analytics,
            },
            shelf: {
                ...shelf,
                tileIndex: adjustedTileIndex,
                shelfIndex: adjustedShelfIndex,
                isUpcoming: this._item.isUpcoming,
                isLive: this._item.isLive,
            },
            tileParams: this._getTrackParams(),
        };
        if (shelf) {
            if (entityType === ENTITY_TYPES.ON_AIR ||
                entityType === ENTITY_TYPES.VIDEO ||
                entityType === ENTITY_TYPES.REPLAY ||
                entityType === ENTITY_TYPES.UPCOMING_LIVE_TILE) {
                Storage.set(STORAGE_KEYS.REFERRING_SHELF, shelf);
            }
            if (shelf.playlistMachineName) {
                Storage.set(STORAGE_KEYS.PLAYLIST_MACHINE_NAME, shelf.playlistMachineName);
            }
        }
        sendMetric(EVENTS.CONTENT_CLICK, params);
    }
    _getTrackParams() {
        return {};
    }
    trackContentPosition() {
        const position = this.fireAncestors('$getCurrentScrollPosition');
        if (position)
            ContentPosition.setPositionForCurrentPage(position);
    }
    get audioLanguageContainer() {
        return this.tag('AudioLanguageContainer');
    }
    static get duration() {
        return 0.3;
    }
    static get timingFunction() {
        return 'cubic-bezier(0.20, 1.00, 0.80, 1.00)';
    }
    static get tag() {
        return 'item';
    }
}
