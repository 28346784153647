export var PARK_ENTITY_TYPES;
(function (PARK_ENTITY_TYPES) {
    PARK_ENTITY_TYPES["series"] = "series";
    PARK_ENTITY_TYPES["movie"] = "movies";
})(PARK_ENTITY_TYPES || (PARK_ENTITY_TYPES = {}));
//These were taken from the generated graphql. They should be removed if we re-implement that
export var QueryNames;
(function (QueryNames) {
    QueryNames["BecauseYouWatchedShelf"] = "becauseYouWatchedShelf";
    QueryNames["BonanzaPage"] = "bonanzaPage";
    QueryNames["BrandSeriesGroupedByCategory"] = "brandSeriesGroupedByCategory";
    QueryNames["BrandTitleCategories"] = "brandTitleCategories";
    QueryNames["ComponentsForPlaceholders"] = "componentsForPlaceholders";
    QueryNames["ContinueWatchingShelf"] = "continueWatchingShelf";
    QueryNames["DaySchedule"] = "daySchedule";
    QueryNames["EditorialShelf"] = "editorialShelf";
    QueryNames["EndCard"] = "endCard";
    QueryNames["FavoriteInteractions"] = "favoriteInteractions";
    QueryNames["FavoritesShelf"] = "favoritesShelf";
    QueryNames["FeaturedSection"] = "featuredSection";
    QueryNames["FeaturedShowsSection"] = "featuredShowsSection";
    QueryNames["GlobalNavigation"] = "globalNavigation";
    QueryNames["MixedEditorialShelf"] = "mixedEditorialShelf";
    QueryNames["NeuralNetShelf"] = "neuralNetShelf";
    QueryNames["NewEpisodesAvailableShelf"] = "newEpisodesAvailableShelf";
    QueryNames["OnAirNowShelf"] = "onAirNowShelf";
    QueryNames["OnboardingBrandCategories"] = "onboardingBrandCategories";
    QueryNames["OnboardingCategoryFavorites"] = "onboardingCategoryFavorites";
    QueryNames["Page"] = "page";
    QueryNames["PaginatedComponent"] = "paginatedComponent";
    QueryNames["PaginatedShelf"] = "paginatedShelf";
    QueryNames["PopularTitlesShelf"] = "popularTitlesShelf";
    QueryNames["RecentEpisodes"] = "recentEpisodes";
    QueryNames["RecentlyUnlockedShelf"] = "recentlyUnlockedShelf";
    QueryNames["ScrollFromSeason"] = "scrollFromSeason";
    QueryNames["SearchShelf"] = "searchShelf";
    QueryNames["SeasonShelfGroup"] = "seasonShelfGroup";
    QueryNames["TitleV2AboutSection"] = "titleV2AboutSection";
    QueryNames["TitleV2HomeSections"] = "titleV2HomeSections";
    QueryNames["TitleV2MoreSections"] = "titleV2MoreSections";
    QueryNames["UserFavorites"] = "userFavorites";
    QueryNames["UserInteractions"] = "userInteractions";
    QueryNames["UserLiveWatches"] = "userLiveWatches";
    QueryNames["UserWatches"] = "userWatches";
    QueryNames["VideosSection"] = "videosSection";
})(QueryNames || (QueryNames = {}));
export var EntityPageType;
(function (EntityPageType) {
    EntityPageType["BrandLanding"] = "BRAND_LANDING";
    EntityPageType["Browse"] = "BROWSE";
    EntityPageType["LiveSchedule"] = "LIVE_SCHEDULE";
    EntityPageType["Page"] = "PAGE";
    EntityPageType["Playlist"] = "PLAYLIST";
    EntityPageType["Schedule"] = "SCHEDULE";
    EntityPageType["Series"] = "SERIES";
    EntityPageType["SportLanding"] = "SPORT_LANDING";
    EntityPageType["Stream"] = "STREAM";
    EntityPageType["Title"] = "TITLE";
    EntityPageType["TitleV2"] = "TITLE_V2";
    EntityPageType["TitleV2About"] = "TITLE_V2_ABOUT";
    EntityPageType["TitleV2Bio"] = "TITLE_V2_BIO";
    EntityPageType["TitleV2Cast"] = "TITLE_V2_CAST";
    EntityPageType["TitleV2Contestants"] = "TITLE_V2_CONTESTANTS";
    EntityPageType["TitleV2Episodes"] = "TITLE_V2_EPISODES";
    EntityPageType["TitleV2More"] = "TITLE_V2_MORE";
    EntityPageType["Video"] = "VIDEO";
    EntityPageType["OlympicsPage"] = "OLYMPICS_PAGE";
})(EntityPageType || (EntityPageType = {}));
