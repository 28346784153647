import { Lightning } from '@lightningjs/sdk';
import LabelsList, { LabelsListRenderingType } from '../../../labelsList/LabelsList';
import { COLORS, MENU_ITEM_TYPE } from '../../../../constants';
const SECTION_HEIGHT = 500;
export default class Filters extends Lightning.Component {
    static _template() {
        return {
            GradientTop: {
                x: 0,
                y: -5,
                w: 490,
                h: 60,
                rect: true,
                colorBottom: COLORS.transparent,
                colorTop: COLORS.black,
                zIndex: 10,
                visible: false,
            },
            FiltersList: {
                clipping: true,
                x: 0,
                y: 0,
                w: 490,
                h: SECTION_HEIGHT,
                type: LabelsList,
                sectionSize: SECTION_HEIGHT,
                mode: LabelsListRenderingType.VERTICAL,
                signals: {
                    changeScrollState: '_changeScrollState',
                },
            },
            GradientBottom: {
                x: 0,
                y: 640,
                w: 490,
                h: 80,
                rect: true,
                colorTop: COLORS.transparent,
                colorBottom: COLORS.black,
                zIndex: 10,
            },
        };
    }
    set filters(filters) {
        var _a;
        this._filters = filters;
        this.tag('FiltersList').items = ((_a = this._filters) === null || _a === void 0 ? void 0 : _a.length)
            ? this._filters.map((item, index) => {
                const isDivider = item.menuItemType === MENU_ITEM_TYPE.DIVIDER;
                return {
                    autoWidth: false,
                    label: item.itemLabel,
                    active: index === 0,
                    fontSize: isDivider ? 30 : 35,
                    focusBackgroundColor: isDivider ? null : COLORS.white,
                    fontColor: isDivider ? COLORS.yellow2 : null,
                    isDivider,
                };
            })
            : [];
    }
    get currentItem() {
        var _a, _b;
        return ((_b = (_a = this._filters) === null || _a === void 0 ? void 0 : _a[this._filterList.activeIndex]) === null || _b === void 0 ? void 0 : _b.index) || 0;
    }
    get initiallySelected() {
        var _a, _b;
        let initallySelected = 0;
        if (((_b = (_a = this._filters) === null || _a === void 0 ? void 0 : _a[initallySelected]) === null || _b === void 0 ? void 0 : _b.menuItemType) === MENU_ITEM_TYPE.DIVIDER) {
            initallySelected += 1;
        }
        return initallySelected;
    }
    _init() {
        this._filterList = this.tag('FiltersList');
    }
    _getFocused() {
        return this.tag('FiltersList') || this;
    }
    selectItem(index) {
        var _a;
        (_a = this._filterList) === null || _a === void 0 ? void 0 : _a.selectItem(index);
    }
    selectFocused() {
        var _a;
        (_a = this._filterList) === null || _a === void 0 ? void 0 : _a.selectFocused();
    }
    _changeScrollState(isScrolled) {
        this.patch({
            GradientTop: {
                visible: isScrolled,
            },
        });
    }
}
