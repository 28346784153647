import { getAssetAuthAnalytics, getPlatformName, getSignInType, setDefaultValue, } from '../../../../helpers';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
import getAuthAttributes from './getAuthAttributes';
import getPageAttributes from './getPageAttributes';
import ModalManager from '../../../ModalManager';
export const getNbcAuthSuccessAttributes = (params = {}) => setDefaultValue({
    ...getAuthAttributes(params),
    'Sign In Type': getSignInType(params.userProfile),
    'Referring Page': getPageAttributes(ModalManager._activeModal || params.path).name,
    'Registration Source': getPlatformName(),
    ...getAssetAuthAnalytics(params.program),
}, MPARTICLE_DEFAULT_ATTR_VALUE);
export default getNbcAuthSuccessAttributes;
