import { collectionComponents, createItems } from '../../components/componentMaps';
import { lowercaseFirst } from '../../helpers';
import { videoSectionRequest } from '../requests';
import Container from '../models/Container';
import Variables from '../../graphql/Variables';
import { COLORS, MENU_ITEM_TYPE } from '../../constants';
import { useRequest } from '../../lib/useRequest';
import { PaginatedComponentRequestConfig, PaginatedShelfRequestConfig, } from '../../components/LinksSelectableGroup/request';
export default class NestedLinksSelectable {
    constructor(linksSelectableGroup) {
        this._linksSelectableGroup = linksSelectableGroup;
    }
    createItemLabels(fontSize = null, focusBackgroundColor = null) {
        var _a, _b;
        const items = ((_a = this._linksSelectableGroup.data) === null || _a === void 0 ? void 0 : _a.itemLabelsConfig) ||
            ((_b = this._linksSelectableGroup.data) === null || _b === void 0 ? void 0 : _b.itemLabels);
        const activeIndex = this.initiallySelected;
        return items.map((item, index) => {
            var _a;
            const isDivider = ((_a = this._linksSelectableGroup.data) === null || _a === void 0 ? void 0 : _a.itemLabelsConfig[index].menuItemType) ===
                MENU_ITEM_TYPE.DIVIDER;
            return {
                autoWidth: false,
                label: item.itemLabel || item,
                active: index === activeIndex,
                fontSize: isDivider ? 30 : fontSize,
                focusBackgroundColor: isDivider ? null : focusBackgroundColor,
                fontColor: isDivider ? COLORS.yellow2 : null,
                isDivider,
            };
        });
    }
    getItems() {
        var _a, _b;
        return (_b = (_a = this._linksSelectableGroup) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.items;
    }
    getItemLabelsConfig() {
        var _a, _b;
        return (_b = (_a = this._linksSelectableGroup) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.itemLabelsConfig;
    }
    async createData(stage, index) {
        let dataComponent = this._linksSelectableGroup.data.items[index].component;
        let dataItem = this._linksSelectableGroup.data.items[index].data;
        if (dataComponent === 'LazyShelf') {
            //Query data in case of Lazy Shelf component
            let queryData;
            const variables = Variables.getMergedVariables(dataItem.queryVariables);
            switch (dataItem.entryField) {
                case 'paginatedShelf':
                    queryData = await useRequest(PaginatedShelfRequestConfig(variables)).fetch();
                    break;
                default:
                    queryData = await videoSectionRequest(variables);
            }
            if (queryData && queryData.data) {
                const containerData = new Container(queryData.data);
                this._linksSelectableGroup.data.items[index] = containerData;
                dataComponent = containerData.component;
                dataItem = containerData.data;
            }
            else {
                return;
            }
        }
        if (dataItem && dataItem.items) {
            return stage.c({
                type: collectionComponents.get(dataComponent),
                tag: `${lowercaseFirst(dataItem.items[0].component)}`,
                items: createItems(dataItem.items),
                title: '',
                moreItems: dataItem.moreItems,
            });
        }
    }
    async getMoreItems(queryVariables) {
        const moreItemsData = await useRequest(PaginatedComponentRequestConfig(queryVariables)).fetch();
        const { data = {} } = new Container(moreItemsData.data);
        return {
            items: createItems(data === null || data === void 0 ? void 0 : data.items),
            moreItems: data === null || data === void 0 ? void 0 : data.moreItems,
        };
    }
    get initiallySelected() {
        var _a;
        let initiallySelected = this._linksSelectableGroup.data.initiallySelected;
        if (((_a = this._linksSelectableGroup.data) === null || _a === void 0 ? void 0 : _a.itemLabelsConfig[this._linksSelectableGroup.data.initiallySelected].menuItemType) === MENU_ITEM_TYPE.DIVIDER) {
            initiallySelected += 1;
        }
        return initiallySelected;
    }
    get itemLabelsTitle() {
        return this._linksSelectableGroup.data.itemLabelsTitle;
    }
    getLabelByIndex(index) {
        return this._linksSelectableGroup.obj.data.obj.itemLabels[index];
    }
}
