import Announce from './Announce';
import { Registry } from '@lightningjs/sdk';
export default class VizioAnnounce extends Announce {
    async speak() {
        return new Promise((resolve) => {
            var _a;
            (_a = window.VIZIO.Chromevox) === null || _a === void 0 ? void 0 : _a.play(this.phrase);
            Registry.setTimeout(() => resolve(), Announce.estimateSpeechTime(this.phrase));
        });
    }
}
