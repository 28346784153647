import { Language } from '@lightningjs/sdk';
import moment from 'moment-timezone';
import { LANGUAGES } from '../../constants';
const DAYS_TO_VALIDATE_LABEL_BADGE = 3;
export const isCurrentDay = (date) => {
    const startDateObject = moment(date);
    return startDateObject.isSame(new Date(), 'day');
};
export const getDateFromUnixTimestamp = (unixTimestamp) => new Date(unixTimestamp * 1000);
export const getUpcomingBadge = (startTime) => {
    var _a;
    const startDateObject = moment(startTime);
    const formatBadge = isCurrentDay(startTime)
        ? `h:mm A [${Language.translate('today').toUpperCase()}]`
        : 'h:mm A ddd MMM DD';
    return (_a = startDateObject.format(formatBadge)) === null || _a === void 0 ? void 0 : _a.toUpperCase();
};
const formatDate = (datestring, isFullYear = false) => {
    const format = isFullYear ? 'MM/DD/YYYY' : 'MM/DD/YY';
    return moment(datestring).format(format);
};
export const getAirDate = (airDate) => {
    const timestamp = airDate * 1000;
    const now = moment();
    const diff = now.diff(timestamp, 'hours');
    if (diff > 24) {
        // Show the air date in 'MM/DD/YY' format if the difference greater than 24 hours
        return formatDate(timestamp);
    }
    else if (diff < 1) {
        // Show "Just added" if difference is less than 1 hour
        return Language.translate('just_added');
    }
    else {
        // In all other cases we use "3h ago" format
        return `${diff}h ${Language.translate('time_ago')}`;
    }
};
export const getFERDate = (startDate, endDate) => {
    const now = moment();
    const startDateObject = moment(startDate);
    const endDateObject = moment(endDate);
    const diff = Math.abs(endDateObject.diff(now, 'hours'));
    return diff < 24
        ? `${diff}h ${Language.translate('time_ago')}`
        : startDateObject.format('MM/DD/YY');
};
export const isLive = (startDate, endDate) => {
    const nowTime = moment();
    const startTime = moment(startDate);
    const endTime = moment(endDate);
    return nowTime.isBetween(startTime, endTime);
};
export const isUpcoming = (startDate) => {
    const nowTime = moment();
    return nowTime.isBefore(startDate);
};
export const showLabelBadge = (endDate) => {
    const now = moment();
    const end = moment(endDate);
    return Math.abs(end.diff(now, 'days')) < DAYS_TO_VALIDATE_LABEL_BADGE;
};
export var PROGRAMMING_TYPE;
(function (PROGRAMMING_TYPE) {
    PROGRAMMING_TYPE["singleLiveEvents"] = "Single Live Event";
    PROGRAMMING_TYPE["fullEventReplays"] = "Full Event Replay";
})(PROGRAMMING_TYPE || (PROGRAMMING_TYPE = {}));
export const MODAL = 'Modal';
export const getLabelBadge = (labelBadgeDefault, labelBadgeEs) => Language.get() === LANGUAGES.SPANISH && labelBadgeEs ? labelBadgeEs : labelBadgeDefault;
export const adjustTimestampByOffset = (timestamp, offsetMinutes) => moment(timestamp * 1000)
    .add(offsetMinutes, 'm')
    .unix();
