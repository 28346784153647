var LEMONADE_PLATFORM;
(function (LEMONADE_PLATFORM) {
    LEMONADE_PLATFORM["XCLASS"] = "platco";
    LEMONADE_PLATFORM["XBOXONE"] = "xboxOne";
    LEMONADE_PLATFORM["VIZIO"] = "vizio";
    LEMONADE_PLATFORM["TIZEN"] = "samsungTv";
    LEMONADE_PLATFORM["KEPLER"] = "fireTvLightning";
})(LEMONADE_PLATFORM || (LEMONADE_PLATFORM = {}));
var APP_IDENTIFIER;
(function (APP_IDENTIFIER) {
    APP_IDENTIFIER["XCLASS"] = "nbcu-x1";
    APP_IDENTIFIER["XBOXONE"] = "nbcu-xboxone";
    APP_IDENTIFIER["VIZIO"] = "nbcu-vizio";
    APP_IDENTIFIER["TIZEN"] = "nbcu-samsung";
    APP_IDENTIFIER["KEPLER"] = "nbcu-firetv";
})(APP_IDENTIFIER || (APP_IDENTIFIER = {}));
var ErrorType;
(function (ErrorType) {
    ErrorType["NETWORK"] = "network";
    ErrorType["MEDIA"] = "media";
    ErrorType["RESTRICTION"] = "restriction";
    ErrorType["ENTITLEMENT"] = "entitlement";
    ErrorType["OTHER"] = "other";
})(ErrorType || (ErrorType = {}));
export { LEMONADE_PLATFORM, APP_IDENTIFIER, ErrorType, };
