import { Language, Lightning, Utils } from '@lightningjs/sdk';
import TextBorderButton from '../../../components/buttons/TextBorderButton';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { EVENTS } from '../../../lib/analytics/types';
import { ALIGN_SELF, COLORS, FLEX_DIRECTION, FONT_FACE, SCREEN_SIZE, TEXT_ALIGN, ROUTE, } from '../../../constants';
import { ErrorModalAction, useErrorModal } from './hooks/useErrorModal';
import ErrorFactory from '../../../components/error/ErrorFactory';
import { StreamLoaderPlayerErrorMap } from '../../../pages/Player/StreamLoader/error';
import { PlayerError } from '../../../components/error/PlayerError';
import Router from '@lightningjs/sdk/src/Router';
import ModalManager, { ModalTypes } from '../../../lib/ModalManager';
var ErrorButtonState;
(function (ErrorButtonState) {
    ErrorButtonState["BACK"] = "ButtonContainer.BackButton";
    ErrorButtonState["RETRY"] = "ButtonContainer.RetryButton";
    ErrorButtonState["LIVEGUIDE"] = "ButtonContainer.LiveGuideButton";
})(ErrorButtonState || (ErrorButtonState = {}));
export const openErrorModal = (e, stream, program, liveGuideEnabled = false) => {
    var _a, _b, _c, _d, _e, _f, _g, _h;
    const errorFactory = new ErrorFactory();
    const code = (_a = e.error) === null || _a === void 0 ? void 0 : _a.detail;
    const playerError = ((_b = (code && StreamLoaderPlayerErrorMap[code])) !== null && _b !== void 0 ? _b : PlayerError.UNKNOWN);
    const error = errorFactory.getError(playerError, (_c = e.error) === null || _c === void 0 ? void 0 : _c.data);
    const analytics = (_d = Router.getActivePage()) === null || _d === void 0 ? void 0 : _d._analyticsDelegate;
    const payload = {
        brand: (stream === null || stream === void 0 ? void 0 : stream.brandDisplayTitle) || ((_e = program === null || program === void 0 ? void 0 : program.brand) === null || _e === void 0 ? void 0 : _e.title) || ((_g = (_f = stream === null || stream === void 0 ? void 0 : stream.analytics) === null || _f === void 0 ? void 0 : _f.brand) === null || _g === void 0 ? void 0 : _g.title),
        error,
        analytics: analytics === null || analytics === void 0 ? void 0 : analytics.getErrorAnalytics(error, (_h = e.error) === null || _h === void 0 ? void 0 : _h.data),
        liveGuideEnabled,
    };
    ModalManager.open(ModalTypes.PLAYER_ERROR, payload);
};
export default class ErrorModal extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._redirectable = false;
        this._focused = ErrorButtonState.BACK;
        this._hook = useErrorModal();
    }
    static _template() {
        return {
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            rect: true,
            color: COLORS.black,
            ModalContainer: {
                w: SCREEN_SIZE.width,
                y: SCREEN_SIZE.height / 2,
                mountY: 0.5,
                flex: { direction: FLEX_DIRECTION.column },
                Image: {
                    flexItem: { margin: 10, alignSelf: ALIGN_SELF.center },
                    src: Utils.asset('images/misc/group.png'),
                },
                Title: {
                    flexItem: { margin: 10, alignSelf: ALIGN_SELF.center, grow: 1 },
                    padding: 22,
                    text: {
                        text: 'Error',
                        fontFace: FONT_FACE.light,
                        textAlign: TEXT_ALIGN.center,
                        fontSize: 60,
                        textColor: COLORS.white,
                    },
                },
                Message: {
                    flexItem: { margin: 10, alignSelf: ALIGN_SELF.center, grow: 1 },
                    padding: 22,
                    text: {
                        text: Language.translate('oops_error'),
                        fontFace: FONT_FACE.light,
                        textAlign: TEXT_ALIGN.center,
                        fontSize: 40,
                        lineHeight: 60,
                        textColor: COLORS.white,
                        wordWrapWidth: 1380,
                    },
                },
                ButtonContainer: {
                    flexItem: { margin: 10, alignSelf: ALIGN_SELF.center, grow: 1 },
                    flex: { direction: FLEX_DIRECTION.row, padding: 25 },
                    RetryButton: {
                        flexItem: { margin: 10 },
                        h: 63,
                        w: 250,
                        type: TextBorderButton,
                        radius: 31,
                        fontSize: 32,
                        fontFace: FONT_FACE.regular,
                        label: Language.translate('Retry'),
                        focusFontColor: COLORS.black,
                        unfocusFontColor: COLORS.white,
                        focusBackGroundColor: COLORS.white,
                        unfocusBackgroundColor: COLORS.black,
                        focusBorderColor: COLORS.transparent,
                        unfocusBorderColor: COLORS.white,
                        padding: 22,
                        visible: false,
                    },
                    BackButton: {
                        flexItem: { margin: 10 },
                        h: 63,
                        w: 250,
                        type: TextBorderButton,
                        radius: 31,
                        fontSize: 32,
                        fontFace: FONT_FACE.regular,
                        label: Language.translate('back'),
                        focusFontColor: COLORS.black,
                        unfocusFontColor: COLORS.white,
                        focusBackGroundColor: COLORS.white,
                        unfocusBackgroundColor: COLORS.black,
                        focusBorderColor: COLORS.transparent,
                        unfocusBorderColor: COLORS.white,
                        padding: 22,
                    },
                    LiveGuideButton: {
                        flexItem: { margin: 10 },
                        h: 63,
                        w: 250,
                        type: TextBorderButton,
                        radius: 31,
                        fontSize: 32,
                        fontFace: FONT_FACE.regular,
                        label: Language.translate('view_live_guide'),
                        focusFontColor: COLORS.black,
                        unfocusFontColor: COLORS.white,
                        focusBackGroundColor: COLORS.white,
                        unfocusBackgroundColor: COLORS.black,
                        focusBorderColor: COLORS.transparent,
                        unfocusBorderColor: COLORS.white,
                        padding: 22,
                        visible: false,
                    },
                },
            },
        };
    }
    _init() {
        this._modalContainer = this.tag('ModalContainer');
        this._buttonContainer = this.tag('ModalContainer.ButtonContainer');
        this._retryBtn = this.tag('ModalContainer.ButtonContainer.RetryButton');
        this._liveGuideBtn = this.tag('ModalContainer.ButtonContainer.LiveGuideButton');
        this._backBtn = this.tag('ModalContainer.ButtonContainer.BackButton');
        this._title = this.tag('ModalContainer.Title');
        this._message = this.tag('ModalContainer.Message');
        this._setState('ButtonContainer');
    }
    _replaceBrand(message) {
        const brandKey = '%{brand}';
        return this._brand && message.indexOf(brandKey) > -1
            ? message.replace(brandKey, this._brand)
            : message;
    }
    set brand(v) {
        this._brand = v;
    }
    set error(v) {
        this._title.text.text = v.title;
        this._message.text.text = this._replaceBrand(v.message);
        this._retryBtn.visible = v.retryable;
        if (v.retryable)
            this._focused = ErrorButtonState.RETRY;
        if (v.buttonLabel)
            this._backBtn.label = v.buttonLabel;
        this._redirectable = v.redirectable;
    }
    set analytics(a) {
        this._analytics = a;
    }
    set liveGuideEnabled(v) {
        this._liveGuideBtn.visible = v;
    }
    set modalData(data) {
        Object.keys(data).forEach((key) => {
            // @ts-expect-error Assigning values programatically
            this[key] = data[key];
        });
    }
    sendAnalytics() {
        sendMetric(EVENTS.ERROR, this._analytics);
    }
    _handleKey() {
        return true;
    }
    static _states() {
        return [
            class ButtonContainer extends this {
                _getFocused() {
                    this._setState(this._focused);
                    let _currentFocus;
                    if (this._focused === ErrorButtonState.BACK)
                        _currentFocus = this._backBtn;
                    if (this._focused === ErrorButtonState.RETRY)
                        _currentFocus = this._retryBtn;
                    if (this._focused === ErrorButtonState.LIVEGUIDE)
                        _currentFocus = this._liveGuideBtn;
                    return _currentFocus || this;
                }
                _handleDown() {
                    return true;
                }
                _handleUp() {
                    return true;
                }
                _handleLeft() {
                    if (this._focused === ErrorButtonState.LIVEGUIDE) {
                        this._focused = ErrorButtonState.BACK;
                    }
                    else if (this._focused === ErrorButtonState.BACK && this._retryBtn.visible) {
                        this._focused = ErrorButtonState.RETRY;
                    }
                    this._setState(this._focused);
                }
                _handleRight() {
                    if (this._focused === ErrorButtonState.BACK && this._liveGuideBtn.visible) {
                        this._focused = ErrorButtonState.LIVEGUIDE;
                    }
                    else if (this._focused === ErrorButtonState.RETRY) {
                        this._focused = ErrorButtonState.BACK;
                    }
                    this._setState(this._focused);
                }
                static _states() {
                    return [
                        class RetryButton extends ButtonContainer {
                            _getFocused() {
                                return this._retryBtn || this;
                            }
                            _handleEnter(e) {
                                this._hook.set(ErrorModalAction.RETRY);
                                e.preventDefault();
                                e.stopPropagation();
                            }
                        },
                        class BackButton extends ButtonContainer {
                            _getFocused() {
                                return this._backBtn || this;
                            }
                            _handleEnter() {
                                const route = Router.getActiveHash();
                                if (route != ROUTE.live) {
                                    Router.back();
                                }
                                else {
                                    if (this._liveGuideBtn.visible) {
                                        this._hook.set(ErrorModalAction.EXIT);
                                        return;
                                    }
                                    this._hook.set(this._redirectable ? ErrorModalAction.REDIRECT : ErrorModalAction.BACK);
                                }
                            }
                            _handleBack() {
                                this._handleEnter();
                            }
                        },
                        class LiveGuideButton extends ButtonContainer {
                            _getFocused() {
                                return this._liveGuideBtn || this;
                            }
                            _handleEnter() {
                                this._hook.set(ErrorModalAction.LIVE_GUIDE);
                            }
                        },
                    ];
                }
            },
        ];
    }
}
