import { Language } from '@lightningjs/sdk';
import { PlayerError } from './PlayerError';
import { IErrorViewModel } from './ErrorViewModel';
import AuthenticationSingleton from '../../authentication/Authentication';
import { ADOBE_PATH_GEO_ERROR } from '../../constants';
export default class ErrorFactory {
    constructor(mvpdData = AuthenticationSingleton.getMvpdData()) {
        this._adobePassErrorMappings = mvpdData ? mvpdData.adobePassErrorMappings : {};
    }
    getError(e, errorEvent) {
        var _a, _b;
        switch (e) {
            case PlayerError.GEO:
                if ((errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.code) && Number(errorEvent.code) === 345) {
                    return this._createErrorViewModel(ADOBE_PATH_GEO_ERROR, false);
                }
                return {
                    title: Language.translate('errors_geoTitle'),
                    message: (errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.description) || Language.translate('errors_geoMessage'),
                    retryable: false,
                };
            case PlayerError.AUTHZ:
                if ((errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.details) || ((_a = errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.data) === null || _a === void 0 ? void 0 : _a.details)) {
                    return {
                        title: Language.translate('errors_retransTitle'),
                        message: (errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.details) || ((_b = errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.data) === null || _b === void 0 ? void 0 : _b.details),
                        retryable: false,
                    };
                }
                else if ((errorEvent === null || errorEvent === void 0 ? void 0 : errorEvent.code) === 345) {
                    // @See PL-709 this comes through as authz with nothing other
                    // than code to identify - it's actually the retrans error.
                    // Happens when user doesn't have access to a channel (brand).
                    return {
                        title: Language.translate('errors_retransTitle'),
                        message: Language.translate('errors_retransMessage'),
                        retryable: false,
                    };
                }
                else {
                    return this._createErrorViewModel('User Not Authorized Error');
                }
            case PlayerError.RETRANS:
                return {
                    title: Language.translate('errors_retransTitle'),
                    message: Language.translate('errors_retransMessage'),
                    retryable: false,
                };
            case PlayerError.EXPIRED: {
                return {
                    title: Language.translate('errors_expiredTitle'),
                    message: Language.translate('errors_expiredMessage'),
                    retryable: false,
                };
            }
            case PlayerError.PARENTAL_CONTROLS: {
                return {
                    title: Language.translate('errors_parentalTitle'),
                    message: Language.translate('errors_parentalMessage'),
                    retryable: false,
                };
            }
            case PlayerError.SLE_ENDED:
                return {
                    title: Language.translate('sle-empty-title'),
                    message: Language.translate('sle-empty-subtitle'),
                    retryable: false,
                    buttonLabel: Language.translate('sle-full-schedule-button'),
                    redirectable: true,
                };
            default:
                return this._createErrorViewModel('Default Error');
        }
    }
    getErrorType(e) {
        switch (e) {
            case PlayerError.GEO:
                return 'Geo';
            case PlayerError.AUTHZ:
                return 'Auth';
            case PlayerError.MISSING_LIVE_DATA:
                return 'Video';
            case PlayerError.PARENTAL_CONTROLS:
                return 'Parental_Controls';
            case PlayerError.UNKNOWN:
                return 'Other';
            case PlayerError.EXPIRED:
                return 'Expired';
        }
    }
    _createErrorViewModel(key, retryable = false) {
        let cmsErrorObject = this._adobePassErrorMappings[key];
        // FIXME: If the user is not signed in the adobe error map is not retrieved.
        if (!cmsErrorObject) {
            cmsErrorObject = {
                title: "Sorry! We're having some trouble...",
                body: 'If the problem persists, please go to More -> Contact Us and send us a note.',
            };
        }
        return new IErrorViewModel(Language.translate(cmsErrorObject.title), Language.translate(cmsErrorObject.body), retryable);
    }
}
