export var AppLaunchTypes;
(function (AppLaunchTypes) {
    AppLaunchTypes["default"] = "Launch";
    AppLaunchTypes["background"] = "Open";
    AppLaunchTypes["deepLink"] = "Deep Link";
})(AppLaunchTypes || (AppLaunchTypes = {}));
export var EVENTS;
(function (EVENTS) {
    EVENTS["AD_BREAK_START"] = "trackAdBreakStart";
    EVENTS["AD_BREAK_END"] = "trackAdBreakEnd";
    EVENTS["AD_START"] = "trackAdStart";
    EVENTS["AD_END"] = "trackAdEnd";
    EVENTS["LIVE_SESSION_START"] = "trackLiveSessionStart";
    EVENTS["LIVE_SESSION_END"] = "trackLiveSessionEnd";
    EVENTS["VOD_SESSION_START"] = "trackVodSessionStart";
    EVENTS["VOD_SESSION_END"] = "trackVodSessionEnd";
    EVENTS["PAGE_LOAD"] = "trackPageLoad";
    EVENTS["ERROR"] = "trackError";
    EVENTS["CONTENT_CLICK"] = "trackContentClick";
    EVENTS["AUTH_SUCCESS_MVPD"] = "trackMvpdAuthSuccess";
    EVENTS["AUTH_SUCCESS_NBC"] = "trackNbcAuthSuccess";
    EVENTS["MVPD_PAGE_ABANDONED"] = "trackMvpdPageAbandoned";
    EVENTS["IDM_CONVERSION"] = "trackIDMConversion";
    EVENTS["NBC_PROFILE_SIGN_OUT"] = "trackNbcProfileSignOut";
    EVENTS["CLICK"] = "trackClick";
    EVENTS["SEARCH_RESULT"] = "trackSearchResult";
    EVENTS["SHELF_IMPRESSION"] = "trackShelfImpression";
    EVENTS["SHELVES_LOAD"] = "trackShelvesLoad";
    EVENTS["DYNAMIC_LEAD_IMPRESSION"] = "trackDynamicLeadImpression";
    EVENTS["MODAL_LOAD"] = "trackModalLoad";
    EVENTS["END_CARD_IMPRESSION"] = "trackEndCardImpression";
    EVENTS["END_CARD"] = "trackEndCard";
    EVENTS["LIVE_TO_VOD_IMPRESSION"] = "trackLiveToVodImpression";
    EVENTS["EXIT"] = "trackExit";
    // Mparticle
    EVENTS["LAUNCH"] = "trackLaunch";
    EVENTS["PLAYER_LOAD_TIME"] = "trackPlayerLoadTime";
    EVENTS["LIVE_PLAYER_LOAD"] = "trackLivePlayerLoad";
    EVENTS["PROGRAM_START"] = "trackProgramStart";
    EVENTS["PROGRAM_END"] = "trackProgramEnd";
    // Singleton Class
    EVENTS["PLAYER_READY"] = "trackPlayerReady";
    EVENTS["AUTHZ_START"] = "trackAuthZStart";
    EVENTS["AUTHZ_COMPLETE"] = "trackAuthZComplete";
    EVENTS["ON_BUFFER_START"] = "trackBufferStart";
    EVENTS["ON_BUFFER_COMPLETE"] = "trackBufferComplete";
    EVENTS["SEEK_START"] = "trackSeekStart";
    EVENTS["SEEK_END"] = "trackSeekEnd";
    EVENTS["LIVE_SESSION_INITIALIZED"] = "trackLiveSessionInitialized";
    EVENTS["LIVE_PROGRAM_CHANGE"] = "trackLiveProgramChange";
    EVENTS["MARKETING_MODULE_IMPRESSION"] = "trackMarketingModuleImpression";
    EVENTS["MARKETING_MODULE_VIDEO_PREVIEW"] = "trackMarketingModuleVideoPreview";
})(EVENTS || (EVENTS = {}));
