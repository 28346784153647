import { get } from 'lodash';
import { Container } from '../models';
import { collectionComponents, createItems } from '../../components/componentMaps';
const createModel = (item) => new Container(item);
const createSlideshow = (stage, model) => {
    return stage.c({
        type: collectionComponents.get(model.component),
        items: createItems(get(model, 'data.items', [])),
    });
};
export default (stage, featured) => {
    return createSlideshow(stage, createModel(featured));
};
