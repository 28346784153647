import { Lightning, Utils } from '@lightningjs/sdk';
import TitleBlock from './TitleBlock';
import CtaBlock from './CtaBlock';
import { FastImg, getBrandName } from '../../../helpers';
import BrandLogo from './BrandLogo';
import ImageHolder from './ImageHolder';
import { SCREEN_SIZE } from '../../../constants';
import { OLY_IMPOLICY } from '../../../constants/images';
// @ts-expect-error TS(2417): Class static side 'typeof Hero' incorrectly extend... Remove this comment to see the full error message
export default class Hero extends Lightning.Component {
    static _template() {
        const timingFunction = 'cubic-bezier(0.20, 1.00, 0.80, 1.00)';
        return {
            rtt: true,
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            transitions: {
                scale: { duration: 0.6, timingFunction },
            },
            ImageHolder: {
                type: ImageHolder,
            },
            MainContent: {
                x: 80,
                alpha: 0.001,
                transitions: {
                    alpha: { duration: 2, timingFunction },
                    x: { duration: 0.6, timingFunction },
                },
                Logo: {
                    h: 55,
                    w: 59,
                    y: 61,
                    src: Utils.asset(`images/logos/logo-${getBrandName()}-small.png`),
                },
                ContentLogo: {
                    y: 165,
                    transitions: {
                        alpha: { duration: 0.6, timingFunction },
                        y: { duration: 0.6, timingFunction },
                        scale: { duration: 0.6, timingFunction },
                    },
                },
                TitleBlock: {
                    positionY: 340,
                    type: TitleBlock,
                },
                CtaBlock: {
                    positionY: 550,
                    type: CtaBlock,
                    position: 1,
                    customPosition: this.bindProp('customPosition'),
                },
                BrandLogoHolder: {
                    timingFunction: timingFunction,
                    type: BrandLogo,
                    positionY: 800,
                    positionX: 1757,
                },
            },
        };
    }
    _init() {
        this._imageHolder = this.tag('ImageHolder');
        this._titleBlock = this.tag('TitleBlock');
        this._ctaBlock = this.tag('CtaBlock');
        this._brandLogo = this.tag('BrandLogoHolder');
        this.tag('Logo').on('txLoaded', () => {
            this.tag('Logo').setSmooth('alpha', 1);
            this._repositionContent();
        });
        this.tag('Logo').on('txError', () => {
            this._repositionContent();
        });
    }
    _getFocused() {
        return this._ctaBlock || this;
    }
    set item(v) {
        this._item = v;
        const { featured = {} } = this._item;
        const { ariaLabel, description, titleLogo, heroCompactImage, title, secondaryDescription, sponsorLogo, whiteBrandLogo, smartTileCTA, primaryCTA, } = featured;
        if (titleLogo === null || titleLogo === void 0 ? void 0 : titleLogo.path) {
            this.patch({
                MainContent: {
                    ContentLogo: {
                        texture: FastImg(titleLogo.path).contain(600, 160, OLY_IMPOLICY.HERO),
                    },
                },
            });
        }
        else {
            this._titleBlock.videoTitle = featured.title || '';
            this._titleBlock.positionY = 165;
        }
        if (ariaLabel)
            this.announce = ariaLabel;
        this._imageHolder.image = heroCompactImage;
        this._titleBlock.videoTitle = !titleLogo && title ? title : '';
        this._titleBlock.secondaryTitle = description || '';
        this._titleBlock.secondaryDescription = secondaryDescription || '';
        this._titleBlock.sponsorLogo = sponsorLogo;
        this._brandLogo.logo = whiteBrandLogo;
        this._ctaBlock.ctaData = (smartTileCTA === null || smartTileCTA === void 0 ? void 0 : smartTileCTA.data) || (primaryCTA === null || primaryCTA === void 0 ? void 0 : primaryCTA.data);
        this._ctaBlock.isSmart = !!(smartTileCTA === null || smartTileCTA === void 0 ? void 0 : smartTileCTA.data);
        this._ctaBlock.analytics = (smartTileCTA === null || smartTileCTA === void 0 ? void 0 : smartTileCTA.analytics) || (primaryCTA === null || primaryCTA === void 0 ? void 0 : primaryCTA.analytics);
        this._ctaBlock.positionY = sponsorLogo
            ? this._ctaBlock.positionY + 50
            : this._ctaBlock.positionY;
        if (!smartTileCTA && !primaryCTA) {
            this._ctaBlock.visible = false;
            this.fireAncestors('$featuredNotAvailable');
        }
    }
    _repositionContent() {
        this.patch({
            MainContent: {
                smooth: { alpha: 1 },
            },
        });
    }
    setInactive(v, blur = false, transitionDuration = false) {
        const alpha = v ? 1 : 0;
        // Handle specified transition druation - used in showAbout.
        const mainContentAlphaTransition = transitionDuration
            ? [
                alpha,
                {
                    duration: transitionDuration,
                    delay: 0,
                    timingFunction: 'ease-out',
                },
            ]
            : alpha;
        this.patch({
            smooth: { alpha: v ? alpha : blur ? 0.7 : 0.2 },
            MainContent: {
                smooth: { alpha: mainContentAlphaTransition },
            },
            ImageHolder: {
                amount: blur ? 2 : 0,
                content: {
                    Gradients: {
                        smooth: { alpha: alpha },
                    },
                },
            },
        });
    }
    static get tag() {
        return 'Hero';
    }
}
