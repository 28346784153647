//pages
import { About, AppLanguage, AppSettings, ClosedCaptioning, ContactUs, Credits, Debug, EpisodeGuide, Error, EventsPlayer, FAQ, LivePlayer, LiveUpcoming, LongText, Main, MyProfile, Networks, NetworksLanding, NotFound, OlympicsHighlights, OlympicsHub, OlympicsReplays, Privacy, Replays, Search, Settings, ShowHome, Shows, Splash, Sports, VODPlayer, } from './pages';
//platform
import TVPlatform from './lib/tv-platform';
//api
import { getText } from './api/Api';
import UserInteractionsStoreSingleton from './store/UserInteractions';
import { fetchUserInteractions } from './store/UserInteractions/actions';
//variables
import { ROUTE } from './constants';
//helpers
import { loadData } from './helpers';
import LaunchDarklySingleton from './lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from './lib/launchDarkly/LaunchDarklyFeatureFlags';
import { WithSeekingV2 } from './pages/Player/hoc/WithSeekingV2';
import { CMPPreferenceCenter } from './pages/Settings/CMPPreferenceCenter';
const routes = [
    {
        path: ROUTE.boot,
        component: Splash,
    },
    {
        path: ROUTE.splash,
        component: Splash,
    },
    {
        path: ROUTE.home,
        component: Main,
        widgets: ['menu', 'loader'],
        before: async (page) => {
            await page.load();
            await TVPlatform.dismissLoadingScreen();
            TVPlatform.checkDeepLinkNavigation();
            await UserInteractionsStoreSingleton.dispatch(fetchUserInteractions());
        },
    },
    {
        path: ROUTE.shows,
        component: Shows,
        widgets: ['menu', 'loader'],
        cache: 300,
        options: {
            reuseInstance: false,
        },
        after: loadData,
    },
    {
        path: ROUTE.showsWithBrand,
        component: Shows,
        widgets: ['menu', 'loader'],
        cache: 300,
        options: {
            reuseInstance: false,
        },
        after: loadData,
    },
    {
        path: ROUTE.networks,
        component: Networks,
        widgets: ['menu', 'loader'],
        after: loadData,
    },
    {
        path: ROUTE.settings,
        component: Settings,
        widgets: ['menu'],
        options: { reuseInstance: true },
    },
    {
        path: ROUTE.profile,
        component: MyProfile,
        widgets: ['menu'],
        options: { preventStorage: true, reuseInstance: false },
    },
    {
        path: ROUTE.appSettings,
        component: AppSettings,
        widgets: ['menu'],
    },
    {
        path: ROUTE.language,
        component: AppLanguage,
        widgets: ['menu'],
        options: {
            preventStorage: true,
            clearHistory: true,
            reuseInstance: false,
        },
    },
    {
        path: ROUTE.closedCaptioning,
        component: ClosedCaptioning,
        widgets: ['menu'],
    },
    {
        path: ROUTE.contactUs,
        component: ContactUs,
        widgets: ['menu'],
    },
    {
        path: ROUTE.about,
        component: About,
        widgets: ['menu'],
    },
    {
        path: ROUTE.privacy,
        component: Privacy,
        widgets: ['menu'],
    },
    {
        path: ROUTE.cmp,
        component: CMPPreferenceCenter,
    },
    {
        path: ROUTE.faq,
        component: FAQ,
        widgets: ['menu'],
        after: loadData,
    },
    {
        path: ROUTE.longtext,
        component: LongText,
        widgets: ['menu'],
        after: async (page, { url, bustCache }) => {
            await getText(url, bustCache).then((data) => {
                page.apiData = data;
            });
        },
    },
    {
        path: ROUTE.showHome,
        component: ShowHome,
        widgets: [],
        options: { reuseInstance: false },
        on: loadData,
    },
    {
        path: ROUTE.movieHome,
        component: ShowHome,
        widgets: [],
        options: { reuseInstance: false },
        on: loadData,
    },
    {
        path: ROUTE.search,
        component: Search,
        widgets: ['menu', 'loader'],
        on: loadData,
    },
    {
        path: ROUTE.video,
        component: VODPlayer,
        options: { preventStorage: true, reuseInstance: true },
        widgets: ['loader', 'playerloadermodal'],
    },
    {
        path: ROUTE.credits,
        component: Credits,
        options: { preventStorage: true },
        widgets: ['loader'],
        on: loadData,
    },
    {
        path: ROUTE.networkLanding,
        component: NetworksLanding,
        widgets: ['menu', 'loader'],
        after: loadData,
    },
    {
        path: ROUTE.live,
        component: async () => {
            const isNewPlayerDesign = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.newPlayerTest);
            return { default: isNewPlayerDesign ? WithSeekingV2(LivePlayer) : LivePlayer };
        },
        options: { preventStorage: true, reuseInstance: false },
        widgets: ['menu', 'loader', 'playerloadermodal'],
    },
    {
        path: ROUTE.liveWithParams,
        component: async () => {
            const isNewPlayerDesign = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.newPlayerTest);
            return { default: isNewPlayerDesign ? WithSeekingV2(LivePlayer) : LivePlayer };
        },
        widgets: ['menu', 'loader', 'playerloadermodal'],
    },
    {
        path: ROUTE.watch,
        component: EventsPlayer,
        options: { preventStorage: true, reuseInstance: false },
        widgets: ['menu', 'loader', 'playerloadermodal'],
    },
    {
        path: ROUTE.liveUpcoming,
        component: LiveUpcoming,
        widgets: ['menu', 'loader'],
    },
    {
        path: ROUTE.replays,
        component: Replays,
        widgets: ['menu', 'loader'],
    },
    {
        path: ROUTE.sports,
        component: Sports,
        widgets: ['menu', 'loader'],
        before: loadData,
    },
    {
        path: ROUTE.debug,
        component: Debug,
        widgets: ['menu'],
    },
    {
        path: ROUTE.episodeGuide,
        component: EpisodeGuide,
        widgets: ['menu', 'loader'],
    },
    {
        path: ROUTE.olympics,
        component: OlympicsHub,
        widgets: ['menu', 'loader'],
        before: loadData,
    },
    {
        path: ROUTE.paralympics,
        component: OlympicsHub,
        widgets: ['menu', 'loader'],
        before: loadData,
    },
    {
        path: ROUTE.olympicsReplays,
        component: OlympicsReplays,
        widgets: ['menu', 'loader'],
        after: loadData,
    },
    {
        path: ROUTE.olympicsHighlights,
        component: OlympicsHighlights,
        widgets: ['menu', 'loader'],
        after: loadData,
    },
    {
        path: ROUTE.notFound,
        component: NotFound,
    },
    {
        path: ROUTE.error,
        component: Error,
    },
];
export default routes;
