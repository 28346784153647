import Variables from '../../graphql/Variables';
import { featuredRequest, mainRequest } from '../../api/requests';
import { createRequestConfig } from '../../lib/useRequest';
export const SportsPageRequestConfig = () => createRequestConfig({
    query: mainRequest,
    variables: Variables.sportsPageVariables(),
    reject: [(data) => !(data === null || data === void 0 ? void 0 : data.sections)],
});
export const SportsPageFeaturedRequestConfig = () => createRequestConfig({
    query: featuredRequest,
    variables: Variables.sportsPageVariables(),
    reject: [(data) => !data],
});
