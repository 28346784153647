import { IPlayerEvent } from './IPlayerEvent';
export class FPSChangeEvent extends IPlayerEvent {
    constructor(fps) {
        super();
        this._fps = fps;
    }
    get fps() {
        return this._fps;
    }
}
