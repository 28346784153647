import sha1 from 'crypto-js/sha1';
const videos = [
    'title',
    'description',
    'type',
    'genre',
    'vChipRating',
    'vChipSubRatings',
    'guid',
    'published',
    'runTime',
    'airdate',
    'available',
    'seasonNumber',
    'episodeNumber',
    'expiration',
    'entitlement',
    'tveAuthWindow',
    'nbcAuthWindow',
    'externalAdId',
    'uplynkStatus',
    'dayPart',
].join();
const shows = [
    'shortTitle',
    'sortTitle',
    'category',
    'description',
    'urlAlias',
    'frontends',
    'type',
    'tuneIn',
    'name',
    'genre',
].join();
const images = ['derivatives', 'path'].join();
export const videoQuery = {
    fields: {
        videos,
        shows,
        images,
    },
    include: ['image', 'show.image', 'show.iosProperties.image'].join(),
    derivatives: [
        'landscape.widescreen.size640.x1',
        'landscape.widescreen.size1024.x1',
        'landscape.widescreen.size1920.x1',
    ].join(),
};
export const collectionQuery = {
    fields: {
        videos,
        shows,
        images,
    },
    include: [
        'collections.slides.iosSlides.compactImage',
        'collections.shows.image',
        'collections.shows.iosProperties.image',
        'collections.videos.image',
        'collections.videos.show.image',
        'collections.videos.show.iosProperties.image',
    ].join(),
    derivatives: 'landscape.widescreen.size640.x1',
};
const getVideoBase = (videoId) => `map:array:2:array:2:string:44:array:2:string:11:entity.typestring:6:videosstring:${videoId.length + String(videoId.length).length + 32}:array:2:string:7:mpxGuidstring:${videoId.length}:${videoId}`;
const getSeriesBase = (seriesName) => `map:array:2:array:2:string:${seriesName.length + String(seriesName.length).length + 36}:array:2:string:10:seriesNamestring:${seriesName.length}:${seriesName}string:44:array:2:string:11:entity.typestring:6:series`;
export const getVideoId = (videoId) => sha1(getVideoBase(videoId)).toString();
export const getSeriesId = (videoId) => sha1(getSeriesBase(videoId)).toString();
