import { IPlayerEvent } from './IPlayerEvent';
export class TimeChangeEvent extends IPlayerEvent {
    constructor(time, seekableRange, isAtLiveEdge) {
        super();
        this._time = time;
        this._seekableRange = seekableRange;
        this._isAtLiveEdge = isAtLiveEdge;
    }
    get time() {
        return this._time;
    }
    get seekableRange() {
        return this._seekableRange;
    }
    get isAtLiveEdge() {
        return this._isAtLiveEdge;
    }
}
