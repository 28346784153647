import { Lightning } from '@lightningjs/sdk';
import { COLORS, FONT_FACE } from '../../../../constants';
import { capitalize } from 'lodash';
export default class Tab extends Lightning.Component {
    static _template() {
        return {
            rect: true,
            color: COLORS.transparent,
            flexItem: {
                marginRight: 8,
            },
            h: 60,
            shader: {
                type: Lightning.shaders.RoundedRectangle,
                radius: 6,
            },
            Text: {
                mount: 0.5,
                x: (w) => w / 2,
                y: (h) => h / 2,
                text: {
                    textColor: COLORS.lightGray7,
                    fontSize: 35,
                    fontFace: FONT_FACE.light,
                    text: this.bindProp('label', (ctx) => (ctx.label ? capitalize(ctx.label) : '')),
                },
            },
        };
    }
    set current(v) {
        this._current = v;
        this._unfocus();
    }
    _init() {
        const textItem = this.tag('Text');
        textItem.loadTexture();
        this.w = (textItem && textItem.renderWidth + 32) || 0;
    }
    _focus() {
        this.patch({
            color: COLORS.white,
            Text: {
                text: {
                    fontFace: FONT_FACE.regular,
                    textColor: COLORS.black,
                },
            },
        });
    }
    _unfocus() {
        this.patch({
            color: COLORS.transparent,
            Text: {
                text: {
                    fontFace: this._current ? FONT_FACE.regular : FONT_FACE.light,
                    textColor: this._current ? COLORS.white : COLORS.lightGray12,
                },
            },
        });
    }
}
