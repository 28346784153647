import { Lightning } from '@lightningjs/sdk';
import { COLORS } from '../../constants';
export default class Button extends Lightning.Component {
    static _template() {
        return {
            w: (w) => w,
            h: (h) => h,
            Shadow: {
                alpha: 0,
                color: COLORS.black7,
                x: -10,
                y: -10,
            },
            BG: {},
        };
    }
    _construct() {
        this._radius = 40;
        this._focusBackGroundColor = COLORS.lightGray3;
        this._unfocusBackgroundColor = COLORS.dark;
        this._autoWidth = false;
        this._paddingLeft = 30;
        this._paddingRight = 30;
        this._explicitWidth = 30;
    }
    _init() {
        if (this._autoWidth) {
            this._calculateButtonWidth();
        }
        else {
            this._updateButton();
        }
    }
    _firstActive() {
        //     if (this._autoWidth) {
        //     } else {
        //     }
    }
    set radius(v) {
        this._radius = v;
    }
    set focusBackGroundColor(focusBackGroundColor) {
        this._focusBackGroundColor = focusBackGroundColor;
    }
    set unfocusBackgroundColor(unfocusBackgroundColor) {
        this._unfocusBackgroundColor = unfocusBackgroundColor;
    }
    set autoWidth(v) {
        this._autoWidth = v;
    }
    set padding(v) {
        this._paddingLeft = v;
        this._paddingRight = v;
    }
    set paddingLeft(v) {
        this._paddingLeft = v;
    }
    set paddingRight(v) {
        this._paddingRight = v;
    }
    _calculateButtonWidth() {
        this.w = this._explicitWidth + this._paddingLeft + this._paddingRight;
        this._updateButton();
    }
    _updateButton() {
        const bgColor = this._isFocus ? this._focusBackGroundColor : this._unfocusBackgroundColor;
        this.patch({
            Shadow: {
                alpha: 0,
            },
            BG: {
                texture: Lightning.Tools.getRoundRect(this.w, this.h, this._radius, 0, 0, true, bgColor),
                mount: 0.5,
            },
        });
        if (this._isFocus) {
            this.tag('Shadow').patch({
                alpha: 1,
                w: this.w + 20,
                h: this.h + 20,
                texture: Lightning.Tools.getShadowRect(this.w + 20, this.h + 20, 10, 40, 30),
                mount: 0.5,
            });
        }
    }
    _focus() {
        this._isFocus = true;
        this._updateButton();
    }
    _unfocus() {
        this._isFocus = false;
        this._updateButton();
    }
}
