import { Language, Lightning, Utils, Router } from '@lightningjs/sdk';
import SelectButton from '../../components/buttons/SelectButton';
import { CCTypes, ClosedCaptionsUtils } from '../../lib/ClosedCaptions/ClosedCaptionsUtils';
import { COLORS, FLEX_DIRECTION, FONT_FACE, ROUTE } from '../../constants';
export default class ClosedCaptioning extends Lightning.Component {
    static _template() {
        return {
            x: 80,
            y: 200,
            w: 1760,
            h: 810,
            rect: true,
            color: COLORS.dark5,
            PageTitleHolder: {
                x: 100,
                y: 78,
                Icon: {
                    h: 40,
                    w: 40,
                    src: Utils.asset('images/settings/settings.png'),
                },
                Title: {
                    x: 60,
                    text: {
                        text: Language.translate('closed_captioning'),
                        fontSize: 30,
                    },
                },
            },
            Content: {
                y: 178,
                x: 233,
                CaptionOptionButtons: {
                    flex: { direction: FLEX_DIRECTION.column },
                },
                Description: {
                    x: 1013 - 230,
                    y: 10,
                    text: {
                        text: Language.translate('select_closed_captioning_message'),
                        wordWrapWidth: 660,
                        fontFace: FONT_FACE.light,
                        fontSize: 30,
                        lineHeight: 45,
                    },
                },
            },
        };
    }
    _init() {
        this.stage.setClearColor(COLORS.dark);
        this.createButtons();
        this._index = 0;
    }
    _active() {
        this.widgets.menu.collapse({
            subCategoryItem: Language.translate('closed_captioning'),
            hideProfile: true,
        });
        this._setState('LanguageButton');
    }
    _inactive() {
        this.widgets.menu.expand();
    }
    createButtons() {
        const selectedCCType = ClosedCaptionsUtils.getCCType();
        const items = [
            {
                optionValue: CCTypes.off,
                selected: selectedCCType === CCTypes.off,
            },
            {
                optionValue: CCTypes.en,
                selected: selectedCCType === CCTypes.en,
            },
            {
                optionValue: CCTypes.es,
                selected: selectedCCType === CCTypes.es,
            },
        ];
        this.tag('CaptionOptionButtons').patch({
            children: items.map((item) => {
                return {
                    flexItem: { marginTop: 10 },
                    w: 740,
                    h: 70,
                    type: SelectButton,
                    radius: 0,
                    fontSize: 30,
                    fontFace: FONT_FACE.light,
                    focusFontColor: COLORS.dark,
                    unfocusFontColor: COLORS.white,
                    focusBackGroundColor: COLORS.lightGray3,
                    unfocusBackgroundColor: COLORS.black3,
                    label: ClosedCaptionsUtils.getCCTypeLabel(item.optionValue),
                    padding: 0,
                    icon: item.selected ? 'images/settings/selected.png' : 'images/settings/selection.png',
                    selected: item.selected ? item.selected : false,
                    optionValue: item.optionValue,
                };
            }),
        });
    }
    get focusItem() {
        return this.getChildItem(this._index);
    }
    getChildItem(index = 0) {
        return this.tag('CaptionOptionButtons').children[index];
    }
    _getFocused() {
        return this.focusItem || this;
    }
    _handleUp() {
        if (this._index > 0) {
            this._index--;
        }
    }
    _handleDown() {
        if (this._index < this.tag('CaptionOptionButtons').children.length - 1) {
            this._index++;
        }
    }
    _handleBack() {
        Router.navigate(ROUTE.settings);
    }
    $valueChanged(value) {
        if (ClosedCaptionsUtils.getCCType() !== value) {
            ClosedCaptionsUtils.setCCType(value);
            this.tag('CaptionOptionButtons').children.forEach((button) => {
                button.selected = button.optionValue === value;
            });
        }
    }
    getActiveButton() {
        let activebutton = null;
        this.tag('CaptionOptionButtons').children.forEach((button) => {
            if (button.selected)
                activebutton = button;
        });
        return activebutton;
    }
}
