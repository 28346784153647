class IHMACKeys {
    constructor(publicKey, privateKey) {
        this._public = publicKey;
        this._private = privateKey;
    }
    get public() {
        return this._public;
    }
    get private() {
        return this._private;
    }
}
export class IEntitlementConfig {
    constructor(enableBlackout, activationUrl, requestorId, server, deviceType, appId, keys) {
        this._enableBlackout = enableBlackout;
        this._activationUrl = activationUrl;
        this._requestorId = requestorId;
        this._server = server;
        this._deviceType = deviceType;
        this._appId = appId;
        this._keys = keys;
    }
    get enableBlackout() {
        return this._enableBlackout;
    }
    get activationUrl() {
        return this._activationUrl;
    }
    get requestorId() {
        return this._requestorId;
    }
    get server() {
        return this._server;
    }
    get deviceType() {
        return this._deviceType;
    }
    get appId() {
        return this._appId;
    }
    get keys() {
        return this._keys;
    }
}
class EntitlementConfig extends IEntitlementConfig {
    constructor() {
        // @ts-expect-error TS(2554): Expected 7 arguments, but got 0.
        super();
        this._enableBlackout = false;
        this._activationUrl = 'http://nbc.com/activate/vizio';
        this._requestorId = 'nbcentertainment';
        this._server = 'https://api.auth.adobe.com';
        this._deviceType = 'xclass';
        this._keys = new IHMACKeys('CJlJWG0JcLacSEZGYPoZdfgDxp0t4tye', 
        // FIXME: This shouldn't be stored here or in any publicly accessable place - Angel C.
        'rcwYfN38DLFIsipS');
    }
}
export default new EntitlementConfig();
