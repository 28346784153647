import Shelf from './Shelf';
import componentTypes from '../../types/componentTypes';
import Tiles from '../tiles';
import { getWhiteLabelBrand } from '../../../helpers';
class OnAirNowShelf extends Shelf {
    mapItems(items) {
        const shelfData = this.shelfData;
        return OnAirNowShelf.getFilteredSortedItems(items).map(
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        (item, index) => new Tiles[item.component](item, shelfData, index));
    }
}
OnAirNowShelf.getFilteredSortedItems = (items) => {
    const nbcNewsNow = 'NBC News NOW';
    const whiteLabelBrand = getWhiteLabelBrand().analytics;
    const appBrand = whiteLabelBrand === 'NBC Sports' ? 'NBC' : whiteLabelBrand;
    const brandTile = items.find((tile) => { var _a; return ((_a = tile === null || tile === void 0 ? void 0 : tile.onAirNowTileData) === null || _a === void 0 ? void 0 : _a.brandDisplayTitle) === appBrand; });
    // PL-1568 - Find NBC News Now tile and push it to the end of the shelf
    const nbcNow = items.find((tile) => { var _a; return ((_a = tile.onAirNowTileData) === null || _a === void 0 ? void 0 : _a.brandDisplayTitle) === nbcNewsNow; });
    const filteredItems = items
        .filter((item) => item &&
        item.onAirNowTileData &&
        ![appBrand, nbcNewsNow].includes(item.onAirNowTileData.brandDisplayTitle) &&
        Object.values(componentTypes).indexOf(item.component) > -1)
        .sort((a, b) => a.onAirNowTileData.brandDisplayTitle.localeCompare(b.onAirNowTileData.brandDisplayTitle));
    // App brand tile is displayed first, followed by tiles ordered alphabetically by brand. NBC News Now should be at the end.
    return [...(brandTile ? [brandTile] : []), ...filteredItems, ...(nbcNow ? [nbcNow] : [])];
};
export default OnAirNowShelf;
