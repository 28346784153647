import { FALLBACK_TIMEZONE } from '../../constants';
import algolia from '../algolia/algolia';
export const getSLEAssets = algolia.sles.getAssets;
export const getSLEDates = async (page = 0) => {
    let result = await algolia.sles.getDates(null, page);
    // Fallback if users device timezone is not supported
    if (!result.dates)
        result = await algolia.sles.getDates(FALLBACK_TIMEZONE, page);
    return result;
};
