import { IPlayerEvent } from './IPlayerEvent';
export class NonLinearAdEvent extends IPlayerEvent {
    constructor(ad) {
        super();
        this._ad = ad;
    }
    consume() {
        return this._ad.consume();
    }
}
