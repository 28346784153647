import { COMPONENT_TYPES } from '../../constants';
const isEvent = (component) => [COMPONENT_TYPES.UPCOMING_LIVE_TILE, COMPONENT_TYPES.REPLAY_TILE].includes(component);
const setSource = (source) => (...components) => components.forEach((component) => {
    if (component.data.tile)
        component.data.tile.source = source;
});
const endCardMapper = (page, source) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    const data = (_a = page.data) === null || _a === void 0 ? void 0 : _a.endCard;
    if (!data)
        return null;
    if ('tileOne' in data.data &&
        'tileTwo' in data.data &&
        ((_b = data.data.tileOne) === null || _b === void 0 ? void 0 : _b.data.tile) &&
        ((_c = data.data.tileTwo) === null || _c === void 0 ? void 0 : _c.data.tile)) {
        setSource(source)(data.data.tileOne, data.data.tileTwo);
        if (isEvent((_g = (_f = (_e = (_d = data === null || data === void 0 ? void 0 : data.data) === null || _d === void 0 ? void 0 : _d.tileOne) === null || _e === void 0 ? void 0 : _e.data) === null || _f === void 0 ? void 0 : _f.tile) === null || _g === void 0 ? void 0 : _g.component)) {
            data.data.tileOne.data.tagLine = 'Watch Next';
        }
        if (isEvent((_l = (_k = (_j = (_h = data === null || data === void 0 ? void 0 : data.data) === null || _h === void 0 ? void 0 : _h.tileTwo) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.tile) === null || _l === void 0 ? void 0 : _l.component)) {
            data.data.tileTwo.data.tagLine = '';
        }
    }
    // Adding the source so that we can manage differences of SLE and FER
    return {
        endCardData: data,
    };
};
export default endCardMapper;
