import { IPlayerEvent } from './IPlayerEvent';
export class PlayerStatusEvent extends IPlayerEvent {
    constructor(status, metadata) {
        super();
        this._status = status;
        this._metadata = metadata;
    }
    get status() {
        return this._status;
    }
    get metadata() {
        var _a;
        return (_a = this._metadata) !== null && _a !== void 0 ? _a : {};
    }
}
