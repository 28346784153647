import { Language, Lightning } from '@lightningjs/sdk';
import AboutTextComponent from './AboutTextComponent';
import { COLORS, FONT_FACE, SCREEN_SIZE } from '../../constants';
// @ts-expect-error TS(2417): Class static side 'typeof ShowAbout' incorrectly e... Remove this comment to see the full error message
export default class ShowAbout extends Lightning.Component {
    static _template() {
        return {
            Container: {
                TextWrapper: {
                    type: AboutTextComponent,
                    x: 355,
                    y: 100,
                    w: 1210,
                    titleStyle: {
                        paddingLeft: 0,
                        wordWrapWidth: 1210,
                        fontSize: 85,
                        lineHeight: 85,
                        textColor: COLORS.white,
                        fontFace: FONT_FACE.light,
                    },
                    contentStyle: {
                        wordWrapWidth: 1200,
                        paddingLeft: 0,
                        lineHeight: 38,
                        fontSize: 34,
                        textColor: COLORS.white,
                        fontFace: FONT_FACE.light,
                    },
                },
                Button: {
                    flex: {},
                    x: 355,
                    h: 70,
                    rect: true,
                    color: COLORS.black3,
                    shader: { type: Lightning.shaders.RoundedRectangle, radius: 35 },
                    Label: {
                        flexItem: { marginLeft: 50, marginRight: 50 },
                        mountY: 0.5,
                        y: 37,
                        text: {
                            fontSize: 40,
                            fontFace: FONT_FACE.light,
                            textColor: COLORS.white,
                            text: Language.translate('back'),
                        },
                    },
                },
            },
        };
    }
    set data(v) {
        this.tag('TextWrapper').title = v.title;
        this.tag('TextWrapper').items = v.content;
        this.tag('Button').y = this.tag('TextWrapper').h + 110;
        this.announce = [v.title, v.content].filter((text) => !!text);
        this._totalSteps = Math.ceil((this.tag('Button').y + this.tag('Button').h - SCREEN_SIZE.height) / 500);
        this._inactive();
    }
    _inactive() {
        this._currentStep = 0;
        this.tag('Container').setSmooth('y', 0);
        if (this._totalSteps > 0) {
            this._setState('Content');
        }
        else {
            this._setState('Button');
        }
    }
    _handleBack(e) {
        this.fireAncestors('$hideAbout');
        e.preventDefault();
        e.stopPropagation();
    }
    static _states() {
        return [
            class Content extends this {
                _handleUp() {
                    if (this._currentStep === 0) {
                        return false;
                    }
                    this._currentStep--;
                    this._scroll();
                }
                _handleDown() {
                    if (this._currentStep === this._totalSteps) {
                        this._setState('Button');
                        return false;
                    }
                    this._currentStep++;
                    this._scroll();
                }
                // @ts-expect-error TS(2416): Property '_getFocused' in type 'Content' is not as... Remove this comment to see the full error message
                _getFocused() {
                    this.tag('TextWrapper') || this;
                }
                _scroll() {
                    const distance = -500 * this._currentStep;
                    this.tag('Container').setSmooth('y', distance);
                }
            },
            class Button extends this {
                $enter() {
                    this._focusUpdate(true);
                }
                $exit() {
                    this._focusUpdate(false);
                }
                _focusUpdate(v) {
                    this.tag('Button').patch({
                        color: v ? COLORS.lightGray3 : COLORS.black3,
                        Label: {
                            text: {
                                textColor: v ? COLORS.dark : COLORS.white,
                            },
                        },
                    });
                }
                _captureUp() {
                    if (this._totalSteps > 0) {
                        this._setState('Content');
                        return false;
                    }
                }
                _handleEnter() {
                    this.fireAncestors('$hideAbout');
                }
            },
        ];
    }
}
