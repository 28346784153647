import { StorageFactorySingleton } from '../storage/StorageFactory';
const keyPrefix = 'debug';
export class DebugModelController {
    constructor() {
        this._storage = StorageFactorySingleton.get();
    }
    get(key) {
        return this._getFromStorage(key);
    }
    set(key, value) {
        this._setInStorage(key, value);
    }
    _getFromStorage(key) {
        return this._storage.get(keyPrefix + '.' + key);
    }
    _setInStorage(key, value) {
        this._storage.set(keyPrefix + '.' + key, value);
    }
}
export const DebugModelControllerSingleton = new DebugModelController();
