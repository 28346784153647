import CryptoJS from 'crypto-js';
import { print } from 'graphql';
import { BonanzaPage, BrandTitleCategories, ComponentsForPlaceholders, EndCardPage, FeaturedSection, FeaturedShowsSection, GlobalNavigation, LdStateflag, MixedEditorialShelf, OnAirNowShelfPage, PaginatedComponent, PaginatedShelf, ScrollFromSeason, UserInteractions, VideosSectionPage, } from '../../graphql/generated/types';
import { featureTransformer } from '../transformers/feature';
export var QueryName;
(function (QueryName) {
    QueryName["scrollFromSeason"] = "scrollFromSeason";
    QueryName["brandTitleCategories"] = "brandTitleCategories";
    QueryName["componentsForPlaceholders"] = "componentsForPlaceholders";
    QueryName["endCard"] = "endCard";
    QueryName["featuredSection"] = "featuredSection";
    QueryName["ldStateFlag"] = "ldStateFlag";
    QueryName["bonanzaPage"] = "bonanzaPage";
    QueryName["globalNavigation"] = "globalNavigation";
    QueryName["mixedEditorialShelf"] = "mixedEditorialShelf";
    QueryName["onAirNowShelf"] = "onAirNowShelf";
    QueryName["paginatedComponent"] = "paginatedComponent";
    QueryName["paginatedShelf"] = "paginatedShelf";
    QueryName["featuredShowsSection"] = "featuredShowsSection";
    QueryName["userInteractions"] = "userInteractions";
    QueryName["videosSection"] = "videosSection";
})(QueryName || (QueryName = {}));
const BffScheme = {
    [QueryName.scrollFromSeason]: ScrollFromSeason,
    [QueryName.brandTitleCategories]: BrandTitleCategories,
    [QueryName.componentsForPlaceholders]: ComponentsForPlaceholders,
    [QueryName.endCard]: EndCardPage,
    [QueryName.featuredSection]: FeaturedSection,
    [QueryName.bonanzaPage]: BonanzaPage,
    [QueryName.globalNavigation]: GlobalNavigation,
    [QueryName.mixedEditorialShelf]: MixedEditorialShelf,
    [QueryName.onAirNowShelf]: OnAirNowShelfPage,
    [QueryName.paginatedComponent]: PaginatedComponent,
    [QueryName.paginatedShelf]: PaginatedShelf,
    [QueryName.featuredShowsSection]: FeaturedShowsSection,
    [QueryName.userInteractions]: UserInteractions,
    [QueryName.videosSection]: VideosSectionPage,
    [QueryName.ldStateFlag]: LdStateflag,
};
export const getQuerySchema = (queryName) => {
    return print(BffScheme[queryName]);
};
const hashMap = new Map();
export const getHash = (query) => {
    const querySchema = getQuerySchema(query);
    if (hashMap.has(query))
        return hashMap.get(query);
    if (querySchema) {
        const hash = CryptoJS.SHA256(querySchema).toString();
        return hash;
    }
};
export const storeHash = (query, hash) => {
    if (hash)
        hashMap.set(query, hash);
};
export const syncQueries = (override) => {
    // Refresh queries
    BffScheme[QueryName.featuredSection] = featureTransformer(FeaturedSection, override);
    BffScheme[QueryName.bonanzaPage] = featureTransformer(BonanzaPage, override);
    BffScheme[QueryName.paginatedShelf] = featureTransformer(PaginatedShelf, override);
    BffScheme[QueryName.endCard] = featureTransformer(EndCardPage, override);
    BffScheme[QueryName.featuredShowsSection] = featureTransformer(FeaturedShowsSection, override);
    BffScheme[QueryName.brandTitleCategories] = featureTransformer(BrandTitleCategories, override);
    BffScheme[QueryName.paginatedComponent] = featureTransformer(PaginatedComponent, override);
    BffScheme[QueryName.componentsForPlaceholders] = ComponentsForPlaceholders;
    hashMap.clear();
};
