export const removeFieldFromNodeSelectionSet = (node, name) => {
    var _a;
    const selections = (_a = node.selectionSet) === null || _a === void 0 ? void 0 : _a.selections;
    const isNotIncluded = Array.isArray(name)
        ? (item) => !(item.kind === 'Field' && name.includes(item.name.value))
        : (item) => !(item.kind === 'Field' && item.name.value === name);
    if (selections) {
        node.selectionSet.selections = selections.filter(isNotIncluded);
    }
    return node;
};
export const removeFragmentSpreadFromNodeSelectionSet = (node, name) => {
    var _a;
    const selections = (_a = node.selectionSet) === null || _a === void 0 ? void 0 : _a.selections;
    const isNotIncluded = Array.isArray(name)
        ? (item) => !(item.kind === 'FragmentSpread' && name.includes(item.name.value))
        : (item) => !(item.kind === 'FragmentSpread' && item.name.value === name);
    if (selections) {
        node.selectionSet.selections = selections.filter(isNotIncluded);
    }
    return node;
};
export const applyToNodeSelectionSet = (node, matchCases) => {
    var _a;
    const selections = (_a = node.selectionSet) === null || _a === void 0 ? void 0 : _a.selections;
    if (selections) {
        selections.forEach((field) => {
            matchCases.forEach((condition) => {
                if (field.kind === condition.kind && field.name.value === condition.name) {
                    condition.callback(field);
                }
            });
        });
    }
    return node;
};
export const removeFragmentsFromNode = (node, fragments) => {
    var _a;
    const disabledKinds = ['FragmentSpread', 'FragmentDefinition'];
    const matches = disabledKinds.includes(node.kind) &&
        fragments.includes((_a = node.name) === null || _a === void 0 ? void 0 : _a.value);
    return matches ? null : undefined;
};
