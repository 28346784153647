import { PlayerControlsStates } from '../../../../constants';
import { PauseAdControlsStateFactory } from '../states/PauseAdControl';
export const WithPauseAds = (base) => class extends base {
    constructor() {
        super(...arguments);
        this._pauseAdCurrent = null;
    }
    static _states() {
        // @ts-expect-error super doesn't work with static methods
        return [...super._states(), PauseAdControlsStateFactory(this)];
    }
    get pauseAdEventData() {
        return this._pauseAdCurrent;
    }
    set pauseAdRefreshRate(refreshData) {
        this._pauseAdRefreshData = refreshData;
    }
    get pauseAdRefreshData() {
        return this._pauseAdRefreshData;
    }
    setShowPauseAds() {
        this._setState(PlayerControlsStates.PauseAdControls);
    }
    shouldShowPauseAds() {
        // Don't give back pause ads data when seeking or in an ad
        return !(this._pauseAdCurrent === null ||
            this._getState() === PlayerControlsStates.AdControls ||
            this._getState() === PlayerControlsStates.Seeking ||
            this.fireAncestors('$isPlaying'));
    }
    setPauseAdsEventData(adEvent, refreshData) {
        this._pauseAdCurrent = adEvent;
        this.pauseAdRefreshRate = refreshData;
    }
    clearPauseAdsEventData() {
        this._pauseAdCurrent = null;
    }
};
