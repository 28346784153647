import isUndefined from 'lodash/isUndefined';
import { SkipButtonDelegate, SkipButtonStates } from '../delegates/SkipButtonDelegate';
import SkipButton from '../../../components/buttons/SkipButton';
import { TimeChangeEvent } from '../../../player/model/event/TimeChangeEvent';
import { PlayerStates } from '../../../constants';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { isSingleStream } from '../../../store/PlayerStore/actions';
import SkipButtons from '../../../api/models/SkipButtons';
import BasePlayer from '../BasePlayer';
class HasSkipButton extends BasePlayer {
}
export const WithSkipButton = (component) => class extends component {
    constructor() {
        super(...arguments);
        this._skipButtonDelegate = new SkipButtonDelegate(this);
        this._skipButtonIsShown = false;
    }
    static _template() {
        return {
            // @ts-expect-error TODO static property
            ...super._template(),
            SkipButton: {
                type: SkipButton,
                zIndex: 100,
            },
        };
    }
    _init() {
        super._init();
        this._skipButton = this.tag('SkipButton');
        this._skipButtons = null;
    }
    _detach() {
        var _a;
        super._detach();
        (_a = this._skipButtonEvents) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    _playerEventsHandler(event) {
        super._playerEventsHandler(event);
        if (event instanceof TimeChangeEvent) {
            this._skipButtonDelegate.sync(event.time);
        }
    }
    async _startStream() {
        await super._startStream();
        const { stream } = PlayerStoreSingleton;
        if (!isSingleStream(stream))
            return;
        const { allowSkipButtons, startIntroTiming, endIntroTiming, startRecapTiming, startTeaserTiming, endTeaserTiming, endRecapTiming, } = stream;
        this._skipButtons = new SkipButtons({
            allowSkipButtons,
            startIntroTiming,
            endIntroTiming,
            startRecapTiming,
            startTeaserTiming,
            endTeaserTiming,
            endRecapTiming,
        });
        this._skipButtonDelegate.reset();
        this._skipButtonEvents = this.$subscribeToSkipButtonDelegate(this.onSkipButtonStatusChanged.bind(this));
    }
    _areControlVisible() {
        return this._controls.getSmooth('alpha') === 1;
    }
    onSkipButtonStatusChanged({ status }) {
        this.skipButtonIsShown = status !== SkipButtonStates.EMPTY_STATE && !isUndefined(status);
        if (!this.skipButtonIsShown) {
            if (this._getState() === PlayerStates.LPPlayer_SkipButton) {
                if (this._areControlVisible()) {
                    this._setState(PlayerStates.LPPlayer_Controls);
                }
                else {
                    this._setState(PlayerStates.LPPlayer);
                }
            }
        }
        else {
            this._setState(PlayerStates.LPPlayer_SkipButton);
        }
    }
    set skipButtonIsShown(skipButtonIsShown) {
        this._skipButtonIsShown = skipButtonIsShown;
    }
    get skipButtonIsShown() {
        return this._skipButtonIsShown;
    }
    _showSkipButton() {
        return this._skipButtonIsShown;
    }
    $subscribeToSkipButtonDelegate(cb) {
        var _a;
        return (_a = this._skipButtonDelegate) === null || _a === void 0 ? void 0 : _a.events.subscribe(cb);
    }
    $selectSkipButton() {
        this._setState(PlayerStates.LPPlayer_SkipButton);
    }
    _hideUI() {
        super._hideUI();
        if (this._skipButton)
            this._skipButton.y = 0;
    }
};
