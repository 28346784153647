import OlympicsEventsWithFilters from '../OlympicsEventsWithFilters';
import { useRequest } from '../../lib/useRequest';
import { OlympicsHighlightsRequestConfig } from './request';
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError';
class OlympicsHighlights extends OlympicsEventsWithFilters {
    constructor() {
        super(...arguments);
        this.announceTitle = 'Olympics Highlights';
    }
    async load() {
        try {
            this.apiData = await useRequest(OlympicsHighlightsRequestConfig()).fetch();
        }
        catch (err) {
            this._setState(WithRequestErrorState);
        }
    }
}
export default WithRequestError(OlympicsHighlights);
