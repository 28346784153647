import OlympicsEventsWithFilters from '../OlympicsEventsWithFilters';
import { useRequest } from '../../lib/useRequest';
import { OlympicsReplaysRequestConfig } from './request';
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError';
class OlympicsReplays extends OlympicsEventsWithFilters {
    constructor() {
        super(...arguments);
        this.announceTitle = 'Olympics Replays';
    }
    async load() {
        try {
            this.apiData = await useRequest(OlympicsReplaysRequestConfig()).fetch();
        }
        catch (err) {
            this._setState(WithRequestErrorState);
        }
    }
}
export default WithRequestError(OlympicsReplays);
