import FavoriteInteraction from './favoriteInteraction/FavoriteInteraction';
import { IFavoriteInteractionFactory } from './IFavoriteInteractionFactory';
/**
 * This creates an instance of the Favorites class.
 */
class FavoriteInteractionFactory extends IFavoriteInteractionFactory {
    constructor() {
        super();
        this._favorites = new FavoriteInteraction();
    }
    get() {
        return this._favorites;
    }
}
export const FavoriteInteractionFactorySingleton = new FavoriteInteractionFactory();
