import { IStorageFactory } from './IStorageFactory';
import { LSStorage } from './LSStorage';
class StorageFactory extends IStorageFactory {
    constructor() {
        super(...arguments);
        this._storage = new LSStorage();
    }
    get() {
        return this._storage;
    }
}
export const StorageFactorySingleton = new StorageFactory();
