import LabelBadge from '../../badges/LabelBadge';
import { COLORS } from '../../../constants';
export default (tile) => {
    var _a;
    const durationBadge = (_a = tile._item) === null || _a === void 0 ? void 0 : _a.durationBadge;
    if (durationBadge) {
        tile.patch({
            ImageHolder: {
                LeftBottomBadge: {
                    type: LabelBadge,
                    label: durationBadge,
                    positionX: 10,
                    positionY: (h) => h - 50,
                    backgroundColor: COLORS.black5,
                    fontColor: COLORS.white,
                    fontSize: 24,
                    width: 68,
                    height: 36,
                },
            },
        });
    }
};
