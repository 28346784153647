import { BehaviorSubject, Subject } from 'rxjs';
import cloneDeep from 'lodash/cloneDeep';
export const createStatelessHook = () => {
    const createObservable = () => new Subject();
    let _subject = null;
    return (cb) => {
        let _subscription;
        if (!_subject) {
            _subject = createObservable();
        }
        if (cb) {
            _subscription = _subject.subscribe(cb);
        }
        return {
            set(value) {
                if (_subject) {
                    _subject.next(value);
                }
                else {
                    _subject = createObservable();
                }
            },
            unsubscribe() {
                if (_subscription) {
                    _subscription.unsubscribe();
                    _subscription = null;
                }
                if (!(_subject === null || _subject === void 0 ? void 0 : _subject.observed)) {
                    _subject = null;
                }
            },
        };
    };
};
export const createHook = (defaultValue) => {
    const createObservable = () => new BehaviorSubject(cloneDeep(defaultValue));
    let _subject = null;
    return (cb) => {
        let _subscription;
        if (!_subject) {
            _subject = createObservable();
        }
        if (cb) {
            _subscription = _subject.subscribe(cb);
        }
        return {
            get value() {
                return (_subject === null || _subject === void 0 ? void 0 : _subject.value) || defaultValue;
            },
            set(value) {
                if (_subject) {
                    _subject.next(value);
                }
                else {
                    _subject = createObservable();
                }
            },
            unsubscribe(destroySubject = true) {
                if (_subscription) {
                    _subscription.unsubscribe();
                    _subscription = null;
                }
                if (!(_subject === null || _subject === void 0 ? void 0 : _subject.observed) && destroySubject) {
                    _subject = null;
                }
            },
        };
    };
};
export const createHookEvent = (hook, setter) => {
    return setter(hook());
};
