import cloneDeep from 'lodash/cloneDeep';
import { Registry } from '@lightningjs/sdk';
import BaseStore from '../BaseStore';
import { fetchUserInteractions, UserInteractionsStoreActions } from './actions';
const defaultState = {
    userWatches: new Map(),
    userLiveWatches: new Map(),
    userFavorites: new Map(),
};
const NEXT_TIME_TO_CALL = 60 * 60; // 1 hour
const createUserFavoriteMap = (userFavorites) => new Map(userFavorites.map((userFavorite) => [userFavorite.titleId, userFavorite]));
const createUserWatchMap = (userWatches) => new Map(userWatches.map((userWatch) => [userWatch.videoId, userWatch]));
const createUserLiveWatchMap = (userLiveWatches) => new Map(userLiveWatches.map((userLiveWatch) => [userLiveWatch.brandId, userLiveWatch]));
export const UserInteractionsStoreEvents = Object.freeze({
    USER_INTERACTIONS_OK: 'userInteractionsOK',
    FAVORITES_OK: 'favoritesOK',
    SET_FAVORITE_OK: 'setFavoriteOK',
    REMOVE_FAVORITE_OK: 'removeFavoriteOK',
    WATCHES_OK: 'watchesOK',
    LIVE_WATCHES_OK: 'liveWatchesOK',
    ERROR: 'error',
});
class UserInteractionsStore extends BaseStore {
    constructor() {
        super(...arguments);
        this.state = cloneDeep(defaultState);
    }
    async dispatch(action) {
        try {
            const result = await this._getActionResult(action);
            const { type, payload } = result;
            switch (type) {
                case UserInteractionsStoreActions.CLEAR_STATE:
                    this.state = cloneDeep(defaultState);
                    break;
                case UserInteractionsStoreActions.SET_USER_INTERACTIONS:
                    this._registerLastCall();
                    this.state.userWatches = createUserWatchMap(payload.userWatches);
                    this.state.userLiveWatches = createUserLiveWatchMap(payload.userLiveWatches);
                    this.state.userFavorites = createUserFavoriteMap(payload.userFavorites);
                    this.events.next({
                        type: UserInteractionsStoreEvents.USER_INTERACTIONS_OK,
                        payload,
                    });
                    break;
                case UserInteractionsStoreActions.SET_FAVORITES:
                    this.state.userFavorites = createUserFavoriteMap(payload);
                    this.events.next({
                        type: UserInteractionsStoreEvents.FAVORITES_OK,
                        payload,
                    });
                    break;
                case UserInteractionsStoreActions.SET_WATCHES:
                    this.state.userWatches = createUserWatchMap(payload);
                    this.events.next({
                        type: UserInteractionsStoreEvents.WATCHES_OK,
                        payload,
                    });
                    break;
                case UserInteractionsStoreActions.SET_WATCH:
                    this.state.userWatches.set(payload.videoId, payload);
                    this.events.next({
                        type: UserInteractionsStoreEvents.WATCHES_OK,
                        payload,
                    });
                    break;
                case UserInteractionsStoreActions.SET_LIVE_WATCHES:
                    this.state.userLiveWatches.set(payload.id, payload);
                    this.events.next({
                        type: UserInteractionsStoreEvents.LIVE_WATCHES_OK,
                        payload,
                    });
                    break;
                case UserInteractionsStoreActions.SET_FAVORITE:
                    this.state.userFavorites.set(payload.titleId, payload);
                    this.events.next({
                        type: UserInteractionsStoreEvents.SET_FAVORITE_OK,
                        payload,
                    });
                    break;
                case UserInteractionsStoreActions.REMOVE_FAVORITE:
                    this.state.userFavorites.delete(payload);
                    this.events.next({
                        type: UserInteractionsStoreEvents.REMOVE_FAVORITE_OK,
                        payload,
                    });
                    break;
                case UserInteractionsStoreActions.NO_CALL:
                default:
                    break;
            }
            return result;
        }
        catch (e) {
            return Promise.reject(e);
        }
    }
    isFavorite(id) {
        return this.state.userFavorites.has(id);
    }
    getPercentViewed(id) {
        var _a;
        return ((_a = this.state.userWatches.get(id)) === null || _a === void 0 ? void 0 : _a.percentViewed) || 0;
    }
    getUserFavorite(id) {
        return this.state.userFavorites.get(id);
    }
    get lastCall() {
        return this._lastCall;
    }
    set lastCall(lastCall) {
        this._lastCall = lastCall;
    }
    _registerLastCall() {
        this.lastCall = new Date();
        Registry.setTimeout(() => {
            this.dispatch(fetchUserInteractions(true));
        }, NEXT_TIME_TO_CALL * 1000);
    }
}
const UserInteractionsStoreSingleton = new UserInteractionsStore();
export default UserInteractionsStoreSingleton;
