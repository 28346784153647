import { Language, Lightning, Router } from '@lightningjs/sdk';
import { COLORS, ROUTE, SCREEN_SIZE } from '../constants';
import ErrorModal from '../widgets/Modals/errorModal/ErrorModal';
import { ErrorModalAction, useErrorModal, } from '../widgets/Modals/errorModal/hooks/useErrorModal';
export default class Error extends Lightning.Component {
    static _template() {
        return {
            ErrorModalContainer: {
                x: 0,
                y: 0,
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                rect: true,
                color: COLORS.black,
                ErrorModal: {
                    y: 180,
                    type: ErrorModal,
                },
            },
        };
    }
    _init() {
        this.tag('ErrorModal').error = {
            title: Language.translate('bffError_title'),
            message: Language.translate('bffError_info'),
            retryable: false,
        };
        this._errorModalHook = useErrorModal((action) => {
            var _a;
            if (action === ErrorModalAction.BACK) {
                if ((_a = this.params) === null || _a === void 0 ? void 0 : _a.reBoot) {
                    // Force hard reload to reboot the app
                    window.location.hash = `#${ROUTE.home}`;
                    window.location.reload();
                }
                else {
                    Router.back();
                }
            }
            else if (action === ErrorModalAction.REDIRECT) {
                Router.navigate(ROUTE.live);
            }
        });
    }
    _getFocused() {
        return this.tag('ErrorModal') || this;
    }
}
