import { Lightning } from '@lightningjs/sdk';
import { FLEX_DIRECTION } from '../../../../constants';
import Tab from './Tab';
import { EpgGuideController, EpgGuideEvents } from '../../EpgGuideController';
export default class GuideTabs extends Lightning.Component {
    constructor() {
        super(...arguments);
        this.selectedIndex = 0;
        this.focusIndex = 0;
        this._items = [];
        this._load = async ({ data }) => {
            var _a;
            const links = (_a = this.tag('Links')) === null || _a === void 0 ? void 0 : _a.children;
            const isAlreadyLoaded = links.length &&
                (links === null || links === void 0 ? void 0 : links.every((item, index) => { var _a, _b, _c; return ((_a = item === null || item === void 0 ? void 0 : item.label) === null || _a === void 0 ? void 0 : _a.toLowerCase()) === ((_c = (_b = data.itemLabels) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.toLowerCase()); }));
            if (!data || isAlreadyLoaded)
                return;
            this.focusIndex = (data === null || data === void 0 ? void 0 : data.initiallySelected) || 0;
            this._items = data.items;
            this.tag('Links').children = data.itemLabels.map((item, index) => ({
                type: Tab,
                current: index === this.focusIndex,
                label: item,
            }));
            // this.queueAction()
        };
    }
    static _template() {
        return {
            w: (w) => w,
            h: (h) => h,
            Links: {
                flex: {
                    direction: FLEX_DIRECTION.row,
                    paddingLeft: 16,
                },
                w: (w) => w,
            },
        };
    }
    _init() {
        this._subscription = EpgGuideController.subscribe({
            type: EpgGuideEvents.TAB_DATA,
            handler: this._load,
        });
    }
    _detach() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._subscription = undefined;
    }
    _getFocused() {
        return this.currentItem;
    }
    get selectedItem() {
        var _a;
        return (_a = this.tag('Links').children) === null || _a === void 0 ? void 0 : _a[this.selectedIndex];
    }
    get currentItem() {
        var _a;
        return ((_a = this.tag('Links').children) === null || _a === void 0 ? void 0 : _a[this.focusIndex]) || this;
    }
    selectCurrent() {
        var _a;
        this.selectedIndex = this.focusIndex;
        EpgGuideController.setActiveTab(this.selectedIndex);
        (_a = this.tag('Links').children) === null || _a === void 0 ? void 0 : _a.forEach((child, index) => {
            child.current = index === this.selectedIndex;
        });
    }
    getCurrentItem() {
        return this._items ? this._items[this.focusIndex] : null;
    }
    changeIndex(delta) {
        const newIndex = this.focusIndex + delta;
        const isInRange = newIndex >= 0 && newIndex < this.tag('Links').children.length;
        if (!isInRange)
            return;
        this.focusIndex = newIndex;
    }
}
