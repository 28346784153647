import { createStatelessHook } from '../../../../lib/Hook';
export var ErrorModalAction;
(function (ErrorModalAction) {
    ErrorModalAction["REDIRECT"] = "redirect";
    ErrorModalAction["RETRY"] = "retry";
    ErrorModalAction["BACK"] = "back";
    ErrorModalAction["LIVE_GUIDE"] = "live_guide";
    ErrorModalAction["EXIT"] = "exit";
})(ErrorModalAction || (ErrorModalAction = {}));
export const useErrorModal = createStatelessHook();
