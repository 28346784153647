import { PlayerStoreActions } from '.';
import { PlayerError } from '../../../components/error/PlayerError';
import { fetchLemonadeData } from '../../../lib/lemonade/Lemonade';
export const setLemonade = (payload) => ({
    type: PlayerStoreActions.SET_LEMONADE,
    payload,
});
export const setPreCheckLemonade = (payload) => ({
    type: PlayerStoreActions.SET_PRE_CHECK_LEMONADE,
    payload,
});
export const getLemonade = (stream) => () => fetchLemonadeData(stream)
    .then(setLemonade)
    .catch(() => ({
    type: PlayerStoreActions.ERROR,
    payload: {
        type: PlayerError.LEMONADE,
    },
}));
