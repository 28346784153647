import { COLORS, FONT_FACE, MAX_LINES_SUFFIX } from '../../../constants';
export default (tile) => {
    var _a, _b, _c;
    if (((_a = tile._item) === null || _a === void 0 ? void 0 : _a.showSfvodData) || ((_b = tile._item) === null || _b === void 0 ? void 0 : _b.replayTileData)) {
        tile.patch({
            Content: {
                TitleCard: {
                    ShortTitle: {
                        text: {
                            maxLines: 1,
                            maxLinesSuffix: MAX_LINES_SUFFIX,
                        },
                    },
                    SmartTimeStamp: {
                        flex: {},
                        flexItem: { marginTop: -8 },
                        ShortTitle: {
                            text: {
                                text: ((_c = tile._item) === null || _c === void 0 ? void 0 : _c.tertiaryTitle) || '',
                                fontSize: 24,
                                textColor: COLORS.white,
                                fontFace: FONT_FACE.light,
                                lineHeight: 24,
                            },
                        },
                    },
                },
            },
        });
    }
};
