import AnalyticsSingleton, { sendMetric } from '../../../../lib/analytics/Analytics';
import { EVENTS } from '../../../../lib/analytics/types';
import { AnalyticsWithEndCardDelegate } from './AnalyticsWithEndCardDelegate';
export class FERAnalyticsDelegate extends AnalyticsWithEndCardDelegate {
    fireSessionInit() {
        // no op
    }
    updateUserDefaultAttributes(tempPassTokenName) {
        AnalyticsSingleton.updateUserDefaultAttributes(tempPassTokenName);
    }
    fireSessionStart() {
        sendMetric(EVENTS.VOD_SESSION_START, this._getMetricsData());
    }
    fireSessionEnd() {
        sendMetric(EVENTS.VOD_SESSION_END, this._getMetricsData());
    }
}
