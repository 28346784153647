import { Router } from '@lightningjs/sdk';
import AppConfigFactorySingleton from '../../../config/AppConfigFactory';
import { PlayerStates, ROUTE, ROUTE_ENTITY, STORAGE_KEYS } from '../../../constants';
import RouterUtil from '../../../util/RouterUtil';
import { CCTypes, ClosedCaptionsUtils } from '../../../lib/ClosedCaptions/ClosedCaptionsUtils';
import { LiveStreamManager } from '../../../lib/LiveStreamManager';
import { navigateLinearItem } from '../../../components/items/helpers/linear';
import { usePreCheckData } from '../../../store/utils/preCheckData';
import { openPlayerLoader } from '../../../widgets/Modals/playerLoader/PlayerLoader';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { clearState } from '../../../store/PlayerStore/actions';
export const EpgStateFactory = (base) => class Epg extends base {
    async $enter({ prevState }, isError) {
        this._preState = prevState;
        if (!this._epg)
            return;
        this._epg.isError = isError;
        if (Router.getActiveRoute() === ROUTE.live) {
            LiveStreamManager.update().finally(() => {
                this._showEpg();
            });
        }
        else {
            this._showEpg();
        }
    }
    _showEpg() {
        var _a;
        this._epg.sync();
        this._epg.load();
        this._epg.visible = true;
        this.widgets.menu.visible = true;
        this._epg.alpha = 1;
        (_a = this._player) === null || _a === void 0 ? void 0 : _a.enableSubtitles(CCTypes.off);
        this._epgOpened = true;
    }
    $exit() {
        var _a;
        this.widgets.menu.visible = false;
        this._epg.alpha = 0;
        (_a = this._player) === null || _a === void 0 ? void 0 : _a.enableSubtitles(ClosedCaptionsUtils.getCCType());
    }
    _handleUp() {
        Router.focusWidget('Menu');
    }
    _handleBack(e) {
        if (this._preState === 'LPPlayer.Controls' && !this._hasError) {
            this.$showPlayer();
        }
        else {
            this._closeMediaPlayer();
        }
        e.preventDefault();
        e.stopPropagation();
    }
    _getFocused() {
        return this.tag('Epg') || this;
    }
    $showPlayer() {
        this._setState(PlayerStates.LPPlayer);
    }
    async $streamSelected(selectedStream, selectedProgram) {
        var _a, _b;
        openPlayerLoader(selectedStream, selectedProgram);
        const { pid } = selectedStream;
        if (pid) {
            RouterUtil.navigateToRoute(ROUTE.watch, {
                entity: ROUTE_ENTITY.pid,
                value: pid,
            });
            return;
        }
        if ((_b = (_a = AppConfigFactorySingleton.config.live) === null || _a === void 0 ? void 0 : _a.savePreviousSelection) === null || _b === void 0 ? void 0 : _b.enabled) {
            this._storage.set(STORAGE_KEYS.PREVIOUS_STREAM, selectedStream.streamAccessName);
        }
        await navigateLinearItem({ stream: selectedProgram, routerBackDisabled: true })
            .then(() => PlayerStoreSingleton.dispatch(clearState()))
            .then(() => {
            if (Router.getActiveRoute() !== ROUTE.live) {
                // Closing player before change route because the attach event for the new route is
                // called before the detach event from the previous route, it generates issues with the player events emitters
                this._closePlayer();
                Router.navigate(ROUTE.live);
            }
            else {
                usePreCheckData();
            }
        })
            .catch((error) => {
            // fail silently
        });
    }
};
