import { END_OF_ROW, FONT_FACE } from '../../../constants';
import { debounce } from 'lodash';
import Announcer from '../../../lib/tts/Announcer';
export class LabelListRenderingStrategy {
    constructor(ctx, sectionSize) {
        // Refers to the item index that's currently focused
        this._index = 0;
        // Refers to the item index that's currently active
        this._activeIndex = 0;
        this._isScrolled = false;
        this._sectionSize = 0;
        // The item focused font face
        this._fontFace = FONT_FACE.semiBold;
        this.onLabelsPageEnd = debounce(() => { var _a; return (_a = this._ctx) === null || _a === void 0 ? void 0 : _a.fireAncestors('$onLabelsPageEnd'); }, 500);
        this._ctx = ctx;
        this._items = ctx.tag('Items');
        this._sectionSize = sectionSize;
    }
    get isScrollable() {
        return false;
    }
    get isScrolled() {
        return this._isScrolled;
    }
    set isScrolled(isScrolled) {
        if (this._isScrolled !== isScrolled)
            this._ctx.signal('changeScrollState', isScrolled);
        this._isScrolled = isScrolled;
    }
    set fontFace(font) {
        this._fontFace = font;
    }
    get focusItem() {
        return this.getChildItem(this._index);
    }
    get activeItem() {
        return this.getChildItem(this._activeIndex);
    }
    set activeIndex(activeIndex) {
        this._activeIndex = activeIndex;
        //When setting active index, make sure we upate index to match
        if (this._index !== activeIndex) {
            this._index = activeIndex;
        }
    }
    // #region Lifecycle
    // Called on component init
    init() { }
    // Called when list is focused
    enter() { }
    // Called when list is unfocused
    exit() { }
    // #endregion
    getChildItem(index = 0) {
        return this._items.children[index];
    }
    setChildItem(index, patch) {
        var _a;
        (_a = this.getChildItem(index)) === null || _a === void 0 ? void 0 : _a.patch(patch);
    }
    announceEndOfRow() {
        Announcer.announce(END_OF_ROW);
    }
    // #region Labels
    resetItems() {
        this._activeIndex = 0;
        this._index = 0;
        this._items.childList.clear();
    }
    createItems(items, offset) {
        if (offset) {
            this._items.childList.a(items.map((item, index) => this._mapItem(item, index + offset)));
        }
        else {
            this._items.children = items.map(this._mapItem);
        }
        this.positionItems();
    }
    _mapItem(item, index) {
        return {};
    }
    positionItems() {
        // Control rendering of the elements
    }
    // #endregion
    // #region Events
    handleLeft() {
        return false;
    }
    handleRight() {
        return false;
    }
    handleUp() {
        return false;
    }
    handleDown() {
        return false;
    }
    // #endregion
    // #region Scrolling
    moveToIndex(index) {
        this.setChildItem(this._activeIndex, { active: false });
        this._activeIndex = index;
        this.setChildItem(this._activeIndex, { active: true });
        this._scrollTo(index);
    }
    moveWithDelta(direction, immediate) {
        this._scrollTo(this._index + direction, immediate ? 0 : 0.3);
    }
    moveToFocused() {
        this.moveToIndex(this._index);
    }
    moveToActive() {
        this.moveToIndex(this._activeIndex !== this._index ? this._activeIndex : 0);
    }
    _scrollTo(index = 0, duration = 0) {
        var _a, _b;
        if (this._items.children.length - 1 < index) {
            return;
        }
        const oldIndex = this._index;
        this._index = index;
        // If the entire items list is inside the viewport
        // the focus element is just a rectangle below the list
        if (!this.isScrollable) {
            this._patchFocusElement(duration);
            return;
        }
        // If there are items outside the viewport
        // we toggle a rectangle behind each element
        (_a = this.getChildItem(oldIndex)) === null || _a === void 0 ? void 0 : _a._setHighlighted(false);
        (_b = this.focusItem) === null || _b === void 0 ? void 0 : _b._setHighlighted(true, this.focusItem.width);
        this._changeListOffsetToFocused();
    }
    _changeListOffsetToFocused() { }
    _patchFocusElement(duration = 0) { }
}
