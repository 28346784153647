import { Discovery } from '@firebolt-js/sdk';
import { ROUTE } from '../../../constants';
import { Log } from '@lightningjs/sdk';
const isInRouteEnum = (input) => input ? Object.values(ROUTE).includes(input) : false;
export const comcastFormatNavigation = (e) => {
    var _a, _b, _c, _d;
    // FIXME: Saving this for when Firebolt team allows debugging of our app, remove afterwards
    Log.info(`Navigation Event: ${e}`);
    switch (e.action) {
        case 'entity':
            if (e.data.assetId) {
                return {
                    route: ROUTE.video,
                    entity: {
                        entityType: ':videoId',
                        value: e.data.entityId,
                    },
                };
            }
            if ([
                Discovery.ProgramType.EPISODE,
                Discovery.ProgramType.SEASON,
                Discovery.ProgramType.SERIES,
            ].includes(e.data.programType)) {
                return {
                    route: ROUTE.showHome,
                    entity: {
                        entityType: ':urlAlias',
                        value: e.data.entityId,
                    },
                };
            }
            if (e.data.programType === Discovery.ProgramType.MOVIE) {
                return {
                    route: ROUTE.movieHome,
                    entity: {
                        entityType: ':itemName',
                        value: e.data.entityId,
                    },
                };
            }
            return {
                route: ROUTE.search,
                entity: {
                    entityType: ':query?',
                    value: e.data.entityId,
                },
            };
        case 'section':
            return {
                route: (isInRouteEnum((_a = e.data) === null || _a === void 0 ? void 0 : _a.sectionName) && ((_b = e.data) === null || _b === void 0 ? void 0 : _b.sectionName)) || ROUTE.home,
            };
        case 'tune':
            return {
                route: ROUTE.live,
            };
        case 'playback':
            if (e.data.assetId) {
                return {
                    route: ROUTE.video,
                    entity: {
                        entityType: ':videoId',
                        value: e.data.assetId,
                    },
                };
            }
            if (e.data.programType === Discovery.ProgramType.EPISODE) {
                return {
                    route: ROUTE.showHome,
                    entity: {
                        entityType: ':urlAlias',
                        value: e.data.entityId,
                    },
                };
            }
            if (e.data.programType === Discovery.ProgramType.MOVIE) {
                return {
                    route: ROUTE.movieHome,
                    entity: {
                        entityType: ':itemName',
                        value: e.data.entityId,
                    },
                };
            }
            return {
                route: ROUTE.search,
                entity: {
                    entityType: ':query?',
                    value: e.data.entityId,
                },
            };
        case 'search':
            if (!((_c = e.data) === null || _c === void 0 ? void 0 : _c.query))
                return {
                    route: ROUTE.search,
                };
            return {
                route: ROUTE.search,
                entity: {
                    entityType: ':query?',
                    value: (_d = e.data) === null || _d === void 0 ? void 0 : _d.query,
                },
            };
        // Not sure how to handle launch app intent (shouldn't this intent handled by the device?)
        case 'launch':
        case 'home':
        default:
            return { route: ROUTE.home };
    }
};
