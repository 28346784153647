import { COLORS } from '../../constants';
import TextButton from './TextButton';
// @ts-expect-error TS(2417): Class static side 'typeof ModalButton' incorrectly... Remove this comment to see the full error message
export default class ModalButton extends TextButton {
    static _template() {
        return {
            ...super._template(),
            w: 400,
            h: 60,
            mountX: 0.5,
        };
    }
    _construct() {
        super._construct();
        this._radius = 30;
        this._fontSize = 30;
        this._unfocusFontColor = COLORS.white;
        this._unfocusBackgroundColor = COLORS.black3;
        this._autoWidth = false;
        this._paddingLeft = 0;
        this._paddingRight = 0;
    }
}
