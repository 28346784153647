import { getAddMetadata } from '../../api/Api';
const parseAdExtensions = (extensions = []) => {
    var _a;
    const freeWheel = extensions.find(({ type }) => type === 'FreeWheel');
    if (!freeWheel) {
        return {};
    }
    // @ts-expect-error TS(2339): Property 'parameters' does not exist on type 'neve... Remove this comment to see the full error message
    return (_a = freeWheel.parameters) === null || _a === void 0 ? void 0 : _a.reduce((prev, cur) => {
        var _a;
        if (cur.name === 'Conviva Ad Insights') {
            return {
                ...prev,
                ...JSON.parse(cur.value),
            };
        }
        if (cur.name === 'hulu_ccr') {
            return {
                ...prev,
                ...(_a = cur.value) === null || _a === void 0 ? void 0 : _a.split(';').map((kv) => kv.split('=')).reduce((p, c) => ({
                    ...p,
                    [c[0]]: c[1],
                }), {}),
            };
        }
        return prev;
    }, {});
};
// Normalize ad data for mparticle's getAdAttributes
export const getAdParams = async (ad) => {
    if (!ad) {
        return {};
    }
    const extensions = parseAdExtensions(ad.extensions);
    const adMetadata = await getAddMetadata({
        renditionId: extensions.renditionID,
        adId: extensions.id,
        csid: extensions.csid,
    }).catch(() => ({}));
    return {
        ...ad,
        duration: ad.expectedDuration,
        extensions: {
            ...extensions,
            ...adMetadata,
        },
    };
};
// Normalize ad data for mparticle's getAdBreakAttributes
export const getAdPodParams = (adBreak) => adBreak
    ? {
        ...adBreak,
        duration: adBreak.expectedDuration,
    }
    : {};
