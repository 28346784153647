import { IPlayerEvent } from './IPlayerEvent';
export class DebugEvent extends IPlayerEvent {
    constructor(event, data) {
        super();
        this._name = event;
        this._data = data;
    }
    get data() {
        return this._data;
    }
    get name() {
        return this._name;
    }
}
