import PlayerStoreSingleton from '../PlayerStore/PlayerStore';
import { setLemonade } from '../PlayerStore/actions/lemonade';
import { clearPreCheckData, clearPreCheckLemonade, setStream, } from '../PlayerStore/actions';
export const usePreCheckData = async () => {
    let streamDataWasPreChecked = false;
    const { stream, program, lemonade } = PlayerStoreSingleton.preCheckData;
    if (lemonade) {
        await PlayerStoreSingleton.dispatch(setLemonade(lemonade));
    }
    if (stream && program) {
        await PlayerStoreSingleton.dispatch(setStream(stream, program));
        streamDataWasPreChecked = true;
    }
    await PlayerStoreSingleton.dispatch(clearPreCheckData());
    await PlayerStoreSingleton.dispatch(clearPreCheckLemonade());
    return streamDataWasPreChecked;
};
