import Collection from './Collection';
export default class PlaceholderSection extends Collection {
    mapItems() { }
    get shelfData() {
        return {
            playlistMachineName: this.playlistMachineName,
            listTitle: this.listTitle,
            shelfIndex: this.index,
            machineName: this.machineName,
        };
    }
    get queryVariables() {
        return this.obj.queryVariables;
    }
}
