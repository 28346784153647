import { get } from 'lodash';
import { Container } from '../models';
import { lowercaseFirst } from '../../helpers';
import { collectionComponents, createItems } from '../../components/componentMaps';
import { Message } from '../../components';
import LinksSelectableGroup from '../../components/LinksSelectableGroup';
import GroupedContinuousScroll from '../../components/GroupedContinuousScroll';
import ContinuousScroll from '../../components/ContinuousScroll';
import NestedLinksSelectableGroup from '../../components/NestedLinksSelectableGroup';
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import { GroupedContinuousScrollSpawner, LinksSelectableSpawner, ContinuousScrollSpawner, NestedLinksSelectableSpawner, } from '.';
import TVPlatform from '../../lib/tv-platform';
import { ErrorType } from '../../lib/tv-platform/types';
import { PlaceholderReferrer } from '../../components/Placeholder';
export const ContainerDelegateTypes = {
    EPG: 'EPG',
};
const SECTIONS_TAG = 'sections';
const createLoaderContainer = (stage, referrer, queryVariables) => {
    return stage.c({
        type: collectionComponents.get('Placeholder'),
        tag: 'videoTile',
        title: '',
        referrer,
        queryVariables,
    });
};
const containerFactory = (item, index) => {
    const supportedCollections = [
        'Shelf',
        'SmartTile',
        'LinksSelectableGroup',
        'NestedLinksSelectableGroup',
        'Grid',
        'EndTiles',
        'Guide',
        'OnAirNowShelf',
        'LazyOnAirNowShelf',
        'LazyShelf',
        'EventSchedule',
        'Message',
        'GroupedContinuousScroll',
        'ContinuousScroll',
        'PlaceholderSection',
        'MarketingModule',
        'Stack',
        'StackGroup',
    ];
    // @ts-expect-error Property 'component' does not exist on type 'DocumentNode'.
    return item && supportedCollections.indexOf(item.component) > -1 && new Container(item, index);
};
const createModels = (items) => (items === null || items === void 0 ? void 0 : items.reduce((acc, item, index) => {
    if (!item)
        return acc;
    const container = containerFactory(item, index);
    return container ? [...acc, container] : acc;
}, [])) || [];
const EmptyCollectionError = {
    type: ErrorType.OTHER,
    description: `${SECTIONS_TAG} Unable to create grouped continuous scroll (likely episodes shelf) due to no items returned`,
};
const createCollections = async (stage, models = [], delegates = {}) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
    models = !Array.isArray(models) ? [models] : models;
    const collections = [];
    for (const model of models) {
        let collection;
        switch (model.component) {
            case 'LinksSelectableGroup':
                collection = stage.c({
                    type: LinksSelectableGroup,
                    spawner: new LinksSelectableSpawner(model),
                    tag: `${lowercaseFirst(model.data.items[model.data.initiallySelected].data.items[0].component)}`,
                    data: model,
                });
                break;
            case 'NestedLinksSelectableGroup':
                collection = stage.c({
                    type: NestedLinksSelectableGroup,
                    spawner: new NestedLinksSelectableSpawner(model),
                    tag: `${lowercaseFirst(model.data.items[model.data.initiallySelected].data.items[0].component)}`,
                    data: model,
                });
                break;
            case 'EventSchedule':
            case 'Guide':
                break;
            case 'Message':
                collection = stage.c({
                    type: Message,
                    tag: `${lowercaseFirst(model.component)}`,
                    componentProps: {
                        firstRow: model.data.firstRow,
                        secondRow: model.data.secondRow,
                    },
                });
                break;
            case 'LazyOnAirNowShelf': {
                collection = createLoaderContainer(stage, PlaceholderReferrer.ON_AIR, (_a = model === null || model === void 0 ? void 0 : model.data) === null || _a === void 0 ? void 0 : _a.queryVariables);
                break;
            }
            case 'LazyShelf': {
                collection = createLoaderContainer(stage, PlaceholderReferrer.MIX_EDITORIAL, (_b = model === null || model === void 0 ? void 0 : model.data) === null || _b === void 0 ? void 0 : _b.queryVariables);
                break;
            }
            case 'PlaceholderSection': {
                collection = createLoaderContainer(stage, PlaceholderReferrer.PLACEHOLDER, (_c = model.data) === null || _c === void 0 ? void 0 : _c.queryVariables);
                break;
            }
            case 'MarketingModule': {
                // if the flag is OFF then dont show the section
                if (!LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.enableMarketingModule)) {
                    break;
                }
                if (!(model === null || model === void 0 ? void 0 : model.obj))
                    return;
                const { marketingModuleData, analytics } = model.obj;
                collection = stage.c({
                    type: collectionComponents.get('MarketingModule'),
                    tag: 'slide',
                    title: 'Marketing Module',
                    items: marketingModuleData,
                    analytics,
                    ariaLabel: marketingModuleData === null || marketingModuleData === void 0 ? void 0 : marketingModuleData.ariaLabel,
                });
                break;
            }
            case 'GroupedContinuousScroll': {
                const component = (_f = (_e = (_d = model === null || model === void 0 ? void 0 : model.data) === null || _d === void 0 ? void 0 : _d.items) === null || _e === void 0 ? void 0 : _e[0]) === null || _f === void 0 ? void 0 : _f.component;
                if (!component) {
                    TVPlatform.reportError(EmptyCollectionError);
                    return;
                }
                const tag = `${lowercaseFirst(model.data.items[0].component)}`;
                collection = stage.c({
                    type: GroupedContinuousScroll,
                    spawner: new GroupedContinuousScrollSpawner(model, stage.urlAlias),
                    tag: delegates[tag] || tag,
                    data: model,
                    ariaLabel: model.ariaLabel,
                });
                break;
            }
            case 'ContinuousScroll': {
                const component = (_j = (_h = (_g = model === null || model === void 0 ? void 0 : model.data) === null || _g === void 0 ? void 0 : _g.items) === null || _h === void 0 ? void 0 : _h[0]) === null || _j === void 0 ? void 0 : _j.component;
                if (!component) {
                    TVPlatform.reportError(EmptyCollectionError);
                    return;
                }
                const tag = `${lowercaseFirst(model.data.items[0].component)}`;
                const items = createItems(model.data.items);
                collection = stage.c({
                    type: ContinuousScroll,
                    spawner: new ContinuousScrollSpawner(model, stage.urlAlias),
                    tag: delegates[tag] || tag,
                    items,
                    groupIds: model.data.groupIds,
                    initialItem: model.data.initialItem,
                    title: model.title,
                    data: model,
                    next: model.data.next,
                    previous: model.data.previous,
                    ariaLabel: model.ariaLabel,
                });
                break;
            }
            default: {
                if (get(model, 'data.items.0')) {
                    const items = createItems(model.data.items);
                    const tag = `${lowercaseFirst(model.data.items[0].component)}`;
                    //Let's not create a shelf with 0 items
                    if ((items === null || items === void 0 ? void 0 : items.length) > 0) {
                        collection = stage.c({
                            type: collectionComponents.get(model.component),
                            tag: delegates[tag] || tag,
                            viewAllCta: (_k = model.data) === null || _k === void 0 ? void 0 : _k.viewAllCta,
                            items,
                            title: model.title,
                            meta: model.meta,
                            component: model.component,
                            sponsorName: model.data.sponsorName || '',
                            ariaLabel: model.ariaLabel,
                        });
                        if (model.data.sponsorLogo) {
                            collection.sponsorLogo = model.data.sponsorLogo;
                        }
                    }
                }
                break;
            }
        }
        if (collection) {
            collection.instanceID = model.data.instanceID;
            collections.push(collection);
        }
    }
    return collections;
};
export default async (stage, sections, delegates = {}) => {
    return await createCollections(stage, createModels(sections), delegates);
};
