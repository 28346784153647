import { createRequestConfig } from '../../lib/useRequest';
import { mainRequest } from '../../api/requests';
import Variables from '../../graphql/Variables';
import { COMPONENT_TYPES } from '../../constants';
export const ShowsPageRequestConfig = () => createRequestConfig({
    query: mainRequest,
    variables: Variables.showsPageVariables(),
    reject: [
        (data) => !data,
        (data) => {
            var _a;
            return !!((_a = data === null || data === void 0 ? void 0 : data.sections) === null || _a === void 0 ? void 0 : _a[0]) &&
                'component' in data.sections[0] &&
                data.sections[0].component !== COMPONENT_TYPES.BRAND_SELECTABLE_GROUP;
        },
    ],
});
