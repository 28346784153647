import { EVENTS } from '../../../lib/analytics/types';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { TEMP_PASS_REDIRECT } from '../BasePlayer';
import { activateTempPassRedirect } from '../StreamLoader/actions/redirect';
import { useRouterBackDisabled } from '../../../widgets/Modals/activation/hooks/useRouterBackDisabled';
export const PlayerTempPassStateFactory = (base) => class TempPass extends base {
    $enter() {
        if (this._tempPassPlayerOverlayBg)
            this._tempPassPlayerOverlayBg.visible = true;
        this._tempPassPlayerOverlay.visible = true;
    }
    $exit() {
        if (this._tempPassPlayerOverlayBg)
            this._tempPassPlayerOverlayBg.visible = false;
        this._tempPassPlayerOverlay.visible = false;
    }
    _captureEnter() {
        sendMetric(EVENTS.CLICK, {
            name: 'TempPass - Link TV Provider',
        });
        useRouterBackDisabled().set(true);
        this.resolveTempPass().then(() => activateTempPassRedirect(TEMP_PASS_REDIRECT.TEMP_PASS_MVPD));
        return false;
    }
    _getFocused() {
        var _a;
        return ((_a = this._tempPassPlayerOverlay) === null || _a === void 0 ? void 0 : _a.visible)
            ? this._tempPassPlayerOverlay
            : this._controls || this;
    }
};
