import { IPlayerEvent } from './IPlayerEvent';
export class BufferLengthChangeEvent extends IPlayerEvent {
    constructor(length) {
        super();
        this._length = length;
    }
    get length() {
        return this._length;
    }
}
