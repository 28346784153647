import MParticleInterface from '../mParticleInterface';
import getMvpdAuthSuccessAttributes from '../attributes/getMvpdAuthSuccessAttributes';
import mParticle from '@mparticle/web-sdk';
export const preTrackEvent = () => (properties) => MParticleInterface.setAuthAttributes(properties);
const getEvent = () => ({
    getAttributes: getMvpdAuthSuccessAttributes,
    getCategory: () => mParticle.EventType.UserPreference,
    type: 'Authentication Success',
    injectGlobals: true,
    preTrackEvent: preTrackEvent(),
});
export default getEvent;
