import { Lightning, Router } from '@lightningjs/sdk';
import MenuItem from './MenuItem';
import { ALIGN_ITEMS, COLORS, FLEX_DIRECTION, FONT_FACE, ROUTE } from '../../constants';
import { LiveStreamManager } from '../../lib/LiveStreamManager';
import { sendMetric } from '../../lib/analytics/Analytics';
import { EVENTS } from '../../lib/analytics/types';
import ContentPosition from '../../util/contentPosition';
const DEFAULT_FOCUS_RADIUS = 5;
const FOCUS_PADDING = 20;
export default class ItemsBar extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._focusIndex = 0;
        this._activeIndex = 0;
        this.initiallySelected = 0;
    }
    static _template() {
        return {
            flex: {
                direction: FLEX_DIRECTION.row,
            },
            Focus: {
                flexItem: false,
                rtt: true,
                shader: {
                    type: Lightning.shaders.RoundedRectangle,
                    radius: DEFAULT_FOCUS_RADIUS,
                },
                rect: true,
                color: COLORS.lightGray1,
                alpha: 0,
                h: 58,
            },
            Items: {
                rtt: true,
                flex: {
                    direction: FLEX_DIRECTION.row,
                    alignItems: ALIGN_ITEMS.center,
                },
                h: 58,
                children: [],
            },
            SubCatHolder: {
                visible: false,
                flex: {
                    direction: FLEX_DIRECTION.row,
                    alignItems: ALIGN_ITEMS.center,
                },
                Line: {
                    rect: true,
                    h: 45,
                    w: 2,
                    color: COLORS.lightGray5,
                },
                SubCat: {
                    flexItem: {
                        marginLeft: 20,
                    },
                },
            },
        };
    }
    setFocusIndex(index) {
        this._focusIndex = index;
    }
    moveFocus(direction, immediate) {
        const nextIndex = this.focusIndex + direction;
        if (nextIndex < 0 || !this.itemsElement.children[nextIndex])
            return false;
        if (!this.itemsElement.children[nextIndex].visible)
            return this.moveFocus(direction >= 0 ? ++direction : --direction);
        this.setFocusIndex(nextIndex);
        const duration = immediate ? 0 : 0.3;
        this.tag('Focus').patch({
            smooth: {
                x: [
                    this.focusItem.hasRoundedFocus
                        ? this.focusItem.coreX - (this.itemsElement.h - this.focusItem.width) / 2
                        : this.focusItem.coreX - FOCUS_PADDING,
                    { duration },
                ],
                w: [
                    this.focusItem.hasRoundedFocus
                        ? this.itemsElement.h
                        : this.focusItem.width + FOCUS_PADDING * 2,
                    { duration },
                ],
            },
        });
        this.tag('Focus').shader.radius = this.focusItem.hasRoundedFocus
            ? this.itemsElement.h / 2
            : DEFAULT_FOCUS_RADIUS;
        return true;
    }
    setActiveIndex(index) {
        this._focusIndex = index;
        this._activeIndex = index;
    }
    collapse(args) {
        var _a, _b, _c, _d;
        if (this._getState() !== 'Collapsed') {
            this._setState('Collapsed', [args]);
            return;
        }
        const currentRoute = (_b = (_a = this.items[this._activeIndex]) === null || _a === void 0 ? void 0 : _a.item) === null || _b === void 0 ? void 0 : _b.route;
        const currentSubCat = (_d = (_c = this.tag('SubCat')) === null || _c === void 0 ? void 0 : _c.text) === null || _d === void 0 ? void 0 : _d.text;
        // Removing the following line will cause the subcategory to disappear when the user navigates to a different route
        this._onRouteInfoChange(args);
    }
    expand() {
        this._setState('Expanded');
    }
    _getFocused() {
        return this.focusItem || this;
    }
    set items(items) {
        this.itemsElement.children = items.map((item, index) => {
            return { type: MenuItem, item, index };
        });
        this.expand();
    }
    get items() {
        return this.itemsElement.children;
    }
    get itemsElement() {
        return this.tag('Items');
    }
    get focusIndex() {
        return this._focusIndex;
    }
    get activeIndex() {
        return this._activeIndex;
    }
    get focusItem() {
        var _a;
        return (_a = this.itemsElement) === null || _a === void 0 ? void 0 : _a.children[this._focusIndex];
    }
    get hasVisibleItems() {
        var _a;
        return ((_a = this.itemsElement) === null || _a === void 0 ? void 0 : _a.children.filter(({ visible }) => visible).length) > 0;
    }
    _firstActive() {
        this.setActiveIndex(this.initiallySelected);
    }
    _setFocusAlpha(alpha) {
        this.tag('Focus').setSmooth('alpha', alpha);
    }
    _handleRight() {
        return this.moveFocus(1);
    }
    _handleLeft() {
        return this.moveFocus(-1);
    }
    _handleEnter() {
        ContentPosition.clearPositionForCurrentPage();
        this.fireAncestors('$onEnterCallback');
        this.setActiveIndex(this.focusIndex);
        this.moveFocus(0, true);
        const item = this.focusItem.item;
        const { route } = item;
        sendMetric(EVENTS.CLICK, {
            name: item.label,
            shelf: { listTitle: 'Global Nav' },
        });
        if (item && route && route !== Router.getActiveRoute()) {
            if (route === ROUTE.live) {
                LiveStreamManager.validate();
                LiveStreamManager.setIsGlobalNavigation(true);
            }
            Router.navigate(route, { customShelfTitle: 'Global Nav' }, item.cache);
            Router.focusPage();
        }
    }
    _focus() {
        if (this.hasVisibleItems)
            this._setFocusAlpha(1);
        return this.moveFocus(0, true);
    }
    _unfocus() {
        this._setFocusAlpha(0);
    }
    _showSubCategory({ parentRoute, subCategoryItem }) {
        var _a, _b;
        if (!subCategoryItem)
            return;
        this.tag('SubCatHolder.Line').visible = parentRoute;
        if (typeof subCategoryItem === 'string') {
            this.tag('SubCatHolder.SubCat').patch({
                text: {
                    text: subCategoryItem,
                    fontFace: FONT_FACE.light,
                    fontSize: 35,
                },
            });
        }
        else if (((_a = subCategoryItem.type) === null || _a === void 0 ? void 0 : _a.name) === 'ScaledImageTexture' ||
            ((_b = subCategoryItem.type) === null || _b === void 0 ? void 0 : _b.name) === 'ImageTexture') {
            this.tag('SubCatHolder.SubCat').patch({
                texture: subCategoryItem,
            });
        }
        this.tag('SubCatHolder').patch({
            visible: true,
        });
    }
    _hideSubCategory() {
        this.tag('SubCatHolder').patch({
            SubCat: {
                flexItem: {
                    marginLeft: 20,
                },
            },
            visible: false,
        });
        this.tag('SubCatHolder').visible = false;
    }
    static _states() {
        return [
            class Expanded extends this {
                $enter() {
                    this.items.forEach((childItem) => {
                        if (!childItem.visible)
                            childItem.visible = true;
                    });
                    this._hideSubCategory();
                }
            },
            class Collapsed extends this {
                $enter(_, routes) {
                    this._onRouteInfoChange(routes);
                }
                // @ts-expect-error TS(2425): Class 'ItemsBar' defines instance member property ... Remove this comment to see the full error message
                _onRouteInfoChange({ parentRoute, subCategoryItem }) {
                    this.items.forEach((childItem) => {
                        const isVisible = childItem.item.route === parentRoute;
                        if (isVisible)
                            this.setActiveIndex(childItem.index);
                        childItem.visible = isVisible;
                    });
                    this._showSubCategory({
                        parentRoute: this.hasVisibleItems,
                        subCategoryItem: subCategoryItem,
                    });
                }
            },
        ];
    }
}
