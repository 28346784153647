import AuthenticationSingleton from '../../../../authentication/Authentication';
import { CONTENT_TYPES } from '../../../../authentication/authConstants';
import { CONTENT_TYPE, ENTITLEMENT, PROGRAMMING_TYPES } from '../../../../constants';
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore';
import { fetchGeo } from '../../../../store/PlayerStore/actions/geo';
import { geoError, unauthenticatedError } from '../error';
import { StreamAuthorizationType } from '../request';
const _getEntitlementId = (stream) => {
    switch (stream === null || stream === void 0 ? void 0 : stream.programmingType) {
        case PROGRAMMING_TYPES.SLE:
        case PROGRAMMING_TYPES.FER:
            return stream === null || stream === void 0 ? void 0 : stream.regionEntitlementId;
        default:
            return null;
    }
};
export const geo = async (request, stream) => {
    var _a;
    try {
        const channelId = (stream === null || stream === void 0 ? void 0 : stream.channelId) || (stream === null || stream === void 0 ? void 0 : stream.resourceId);
        const channelName = stream === null || stream === void 0 ? void 0 : stream.streamAccessName;
        if (channelId) {
            const contentType = CONTENT_TYPES[stream === null || stream === void 0 ? void 0 : stream.programmingType] || (stream === null || stream === void 0 ? void 0 : stream.contentType);
            const result = await PlayerStoreSingleton.dispatch(fetchGeo(channelId, channelName, {
                contentType: contentType === CONTENT_TYPE.LINEAR ? CONTENT_TYPES.live : contentType,
                failOnRestrictionError: true,
                entitlementId: _getEntitlementId(stream),
            }));
            const linear = (stream === null || stream === void 0 ? void 0 : stream.contentType) === CONTENT_TYPE.LINEAR;
            if (((_a = result.payload) === null || _a === void 0 ? void 0 : _a.authKill) || (stream === null || stream === void 0 ? void 0 : stream.entitlement) === ENTITLEMENT.FREE) {
                request.authType = StreamAuthorizationType.UNLOCKED;
            }
            else if (linear && !AuthenticationSingleton.isAuthenticated()) {
                request.error = unauthenticatedError({});
                throw request;
            }
        }
        return request;
    }
    catch (error) {
        if (!request.error) {
            request.error = geoError(error);
        }
        throw request;
    }
};
