import { createHook, createHookEvent } from '../../../../lib/Hook';
export const useProgress = createHook([0, 0, undefined, 0]);
export const createProgressHookEvent = createHookEvent(useProgress, (hook) => {
    return (data) => {
        const current = hook.value;
        if (typeof data === 'number') {
            current[0] = data;
        }
        else {
            if (data.currentTime !== undefined)
                current[0] = data.currentTime;
            if (data.duration !== undefined)
                current[1] = data.duration;
            if (data.seekableRange !== undefined)
                current[2] = [data.seekableRange.start, data.seekableRange.end];
            if (data.seekAdjust !== undefined)
                current[3] = data.seekAdjust;
        }
        return current;
    };
});
