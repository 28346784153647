import getLinearAttributes from '../attributes/getLinearAttributes';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
const getLinearStartAttributes = (params = {}) => {
    var _a, _b, _c, _d, _e;
    return ({
        ...getLinearAttributes(params),
        // @ts-expect-error TS(2339): Property 'duration' does not exist on type '{}'.
        'Video Duration': params.duration,
        // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
        'Custom Shelf Title': ((_a = params.shelf) === null || _a === void 0 ? void 0 : _a.listTitle) || MPARTICLE_DEFAULT_ATTR_VALUE,
        // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
        'Custom Shelf Position': ((_b = params.shelf) === null || _b === void 0 ? void 0 : _b.shelfIndex) || MPARTICLE_DEFAULT_ATTR_VALUE,
        // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
        'Content Position': ((_c = params.shelf) === null || _c === void 0 ? void 0 : _c.tileIndex) || MPARTICLE_DEFAULT_ATTR_VALUE,
        // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
        'Shelf Machine Name': ((_d = params.shelf) === null || _d === void 0 ? void 0 : _d.machineName) || MPARTICLE_DEFAULT_ATTR_VALUE,
        // @ts-expect-error TS(2339): Property 'shelf' does not exist on type '{}'.
        'Custom Shelf Type': ((_e = params.shelf) === null || _e === void 0 ? void 0 : _e.shelfType) || 'Regular',
    });
};
export default getLinearStartAttributes;
