import { Lightning } from '@lightningjs/sdk';
import { VerticalTabs } from './VerticalTabs';
import { CMPPageSelectedTabIndex } from './hook';
import { ConsentManagement } from '../../../lib/cmp/ConsentManagement';
import { VerticalText } from './VerticalText';
import { cmpComponentFactory } from './components';
var CMPWrapperComponents;
(function (CMPWrapperComponents) {
    CMPWrapperComponents["VerticalTabs"] = "VerticalTabs";
    CMPWrapperComponents["Content"] = "Content";
})(CMPWrapperComponents || (CMPWrapperComponents = {}));
export class Wrapper extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._focusedComponent = CMPWrapperComponents.VerticalTabs;
        this._items = [];
        this._tabIndex = CMPPageSelectedTabIndex(this._onTabChange.bind(this));
    }
    static _template() {
        return {
            VerticalTabs: {
                y: 152,
                x: 0,
                type: VerticalTabs,
                signals: {
                    onTabSelected: '_onTabSelected',
                },
            },
            Content: {},
        };
    }
    async _init() {
        try {
            const response = (await ConsentManagement.fetchPreferenceCenter()).pcUIData;
            const { text: textQRCode, url } = response.links.policyLink;
            this._items = [
                {
                    key: 'summary',
                    label: response.summary.title.text,
                    component: VerticalText,
                    componentProps: {
                        title: response.summary.title.text,
                        content: response.summary.description.text,
                        textQRCode: textQRCode,
                        urlQRCode: url,
                    },
                },
                ...response.purposeTree.purposes.reduce((acc, item) => [
                    ...acc,
                    cmpComponentFactory(item.groupId, item, response),
                ], []),
            ];
            this.tag('VerticalTabs').tabs = this._items;
            this._tabIndex.set(0);
        }
        catch (_) {
            // no op - network errors already handled at the ConsentManagement class
        }
    }
    _onTabChange(index) {
        this.patch({ Content: undefined });
        const item = this._items[index];
        if (item) {
            this.patch({
                Content: {
                    y: 152,
                    x: 674,
                    type: item === null || item === void 0 ? void 0 : item.component,
                    ...item === null || item === void 0 ? void 0 : item.componentProps,
                },
            });
        }
    }
    _handleLeft() {
        if (this._focusedComponent === CMPWrapperComponents.Content) {
            this._focusedComponent = CMPWrapperComponents.VerticalTabs;
        }
    }
    _handleRight() {
        if (this._focusedComponent === CMPWrapperComponents.VerticalTabs &&
            this.tag('Content').focusable) {
            this._focusedComponent = CMPWrapperComponents.Content;
        }
    }
    _getFocused() {
        return this.tag(this._focusedComponent);
    }
}
