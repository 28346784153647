import { Lightning, Settings, Utils } from '@lightningjs/sdk';
import { getMvpdLogo, checkGetMvpd } from './auth';
import { CLIP_PROGRAMMING_TYPES, COLORS, FONT_FACE, svgRegExp, UNAUNTHENTICATED, } from '../constants';
import { cloneDeep } from 'lodash';
import { IMAGE_RESIZE_MODE, OLY_IMPOLICY } from '../constants';
export const DEFAULT_TIME_LIMIT_UPCOMING_ITEM = 600000;
export const world = (el) => {
    const { px: x, py: y } = el.core.renderContext;
    return { x, y, w: el.finalW };
};
export const setSmooth = (child, p, v, settings = {}) => {
    if (!child)
        return;
    if (!Settings.get('platform', 'transitions')) {
        const anyChild = child;
        anyChild[`${p}`] = v;
    }
    else {
        child.setSmooth(p, v, settings);
    }
};
export const updateElementH = (elem) => {
    let yOffset = 0;
    for (const ch of elem.children) {
        ch.y = yOffset;
        yOffset += ch.h;
    }
    elem.h = elem.children.reduce((acc, ch) => acc + ch.h, 0);
};
const isTextType = (item) => typeof item !== 'string';
export const createFlexText = (text, common) => {
    return text.map((t) => {
        const isObject = isTextType(t);
        const verticalAlign = (isObject && t.verticalAlign) || (common === null || common === void 0 ? void 0 : common.verticalAlign);
        return {
            w: (isObject && t.w) || (common === null || common === void 0 ? void 0 : common.w) || 0,
            flexItem: { marginRight: 10 },
            text: {
                text: isObject ? t.text : t,
                fontFace: (isObject && t.fontFace) || (common === null || common === void 0 ? void 0 : common.fontFace) || FONT_FACE.light,
                fontSize: (isObject && t.fontSize) || (common === null || common === void 0 ? void 0 : common.fontSize) || 40,
            },
            ...(verticalAlign ? { verticalAlign } : {}),
        };
    });
};
export const checkSetMvpdLogo = (context) => checkGetMvpd().then((mvpd) => {
    if (mvpd === UNAUNTHENTICATED)
        hideMvpdLogo(context);
    if (typeof mvpd === 'object')
        setMvpdLogo(context, mvpd);
});
export const checkAddClipToVideoTitles = (meta, titlesPatchData) => {
    if (isAClip(meta === null || meta === void 0 ? void 0 : meta.programmingType)) {
        titlesPatchData.TitleContainer.Clip = {
            Icon: {
                x: 143,
                flexItem: { marginRight: 8, marginTop: 3 },
                texture: Lightning.Tools.getRoundRect(54, 26, 0, 1, COLORS.mediumGray4, true, COLORS.transparent),
                Label: {
                    mount: 0.5,
                    x: 29,
                    y: 16,
                    text: { text: 'CLIP', fontFace: FONT_FACE.light, fontSize: 24 },
                },
            },
        };
        titlesPatchData.TitleContainer.SecondaryTitle.x = 209;
        titlesPatchData.TitleContainer.SecondaryTitle.y = 77;
    }
    return titlesPatchData;
};
// Using the context ('this' from component), animate component with given tagname (default is 'MvpdLogo')
export const animateMvpdLogo = (context, show = true, tagName = 'MvpdLogo') => {
    context.tag(tagName).setSmooth('alpha', +show, {
        duration: 0.9,
        timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)',
    });
};
export const setMvpdLogo = (context, mvpd = null, tagName = 'MvpdLogo') => {
    if (!mvpd)
        return;
    // Update the image path.
    context.tag(tagName).patch({
        // Build full mvpd logo path.
        src: getMvpdLogo(mvpd),
    });
    // Animate the logo in.
    animateMvpdLogo(context, true, tagName);
};
export const hideMvpdLogo = (context, tagName = 'MvpdLogo') => {
    context.tag(tagName).patch({
        alpha: 0,
        src: undefined,
    });
};
/**
 * Checks if a video item is a clip.
 * @return {boolean} Is a clip
 * @param programmingType
 */
export const isAClip = (programmingType) => {
    return CLIP_PROGRAMMING_TYPES.has(programmingType);
};
export const sponsorBadgeText = (text) => {
    return {
        text,
        textColor: COLORS.mediumGray,
        fontSize: 32,
        maxLines: 1,
        wordWrapWidth: 600,
        fontFace: FONT_FACE.light,
    };
};
const isObject = (value) => {
    return value !== null && typeof value === 'object';
};
const merge = (objA, objB) => {
    const aKeys = Object.keys(objA);
    const bKeys = Object.keys(objB).filter((key) => !aKeys.includes(key));
    aKeys.forEach((key) => {
        const a = objA[key];
        const b = objB[key];
        if (key in objB && b === undefined) {
            delete objA[key];
        }
        else if (isObject(a) && isObject(b)) {
            objA[key] = merge(a, b);
        }
        else {
            objA[key] = b !== null && b !== void 0 ? b : a;
        }
    });
    bKeys.forEach((key) => {
        objA[key] = objB[key];
    });
    return objA;
};
export function templateDeepMerge(original, override) {
    if (original === override || !isObject(override)) {
        return original;
    }
    return merge(original, override);
}
export const getSvgTexture = (imgUrl, { w, h }) => {
    const url = Utils.proxyUrl(imgUrl);
    return Lightning.Tools.getCanvasTexture((cb, stage) => {
        const canvas = stage.platform.getDrawingCanvas();
        const ctx = canvas === null || canvas === void 0 ? void 0 : canvas.getContext('2d');
        if (ctx)
            ctx.imageSmoothingEnabled = true;
        const img = new Image();
        img.onload = () => {
            const aspectRatio = img.naturalWidth / img.naturalHeight;
            const sizesKnown = w && h;
            if ((!w && h) || (sizesKnown && w < h)) {
                canvas.width = h * aspectRatio;
                canvas.height = h;
            }
            else if ((!h && w) || (sizesKnown && w > h)) {
                canvas.width = w;
                canvas.height = w / aspectRatio;
            }
            else if (sizesKnown) {
                canvas.width = w;
                canvas.height = h;
            }
            else {
                canvas.width = img.naturalWidth;
                canvas.height = img.naturalHeight;
            }
            if (ctx)
                ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
            cb(null, canvas);
        };
        img.crossOrigin = 'Anonymous';
        img.src = url;
    }, `svg${url}`);
};
export const FastImg = (url) => {
    const make = (mode, width, height, impolicy) => {
        var _a, _b, _c;
        if (!url)
            return {};
        const isNbcImage = (_a = url === null || url === void 0 ? void 0 : url.includes) === null || _a === void 0 ? void 0 : _a.call(url, 'nbc.com/');
        const isOlympicsImage = (_b = url === null || url === void 0 ? void 0 : url.includes) === null || _b === void 0 ? void 0 : _b.call(url, 'nbcolympics.com/');
        const isLocal = !/^(?:https?:)?\/\//i.test(url) && !((_c = url === null || url === void 0 ? void 0 : url.includes) === null || _c === void 0 ? void 0 : _c.call(url, 'data:image/png;base64'));
        if (isLocal) {
            url = Utils.asset(url);
        }
        else if (isNbcImage || isOlympicsImage) {
            const urlWithoutQueryParams = url.split('?')[0];
            const widthHeightRegex = /([0-9]+)x([0-9]+)/;
            const widthHeight = url.match(widthHeightRegex);
            const impolicyFallbackWidths = [60, 380, 420];
            let defaultTransformedUrl = urlWithoutQueryParams;
            if (isOlympicsImage && impolicy != OLY_IMPOLICY.NONE)
                defaultTransformedUrl += `?impolicy=${impolicy}`;
            else if (isNbcImage)
                defaultTransformedUrl += `?impolicy=nbc_com&imwidth=${width}&imheight=${height}`;
            if (widthHeight) {
                const originalWidth = Number(widthHeight[1]);
                const originalHeight = Number(widthHeight[2]);
                const isOriginalBigger = originalHeight > height && originalWidth > width;
                if (isOriginalBigger) {
                    const adjustedWidth = (height * originalWidth) / originalHeight;
                    const adjustByHeight = adjustedWidth > width;
                    url =
                        width === adjustedWidth
                            ? `${urlWithoutQueryParams}?impolicy=nbc_com&im=Resize,${adjustByHeight ? 'height' : 'width'}=${adjustByHeight ? height : width};Crop,width=${width},height=${height}`
                            : defaultTransformedUrl;
                }
            }
            else if (impolicyFallbackWidths.indexOf(width) > -1 || isOlympicsImage) {
                // if the resolution width matches one of the widths we look to fallback on,
                // ensure that we use the impolicy instead of bringing in a larger image than required
                url = defaultTransformedUrl;
            }
        }
        if (svgRegExp.test(url)) {
            return getSvgTexture(url, { w: width, h: height });
        }
        return {
            type: Lightning.textures.ImageTexture,
            src: url,
            resizeMode: {
                type: mode,
                w: width,
                h: height,
            },
        };
    };
    return {
        cover: (width, height, impolicy = OLY_IMPOLICY.NONE) => make(IMAGE_RESIZE_MODE.COVER, width, height, impolicy),
        contain: (width, height, impolicy = OLY_IMPOLICY.NONE) => make(IMAGE_RESIZE_MODE.CONTAIN, width, height, impolicy),
        portrait: (height, impolicy = OLY_IMPOLICY.NONE) => make(IMAGE_RESIZE_MODE.CONTAIN, height, height, impolicy),
        landscape: (width, impolicy = OLY_IMPOLICY.NONE) => make(IMAGE_RESIZE_MODE.CONTAIN, width, width, impolicy),
    };
};
/**
 * @typedef {Object} StateMap - An array of states to check, in order of priority
 * @property {string} state - State name
 * @property {function(): boolean} if - Are we allowed to go into this state?
 */
/**
 * Will traverse the array of items and switch to the first state that meets the requirements
 * @param {*} ctx Class context
 * @param {(StateMap | string)[]} states Array of states
 */
export const switchState = (ctx, states) => {
    if (!ctx || !states.length) {
        return;
    }
    for (let i = 0, n = states.length; i < n; i++) {
        const item = states[i];
        const isString = typeof item === 'string';
        const comparisonFunc = isString
            ? () => {
                const component = ctx.tag(item);
                // It doesn't make sense to change the state to another one in which there is no element to focus on, or it is hidden.
                return component && component.visible && component.alpha > 0.1;
            }
            : item.if;
        const state = isString ? item : item.state;
        if (typeof comparisonFunc === 'function' && comparisonFunc()) {
            ctx._setState(state);
            break;
        }
    }
};
export const convertHexToRgb = (hexColor) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hexColor.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexColor);
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
};
const parseMarkdown = (separator, text, regularObject, boldObject, baseObject) => {
    let piece = 0;
    let nextVal = '';
    let i = 0;
    let isClosing = true;
    while (i < text.length) {
        // Current text index matches separator[0]
        if (text[i] === separator[0]) {
            let lengthRemaining = separator.length - 1;
            let currentLength = 1;
            let similarSplit = text[i];
            while (lengthRemaining) {
                if (text[i + currentLength] === separator[currentLength]) {
                    similarSplit += text[i + currentLength];
                    currentLength++;
                    lengthRemaining--;
                }
                else {
                    break;
                }
            }
            if (!lengthRemaining) {
                if (nextVal) {
                    const component = cloneDeep(isClosing ? regularObject : boldObject);
                    component.text.text = nextVal.trim();
                    baseObject[`Text${piece}`] = component;
                    piece++;
                    nextVal = '';
                }
                isClosing = !isClosing;
            }
            else {
                nextVal += similarSplit;
            }
            i += currentLength;
        }
        else {
            nextVal += text[i];
            i++;
            if (i === text.length) {
                const component = cloneDeep(regularObject);
                component.text.text = nextVal.trim();
                baseObject[`Text${piece}`] = component;
            }
        }
    }
    return baseObject;
};
export const parseMarkdownBold = (text, regularObject, boldObject, baseObject) => parseMarkdown('**', text, regularObject, boldObject, baseObject);
export const getHexColor = (hex) => {
    if (!hex) {
        return 0x00;
    }
    hex = hex.replace('#', '');
    const number = Number(`0xff${hex}`);
    return parseInt(`${number}`, 10);
};
export const fadeComponent = (component, endValue, options) => {
    var _a;
    component
        .animation({
        duration: (_a = options === null || options === void 0 ? void 0 : options.duration) !== null && _a !== void 0 ? _a : 0.5,
        repeat: 0,
        stopMethod: 'immediate',
        actions: [{ p: 'alpha', v: { 0: Number(!endValue), 1: endValue } }],
    })
        .start();
};
export const getFirstNonNull = (obj, keys) => {
    for (let i = 0; i < keys.length; i++) {
        const key = keys[i];
        if (key && ![undefined, null].includes(obj[key]))
            return obj[key];
    }
    return undefined;
};
export const getAsString = (input, prefix) => input ? (prefix ? prefix + input : input) : '';
