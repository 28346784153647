import { Language } from '@lightningjs/sdk';
import { COLORS } from '../../../constants';
import LabelBadge from '../../badges/LabelBadge';
const FREE_VALUE = 'Free';
const wrapText = (words, maxLength) => {
    let finalWords = Array(15).fill('\xa0').join('');
    let lines = 1;
    words.forEach((word, i) => {
        if (finalWords.length + word.length + 1 < maxLength ||
            (lines > 1 && (finalWords.length + word.length + 1) % maxLength < maxLength)) {
            finalWords += word + ' ';
        }
        else if (i > 0 && word.length + 1 < maxLength) {
            finalWords += '\n' + word + ' ';
            lines++;
        }
        else {
            finalWords +=
                word.substring(0, maxLength - finalWords.length) +
                    '\n' +
                    word.substring(maxLength - finalWords.length) +
                    ' ';
            lines++;
        }
    });
    return finalWords;
};
export default (marginTop = 0) => {
    return (tile) => {
        var _a, _b, _c;
        const isFree = ((_b = (_a = tile._item) === null || _a === void 0 ? void 0 : _a.analytics) === null || _b === void 0 ? void 0 : _b.liveEntitlement) === FREE_VALUE;
        if (isFree) {
            const titleWords = (_c = tile._getSubTitle()) === null || _c === void 0 ? void 0 : _c.split(' ');
            tile.patch({
                Content: {
                    TitleCard: {
                        Title: {
                            text: {
                                wordWrap: true,
                                maxLines: 2,
                                text: wrapText(titleWords, 46),
                                wordWrapWidth: 400,
                            },
                            Badge: {
                                y: marginTop,
                                type: LabelBadge,
                                label: Language.translate('livePlayer-streamBadge-free'),
                                backGroundColor: COLORS.white1,
                                fontColor: COLORS.dark,
                                fontSize: 18,
                                widthSize: 64,
                                heightSize: 20,
                                heightSizeContainer: 30,
                            },
                        },
                    },
                },
            });
        }
    };
};
