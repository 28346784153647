export var ACTIVATION_LANDING;
(function (ACTIVATION_LANDING) {
    ACTIVATION_LANDING["NBC"] = "nbcFirst";
    ACTIVATION_LANDING["MVPD"] = "mvpdFirst";
    ACTIVATION_LANDING["FORK"] = "fork";
})(ACTIVATION_LANDING || (ACTIVATION_LANDING = {}));
export var ACTIVATION_REFERRER;
(function (ACTIVATION_REFERRER) {
    ACTIVATION_REFERRER["LINEAR"] = "linear";
    ACTIVATION_REFERRER["EVENT"] = "event";
    ACTIVATION_REFERRER["VOD"] = "vod";
    ACTIVATION_REFERRER["TEMPPASS"] = "temppass";
    ACTIVATION_REFERRER["CONTENT_GATING"] = "contentGating";
})(ACTIVATION_REFERRER || (ACTIVATION_REFERRER = {}));
export var ACTIVATION_TYPE;
(function (ACTIVATION_TYPE) {
    ACTIVATION_TYPE["NBC"] = "NBC";
    ACTIVATION_TYPE["MVPD"] = "MVPD";
    ACTIVATION_TYPE["TEMPPASS"] = "TEMPPASS";
})(ACTIVATION_TYPE || (ACTIVATION_TYPE = {}));
export const ACTIVATION_SCREEN_FONT_OVERFLOW_MULTIPLIER = 2.6;
