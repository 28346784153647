import { Lightning, Utils } from '@lightningjs/sdk';
import { FLEX_DIRECTION } from '../constants';
export default class LogoRow extends Lightning.Component {
    static _template() {
        return {
            w: 936,
            h: 56,
            flex: {
                direction: FLEX_DIRECTION.row,
            },
        };
    }
    _init() {
        const brands = [
            'agt',
            'bravo',
            'e',
            'nbc',
            'news',
            'sports',
            'oxygen',
            'syfy',
            'voice',
            'today',
            'telemundo',
            'usa',
        ];
        brands.forEach((name) => {
            this.childList.a({
                flexItem: {
                    marginRight: 24,
                },
                ref: `LogoRow-${name}`,
                w: 56,
                h: 56,
                src: Utils.asset(`images/onboarding/logo-${name}.png`),
            });
        });
    }
}
