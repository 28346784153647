import moment from 'moment-timezone';
import { Language, Log, Registry } from '@lightningjs/sdk';
import { ReplaySubject } from 'rxjs';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { isEpgProgram } from '../../../store/PlayerStore/actions/epg';
const TIME_BEFORE_END = 300; //SECONDS
const TIME_AFTER_START = 300; //SECONDS
export var LiveToVodButtonStates;
(function (LiveToVodButtonStates) {
    LiveToVodButtonStates["EMPTY_STATE"] = "EmptyState";
    LiveToVodButtonStates["PLAY_FROM_BEGINNING"] = "PlayFromBeginning";
    LiveToVodButtonStates["PLAY_NEXT_EPISODE"] = "PlayNextEpisode";
    LiveToVodButtonStates["GO_TO_SHOW"] = "GoToShow";
})(LiveToVodButtonStates || (LiveToVodButtonStates = {}));
export var LiveToVodPositions;
(function (LiveToVodPositions) {
    LiveToVodPositions["FIRST_5_MINUTES"] = "FIRST_5_MINUTES";
    LiveToVodPositions["MID_CONTENT"] = "MID_CONTENT";
    LiveToVodPositions["LAST_5_MINUTES"] = "LAST_5_MINUTES";
})(LiveToVodPositions || (LiveToVodPositions = {}));
const getTimeBeforeStart = (startTime) => {
    const startTimeObject = moment(startTime).add(TIME_AFTER_START, 'seconds');
    const currentTime = moment();
    return startTimeObject.diff(currentTime, 'seconds');
};
const getTimeBeforeEnd = (endTime) => {
    const endTimeTimeObject = moment(endTime).subtract(TIME_BEFORE_END, 'seconds');
    const currentTime = moment();
    return endTimeTimeObject.diff(currentTime, 'seconds');
};
export class LiveToVodDelegate {
    constructor(ctx) {
        this._timeout = null;
        this.events = new ReplaySubject(1);
        this.sync = () => {
            var _a;
            this._clearTimeout();
            if (!isEpgProgram(PlayerStoreSingleton.program))
                return;
            const { mpxGuid, nextEpisodeMpxGuid, seriesUrlAlias, startTime, endTime } = PlayerStoreSingleton.program;
            const allowed = (_a = PlayerStoreSingleton.program) === null || _a === void 0 ? void 0 : _a.allowLiveToVodButton;
            if (!allowed) {
                Log.info(this._parent._log_tag, `livetovod not allowed due to program allowLiveToVodButton value: ${allowed}`);
                this.events.next({
                    status: LiveToVodButtonStates.EMPTY_STATE,
                    label: '',
                });
                return;
            }
            const position = this._getPosition();
            const previousStatus = this.status;
            switch (position) {
                case LiveToVodPositions.FIRST_5_MINUTES:
                    this.status = LiveToVodButtonStates.EMPTY_STATE;
                    this._createTimeout(this.sync, getTimeBeforeStart(startTime));
                    break;
                case LiveToVodPositions.MID_CONTENT:
                    this.status = mpxGuid
                        ? LiveToVodButtonStates.PLAY_FROM_BEGINNING
                        : LiveToVodButtonStates.EMPTY_STATE;
                    this._createTimeout(this.sync, getTimeBeforeEnd(endTime));
                    break;
                case LiveToVodPositions.LAST_5_MINUTES:
                    this.status =
                        (nextEpisodeMpxGuid && LiveToVodButtonStates.PLAY_NEXT_EPISODE) ||
                            (seriesUrlAlias && LiveToVodButtonStates.GO_TO_SHOW) ||
                            LiveToVodButtonStates.EMPTY_STATE;
                    break;
                default:
                    break;
            }
            if (previousStatus !== this.status && this.status) {
                this.events.next({ status: this.status, label: this.label });
            }
        };
        this._parent = ctx;
    }
    get label() {
        const labels = {
            [LiveToVodButtonStates.EMPTY_STATE]: '',
            [LiveToVodButtonStates.PLAY_FROM_BEGINNING]: Language.translate('livePlayerVOD-button-playBeginning'),
            [LiveToVodButtonStates.PLAY_NEXT_EPISODE]: Language.translate('livePlayerVOD-button-playNextEpisode'),
            [LiveToVodButtonStates.GO_TO_SHOW]: Language.translate('livePlayerVOD-button-goToShow'),
        };
        return this.status ? labels[this.status] : '';
    }
    get route() {
        if (!isEpgProgram(PlayerStoreSingleton.program))
            return '';
        const { mpxGuid, nextEpisodeMpxGuid, seriesUrlAlias } = PlayerStoreSingleton.program || {};
        const routes = {
            [LiveToVodButtonStates.EMPTY_STATE]: '',
            [LiveToVodButtonStates.PLAY_FROM_BEGINNING]: `video/${mpxGuid}`,
            [LiveToVodButtonStates.PLAY_NEXT_EPISODE]: `video/${nextEpisodeMpxGuid}`,
            [LiveToVodButtonStates.GO_TO_SHOW]: `show/${seriesUrlAlias}`,
        };
        return this.status ? routes[this.status] : '';
    }
    reset() {
        this.status = undefined;
    }
    destroy() {
        this._clearTimeout();
    }
    _getPosition() {
        if (!isEpgProgram(PlayerStoreSingleton.program))
            return LiveToVodPositions.FIRST_5_MINUTES;
        const currentTime = moment();
        const { startTime, endTime } = PlayerStoreSingleton.program || {};
        const timeDiffFromStart = currentTime.diff(moment(startTime), 'seconds');
        const timeDiffFromEnd = moment(endTime).diff(currentTime, 'seconds');
        if (timeDiffFromStart >= TIME_AFTER_START && timeDiffFromEnd >= TIME_BEFORE_END) {
            return LiveToVodPositions.MID_CONTENT;
        }
        else if (timeDiffFromEnd <= TIME_BEFORE_END) {
            return LiveToVodPositions.LAST_5_MINUTES;
        }
        return LiveToVodPositions.FIRST_5_MINUTES;
    }
    _clearTimeout() {
        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
            this._timeout = null;
        }
    }
    _createTimeout(cb, seconds) {
        this._clearTimeout();
        this._timeout = Registry.setTimeout(cb, seconds * 1000);
    }
}
