import { Lightning, Utils } from '@lightningjs/sdk';
import { COLORS, FONT_FACE } from '../../constants';
export default class AudioDescriptionBadge extends Lightning.Component {
    static _template() {
        return {
            w: 50,
            AudioDescriptionContainer: {
                AudioDescriptionText: {
                    text: {
                        fontSize: 22,
                        fontFace: FONT_FACE.light,
                        text: 'AD',
                        paddingLeft: 8,
                    },
                },
                Icon: {
                    src: Utils.asset('images/audio-description-white.png'),
                    x: 35,
                    y: 8,
                },
                Border: {
                    texture: Lightning.Tools.getRoundRect(50, 28, 4, 1, COLORS.white, false),
                },
            },
        };
    }
}
