import { Utils } from '@lightningjs/sdk';
export const AdControlsStateFactory = (base) => class AdControls extends base {
    _handleEnter() {
        this.fireAncestors('$playOrPause');
    }
    set playOrPause(v) {
        const icon = v ? 'images/player/pause.png' : 'images/player/play.png';
        this.tag('AdControlIcon').src = Utils.asset(icon);
        this._setControlIcon(icon);
    }
    _updateProgress(progress) { }
};
