import { AUDIO_DESCRIPTION_TAG, COLORS } from '../../../constants';
import AudioLanguageBadge from '../../badges/AudioLanguageBadge';
import AudioDescriptionBadge from '../../badges/AudioDescriptionBadge';
export default (item) => {
    var _a, _b;
    if (item.audioLanguageContainer && ((_b = (_a = item._item) === null || _a === void 0 ? void 0 : _a.language) === null || _b === void 0 ? void 0 : _b.length)) {
        const audioLanguages = item._item.language.filter((tag) => tag !== AUDIO_DESCRIPTION_TAG);
        if (item._item.language.includes(AUDIO_DESCRIPTION_TAG)) {
            item.audioLanguageContainer.patch({
                ADBadgeContainer: {
                    flexItem: { marginRight: 5 },
                    type: AudioDescriptionBadge,
                    color: COLORS.transparent,
                },
            });
        }
        if (audioLanguages.length) {
            item.audioLanguageContainer.patch({
                ADLanguagesContainer: {
                    type: AudioLanguageBadge,
                    color: COLORS.transparent,
                    label: audioLanguages.join(' \u2022 ').toUpperCase(),
                },
            });
        }
    }
};
