import { Lightning, Log, Registry } from '@lightningjs/sdk';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { AdBreakEndEvent } from '../../player/model/event/AdBreakEndEvent';
import { LoadSourceEvent } from '../../player/model/event/LoadSourceEvent';
import { FastImg } from '../../helpers';
import { AdBreakStartEvent } from '../../player/model/event/AdBreakStartEvent';
export default class TVRatingGuide extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._getImageUrl = (vChipRating, vChipSubRatings, size = 'L') => {
            const rating = vChipRating === null || vChipRating === void 0 ? void 0 : vChipRating.replace('-', '').toUpperCase();
            const subRatings = vChipSubRatings === null || vChipSubRatings === void 0 ? void 0 : vChipSubRatings.join('').toUpperCase();
            const baseUrl = AppConfigFactorySingleton.config.tvratings_base_url;
            const sizeDir = size === 'M' ? 'hd_mid' : 'hd_large';
            const filename = `${size}${rating}${subRatings}H.png`;
            return `${baseUrl}${sizeDir}/${filename}`;
        };
    }
    static _template() {
        return {};
    }
    _init() {
        this._player = null;
        this._subscription = null;
        this._assetInfo = null;
    }
    _attachPlayer(player) {
        this._player = player;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        this._subscription = this._player.events.subscribe((event) => {
            if (event instanceof AdBreakEndEvent) {
                self === null || self === void 0 ? void 0 : self._onAdBreakEnd(event.adBreak);
            }
            else if (event instanceof LoadSourceEvent) {
                if (event.metadata instanceof Map) {
                    const vodAdBreaks = event.metadata.get('vodAdBreaks');
                    const startTime = event.metadata.get('startTime');
                    const prerollAd = vodAdBreaks.find((adBreak) => adBreak.startTime === 0);
                    if (prerollAd || !startTime)
                        return;
                }
                self === null || self === void 0 ? void 0 : self._showRating();
            }
            else if (event instanceof AdBreakStartEvent) {
                self === null || self === void 0 ? void 0 : self._onAdBreakStart();
            }
        });
    }
    _detach() {
        this._detachPlayer();
    }
    _detachPlayer() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._subscription = null;
        this._player = null;
    }
    set assetInfo(v) {
        this._assetInfo = v;
    }
    _onAdBreakStart() {
        Registry.clearTimeout(this.fadeTimeout);
        this.patch({
            alpha: 0,
        });
    }
    _onAdBreakEnd(adBreak) {
        if (adBreak.startTime === 0) {
            this._showRating();
        }
        else {
            this._showRating('M');
        }
    }
    _showRating(size = 'L') {
        Registry.clearTimeout(this.fadeTimeout);
        Log.debug('Show TV Rating Guide');
        if (!this._assetInfo)
            return;
        const url = this._getImageUrl(this._assetInfo.rating, this._assetInfo.ratingAdvisories, size);
        this.patch({
            alpha: 1,
            Image: {
                texture: FastImg(url).landscape(150), //.contain(150, 98),
            },
        });
        this.fadeTimeout = Registry.setTimeout(() => {
            this.patch({
                alpha: 0,
            });
        }, AppConfigFactorySingleton.config.vod.ratingsDelay);
    }
}
