import { COLORS, FONT_FACE } from '../../../constants';
export default (label) => (tile) => {
    if (label) {
        tile.patch({
            Image: {
                Badge: {
                    flex: {},
                    rect: true,
                    color: COLORS.white1,
                    h: 40,
                    Label: {
                        flexItem: {
                            marginTop: 3,
                            marginBottom: -4,
                            marginLeft: 15,
                            marginRight: 15,
                        },
                        color: COLORS.dark,
                        text: {
                            fontFace: FONT_FACE.regular,
                            fontSize: 24,
                            text: tile._getBadgeLabel(label),
                        },
                    },
                },
            },
        });
    }
};
