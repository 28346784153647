import { BaseCCDelegate } from './BaseCCDelegate';
export class AAMPCCDelegate extends BaseCCDelegate {
    setCCStyle(options) {
        var _a, _b, _c, _d, _e;
        // AAMP code reference
        // Fonts: https://github.com/rdkcmf/rdk-aamp/blob/3e572144bdce84e518f484a53b88e6ea866f04c2/closedcaptions/AampCCManager.cpp
        // Colors: https://github.com/rdkcmf/rdk-aamp/blob/3e572144bdce84e518f484a53b88e6ea866f04c2/closedcaptions/subtec/SubtecConnector.h
        const sizeMappings = {
            56: 'large',
            45: 'medium',
            33: 'small',
        };
        const fontMappings = {
            Default: 'default',
            Arial: 'proportional_sanserif',
            Helvetica: 'monospaced_sanserif',
            Coronet: 'cursive',
            TimesNewRoman: 'proportional_serif',
            Courier: 'monospaced_serif',
            Impress: 'casual',
            Copperplate: 'smallcaps',
        };
        const opacityMappings = {
            ff: 'solid',
            '40': 'translucent',
            '80': 'translucent',
            bf: 'translucent',
            '00': 'transparent',
        };
        const colorsMappings = {
            Black: 'black',
            Blue: 'blue',
            Green: 'green',
            Magenta: 'magenta',
            Cyan: 'cyan',
            Teal: 'green',
            Red: 'red',
            Purple: 'magenta',
            Yellow: 'yellow',
            White: 'white',
        };
        const edgeStyleMappings = {
            none: 'none',
            uniform: 'uniform',
            raised: 'raised',
            depressed: 'depressed',
            shadow: 'drop_shadow_right',
        };
        const backgroundOpacity = opacityMappings[options.backgroundOpacity];
        const optionsObject = {
            penItalicized: false,
            textEdgeStyle: edgeStyleMappings[options.edgeStyle],
            textEdgeColor: colorsMappings[options.edgeColor],
            penSize: sizeMappings[options.fontSize],
            windowFillColor: colorsMappings[options.windowColor],
            fontStyle: fontMappings[options.fontStyle],
            textForegroundColor: colorsMappings[options.fontColor],
            windowFillOpacity: opacityMappings[options.windowOpacity],
            textForegroundOpacity: opacityMappings[options.fontOpacity],
            textBackgroundColor: backgroundOpacity !== 'transparent' ? colorsMappings[options.backgroundColor] : 'auto',
            // Transparent background hides text completely, which should be bad user experience
            textBackgroundOpacity: backgroundOpacity !== 'transparent' ? backgroundOpacity : 'solid',
            windowBorderEdgeStyle: 'none',
            windowBorderEdgeColor: colorsMappings[options.windowColor],
            penUnderline: false,
        };
        (_e = (_d = (_c = (_b = (_a = this._ctx) === null || _a === void 0 ? void 0 : _a._coreVideoController) === null || _b === void 0 ? void 0 : _b.engine) === null || _c === void 0 ? void 0 : _c.playerItem) === null || _d === void 0 ? void 0 : _d.player) === null || _e === void 0 ? void 0 : _e.setTextStyleOptions(JSON.stringify(optionsObject));
    }
}
