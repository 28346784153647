import AnalyticsSingleton, { sendMetric } from '../../../../lib/analytics/Analytics';
import { EVENTS } from '../../../../lib/analytics/types';
import { AnalyticsWithEndCardDelegate } from './AnalyticsWithEndCardDelegate';
export class LinearSLEAnalyticsDelegate extends AnalyticsWithEndCardDelegate {
    fireProgramChange() {
        sendMetric(EVENTS.LIVE_PROGRAM_CHANGE, this._getMetricsData());
    }
    updateUserDefaultAttributes(tempPassTokenName) {
        AnalyticsSingleton.updateUserDefaultAttributes(tempPassTokenName);
    }
}
