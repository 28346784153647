import { setDefaultValue } from '../../../../helpers/analytics';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
import OnAirNowShelf from '../../../../api/models/collections/OnAirNowShelf';
const getTitle = (item) => {
    var _a, _b, _c, _d, _e;
    if (!item)
        return '';
    if ((_a = item.data) === null || _a === void 0 ? void 0 : _a.title)
        return item.data.title;
    // OnAirNowShelf Tile
    if ((_b = item.onAirNowTileData) === null || _b === void 0 ? void 0 : _b.title)
        return item.onAirNowTileData.title;
    // BrandTile
    if ((_c = item.data) === null || _c === void 0 ? void 0 : _c.displayTitle)
        return item.data.displayTitle;
    // PremiumShelf or unknown (has no known title)
    return (_e = (_d = item.analytics) === null || _d === void 0 ? void 0 : _d.entityTitle) !== null && _e !== void 0 ? _e : MPARTICLE_DEFAULT_ATTR_VALUE;
};
const getShelfTitle = (data) => {
    let title = '';
    if (data.listTitle)
        title += `${data.listTitle},`;
    return !data.machineName ? title : title + data.machineName;
};
const makeShelfItem = (shelves, number, val, isTitle = true) => (shelves[`Custom Shelf ${isTitle ? 'Title' : 'Items'} ${number}`] = val);
const makeShelfItems = (shelves, number, data) => {
    makeShelfItem(shelves, number, getShelfTitle(data) || MPARTICLE_DEFAULT_ATTR_VALUE, true);
    makeShelfItem(shelves, number, getItemTitles(data.items) || MPARTICLE_DEFAULT_ATTR_VALUE, false);
};
const getItemTitles = (items) => Object.keys(items)
    .map((k) => getTitle(items[k]))
    .join(',');
const getShelvesTitleData = ({ params, featured }) => {
    const shelves = {};
    // Start the shelf count at 1
    let shelfNumber = 1;
    //Check for a separate feature shelf (dynamic lead shelf)
    if (featured) {
        makeShelfItems(shelves, 1, featured.data);
        shelfNumber++;
    }
    if (params) {
        Object.keys(params).forEach((key) => {
            // key/item have been null, protect from this.
            if (params[key]) {
                const { data } = params[key];
                // Data can sometimes be undefined if for instance the firstVisit is passed
                if (data === null || data === void 0 ? void 0 : data.items) {
                    // On air now shelf needs to be reordered to follow tiles.
                    const _data = data.__typename === 'OnAirNowList'
                        ? {
                            ...data,
                            items: OnAirNowShelf.getFilteredSortedItems(data.items),
                        }
                        : data;
                    makeShelfItems(shelves, shelfNumber, _data);
                    shelfNumber++;
                }
            }
        });
    }
    return shelves;
};
const getShelvesLoadAttributes = (params) => {
    if (!params) {
        return {};
    }
    const shelves = getShelvesTitleData(params);
    return setDefaultValue(shelves, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getShelvesLoadAttributes;
