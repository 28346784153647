import moment from 'moment-timezone';
import Tile from './Tile';
import { formatDate, pipeSeparate } from '../../../helpers';
import { getLabelBadge, showLabelBadge } from '../../algolia/helpers';
export default class ReplayTile extends Tile {
    get replayTileData() {
        return this.obj.replayTileData || this.obj.data;
    }
    get showMetadata() {
        const { seasonNumber, episodeNumber, airDate } = this.replayTileData;
        return pipeSeparate(`S${seasonNumber} E${episodeNumber} | ${formatDate(airDate)}`);
    }
    get ariaLabel() {
        return this.replayTileData.ariaLabel;
    }
    get brandDisplayTitle() {
        return this.replayTileData.brandDisplayTitle || '';
    }
    get channelId() {
        return this.replayTileData.channelId;
    }
    get colorBrandLogo() {
        return this.replayTileData.channelId;
    }
    get entitlement() {
        return this.replayTileData.entitlement;
    }
    get genre() {
        return this.replayTileData.genre;
    }
    get image() {
        return this.replayTileData.image || null;
    }
    get isLocked() {
        return this.replayTileData.locked || this.analytics.locked;
    }
    get labelBadge() {
        const { labelBadge, labelBadgeEs } = this.replayTileData;
        return showLabelBadge(this.eventStart) && getLabelBadge(labelBadge, labelBadgeEs);
    }
    get pid() {
        return this.replayTileData.pid;
    }
    get title() {
        return this.replayTileData.title;
    }
    get secondaryTitle() {
        return this.replayTileData.secondaryTitle;
    }
    get eventStart() {
        return this.replayTileData.startTime || this.replayTileData.startDate;
    }
    get tertiaryTitle() {
        if (!this.replayTileData.startTime) {
            return this.replayTileData.tertiaryTitle;
        }
        const replayDate = moment(this.replayTileData.startTime);
        const diff = Math.abs(replayDate.diff(moment(), 'hours'));
        return diff < 24 ? `${diff}h ago` : replayDate.format('MM/DD/YY');
    }
    get logo() {
        return this.replayTileData.whiteBrandLogo;
    }
    get HARDCODEDSTREAM() {
        return this.replayTileData.HARDCODEDSTREAM;
    }
    get isMedalSession() {
        return this.replayTileData.isMedalSession;
    }
    get programmingType() {
        return this.replayTileData.programmingType;
    }
    get language() {
        return this.replayTileData.audioLanguage;
    }
}
