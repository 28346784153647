import getSlideAttributes, { isSLE } from '../attributes/getSlideAttributes';
import { getIsOlympics, setDefaultValue } from '../../../../helpers/analytics';
import { ENTITY_TYPES, MPARTICLE_DEFAULT_ATTR_VALUE, OLYMPICS_FALLBACK_VALUE, } from '../../../../constants';
export const getDynamicLeadImpressionAttributes = (params = {}) => {
    var _a, _b, _c;
    const analytics = params.analytics || ((_a = params.entity) === null || _a === void 0 ? void 0 : _a.analytics) || {};
    const shelf = params.shelf || {};
    const tileParams = params.tileParams || {};
    return setDefaultValue({
        Brand: MPARTICLE_DEFAULT_ATTR_VALUE,
        Show: getIsOlympics(analytics) ? OLYMPICS_FALLBACK_VALUE : MPARTICLE_DEFAULT_ATTR_VALUE,
        'Content Position': shelf.position || shelf.tileIndex,
        'Custom Shelf Position': shelf.customPosition || shelf.shelfIndex,
        Sponsor: analytics.sponsorName,
        'Destination URL': analytics.destination,
        // @ts-expect-error TS(2774): This condition will always return true since this ... Remove this comment to see the full error message
        'Destination Type': isSLE ? ENTITY_TYPES.VIDEO : analytics.destinationType,
        'Notification Title': ((_b = params === null || params === void 0 ? void 0 : params.entity) === null || _b === void 0 ? void 0 : _b.notificationTitle) || analytics.videoTitle,
        'Dynamic Lead Logic': (_c = params === null || params === void 0 ? void 0 : params.entity) === null || _c === void 0 ? void 0 : _c.dynamicSmartLeadLogic,
        ...getSlideAttributes(analytics, shelf, tileParams),
    }, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getDynamicLeadImpressionAttributes;
