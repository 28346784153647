import { Lightning, Router } from '@lightningjs/sdk';
import LongScrollPage from '../LongScrollPage';
import { LongScroll } from '../../components';
import { FeaturedSpawner, SectionsSpawner } from '../../api/spawners';
import { EVENTS } from '../../lib/analytics/types';
import { COLORS, MainStates } from '../../constants';
import { sendMetric } from '../../lib/analytics/Analytics';
import { EmptyResponse, FailedResponse, RejectedResponse, useRequest } from '../../lib/useRequest';
import { MainPageFeaturedRequestConfig, MainPageRequestConfig } from './request';
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError';
import { setUserAttributes } from '../../lib/analytics/Amplitude/Amplitude';
import { getMpid } from '../../helpers';
var SCROLLER_POSITIONS;
(function (SCROLLER_POSITIONS) {
    SCROLLER_POSITIONS[SCROLLER_POSITIONS["FEATURED"] = 801] = "FEATURED";
    SCROLLER_POSITIONS[SCROLLER_POSITIONS["SCROLLER"] = 175] = "SCROLLER";
})(SCROLLER_POSITIONS || (SCROLLER_POSITIONS = {}));
class Main extends LongScrollPage {
    constructor() {
        super(...arguments);
        this.mainRequestConfig = MainPageRequestConfig;
        this.featuredRequestConfig = MainPageFeaturedRequestConfig;
    }
    static _template() {
        return {
            ...super._template(),
            Featured: {
                type: Lightning.Component,
            },
            Scroller: {
                type: LongScroll,
                scrollFocus: 0.5,
                x: 83,
                y: SCROLLER_POSITIONS.FEATURED,
                transitions: {
                    y: {
                        duration: 0.3,
                        timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)',
                    },
                },
            },
        };
    }
    _setup() {
        super._build();
        this.widgets.loader.setSmooth('alpha', 1);
    }
    _attach() {
        this.stage.setClearColor(COLORS.dark);
    }
    $firstShelfImpression() {
        this.tag('Scroller').fireFirstShelfImpression();
    }
    async load() {
        var _a, _b;
        try {
            const data = (await useRequest(this.mainRequestConfig()).fetch());
            this.sections = data.sections;
            await this.loadFeatured();
            this.containers = await SectionsSpawner(this.stage, data.sections);
            // Make Amplitude call
            setUserAttributes(getMpid(), (_b = (_a = data === null || data === void 0 ? void 0 : data.ldStateFlag) === null || _a === void 0 ? void 0 : _a.user_properties) === null || _b === void 0 ? void 0 : _b['Server Feature Flags']);
            sendMetric(EVENTS.SHELVES_LOAD, {
                params: data.sections,
                featured: data.featured,
            });
        }
        catch (err) {
            if (err instanceof RejectedResponse || err instanceof FailedResponse) {
                this._setState(WithRequestErrorState);
            }
            if (err instanceof EmptyResponse) {
                return;
            }
        }
    }
    async loadFeatured() {
        try {
            const featured = await useRequest(this.featuredRequestConfig()).fetch();
            const spawnedFeatured = FeaturedSpawner(this.stage, featured);
            if (!spawnedFeatured)
                return;
            this.patch({ Featured: spawnedFeatured });
            if (this._getState() !== MainStates.Scroller) {
                this._setState(MainStates.Featured, [{ immediate: true }]);
            }
            else {
                this.hideFeatured();
            }
        }
        catch {
            this._setState(MainStates.Scroller, [{ immediate: true }]);
            this.removeFeatured();
        }
        finally {
            this.widgets.loader.setSmooth('alpha', 0);
        }
    }
    get featured() {
        return this.tag('Featured');
    }
    set containers(v) {
        this.tag('Scroller').add(v);
    }
    removeFeatured() {
        this.childList.remove(this.tag('Featured'));
    }
    hideFeatured() {
        this.stage.setClearColor(COLORS.dark);
        this.widgets.loader.setSmooth('alpha', 0);
        this.tag('Featured').alpha = 0;
    }
    $scrollRequested(args) {
        var _a;
        if (this.tag('Featured'))
            this.tag('Featured').alpha = 0;
        if (this._getState() === MainStates.Scroller &&
            ((_a = this.tag('Scroller')) === null || _a === void 0 ? void 0 : _a.y) !== SCROLLER_POSITIONS.SCROLLER)
            this.tag('Scroller').patch({ y: SCROLLER_POSITIONS.SCROLLER });
        super.$scrollRequested(args);
    }
    $setFeaturedIndex(index) {
        var _a;
        if ((_a = this.tag('Featured')) === null || _a === void 0 ? void 0 : _a.totalSlides)
            this.tag('Featured').setSlideByIndex(index);
    }
    $hideMenuBackground() {
        var _a;
        (_a = this.widgets.menu) === null || _a === void 0 ? void 0 : _a.hideBackground();
    }
    $showMenuBackground() {
        var _a;
        (_a = this.widgets.menu) === null || _a === void 0 ? void 0 : _a.showBackground();
    }
    static _states() {
        return [
            class Featured extends this {
                $enter(_states, args) {
                    this._setBackgroundColor(COLORS.black);
                    if (args === null || args === void 0 ? void 0 : args.immediate)
                        this.tag('Scroller').patch({ y: SCROLLER_POSITIONS.FEATURED });
                    else
                        this.tag('Scroller').setSmooth('y', SCROLLER_POSITIONS.FEATURED);
                    this.tag('Featured').alpha = 1;
                    this.widgets.menu.setSmooth('alpha', 1);
                }
                _handleUp() {
                    Router.focusWidget('Menu');
                }
                _handleBack(e) {
                    Router.focusWidget('Menu');
                    e.preventDefault();
                    e.stopPropagation();
                }
                _handleDown() {
                    if (this.tag('Scroller').itemsLength) {
                        this._setState(MainStates.Scroller);
                    }
                }
                _getFocused() {
                    return this.tag('Featured') || this;
                }
            },
            class Scroller extends this {
                $enter(_states, args) {
                    var _a;
                    this._setBackgroundColor(COLORS.dark);
                    const scroller = this.tag('Scroller');
                    // for some reason if we use setSmooth with 0 value it invokes with delay and works wrong
                    if (args === null || args === void 0 ? void 0 : args.immediate)
                        scroller.patch({ y: SCROLLER_POSITIONS.SCROLLER });
                    else
                        scroller.setSmooth('y', SCROLLER_POSITIONS.SCROLLER);
                    // Fire shelf impression for the first time.
                    this.tag('Scroller')._impressionIncrement(1);
                    (_a = !this.featured) !== null && _a !== void 0 ? _a : this.widgets.menu.setSmooth('alpha', 0);
                }
                _inactive() {
                    this.widgets.menu.setSmooth('alpha', 1);
                }
                _handleUp() {
                    this.featured ? this._setState(MainStates.Featured) : Router.focusWidget('Menu');
                }
                _getFocused() {
                    return this.tag('Scroller') || this;
                }
                _handleBack(e) {
                    this.tag('Scroller').setIndex(0);
                    this._setState(MainStates.Featured);
                    e.preventDefault();
                    e.stopPropagation();
                }
            },
        ];
    }
}
export default WithRequestError(Main);
