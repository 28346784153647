import { BehaviorSubject, Subject } from 'rxjs';
import { COMPONENT_TYPES, EPG_VIEWS } from '../../constants';
import moment from 'moment-timezone';
import { cloneDeep } from 'lodash';
export const isNestedLinksSelectableGroup = (input) => { var _a; return typeof input === 'object' && !!((_a = input === null || input === void 0 ? void 0 : input['data']) === null || _a === void 0 ? void 0 : _a.items); };
export var EpgGuideEvents;
(function (EpgGuideEvents) {
    EpgGuideEvents["PROGRESS"] = "progress";
    EpgGuideEvents["VIEW_CHANGE"] = "viewChange";
    EpgGuideEvents["TAB_DATA"] = "tabData";
})(EpgGuideEvents || (EpgGuideEvents = {}));
const defaultTabData = {
    component: 'LinksSelectableGroup',
    data: { instanceID: '', initiallySelected: 0, itemLabels: [], items: [] },
    treatment: '',
    treatments: [],
};
/**
 * Controller for Live Guide (EPG) view
 * Handles tab and program progress data/events
 */
class GuideController {
    constructor() {
        // Tab properties
        this._tabIndex = -1;
        this._tabDataSubject = new BehaviorSubject(cloneDeep(defaultTabData));
        this._viewSubject = new Subject();
        this._progressSubject = new BehaviorSubject(0);
        this._updateIntervalTime = 10000; // ms
        this._programUpdateInterval = 1800; // seconds
        this._updateProgress = () => {
            const subject = this.getSubject(EpgGuideEvents.PROGRESS);
            if ((subject === null || subject === void 0 ? void 0 : subject.value) < 100) {
                subject === null || subject === void 0 ? void 0 : subject.next((moment().diff(this._startTime, 'seconds') * 100) / this._programUpdateInterval);
            }
        };
        // #endregion
    }
    subscribe({ type, handler }) {
        const subject = this.getSubject(type);
        return subject === null || subject === void 0 ? void 0 : subject.subscribe(handler);
    }
    getSubject(type) {
        switch (type) {
            case EpgGuideEvents.PROGRESS:
                if (!this._progressSubject)
                    this._progressSubject = new BehaviorSubject(0);
                return this._progressSubject;
            case EpgGuideEvents.VIEW_CHANGE:
                if (!this._viewSubject)
                    this._viewSubject = new Subject();
                return this._viewSubject;
            case EpgGuideEvents.TAB_DATA:
                if (!this._tabDataSubject)
                    this._tabDataSubject = new BehaviorSubject(cloneDeep(defaultTabData));
                return this._tabDataSubject;
            default:
                return undefined;
        }
    }
    clear() {
        var _a, _b, _c;
        if (this._tabDataSubject) {
            (_a = this._tabDataSubject) === null || _a === void 0 ? void 0 : _a.complete();
            this._tabDataSubject = undefined;
        }
        if (this._viewSubject) {
            (_b = this._viewSubject) === null || _b === void 0 ? void 0 : _b.complete();
            this._viewSubject = undefined;
        }
        if (this._progressSubject) {
            (_c = this._progressSubject) === null || _c === void 0 ? void 0 : _c.complete();
            this._progressSubject = undefined;
        }
    }
    // #region Tab methods/getters
    get tabs() {
        var _a, _b;
        return (_b = (_a = this._tabDataSubject) === null || _a === void 0 ? void 0 : _a.value.data) === null || _b === void 0 ? void 0 : _b.items;
    }
    get currentTab() {
        var _a;
        return (_a = this.tabs) === null || _a === void 0 ? void 0 : _a[this._tabIndex];
    }
    get currentEpgView() {
        var _a;
        const tab = (_a = this.tabs) === null || _a === void 0 ? void 0 : _a[this._tabIndex];
        switch (tab === null || tab === void 0 ? void 0 : tab.component) {
            case COMPONENT_TYPES.NESTED_LINK_SELECTABLE_GROUP:
                return EPG_VIEWS.filters;
            case COMPONENT_TYPES.PLACEHOLDER_ELEMENT:
            default:
                return EPG_VIEWS.channels;
        }
    }
    setTabs(tabs) {
        var _a;
        if (!tabs)
            return;
        (_a = this._tabDataSubject) === null || _a === void 0 ? void 0 : _a.next(tabs);
    }
    setActiveTab(index) {
        var _a, _b;
        if (index === this._tabIndex || index >= ((_a = this.tabs) === null || _a === void 0 ? void 0 : _a.length) || index < 0)
            return;
        this._tabIndex = index;
        (_b = this.getSubject(EpgGuideEvents.VIEW_CHANGE)) === null || _b === void 0 ? void 0 : _b.next(this.currentEpgView);
    }
    // #endregion
    // #region Progress methods/getters
    get progress() {
        var _a;
        return ((_a = this._progressSubject) === null || _a === void 0 ? void 0 : _a.value) || 0;
    }
    setupTabIndex() {
        this._tabIndex = -1;
    }
    setupProgress(startTime) {
        var _a;
        this.resetProgress();
        this._startTime = moment(startTime);
        (_a = this.getSubject(EpgGuideEvents.PROGRESS)) === null || _a === void 0 ? void 0 : _a.next(0);
        this._startProgress();
    }
    resetProgress() {
        this._clearProgressUpdateInterval();
    }
    _startProgress() {
        this._updateProgress();
        this._updateTimer = setInterval(this._updateProgress, this._updateIntervalTime);
    }
    _clearProgressUpdateInterval() {
        if (this._updateTimer) {
            clearInterval(this._updateTimer);
            this._updateTimer = null;
        }
    }
}
export const EpgGuideController = new GuideController();
