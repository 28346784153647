import SkipButtons from './SkipButtons';
export default class Metadata {
    constructor(metadata) {
        this._metadata = metadata;
        this._skipButtons = new SkipButtons(metadata);
    }
    get metadata() {
        return this._metadata;
    }
    get whiteBrandLogo() {
        return this._metadata.whiteBrandLogo;
    }
    get brandLandingHeadline() {
        return this._metadata.brandLandingHeadline;
    }
    get brandLandingDescription() {
        return this._metadata.brandLandingDescription;
    }
    get image() {
        return this._metadata.image;
    }
    get locked() {
        return this._metadata.locked;
    }
    get mpxGuid() {
        return this._metadata.mpxGuid;
    }
    get urlAlias() {
        return this._metadata.urlAlias || '';
    }
    get programmingType() {
        return this._metadata.programmingType;
    }
    get title() {
        return this._metadata.title;
    }
    get secondaryTitle() {
        return this._metadata.secondaryTitle;
    }
    get seriesShortTitle() {
        return this._metadata.seriesShortTitle;
    }
    get rating() {
        return this._metadata.rating;
    }
    get resourceId() {
        return this._metadata.resourceId;
    }
    get externalAdvertiserId() {
        return this._metadata.externalAdvertiserId;
    }
    get mpxAccountId() {
        return this._metadata.mpxAccountId;
    }
    get description() {
        return this._metadata.description;
    }
    get watchId() {
        return this._metadata.watchId;
    }
    get airDate() {
        return this._metadata.airDate;
    }
    get duration() {
        return this._metadata.duration;
    }
    get episodeNumber() {
        return this._metadata.episodeNumber;
    }
    get permalink() {
        return this._metadata.permalink;
    }
    get seasonNumber() {
        return this._metadata.seasonNumber;
    }
    get v4ID() {
        return this._metadata.v4ID;
    }
    get channelId() {
        return this._metadata.channelId;
    }
    get genre() {
        return this._metadata.genre;
    }
    get brandDisplayTitle() {
        return this._metadata.brandDisplayTitle;
    }
    get skipButtons() {
        return this._skipButtons;
    }
    get sport() {
        return this._metadata.sport;
    }
    get league() {
        return this._metadata.league;
    }
}
