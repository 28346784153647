import { Router, Storage } from '@lightningjs/sdk';
import LongScrollPage from '../LongScrollPage';
import { LongScroll } from '../../components';
import { evict as evictFromCache } from '../../graphql/cache';
import { SectionsSpawner } from '../../api/spawners';
import Metadata from '../../api/models/Metadata';
import ContentPosition from '../../util/contentPosition';
import { COLORS, FONT_FACE, ROUTE, OLY_IMPOLICY } from '../../constants';
import { FastImg, setSmooth } from '../../helpers';
import { useRequest } from '../../lib/useRequest';
import { NetworksLandingFeaturedRequestConfig, NetworksLandingRequestConfig } from './request';
export default class NetworksLanding extends LongScrollPage {
    constructor() {
        super(...arguments);
        this._sections = [];
        this._listIndex = 0;
    }
    static _template() {
        return {
            Metadata: {
                x: 80,
                y: 266,
                BrandLogo: {},
                BrandLandingHeadline: {
                    y: 203,
                    text: {
                        fontFace: FONT_FACE.bold,
                        fontSize: 30,
                        textColor: COLORS.white,
                        maxLines: 1,
                        wordWrapWidth: 650,
                        text: this.bindProp('brandLandingHeadline'),
                    },
                },
                BrandLandingDescription: {
                    y: 252,
                    text: {
                        fontFace: FONT_FACE.regular,
                        fontSize: 26,
                        textColor: COLORS.white,
                        maxLines: 5,
                        wordWrapWidth: 650,
                        text: this.bindProp('brandLandingDescription'),
                    },
                },
            },
            Scroller: {
                type: LongScroll,
                scrollFocus: 0.5,
                x: 80,
                y: 170,
                transitions: {
                    y: {
                        duration: 0.3,
                        timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)',
                    },
                },
            },
        };
    }
    _init() {
        this.widgets.menu.collapse({
            parentRoute: ROUTE.networks,
            hideProfile: true,
        });
        this.stage.setClearColor(COLORS.dark);
        this.widgets.loader.alpha = 1;
    }
    _inactive() {
        this.widgets.menu.visible = true;
        this.widgets.menu.expand();
    }
    _detach() {
        this._listIndex = 0;
    }
    async load() {
        var _a;
        try {
            this.apiData = (await useRequest(NetworksLandingRequestConfig((_a = this.params) === null || _a === void 0 ? void 0 : _a.machineName)).fetch());
            this.loadFeatured();
        }
        catch (e) {
            Router.navigate(ROUTE.error);
        }
    }
    loadFeatured() {
        var _a;
        useRequest(NetworksLandingFeaturedRequestConfig((_a = this.params) === null || _a === void 0 ? void 0 : _a.machineName))
            .fetch()
            .then((data) => {
            if (!data)
                return;
            SectionsSpawner(this.stage, [{ ...data, data: data.shelfData }]).then((sections) => {
                var _a;
                if (sections && (sections === null || sections === void 0 ? void 0 : sections.length)) {
                    if ((_a = this._sections) === null || _a === void 0 ? void 0 : _a[0])
                        this._sections[0].x = 0;
                    this._sections = [...sections, ...this._sections];
                    this.networkData = this._sections;
                }
                setSmooth(this.widgets.loader, 'alpha', 0);
            });
        })
            .catch(() => {
            // render the shelf when the feature section is returning null
            this.networkData = this._sections;
            setSmooth(this.widgets.loader, 'alpha', 0);
        });
    }
    _saveNetworkCacheId(id) {
        const storageKey = 'bff.networkCacheId';
        const previousNetworkId = Storage.get(storageKey);
        if (previousNetworkId && previousNetworkId !== id) {
            // Clear previous network landing page from cache.
            evictFromCache(previousNetworkId);
        }
        Storage.set(storageKey, id);
    }
    set apiData({ id, sections, metadata }) {
        if (!metadata || !sections)
            return;
        this.networkId = id;
        SectionsSpawner(this.stage, sections, {}).then((data) => {
            if (data && data.length) {
                this._sections = [...this._sections, ...data];
            }
        });
        this.metadata = new Metadata(metadata);
    }
    set networkId(v) {
        this._saveNetworkCacheId(v);
    }
    set networkData(v) {
        if (v === null || v === void 0 ? void 0 : v[0])
            v[0].x = 736;
        this.tag('Scroller').clear();
        this.tag('Scroller').add(v);
        this._refocus();
    }
    set metadata(v) {
        this._metadata = v;
        this.tag('Scroller').announce = [];
        this.tag('BrandLogo').texture = FastImg(v.whiteBrandLogo).contain(195, 155, OLY_IMPOLICY.RECT_SMALL_384_216);
        if (v.brandLandingHeadline) {
            this.brandLandingHeadline = v.brandLandingHeadline;
            this.tag('Scroller').announce.push(v.brandLandingHeadline);
        }
        if (v.brandLandingDescription) {
            this.brandLandingDescription = v.brandLandingDescription;
            this.tag('Scroller').announce.push(v.brandLandingDescription);
        }
        this.widgets.menu.collapse({
            parentRoute: ROUTE.networks,
            subCategoryItem: FastImg(this._metadata.whiteBrandLogo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216),
            hideProfile: true,
        });
    }
    _handleBack(e) {
        ContentPosition.clearPositionForCurrentPage();
        if (this.tag('Scroller').index === 0) {
            return false;
        }
        else {
            this.tag('Scroller').setIndex(0);
            e.preventDefault();
            e.stopPropagation();
        }
    }
    $scrolledListItemIndex({ index }) {
        if (this._listIndex === 0) {
            setSmooth(this.tag('Metadata'), 'x', index === 0 ? 80 : -736, {
                duration: 0.3,
            });
            setSmooth(this.tag('Scroller').scrolledItem, 'x', index === 0 ? 736 : 0, {
                duration: 0.3,
            });
        }
    }
    $indexChanged({ index }) {
        this._listIndex = index;
        setSmooth(this.tag('Metadata'), 'y', index === 0 ? 266 : -987, {
            duration: 0.3,
        });
        this.widgets.menu.visible = index === 0;
    }
    _getFocused() {
        return this.tag('Scroller') || this;
    }
}
