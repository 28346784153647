import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { getPlatformName, isXbox, PLATFORM_TYPES } from '../../helpers';
export const CVSDK_PLATFORMS = {
    [PLATFORM_TYPES.XBOX]: 'xbox',
    [PLATFORM_TYPES.XBOXDEV]: 'xboxdev',
    [PLATFORM_TYPES.VIZIO]: 'viziotv',
    [PLATFORM_TYPES.TIZEN]: 'samsungtv',
    [PLATFORM_TYPES.XCLASS]: 'Xclass',
    [PLATFORM_TYPES.KEPLER]: 'firetv-kepler',
};
/**
 * Get the platform name for Core Video
 * @returns {typeof CVSDK_PLATFORMS[keyof typeof CVSDK_PLATFORMS]}
 */
export const getCoreVideoSdkPlatform = () => {
    const { isDrmSecurityElevated } = AppConfigFactorySingleton.config.core_video_sdk;
    // For XBOX lower environments, we need to use the xboxdev device type for DRM
    const isXboxDev = isXbox() && !isDrmSecurityElevated;
    const platform = getPlatformName();
    return (CVSDK_PLATFORMS[isXboxDev ? PLATFORM_TYPES.XBOXDEV : platform] ||
        CVSDK_PLATFORMS[PLATFORM_TYPES.XCLASS]);
};
