import { Language, Lightning, Utils } from '@lightningjs/sdk';
import TextButton from '../../../components/buttons/TextButton';
import { ALIGN_ITEMS, ALIGN_SELF, COLORS, FLEX_DIRECTION, FONT_FACE, JUSTIFY_CONTENT, SCREEN_SIZE, TEXT_ALIGN, WHITE_LABEL_BRANDS, } from '../../../constants';
import { FastImg, getBrandName, topLogoPathMapper } from '../../../helpers';
import { ACTIVATION_LANDING, ACTIVATION_REFERRER } from '../activation/constants';
import LogoRow from '../../LogoRow';
import Benefit from '../../Benefit';
import ModalManager, { ModalTypes } from '../../../lib/ModalManager';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { EVENTS } from '../../../lib/analytics/types';
import { ContentGatingType } from '../../../api/types/bffTypes';
import Announcer from '../../../lib/tts/Announcer';
var ModalName;
(function (ModalName) {
    ModalName["Soft"] = "Free Content Soft Gating Modal";
    ModalName["Hard"] = "Free Content Hard Gating Modal";
})(ModalName || (ModalName = {}));
export default class ContentGatingModal extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._buttons = ['ActivationButton', 'CancelButton'];
        this._focusIndex = 0;
    }
    static _template() {
        const brand = getBrandName();
        const name = WHITE_LABEL_BRANDS[brand].analytics;
        const cancelButtonLabel = Language.translate('maybe_later');
        return {
            rect: true,
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            color: COLORS.dark,
            flex: {
                direction: FLEX_DIRECTION.row,
                justifyContent: JUSTIFY_CONTENT.center,
            },
            Background: {
                flexItem: false,
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                src: Utils.asset(`images/onboarding/bg-${brand}.png`),
            },
            Content: {
                flex: {
                    direction: FLEX_DIRECTION.column,
                    alignItems: ALIGN_ITEMS.center,
                },
                flexItem: {
                    alignSelf: ALIGN_SELF.center,
                },
                w: 936,
                h: 776,
                LogoWrapper: {
                    w: 100,
                    h: 100,
                    flex: {
                        direction: FLEX_DIRECTION.column,
                        justifyContent: JUSTIFY_CONTENT.center,
                    },
                    Image: {
                        flexItem: {
                            alignSelf: ALIGN_SELF.center,
                        },
                        texture: FastImg(topLogoPathMapper(brand)).contain(100, 100),
                    },
                },
                Title: {
                    flexItem: {
                        marginTop: 15,
                    },
                    text: {
                        text: Language.translate('content_gating_title').replace('[Brand]', name),
                        fontSize: 54,
                        lineHeight: 70,
                        textColor: COLORS.white5,
                        textAlign: TEXT_ALIGN.center,
                        fontFace: FONT_FACE.light,
                        letterSpacing: -0.25,
                    },
                },
                Benefits: {
                    h: 276,
                    flex: {
                        direction: FLEX_DIRECTION.column,
                    },
                    flexItem: {
                        marginTop: 15,
                    },
                    Item1: {
                        type: Benefit,
                        image: Utils.asset('images/watch-credits.png'),
                        title: ['onboarding-benefit-1_line1', 'onboarding-benefit-1_line2'].map(Language.translate),
                    },
                    Item2: {
                        flexItem: {
                            marginTop: 24,
                        },
                        type: Benefit,
                        image: Utils.asset('images/cross-platform.png'),
                        title: Language.translate('onboarding-benefit-2'),
                    },
                    Item3: {
                        flexItem: {
                            marginTop: 24,
                        },
                        type: Benefit,
                        image: Utils.asset('images/vote.png'),
                        title: Language.translate('onboarding-benefit-3'),
                    },
                },
                ActivationButton: {
                    w: 420,
                    h: 50,
                    flexItem: {
                        marginTop: 58,
                    },
                    type: TextButton,
                    radius: 26,
                    fontSize: 26,
                    fontFace: FONT_FACE.regular,
                    focusFontColor: COLORS.black,
                    unfocusFontColor: COLORS.warmGray2,
                    focusBackGroundColor: COLORS.white,
                    unfocusBackgroundColor: COLORS.transparent,
                    strokeWidth: 2,
                    strokeColor: COLORS.warmGray2,
                    padding: 34,
                    label: Language.translate('onboarding-sign-in-or-sign-up').toUpperCase(),
                    announce: Language.translate('gen_new_code_tts', Language.translate('onboarding-sign-in-or-sign-up')),
                },
                CancelButton: {
                    w: 420,
                    h: 50,
                    flexItem: {
                        marginTop: 16,
                    },
                    type: TextButton,
                    radius: 26,
                    fontSize: 26,
                    fontFace: FONT_FACE.regular,
                    focusFontColor: COLORS.black,
                    unfocusFontColor: COLORS.warmGray2,
                    focusBackGroundColor: COLORS.white,
                    unfocusBackgroundColor: COLORS.transparent,
                    strokeWidth: 2,
                    strokeColor: COLORS.warmGray2,
                    padding: 34,
                    label: cancelButtonLabel.toUpperCase(),
                    announce: Language.translate('gen_new_code_tts', cancelButtonLabel),
                },
                LogoRow: {
                    flexItem: {
                        marginTop: 61,
                    },
                    type: LogoRow,
                },
            },
        };
    }
    set modalData(data) {
        if (data.resolveCallback)
            this._resolveCallback = data.resolveCallback;
        if (data.rejectCallback)
            this._rejectCallback = data.rejectCallback;
        this.type = data.type;
        this._program = data.program;
        this._stream = data.stream;
        this._referrerType = ACTIVATION_REFERRER.VOD;
    }
    set type(type) {
        this._type = type;
        this._setTranslation();
    }
    _active() {
        sendMetric(EVENTS.MODAL_LOAD, {
            modalName: ModalName[this._type],
            modalType: 'Identity Profile Modal',
        });
        Announcer.announce(this._getAnnouncement());
    }
    _handleUp() {
        if (this._focusIndex > 0) {
            this._focusIndex--;
        }
    }
    _handleDown() {
        if (this._focusIndex < this._buttons.length - 1) {
            this._focusIndex++;
        }
    }
    _reset() {
        this._focusIndex = 0;
    }
    _handleEnter() {
        var _a, _b;
        const type = this._buttons[this._focusIndex];
        switch (type) {
            case 'ActivationButton':
                sendMetric(EVENTS.CLICK, {
                    name: 'Sign In or Sign Up',
                });
                ModalManager.open(ModalTypes.CONTENT_GATING_ACTIVATION, {
                    referrer: ACTIVATION_REFERRER.CONTENT_GATING,
                    landing: ACTIVATION_LANDING.NBC,
                    closeCallback: this._type === ContentGatingType.SOFT ? this._resolveCallback : this._rejectCallback,
                    program: this._program,
                    stream: this._stream,
                    referrerType: this._referrerType,
                });
                this._reset();
                break;
            case 'CancelButton':
                if (this._type === ContentGatingType.SOFT) {
                    (_a = this._resolveCallback) === null || _a === void 0 ? void 0 : _a.call(this);
                }
                else {
                    (_b = this._rejectCallback) === null || _b === void 0 ? void 0 : _b.call(this);
                }
                sendMetric(EVENTS.CLICK, {
                    name: this._type === ContentGatingType.SOFT ? 'Maybe Later' : 'No Thanks',
                });
                ModalManager.close();
                this._reset();
                break;
            default:
                break;
        }
    }
    _handleBack() {
        var _a;
        (_a = this._rejectCallback) === null || _a === void 0 ? void 0 : _a.call(this);
        ModalManager.close();
        this._reset();
    }
    _getFocused() {
        return this.tag(this._buttons[this._focusIndex] || this._buttons[0]);
    }
    _setTranslation() {
        const name = WHITE_LABEL_BRANDS[getBrandName()].analytics;
        this.tag('Content.Title').patch({
            text: {
                text: Language.translate('content_gating_title').replace('[Brand]', name),
            },
        });
        this.tag('Content.Benefits.Item1').patch({
            title: ['onboarding-benefit-1_line1', 'onboarding-benefit-1_line2'].map(Language.translate),
        });
        this.tag('Content.Benefits.Item2').patch({
            title: Language.translate('onboarding-benefit-2'),
        });
        this.tag('Content.Benefits.Item3').patch({
            title: Language.translate('onboarding-benefit-3'),
        });
        this.tag('Content.ActivationButton').patch({
            label: Language.translate('onboarding-sign-in-or-sign-up').toUpperCase(),
        });
        const label = Language.translate(this._type === ContentGatingType.SOFT ? 'maybe_later' : 'onboarding-no-thanks').toUpperCase();
        this.tag('Content.CancelButton').patch({
            label,
            announce: Language.translate('gen_new_code_tts', label),
        });
    }
    _getAnnouncement() {
        return [
            this.tag('Title').text.text,
            ...this.tag('Benefits').children.map((child) => child.title),
        ];
    }
}
