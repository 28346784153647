import { IPlayerEvent } from './IPlayerEvent';
export class TrickPlayImageEvent extends IPlayerEvent {
    constructor(thumbnailVariants) {
        super();
        this._thumbnailVariants = thumbnailVariants;
    }
    get thumbnailVariants() {
        return this._thumbnailVariants;
    }
}
