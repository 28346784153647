import { paginatedComponent, paginatedShelfRequest, videoSectionRequest } from '../../api/requests';
import rsnFilter from '../../graphql/filter/rsnFilter';
import videoSectionMapper from '../../graphql/mappers/videoSection';
import { createRequestConfig } from '../../lib/useRequest';
export const PaginatedShelfRequestConfig = (variables) => createRequestConfig({
    query: paginatedShelfRequest,
    variables,
    reject: [],
});
export const PaginatedComponentRequestConfig = (variables) => createRequestConfig({
    query: paginatedComponent,
    variables,
    reject: [],
});
export const VideoSectionRequestConfig = (variables) => createRequestConfig({
    query: videoSectionRequest,
    variables,
    mapper: (data) => rsnFilter(videoSectionMapper(data)),
});
