import { Registry } from '@lightningjs/sdk';
import BaseCollection from './BaseCollection';
import ItemWrapper from './items/ItemWrapper';
import Bullet from './Bullet';
import { sendMetric } from '../lib/analytics/Analytics';
import { EVENTS } from '../lib/analytics/types';
import Announcer, { AnnouncerEvent } from '../lib/tts/Announcer';
import { SubscriptionBuilder, SubscriptionSources } from '../util/SubscriptionBuilder';
import { getAnalyticsObjectByComponent } from '../helpers';
import { COMPONENT_TYPES } from '../constants';
const CAROUSEL_TIMEOUT = 5000;
// @ts-expect-error TS(2417): Class static side 'typeof Carousel' incorrectly ex... Remove this comment to see the full error message
export default class Carousel extends BaseCollection {
    constructor() {
        super(...arguments);
        this._setAutoPlay = (event = undefined) => {
            if (!event || event.type === AnnouncerEvent.TTS_END) {
                this._clearAutoPlay();
                this._nextSlideTimeout = Registry.setTimeout(() => {
                    this._nextSlideTimeout = null;
                    this._nextSlide();
                }, CAROUSEL_TIMEOUT);
            }
        };
        this._nextSlide = () => {
            if (this._index < this.tag('Items').children.length - 1) {
                this.setIndex(1);
            }
            else {
                this.setIndex(0, true);
            }
        };
    }
    static _template() {
        return {
            Items: {
                forceZIndexContext: true,
                boundsMargin: [500, 100, 500, 100],
            },
            Bullets: {
                y: 759,
                x: 83,
            },
            transitions: {
                alpha: { duration: 0.15, timingFunction: 'cubic-bezier(0.20, 1.00, 0.80, 1.00)' },
            },
        };
    }
    _init() {
        this._index = 0;
        this._stayVisible = false;
        // Basically same as _index however can't/shouldn't be reset.
        this._impressions = 0;
    }
    _active() {
        super._active();
        this.fireAncestors('$hideMenuBackground');
    }
    _inactive() {
        this._clearAutoPlay();
        this.fireAncestors('$showMenuBackground');
    }
    set items(v) {
        this._items = v;
        this._totalSlides = this._items.length;
        this.tag('Items').patch({
            children: this.create({ items: this._items }),
        });
        // Make sure the first item gets sent.
        // Takes a moment for analytics to become avail.
        // Wait 50ms for this to happen.
        const analyticsTimeout = Registry.setTimeout(() => {
            this.totalSlides && this._fireImpressionMetric(this._items[0]);
            Registry.clearTimeout(analyticsTimeout);
        }, 50);
        this._createBullets(this._items.length);
    }
    get totalSlides() {
        return this._totalSlides;
    }
    create({ items }) {
        return items.map((item, index) => {
            return {
                type: ItemWrapper,
                itemType: item.itemType,
                item: item.item,
                alpha: index === 0 ? 1 : 0,
                index,
            };
        });
    }
    _createBullets(amount) {
        new Array(amount).fill({}).forEach((bullet, index) => {
            this._addBullet(index);
        });
    }
    _addBullet(index) {
        const el = this.stage.c({
            type: Bullet,
            x: index * 30,
            scale: index === 0 ? 1 : 0.66,
            alpha: index === 0 ? 1 : 0.4,
        });
        this.tag('Bullets').childList.add(el);
    }
    _handleUp() {
        this._stayVisible = true;
        return false;
    }
    _handleLeft() {
        if (this._index > 0) {
            this.setIndex(-1);
        }
    }
    _handleRight() {
        this._nextSlide();
    }
    _handleBack() {
        this._stayVisible = true;
        this._clearAutoPlay();
        return false;
    }
    setIndex(direction, reset) {
        this._clearAutoPlay();
        this._selectSlide(false);
        this._index = reset ? 0 : (this._index += direction);
        this._selectSlide(true);
        this.totalSlides && this._fireImpressionMetric(this._items[this._index]);
        if (!Announcer.enabled)
            this._setAutoPlay();
    }
    setSlideByIndex(index) {
        this._selectSlide(false);
        this._index = index >= this._totalSlides || index < 0 ? 0 : index;
        this._selectSlide(true);
        this.totalSlides && this._fireImpressionMetric(this._items[this._index]);
        if (!Announcer.enabled)
            this._setAutoPlay();
    }
    _clearAutoPlay() {
        if (this._nextSlideTimeout)
            Registry.clearTimeout(this._nextSlideTimeout);
        this._nextSlideTimeout = null;
    }
    _selectSlide(v) {
        if (this.tag('Bullets').children[this._index]) {
            this.tag('Bullets').children[this._index].setFocus(v);
            this.tag('Items').children[this._index].setSmooth('alpha', v ? 1 : 0);
        }
    }
    _focus() {
        var _a, _b, _c;
        if (this._stayVisible) {
            (_c = (_b = (_a = this.tag('Items')) === null || _a === void 0 ? void 0 : _a.children[this._index]) === null || _b === void 0 ? void 0 : _b.child) === null || _c === void 0 ? void 0 : _c.setInactive(true);
            this._stayVisible = false;
        }
        this.patch({
            smooth: { alpha: 1 },
        });
        this._clearAutoPlay();
        if (Announcer.enabled) {
            this._ttsSubscription = new SubscriptionBuilder()
                .with({
                type: SubscriptionSources.TTS_ANNOUNCER,
                events: [AnnouncerEvent.TTS_END],
                handler: this._setAutoPlay.bind(this),
            })
                .subscribe();
        }
        else {
            this._setAutoPlay();
        }
    }
    _unfocus() {
        var _a, _b, _c, _d;
        if (!this._stayVisible) {
            this.patch({
                smooth: { alpha: 0 },
            });
        }
        else {
            (_c = (_b = (_a = this.tag('Items')) === null || _a === void 0 ? void 0 : _a.children[this._index]) === null || _b === void 0 ? void 0 : _b.child) === null || _c === void 0 ? void 0 : _c.setInactive(false);
        }
        (_d = this._ttsSubscription) === null || _d === void 0 ? void 0 : _d.unsubscribe();
        if (this.attached)
            this._setAutoPlay();
    }
    $firstItemCreated() {
        this._refocus();
    }
    _fireImpressionMetric(slide) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v;
        this._impressions++;
        const params = {
            entity: {
                notificationTitle: (_e = (_d = (_c = (_b = (_a = slide === null || slide === void 0 ? void 0 : slide.item) === null || _a === void 0 ? void 0 : _a.obj) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.cta) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.title,
                analytics: getAnalyticsObjectByComponent(slide === null || slide === void 0 ? void 0 : slide.item),
                dynamicSmartLeadLogic: (_f = slide === null || slide === void 0 ? void 0 : slide.item) === null || _f === void 0 ? void 0 : _f.dynamicSmartLeadLogic,
            },
            shelf: {
                machineName: (_h = (_g = slide === null || slide === void 0 ? void 0 : slide.item) === null || _g === void 0 ? void 0 : _g.shelf) === null || _h === void 0 ? void 0 : _h.machineName,
                tileIndex: ((_k = (_j = slide === null || slide === void 0 ? void 0 : slide.item) === null || _j === void 0 ? void 0 : _j.shelf) === null || _k === void 0 ? void 0 : _k.tileIndex) + 1,
                shelfIndex: 1,
                listTitle: 'Dynamic Lead',
                isUpcoming: (_l = slide === null || slide === void 0 ? void 0 : slide.item) === null || _l === void 0 ? void 0 : _l.isUpcoming,
                isLive: (_m = slide === null || slide === void 0 ? void 0 : slide.item) === null || _m === void 0 ? void 0 : _m.isLive,
            },
            tileParams: ((_o = slide === null || slide === void 0 ? void 0 : slide.item) === null || _o === void 0 ? void 0 : _o.component) === COMPONENT_TYPES.UPCOMING_LIVE_SLIDE
                ? {
                    isUpcoming: (_p = slide === null || slide === void 0 ? void 0 : slide.item) === null || _p === void 0 ? void 0 : _p.isUpcoming,
                    isLive: (_q = slide === null || slide === void 0 ? void 0 : slide.item) === null || _q === void 0 ? void 0 : _q.isLive,
                }
                : {},
        };
        sendMetric(EVENTS.DYNAMIC_LEAD_IMPRESSION, params);
        // For the first item fire shelf impression metric as if it were the first shelf.
        if (this._impressions === 1) {
            const shelfParams = {
                'Content Position': 1,
                'Custom Shelf Position': 1,
                'Custom Shelf Title': (_s = (_r = slide === null || slide === void 0 ? void 0 : slide.item) === null || _r === void 0 ? void 0 : _r.shelf) === null || _s === void 0 ? void 0 : _s.machineName,
                Sponsor: (_v = (_u = (_t = slide === null || slide === void 0 ? void 0 : slide.item) === null || _t === void 0 ? void 0 : _t.obj) === null || _u === void 0 ? void 0 : _u.analytics) === null || _v === void 0 ? void 0 : _v.sponsorName,
            };
            sendMetric(EVENTS.SHELF_IMPRESSION, shelfParams);
            // Send msg so the 2nd shelf (first tray after dl) knows to fire.
            this.fireAncestors('$firstShelfImpression');
        }
    }
}
