import debugAssets from './debugAssets';
const debugSection = () => {
    return {
        component: 'Shelf',
        meta: {
            machineName: 'nbc-best-of-peacock-trailers-collection',
            seoFriendlyName: 'nbc-best-of-peacock-trailers-collection',
        },
        treatments: [],
        __typename: 'Shelf',
        logicName: null,
        deepLinkHandle: null,
        data: {
            instanceID: 'b6ed980b-7aa6-4de3-a3dd-02a267f743d1',
            __typename: 'TileList',
            machineName: 'nbc-best-of-peacock-trailers-collection',
            playlistMachineName: null,
            listTitle: 'Hard coded shelf',
            ariaLabel: 'Hard coded shelf',
            listTitleImage: null,
            sponsorLogo: null,
            sponsorName: null,
            sponsorLogoAltText: null,
            lastModified: null,
            items: debugAssets(),
            moreItems: null,
            viewAllCta: null,
        },
        analytics: {
            isPlaylist: false,
            playlistMachineName: null,
            listTitle: 'Hard coded shelf',
            isSponsoredContent: false,
            sponsorName: null,
            isMixedTiles: false,
            machineName: 'nbc-best-of-peacock-trailers-collection',
            itemsList: [
                'PCKN828805879',
                'PCKN830341796',
                'PCKN206405590',
                'PCKN856069889',
                'PCKN339589633',
                'PCKN340101875',
                'PCKN234693944',
                'PCKN234693941',
                'PCKN826693513',
                'PCKN826181696',
                'PCKN694597906',
                'PCKN852357734',
                'PCKN828869798',
                'PCKN659589921',
                'PCKN121605626',
            ],
            __typename: 'ShelfAnalyticsAttributes',
        },
    };
};
export default debugSection;
