import { createRequestConfig } from '../../lib/useRequest';
import { featuredRequest, mainRequest } from '../../api/requests';
import Variables from '../../graphql/Variables';
export const NetworksLandingRequestConfig = (machineName) => createRequestConfig({
    query: mainRequest,
    variables: Variables.networkLandingPageVariables(machineName),
    reject: [(data) => { var _a; return !(data === null || data === void 0 ? void 0 : data.metadata) || !((_a = data === null || data === void 0 ? void 0 : data.sections) === null || _a === void 0 ? void 0 : _a.length); }],
});
export const NetworksLandingFeaturedRequestConfig = (machineName) => createRequestConfig({
    query: featuredRequest,
    variables: Variables.networkLandingPageVariables(machineName),
    reject: [(data) => !data],
});
