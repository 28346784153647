import { Language } from '@lightningjs/sdk';
import LiveBadge from '../../badges/LiveBadge';
export default (label, positionY) => {
    return (tile) => {
        if (!tile._item.isUpcoming) {
            tile.patch({
                ImageHolder: {
                    LiveBadge: {
                        x: 10,
                        y: positionY,
                        h: 36,
                        fontSize: 25,
                        type: LiveBadge,
                        label: label || Language.translate('live'),
                    },
                },
            });
        }
    };
};
