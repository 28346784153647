import { COLORS } from '../../../constants';
import OlympicMedalBadge from '../../badges/OlympicMedalBadge';
export default (marginTop) => {
    return (item) => {
        var _a;
        if ((_a = item._item) === null || _a === void 0 ? void 0 : _a.isMedalSession) {
            item.olympicsMedalContainer.patch({
                MedalHolder: {
                    flexItem: { marginLeft: 15, marginTop },
                    type: OlympicMedalBadge,
                    color: COLORS.transparent,
                },
            });
        }
    };
};
