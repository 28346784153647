import { Language, Lightning } from '@lightningjs/sdk';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../constants';
/**
 * BaseComponent class that allows us to extend the functionality of the Lightning.Component methods
 */
export default class BaseComponent extends Lightning.Component {
    static _template(override) {
        return {};
    }
    get attributes() {
        return {
            name: MPARTICLE_DEFAULT_ATTR_VALUE,
            type: MPARTICLE_DEFAULT_ATTR_VALUE,
        };
    }
    _setTranslation() { }
    /**
     * Check the language that was used in the init of the component,
     * if the language has changed, execute the _setTranslation method,
     * which replaces the translation elements
     * @protected
     */
    _enable() {
        const currentLang = Language.get();
        if (typeof this._setTranslation === 'function' && this._prevLang !== currentLang) {
            this._prevLang = currentLang;
            this._setTranslation();
        }
    }
    /**
     * Private method meant to be called from inside the component
     */
    _addDecorators(decorators) {
        decorators.forEach((decorator) => {
            decorator(this);
        });
    }
}
