import { Language, Log } from '@lightningjs/sdk';
import moment from 'moment-timezone';
import AuthenticationSingleton from '../../../authentication/Authentication';
import { ALGOLIA_ENTITY_TYPES, transformResources, } from '../../../helpers';
import { getDateFromUnixTimestamp, getUpcomingBadge, MODAL, PROGRAMMING_TYPE } from '../helpers';
import { removeWhiteSpace } from '../../../helpers/string';
const CONTENT_TYPE = {
    singleLiveEvents: 'SLE',
    fullEventReplays: 'REPLAY',
};
var COMPONENT;
(function (COMPONENT) {
    COMPONENT["singleLiveEvents"] = "UpcomingLiveTile";
    COMPONENT["fullEventReplays"] = "ReplayTile";
})(COMPONENT || (COMPONENT = {}));
const mapAsset = (asset) => {
    const { algoliaProperties = {}, brand = {}, event = {}, league = {}, sport = {} } = asset;
    const { title: brandTitle, channelId, colorBrandLogo, whiteBrandLogo, displayTitle, staticPreviewImage, machineName, } = brand;
    const { ariaLabel, customerPlayableDate, endDate, image, labelBadge, labelBadgeEs, pid, title, description, dismissText, countdownDayLabel, countdownHourLabel, countdownMinutesLabel, locked, } = event;
    const startTime = getDateFromUnixTimestamp(event === null || event === void 0 ? void 0 : event.startDate);
    const entityType = algoliaProperties === null || algoliaProperties === void 0 ? void 0 : algoliaProperties.entityType;
    const assetTitle = (league === null || league === void 0 ? void 0 : league.title) || (sport === null || sport === void 0 ? void 0 : sport.title);
    const customerPlayableDateFormatted = getDateFromUnixTimestamp(customerPlayableDate);
    const replayAriaLabel = ariaLabel === null || ariaLabel === void 0 ? void 0 : ariaLabel.replace('[Date]', moment(customerPlayableDateFormatted).format('MMMM Do, YYYY')); // NFL, Los Angeles Rams at Jacksonville Jaguars, Aired on June 10th, 2022
    const upcomingAriaLabel = ariaLabel === null || ariaLabel === void 0 ? void 0 : ariaLabel.replace('[date]', moment(customerPlayableDateFormatted).format('h:mm A dddd MMMM D')); // example: Upcoming on NBC, Live at 2:00 PM Thursday July 21, NFL, Los Angeles Rams at Jacksonville Jaguars
    return {
        component: COMPONENT[entityType],
        analytics: {
            programType: PROGRAMMING_TYPE[entityType],
            title,
            secondaryTitle: assetTitle,
            league: league === null || league === void 0 ? void 0 : league.title,
            sport: sport === null || sport === void 0 ? void 0 : sport.title,
            locked,
            brand: {
                title: brandTitle,
            },
        },
        data: {
            ariaLabel: COMPONENT[entityType] === COMPONENT.fullEventReplays ? replayAriaLabel : upcomingAriaLabel,
            brandDisplayTitle: brandTitle,
            channelId: channelId,
            colorBrandLogo: colorBrandLogo === null || colorBrandLogo === void 0 ? void 0 : colorBrandLogo.path,
            contentType: CONTENT_TYPE[entityType],
            customerPlayableDate: customerPlayableDateFormatted,
            endTime: getDateFromUnixTimestamp(endDate),
            image: image === null || image === void 0 ? void 0 : image.path,
            liveAriaLabel: ariaLabel,
            labelBadge,
            labelBadgeEs,
            liveBadge: Language.translate('live'),
            pid: pid,
            programType: PROGRAMMING_TYPE[entityType],
            secondaryTitle: title,
            shortDescription: description,
            startTime: startTime,
            title: assetTitle,
            upcomingAriaLabel,
            upcomingBadge: getUpcomingBadge(startTime),
            upcomingModal: {
                component: 'UpcomingModal',
                analytics: {
                    modalType: `${PROGRAMMING_TYPE[entityType]} ${MODAL}`,
                    programTitle: title,
                    modalName: Language.translate('continueToLive'),
                    dismissText: dismissText,
                    brand: {
                        title: displayTitle,
                    },
                },
                data: {
                    backgroundImage: staticPreviewImage === null || staticPreviewImage === void 0 ? void 0 : staticPreviewImage.path,
                    channelId: channelId,
                    contentType: CONTENT_TYPE[entityType],
                    countdownDayLabel: countdownDayLabel,
                    countdownHourLabel: countdownHourLabel,
                    countdownMinLabel: countdownMinutesLabel,
                    customerPlayableDate: getDateFromUnixTimestamp(customerPlayableDate),
                    ctaText: Language.translate('continueToLive'),
                    description: `${title} ${Language.translate('willBegin')}`,
                    dismissText: dismissText,
                    lastMinuteModalLifespan: 5,
                    machineName: machineName,
                    pid: pid,
                    resourceId: channelId,
                    startTime: startTime,
                    title: (league === null || league === void 0 ? void 0 : league.title) || (sport === null || sport === void 0 ? void 0 : sport.title),
                },
            },
            whiteBrandLogo: whiteBrandLogo === null || whiteBrandLogo === void 0 ? void 0 : whiteBrandLogo.path,
        },
    };
};
export const assetsMapper = async (assetsData) => {
    var _a;
    if (!(assetsData === null || assetsData === void 0 ? void 0 : assetsData.hits)) {
        return {
            assets: [],
            totalAssets: 0,
            page: 0,
            totalPages: 0,
        };
    }
    const isMvpd = AuthenticationSingleton.isAuthenticated();
    const mvpdData = await AuthenticationSingleton.getMvpdData();
    let resources = [];
    let resourceAuthorization = {};
    if ((_a = mvpdData === null || mvpdData === void 0 ? void 0 : mvpdData.preAuthData) === null || _a === void 0 ? void 0 : _a.resources) {
        resources = mvpdData.preAuthData.resources;
        resourceAuthorization = transformResources(resources);
    }
    Log.info('Preauthorized Resources::', resources, resourceAuthorization);
    const currentDate = moment();
    const filteredAssets = assetsData.hits.filter((asset) => {
        var _a, _b, _c, _d;
        if (!asset)
            return;
        if (asset.entityType === ALGOLIA_ENTITY_TYPES.SLE ||
            ((_a = asset.event) === null || _a === void 0 ? void 0 : _a.programmingType) === PROGRAMMING_TYPE.singleLiveEvents) {
            if (!moment(getDateFromUnixTimestamp((_b = asset.event) === null || _b === void 0 ? void 0 : _b.endDate)).isSameOrAfter(currentDate))
                return;
        }
        // Early exit from the function if mvpd is empty
        if (!isMvpd)
            return asset;
        const resourceId = removeWhiteSpace((_c = asset === null || asset === void 0 ? void 0 : asset.event) === null || _c === void 0 ? void 0 : _c.resourceId);
        Log.info('Resource ID, IsRegional, PreAuth, resourceAuth::', resourceId, resourceAuthorization[resourceId]);
        if (isMvpd && resourceId) {
            return asset;
        }
        else {
            Log.info('Binge Hidden Resource Ids::', (_d = asset === null || asset === void 0 ? void 0 : asset.event) === null || _d === void 0 ? void 0 : _d.resourceId, 'Authorization:');
        }
    });
    return {
        assets: filteredAssets.map(mapAsset),
        totalAssets: assetsData === null || assetsData === void 0 ? void 0 : assetsData.nbHits,
        page: assetsData === null || assetsData === void 0 ? void 0 : assetsData.page,
        totalPages: assetsData === null || assetsData === void 0 ? void 0 : assetsData.nbPages,
    };
};
