import { Language } from '@lightningjs/sdk';
import BaseComponent from '../../base';
import TextButton from '../../buttons/TextButton';
import { COLORS, FONT_FACE, VERTICAL_ALIGN, RATINGS } from '../../../constants';
export default class TVRating extends BaseComponent {
    static _template() {
        return {
            h: 189,
            w: 189,
            rect: true,
            colorTop: COLORS.black,
            colorBottom: COLORS.black,
            TVRating: {
                type: TextButton,
                announce: Language.translate('rating'),
                radius: 0,
                verticalAlign: VERTICAL_ALIGN.middle,
                fontSize: 100,
                fontFace: FONT_FACE.helvetica,
            },
            ContentDescriptors: {
                type: TextButton,
                announce: Language.translate('rating'),
                radius: 0,
                verticalAlign: VERTICAL_ALIGN.middle,
                fontSize: 100,
                fontFace: FONT_FACE.helvetica,
            },
        };
    }
    _init() {
        this._tvRating = this.tag('TVRating');
    }
    setup(program) {
        const rating = program === null || program === void 0 ? void 0 : program.rating;
        if (rating && rating !== RATINGS.NO_RATING) {
            this._tvRating.label = program === null || program === void 0 ? void 0 : program.rating;
            this.visible = true;
        }
        else {
            this.visible = false;
        }
    }
}
