import { useProgress } from '../hooks/useProgress';
/**
 * Controls how the Player Controls are updated
 */
export default class IPlayerControlsStrategy {
    constructor(parent) {
        this._totalTime = 0;
        this._progress = useProgress();
        // Reference to the PlayerControls component
        this.parent = parent;
    }
    init(stream, program) {
        // This should be overridden
    }
    // Should be called on TimeChange events
    update(time) { }
}
