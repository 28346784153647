import TVPlatform from '../tv-platform';
import { PLATFORM_TYPES, splitTextToFitLength } from '../../helpers';
class SpeechController {
    constructor() {
        this._active = false;
        this._voiceOutDisabled = false;
        this._announces = [];
        this._tts = TVPlatform.tts();
    }
    get active() {
        return this._active;
    }
    get voiceOutDisabled() {
        return this._voiceOutDisabled;
    }
    append(toSpeak, notification = false) {
        const seriesArray = this._normalize(Array.isArray(toSpeak) ? toSpeak : [toSpeak]);
        for (const phrase of seriesArray) {
            this._announces.push(this._tts.speak(phrase, notification));
        }
    }
    cancel() {
        this._announces = [];
        this._tts.cancel();
        this._active = false;
        this._voiceOutDisabled = false;
    }
    async speak(series, notification = false) {
        this._active = true;
        this.append(series, notification);
        await this._speakSeries();
    }
    async _speakSeries() {
        while (this._announces.length) {
            const series = this._announces.shift();
            if (!series)
                continue;
            this._voiceOutDisabled = series.notification;
            await series.speak();
        }
        this._voiceOutDisabled = false;
        this._active = false;
    }
    _normalize(phrasesArray) {
        let normalizedPhrasesArray = [];
        for (const string of phrasesArray) {
            const phrase = typeof string === 'function' ? string() : string;
            const result = DEVICE_PLATFORM === PLATFORM_TYPES.XCLASS && phrase.length > 200
                ? splitTextToFitLength(phrase)
                : phrase;
            if (Array.isArray(result)) {
                normalizedPhrasesArray.push(...result);
            }
            else {
                normalizedPhrasesArray.push(result);
            }
        }
        return normalizedPhrasesArray;
    }
}
export default new SpeechController();
