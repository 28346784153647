import { CorePlayerStatusEmitter } from './CorePlayerStatusEmitter';
import { PlayerStatusEvent, TimeChangeEvent } from '../event';
import { CoreVideoAdReporter } from './CoreVideoAdReporter';
export var PlayerEventSetup;
(function (PlayerEventSetup) {
    PlayerEventSetup["ALL"] = "all";
    PlayerEventSetup["MINIMAL"] = "minimal";
})(PlayerEventSetup || (PlayerEventSetup = {}));
export var AvailableEmitters;
(function (AvailableEmitters) {
    AvailableEmitters["CORE_PLAYER_STATUS"] = "corePlayerStatus";
    AvailableEmitters["CORE_AD_REPORTER"] = "coreAdReporter";
})(AvailableEmitters || (AvailableEmitters = {}));
export class PlayerEventEmitterRegistry {
    constructor(events = PlayerEventSetup.ALL) {
        this.emitters = new Map();
        const isMinimal = events === PlayerEventSetup.MINIMAL;
        if (isMinimal) {
            this.emitters.set(AvailableEmitters.CORE_PLAYER_STATUS, new CorePlayerStatusEmitter([PlayerStatusEvent, TimeChangeEvent]));
        }
        else {
            this.emitters.set(AvailableEmitters.CORE_PLAYER_STATUS, new CorePlayerStatusEmitter());
            this.emitters.set(AvailableEmitters.CORE_AD_REPORTER, new CoreVideoAdReporter());
        }
    }
    getEmitter(emitterName) {
        return this.emitters.get(emitterName);
    }
    attach(player, events) {
        this.emitters.forEach((emitter) => emitter.attach(player, events));
    }
    detach() {
        this.emitters.forEach((emitter) => emitter.detach());
        this.emitters.clear();
    }
}
