import { Router } from '@lightningjs/sdk';
import SLEPlayerControlsV2 from '../../components/player/PlayerControls/SLEPlayerControlsV2';
import BaseEventsPlayer from './BaseEventsPlayer';
import { LinearSLEAnalyticsDelegate } from './delegates/analytics/LinearSLEAnalyticsDelegate';
import { WithTempPass } from './hoc/WithTempPass';
import { WithEndCardSLE } from './hoc/WithEndCard/WithEndCardSLE';
import { AD_TYPES, CONTENT_TYPE } from '../../constants';
import { WithEpg } from './hoc/WithEpg';
import { WithBackToLive } from './hoc/WithBackToLive';
import { useProgress } from '../../components/player/PlayerControls/hooks/useProgress';
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import SLEPlayerControls from '../../components/player/PlayerControls/SLEPlayerControls';
class SLEPlayer extends BaseEventsPlayer {
    constructor() {
        super(...arguments);
        this._log_tag = 'SLE Player';
        this._stream_type = CONTENT_TYPE.SLE;
        this._progress = useProgress();
    }
    static _template() {
        const isNewPlayerDesign = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.newPlayerTest);
        if (isNewPlayerDesign) {
            return super._template({
                Controls: {
                    type: SLEPlayerControlsV2,
                },
            });
        }
        else {
            return super._template({
                Controls: {
                    type: SLEPlayerControls,
                },
            });
        }
    }
    set params(params) {
        this._params = params;
        this.pid = params === null || params === void 0 ? void 0 : params.pid;
    }
    async _init() {
        super._init();
        this._analyticsDelegate = new LinearSLEAnalyticsDelegate(this);
    }
    _detach() {
        super._detach();
    }
    _onStreamEnd() {
        Router.back();
    }
    _onAdStart(event) {
        if (event.adBreak.type === AD_TYPES.PREROLL) {
            this._updateAdIndicator(event.ad.index, event.adBreak.ads.length);
        }
        else {
            this._hideAdIndicator();
        }
    }
    loadEpg() { }
    loadStream() { }
}
export default WithBackToLive(WithEndCardSLE(WithEpg(WithTempPass(SLEPlayer))));
