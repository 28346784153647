import { Registry } from '@lightningjs/sdk';
import { switchState, setSmooth } from '../../../helpers';
import { PlayerStates } from '../../../constants';
export const PlayerControlsStateFactory = (base) => class Controls extends base {
    $enter(_, args) {
        this._switchStateWithDelay(args === null || args === void 0 ? void 0 : args.timeoutLength);
        setSmooth(this._controls, 'alpha', 1);
        setSmooth(this._captions, 'y', 700);
        if (this._skipButton)
            setSmooth(this._skipButton, 'y', -200);
    }
    $exit() {
        this._clearTimeOut();
        if (this._skipButton && this._skipButton.alpha > 0)
            return;
        setSmooth(this._controls, 'alpha', 0);
        setSmooth(this._captions, 'y', 855);
        if (this._skipButton)
            setSmooth(this._skipButton, 'y', 0);
    }
    $toggleQOSPanel() {
        setSmooth(this._qosPanel, 'alpha', !this._qosPanel.alpha);
    }
    $showMediaOptionsMenu() {
        var _a, _b;
        (_a = this._mediaOptionsPanel) === null || _a === void 0 ? void 0 : _a._createLanguageList();
        this._setState(PlayerStates.LPPlayer_MediaOptions);
        (_b = this._analyticsDelegate) === null || _b === void 0 ? void 0 : _b.fireClick('Closed Captions');
    }
    $startSeeking() {
        this._clearTimeOut();
        this._setState(PlayerStates.LPPlayer_Controls_Seeking);
    }
    $endSeeking() {
        this._setState(PlayerStates.LPPlayer_Controls);
        this._switchStateWithDelay(5000, true);
    }
    _switchToPlayerState() {
        this._clearTimeOut();
        switchState(this, [
            { state: PlayerStates.LPPlayer_LiveToVod, if: () => { var _a; return (_a = this._showLiveToVodFixed) === null || _a === void 0 ? void 0 : _a.call(this); } },
            { state: PlayerStates.LPPlayer_SkipButton, if: () => { var _a; return (_a = this._showSkipButton) === null || _a === void 0 ? void 0 : _a.call(this); } },
            {
                state: PlayerStates.LPPlayer_TempPass,
                if: () => { var _a; return ((_a = this._tempPassPlayerOverlay) === null || _a === void 0 ? void 0 : _a.remaining) && this._tempPassPlayerOverlay.remaining <= 30; },
            },
            { state: PlayerStates.LPPlayer, if: () => true },
        ]);
    }
    _switchStateWithDelay(timeoutLength = 5000, bypassPauseAds = false) {
        this._clearTimeOut();
        this._timeout = Registry.setTimeout(() => {
            var _a, _b, _c, _d;
            !bypassPauseAds && ((_b = (_a = this._controls).shouldShowPauseAds) === null || _b === void 0 ? void 0 : _b.call(_a))
                ? (_d = (_c = this._controls).setShowPauseAds) === null || _d === void 0 ? void 0 : _d.call(_c)
                : this._switchToPlayerState();
        }, timeoutLength);
    }
    _clearTimeOut() {
        if (this._timeout) {
            Registry.clearTimeout(this._timeout);
            this._timeout = null;
        }
    }
    _captureBack() {
        var _a, _b;
        (_b = (_a = this._controls) === null || _a === void 0 ? void 0 : _a._captureBack) === null || _b === void 0 ? void 0 : _b.call(_a);
        this._switchToPlayerState();
        return true;
    }
    _captureKey() {
        this._switchStateWithDelay();
        return false;
    }
    _getFocused() {
        return this._controls || this;
    }
    static _states() {
        return [
            class Seeking extends this {
                _captureKey() {
                    return false;
                }
            },
        ];
    }
};
