import { PlatformSubscriptionType } from './tv-platform/base';
import { AppLaunchTypes } from './analytics/types';
import { getAppLaunchType, setAppLaunchType } from '../helpers';
import { SubscriptionBuilder, SubscriptionSources } from '../util/SubscriptionBuilder';
import { Router } from '@lightningjs/sdk';
import { ROUTE } from '../constants';
import { isPathSkippable } from '../routerConfig';
class AppState {
    constructor() {
        this.onBackground = () => {
            var _a;
            this._lastPlayerTime = undefined;
            const page = Router.getActivePage();
            if (page._player) {
                this._lastPlayerTime = page === null || page === void 0 ? void 0 : page.currentTime;
                (_a = page === null || page === void 0 ? void 0 : page._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireVideoEnd({
                    endType: 'Background',
                });
            }
            setAppLaunchType(AppLaunchTypes.background);
            const hash = Router.getActiveHash();
            if (hash === ROUTE.boot || hash === ROUTE.home)
                return; // Avoid infinite loop at app startup
            const returnPath = !hash || isPathSkippable(hash) ? ROUTE.home : hash;
            Router.navigate(ROUTE.splash, { returnPath });
        };
        this.checkPendingEvents = () => {
            var _a;
            if (this._lastPlayerTime === undefined)
                return;
            const page = Router.getActivePage();
            (_a = page === null || page === void 0 ? void 0 : page._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireVideoStart({
                resumeTime: this._lastPlayerTime,
                launchType: getAppLaunchType(),
            });
            this._lastPlayerTime = undefined;
        };
    }
    init() {
        this._subscription = new SubscriptionBuilder()
            .with({
            type: SubscriptionSources.TV_PLATFORM,
            event: PlatformSubscriptionType.BACKGROUND,
            handler: this.onBackground,
        })
            .subscribe();
    }
}
export const AppStateManager = new AppState();
