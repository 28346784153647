import { GraphQlClient } from '../../graphql/client';
import searchFeaturedMapper from '../../graphql/mappers/searchFeatured';
import rsnFilter from '../../graphql/filter/rsnFilter';
import { QueryName } from './bffQuery';
export default async (variables, options) => {
    return rsnFilter(searchFeaturedMapper(await GraphQlClient.query({
        query: QueryName.featuredShowsSection,
        variables,
    }, options)));
};
