import { Language, Lightning } from '@lightningjs/sdk';
import SpinnerLoader from '../../../components/loader/SpinnerLoader';
import { addBaseTitleContainer, addSFVODTitleContainer, getSubtitleForLive, getTitles, } from '../../../components/player/helpers/metadata';
import { checkAddClipToVideoTitles, FastImg } from '../../../helpers';
import { COLORS, CONTENT_TYPE, FONT_FACE, PROGRAMMING_TYPES, SCREEN_SIZE, VERTICAL_ALIGN, } from '../../../constants';
import { OLY_IMPOLICY } from '../../../constants';
import ModalManager, { ModalTypes } from '../../../lib/ModalManager';
import { PLAYER_TYPE, PlayerFactorySingleton } from '../../../player/core/PlayerFactory';
export const openPlayerLoader = (stream, program, buffering = false) => {
    var _a;
    if (ModalManager._activeModal && ModalManager._activeModal === ModalTypes.UPCOMING)
        ModalManager.close();
    if (ModalManager._activeModal && ModalManager._activeModal !== ModalTypes.PLAYER_LOADER)
        return;
    const isBuffering = buffering || ((_a = PlayerFactorySingleton.player(PLAYER_TYPE.MAIN)) === null || _a === void 0 ? void 0 : _a.isBuffering);
    const dataModal = {
        data: {
            program: program,
            stream: stream,
        },
        loaderText: Language.translate(isBuffering ? 'buffering' : 'loading'),
    };
    if (ModalManager._activeModal)
        ModalManager.update(ModalTypes.PLAYER_LOADER, dataModal);
    else
        ModalManager.open(ModalTypes.PLAYER_LOADER, dataModal);
};
export default class PlayerLoader extends Lightning.Component {
    static _template() {
        return {
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            rect: true,
            color: COLORS.black,
            Image: {
                alpha: 0.6,
            },
            ImageOverLay: {
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                rect: true,
                colorTop: COLORS.transparent,
                colorLeft: COLORS.transparent,
                colorRight: COLORS.black1,
                colorBottom: COLORS.black2,
            },
            Loader: {
                x: (w) => w / 2,
                y: (h) => h / 2,
                mount: 0.5,
                type: SpinnerLoader,
            },
            TitleContainer: {
                x: 115,
                y: 898,
                Title: {
                    text: {
                        textColor: COLORS.white,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        fontSize: 58,
                        fontFace: FONT_FACE.light,
                        maxLines: 1,
                        wordWrapWidth: 1750,
                    },
                },
                Clip: {
                    flex: {},
                    flexItem: { marginTop: 4 },
                    y: 76,
                    Icon: {},
                },
                SecondaryTitle: {
                    y: 76,
                    text: {
                        textColor: COLORS.white,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        fontSize: 26,
                        fontFace: FONT_FACE.regular,
                        maxLines: 1,
                        wordWrapWidth: 1534,
                    },
                },
            },
        };
    }
    applyData(title, secondaryTitle, data) {
        const titlesData = {
            Image: {
                texture: FastImg(data.image).contain(SCREEN_SIZE.width, SCREEN_SIZE.height, OLY_IMPOLICY.RECT_LARGE_1920_1080),
            },
            TitleContainer: (data.programmingType === PROGRAMMING_TYPES.SFVOD
                ? addSFVODTitleContainer
                : addBaseTitleContainer)({ title, secondaryTitle }, data),
        };
        this.patch(checkAddClipToVideoTitles(data, titlesData));
    }
    clear() {
        this.patch({
            Image: {
                texture: null,
            },
            TitleContainer: {
                Logo: undefined,
                Title: {
                    text: {
                        text: '',
                    },
                },
                SecondaryTitle: {
                    text: {
                        text: '',
                    },
                },
                Description: {
                    text: {
                        text: '',
                    },
                },
                Clip: {
                    Icon: undefined,
                },
            },
        });
    }
    set modalData(data) {
        this.clear();
        Object.keys(data).forEach((key) => {
            // @ts-expect-error Assigning values programatically
            this[key] = data[key];
        });
    }
    set loaderText(loaderText) {
        this.tag('Loader').loaderText = loaderText;
    }
    set data(data) {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!data)
            return;
        this.clear();
        let title;
        let secondaryTitle;
        if (((_a = data.stream) === null || _a === void 0 ? void 0 : _a.contentType) === CONTENT_TYPE.VOD) {
            const vodTitles = getTitles(data.stream);
            title = vodTitles.title;
            secondaryTitle = vodTitles.secondaryTitle;
        }
        else if (((_b = data.stream) === null || _b === void 0 ? void 0 : _b.programmingType) === PROGRAMMING_TYPES.FER) {
            title = ((_c = data.stream) === null || _c === void 0 ? void 0 : _c.headerTitle) || Language.translate('program_unavailable');
            secondaryTitle = data.stream.title || '';
        }
        else if (((_d = data.stream) === null || _d === void 0 ? void 0 : _d.programmingType) === PROGRAMMING_TYPES.SLE) {
            title = data.stream.title || Language.translate('program_unavailable');
            secondaryTitle = ((_e = data.program) === null || _e === void 0 ? void 0 : _e.league) || ((_f = data.program) === null || _f === void 0 ? void 0 : _f.sport) || '';
        }
        else {
            title =
                ((_g = data.stream) === null || _g === void 0 ? void 0 : _g.title) ||
                    ((_h = data.program) === null || _h === void 0 ? void 0 : _h.programTitle) ||
                    Language.translate('program_unavailable');
            secondaryTitle = getSubtitleForLive(data.program, data.stream) || '';
        }
        if (data.stream)
            this.applyData(title || '', secondaryTitle || '', data.stream);
    }
}
