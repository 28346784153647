import List from './List';
import { COLORS, FONT_FACE } from '../constants';
export default class BrandsList extends List {
    constructor() {
        super(...arguments);
        this._activeIndex = 0;
    }
    _init() {
        super._init();
        this.patch({
            TitleHolder: {
                Title: {
                    alpha: 1,
                    text: {
                        fontFace: FONT_FACE.light,
                        fontSize: 35,
                        textColor: COLORS.warmGray,
                    },
                },
            },
            Items: {
                y: 60,
            },
        });
    }
    set activeIndex(activeIndex) {
        this._activeIndex = activeIndex;
    }
    _handleEnter() {
        if (this._activeIndex !== this._index) {
            this.setChildPropsForIndex(this._activeIndex, { active: false });
            this._activeIndex = this._index;
            this.setChildPropsForIndex(this._activeIndex, { active: true });
        }
        return false;
    }
}
