import Container from '../models/Container';
import { lowercaseFirst } from '../../helpers';
import BrandsList from '../../components/BrandsList';
import { createItems } from '../../components/componentMaps';
import { Language } from '@lightningjs/sdk';
export default class BrandSelectable {
    constructor(sections) {
        this._container = new Container(sections[0]);
    }
    createBrandLabelsList(stage, brand) {
        const data = this._container.data;
        let initiallySelected = data.initiallySelected;
        if (brand) {
            const brandIndex = data.brandItemLabels.findIndex(({ machineName }) => machineName.replace('-', '') === brand // nbcsports comes as nbc-sports from backend
            );
            if (brandIndex > -1) {
                initiallySelected = brandIndex;
                this._container.data.obj.initiallySelected = brandIndex;
            }
        }
        return stage.c({
            type: BrandsList,
            tag: `${lowercaseFirst(data.brandItemLabels[0].component)}`,
            items: createItems(data.brandItemLabels, initiallySelected),
            title: Language.translate('networks'),
            activeIndex: initiallySelected,
        });
    }
    get initiallySelected() {
        return this._container.data.initiallySelected;
    }
    getBrandData(index) {
        return this._container.data.items[index];
    }
    getBrandLabel(index) {
        var _a, _b;
        return (_b = (_a = this._container.data.brandItemLabels[index]) === null || _a === void 0 ? void 0 : _a.analytics) === null || _b === void 0 ? void 0 : _b.displayTitle;
    }
}
