import { Language, Lightning, Router, Utils } from '@lightningjs/sdk';
import SettingsButton from '../../components/buttons/SettingsButton';
import { ClosedCaptionsUtils } from '../../lib/ClosedCaptions/ClosedCaptionsUtils';
import { COLORS, FONT_FACE, ROUTE } from '../../constants';
export default class Settings extends Lightning.Component {
    static _template() {
        return {
            x: 80,
            y: 200,
            w: 1760,
            h: 810,
            rect: true,
            color: COLORS.dark5,
            PageTitleHolder: {
                x: 100,
                y: 78,
                Icon: {
                    h: 40,
                    w: 40,
                    src: Utils.asset('images/settings/settings.png'),
                },
                Title: {
                    x: 60,
                    text: {
                        text: Language.translate('settings'),
                        fontSize: 30,
                    },
                },
            },
            Content: {
                y: 178,
                x: 233,
                LanguageButton: {
                    y: 10,
                    w: 740,
                    h: 70,
                    type: SettingsButton,
                    radius: 0,
                    fontSize: 30,
                    fontFace: FONT_FACE.light,
                    focusFontColor: COLORS.dark,
                    unfocusFontColor: COLORS.white,
                    focusBackGroundColor: COLORS.lightGray3,
                    unfocusBackgroundColor: COLORS.black3,
                    label: Language.translate('app_language'),
                    padding: 0,
                    icon: 'images/settings/line.png',
                    value: Language.translate('english'),
                },
                CCButton: {
                    y: 90,
                    w: 740,
                    h: 70,
                    type: SettingsButton,
                    radius: 0,
                    fontSize: 30,
                    fontFace: FONT_FACE.light,
                    focusFontColor: COLORS.dark,
                    unfocusFontColor: COLORS.white,
                    focusBackGroundColor: COLORS.lightGray3,
                    unfocusBackgroundColor: COLORS.black3,
                    label: Language.translate('closed_captioning'),
                    padding: 0,
                    icon: 'images/settings/line.png',
                    value: Language.translate('off'),
                },
            },
        };
    }
    _init() {
        this.stage.setClearColor(COLORS.dark);
    }
    _active() {
        this.widgets.menu.collapse({
            subCategoryItem: Language.translate('settings'),
            hideProfile: true,
        });
        this._setState('LanguageButton');
        this.tag('CCButton').value = ClosedCaptionsUtils.getCCTypeLabel(ClosedCaptionsUtils.getCCType());
    }
    _inactive() {
        this.widgets.menu.expand();
    }
    static _states() {
        return [
            class LanguageButton extends this {
                _getFocused() {
                    return this.tag('LanguageButton') || this;
                }
                _handleDown() {
                    this._setState('CCButton');
                }
                _handleEnter() {
                    Router.navigate(ROUTE.language);
                }
            },
            class CCButton extends this {
                _getFocused() {
                    return this.tag('CCButton') || this;
                }
                _handleUp() {
                    this._setState('LanguageButton');
                }
                _handleEnter() {
                    Router.navigate(ROUTE.closedCaptioning);
                }
            },
        ];
    }
}
