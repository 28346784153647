export const isObject = (v) => {
    return typeof v === 'object' && v !== null;
};
export const arrayMergeSplit = (array, num) => {
    const ret = [];
    for (let i = 0; i < array.length; i += num) {
        ret.push(array.slice(i, i + num).join('\n'));
    }
    return ret;
};
