import Collection from './Collection';
import { EventTile } from '../index';
export default class EventSchedule extends Collection {
    constructor(obj = {}) {
        super(obj);
        this.obj.events = this.mapEvents(this.obj.events);
    }
    mapEvents(events) {
        return events.map((events) => {
            return new EventTile(events);
        });
    }
    get events() {
        return this.obj.events;
    }
}
