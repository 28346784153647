import { GraphQlClient } from '../../graphql/client';
import brandTitleCategoriesMapper from '../../graphql/mappers/brandTitleCategories';
import rsnFilter from '../../graphql/filter/rsnFilter';
import { QueryName } from './bffQuery';
export default async (variables) => {
    return await GraphQlClient.query({
        query: QueryName.brandTitleCategories,
        variables,
    })
        .then(brandTitleCategoriesMapper)
        .then(rsnFilter);
};
