import { PlayerStates } from '../../../constants';
export const PlayerMediaOptionsStateFactory = (base) => class MediaOptions extends base {
    $enter() {
        this._mediaOptionsPanel._showMediaOptionsMenu(true);
    }
    $exit() {
        this._mediaOptionsPanel._showMediaOptionsMenu(false);
    }
    _getFocused() {
        return this._mediaOptionsPanel || this;
    }
    _captureKey() {
        return false;
    }
    $ccLanguageUpdated({ value }) {
        var _a, _b, _c, _d;
        if (value === 'off') {
            (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireClick('Closed Caption Off');
        }
        else {
            (_b = this._analyticsDelegate) === null || _b === void 0 ? void 0 : _b.fireClick('Closed Caption On');
        }
        (_d = (_c = this._player) === null || _c === void 0 ? void 0 : _c.enableSubtitles) === null || _d === void 0 ? void 0 : _d.call(_c, value);
        this._captions._ccLanguageUpdated(value);
    }
    $ccSettingUpdated({ ccSettingItem, selectedValue }) {
        this._captions._ccSettingUpdated(ccSettingItem, selectedValue);
    }
    $resetCCSettings() {
        this._captions.updateUI();
    }
    _captureBack(e) {
        if (!this._mediaOptionsPanel._handleBackButton()) {
            this._setState(PlayerStates.LPPlayer_Controls);
        }
        e.preventDefault();
        e.stopPropagation();
    }
};
