import Main from '../Main/Main';
import { OlympicsHubFeaturedRequestConfig, OlympicsHubRequestConfig } from './request';
export default class OlympicsHub extends Main {
    constructor() {
        super(...arguments);
        this.mainRequestConfig = OlympicsHubRequestConfig;
        this.featuredRequestConfig = OlympicsHubFeaturedRequestConfig;
    }
    $getLoadAnnounce() {
        return 'All Sports';
    }
}
