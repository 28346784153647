import moment from 'moment-timezone';
import { StorageFactorySingleton } from '../../util/storage/StorageFactory';
export const FIRST_RUN_LEGACY_STR = 'mparticle.firstRun';
/**
 * Save to localStorage the date of the app's first launch on this device.
 *
 * @example
 * saveFirstRun()
 * // sets and returns "2018-12-17T10:30:24-06:00"
 *
 * @return {string}
 */
export const saveFirstRun = (date = '') => {
    let runDate = date;
    if (!moment(runDate).isValid())
        runDate = moment().format();
    StorageFactorySingleton.get().set(FIRST_RUN_LEGACY_STR, runDate);
    return runDate;
};
/**
 * Get the date of the app's first launch on this device,
 * according to localStorage.
 * Returns `null` if 'mparticle.firstRun' isn't set.
 *
 * @param {object} setIfUnset - if the first run date is not in localStorage,
 * then set it
 *
 * @example
 * getFirstRun({ setIfUnset: false })
 * // returns "2018-12-17T10:30:24-06:00"
 *
 * @return {string|null}
 */
export const getFirstRun = () => StorageFactorySingleton.get().get(FIRST_RUN_LEGACY_STR);
