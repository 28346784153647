import { Router } from '@lightningjs/sdk';
import { formatDate, isAClip, getBrandName } from '../../../helpers';
import Tile from './Tile';
import { BRAND, ROUTE } from '../../../constants';
export default class Slide extends Tile {
    get titleLogo() {
        return this.data.titleLogo;
    }
    get image() {
        return this.data.image;
    }
    get whiteBrandLogo() {
        return this.data.whiteBrandLogo;
    }
    get tuneIn() {
        return this.data.tuneIn;
    }
    get labelBadge() {
        return this.data.labelBadge;
    }
    get videoTitle() {
        return this.data.videoTitle;
    }
    get secondaryTitle() {
        return this.data.secondaryTitle;
    }
    get buttonText() {
        var _a, _b;
        return (_b = (_a = this.data.cta) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.text;
    }
    get isLive() {
        var _a, _b;
        return (_b = (_a = this.data.cta) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.isLive;
    }
    get destinationType() {
        var _a, _b;
        return (_b = (_a = this.data.cta) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.destinationType;
    }
    get destination() {
        var _a, _b;
        return (_b = (_a = this.data.cta) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.destination;
    }
    get playlistMachineName() {
        var _a, _b;
        return (_b = (_a = this.data.cta) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.playlistMachineName;
    }
    get urlAlias() {
        var _a, _b;
        return (_b = (_a = this.data.cta) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.urlAlias;
    }
    get buttonColor() {
        var _a;
        const currRoute = Router.getActiveRoute();
        const isOlyPage = [ROUTE.olympics, ROUTE.paralympics].includes(currRoute);
        const isHomePage = [ROUTE.home, ROUTE.boot].includes(currRoute);
        const isNBCSportsBrand = getBrandName() === BRAND.nbcsports;
        const isOlympicsSlide = (_a = this === null || this === void 0 ? void 0 : this.data) === null || _a === void 0 ? void 0 : _a.isOlympics;
        return isOlympicsSlide && (isOlyPage || (isNBCSportsBrand && isHomePage))
            ? this.olyButtonColor
            : this.regularButtonColor;
    }
    get regularButtonColor() {
        return this.data.gradientStart ? this.data.gradientStart.replace('#', '') : '#B78623';
    }
    get olyButtonColor() {
        var _a, _b;
        return ((_b = (_a = this.data.cta) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.color) || '#B4A074';
    }
    get isLocked() {
        return this.data.locked;
    }
    get showClipData() {
        return isAClip(this.data.programmingType);
    }
    get programmingType() {
        return this.data.programmingType;
    }
    get showMovieData() {
        return this.data.programmingType === 'Movie';
    }
    get rating() {
        return this.data.rating;
    }
    get date() {
        return formatDate(this.data.airDate);
    }
    get dynamicSmartLeadLogic() {
        var _a, _b, _c;
        return ((_a = this === null || this === void 0 ? void 0 : this.analytics) === null || _a === void 0 ? void 0 : _a.dynamicallyGenerated) === false
            ? 'None'
            : (_c = (_b = this === null || this === void 0 ? void 0 : this.meta) === null || _b === void 0 ? void 0 : _b.dynamicSmartLead) === null || _c === void 0 ? void 0 : _c.logic;
    }
    get ctaAnalytics() {
        var _a, _b;
        return (_b = (_a = this === null || this === void 0 ? void 0 : this.data) === null || _a === void 0 ? void 0 : _a.cta) === null || _b === void 0 ? void 0 : _b.analytics;
    }
    get isSportsVideoSlide() {
        var _a, _b;
        return (_b = (_a = this.data.cta) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.isSportsVideoSlide;
    }
    get sponsorName() {
        return this.data.sponsorName;
    }
    get sponsorLogo() {
        return this.data.sponsorLogo;
    }
    get sponsorLogoAltText() {
        return this.data.sponsorLogoAltText;
    }
    get tertiaryTitle() {
        return this.data.tertiaryTitle;
    }
    get description() {
        return this.data.description;
    }
    get pid() {
        var _a, _b, _c;
        return this.data.pid || ((_c = (_b = (_a = this === null || this === void 0 ? void 0 : this.data) === null || _a === void 0 ? void 0 : _a.cta) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.pid);
    }
    get contentType() {
        var _a, _b, _c;
        return this.data.contentType || ((_c = (_b = (_a = this === null || this === void 0 ? void 0 : this.data) === null || _a === void 0 ? void 0 : _a.cta) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.contentType);
    }
    get isMedalSession() {
        return this.data.isMedalSession;
    }
    get isOlympics() {
        return this.data.isOlympics;
    }
    get language() {
        return this.data.language;
    }
}
