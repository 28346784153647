import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly';
import { EndCardAlternate } from '../index';
import { END_CARD_SLE_AUTOPLAY_TIME, BASE_FPS, CONTENT_TYPE } from '../../constants';
export default class EndCardAlternateSLE extends EndCardAlternate {
    constructor() {
        super(...arguments);
        this._isSLE = true;
        this._countdownInterval = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.sleBingeTimer) ||
            END_CARD_SLE_AUTOPLAY_TIME;
        this._fps = BASE_FPS / this._countdownInterval;
        this._animEndDuration = this._countdownInterval * this._fps;
    }
    static _template() {
        const template = super._template();
        return {
            ...template,
            mount: 0.5,
        };
    }
    _focusAnimation(v) {
        super._focusAnimation(v);
        this.patch({
            smooth: {
                scale: v ? 1.03 : 1,
            },
        });
    }
    get announce() {
        var _a, _b;
        const announce = [
            `Watch next in ${this._countdownInterval} seconds`,
            `${this._item.analytics.title}, ${this._item.analytics.sport}`,
        ];
        announce.push(this._item.contentType === CONTENT_TYPE.SLE
            ? `LIVE on ${this._item.analytics.brand.title}`
            : this._item.tertiaryTitle);
        if (((_a = this.parent) === null || _a === void 0 ? void 0 : _a.parent) && ((_b = this.parent.parent.children) === null || _b === void 0 ? void 0 : _b.length) > 1) {
            announce.push(`On item ${this._index + 1} of ${this.parent.parent.children.length} in the row of available content`);
        }
        return announce;
    }
}
