import { Utils } from '@lightningjs/sdk';
import Item from './Item';
import { COLORS, FLEX_DIRECTION, FONT_FACE, TEXT_ALIGN } from '../../constants';
export default class SettingsTile extends Item {
    _init() {
        super._init();
        this.containerHeight = 810;
        this.containerWidth = 460;
    }
    static _template() {
        const template = super._template();
        return {
            ...template,
            Holder: {
                rect: true,
                color: COLORS.dark5,
                alpha: 0.5,
                h: 810,
                w: 460,
                Content: {
                    y: 287,
                    w: 460,
                    flex: { direction: FLEX_DIRECTION.column },
                    ImageHolder: {
                        x: 190,
                        Image: {},
                    },
                    Title: {
                        mountX: 0.5,
                        x: 230,
                        flexItem: { marginTop: 25 },
                        text: {
                            fontFace: FONT_FACE.regular,
                            fontSize: 40,
                            lineHeight: 40,
                            maxLines: 2,
                            wordWrapWidth: 398,
                            textAlign: TEXT_ALIGN.center,
                        },
                    },
                    Description: {
                        mountX: 0.5,
                        x: 230,
                        flexItem: { marginTop: -5 },
                        text: {
                            fontFace: FONT_FACE.light,
                            fontSize: 30,
                            lineHeight: 35,
                            wordWrapWidth: 398,
                            textAlign: TEXT_ALIGN.center,
                        },
                    },
                },
            },
        };
    }
    set item(v) {
        this._item = v;
        this.patch({
            Holder: {
                Content: {
                    ImageHolder: {
                        w: 80,
                        h: 80,
                        Image: {
                            src: Utils.asset(v.icon),
                        },
                    },
                    Title: {
                        text: { text: v.title },
                    },
                    Description: {
                        text: { text: v.description },
                    },
                },
            },
        });
        this.announce = [this._item.title, this._item.description];
    }
    _handleEnter() {
        return false;
    }
    _focusAnimation(v) {
        super._focusAnimation(v);
        this.tag('Holder').setSmooth('alpha', v ? 1 : 0.5);
    }
    static get tag() {
        return 'SettingsTile';
    }
}
