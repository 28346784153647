export default (tile) => {
    if (tile.showTertiaryTitle) {
        tile.patch({
            Description: {
                text: {
                    text: tile.description,
                },
            },
            SubTitle: {
                y: 73,
                text: {
                    maxLines: 1,
                },
            },
            TertiaryTitle: {
                y: 116,
                text: {
                    text: tile.tertiaryTitle,
                },
            },
        });
    }
};
