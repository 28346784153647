import { checkContentGating } from '../../../../helpers';
import { StreamLoaderErrors } from '../error';
import { TOKEN_TYPE } from '../../../../constants';
export const contentGating = async (request, stream, program, _) => {
    try {
        const wasContentGatingShown = await checkContentGating(stream, program);
        if (wasContentGatingShown)
            request.tokenType = TOKEN_TYPE.FREE_CONTENT_GATING;
        return request;
    }
    catch (_) {
        request.error = {
            handled: true,
            detail: StreamLoaderErrors.USER_CANCELLED,
            data: undefined,
        };
        throw request;
    }
};
