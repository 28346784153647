import { DebugControllerSingleton } from '../../util/debug/DebugController';
import debugSection from '../debug/debugSection';
const bffPageMapper = (page) => {
    var _a, _b;
    const bonanzaPage = (_a = page.data) === null || _a === void 0 ? void 0 : _a.bonanzaPage;
    const data = bonanzaPage === null || bonanzaPage === void 0 ? void 0 : bonanzaPage.data;
    if (!data)
        return null;
    if (DebugControllerSingleton.hardCodedShelfEnabled) {
        addDebugSection(data.sections);
    }
    return {
        analytics: bonanzaPage === null || bonanzaPage === void 0 ? void 0 : bonanzaPage.analytics,
        featured: data.featured,
        sections: data.sections,
        metadata: bonanzaPage === null || bonanzaPage === void 0 ? void 0 : bonanzaPage.metadata,
        id: bonanzaPage === null || bonanzaPage === void 0 ? void 0 : bonanzaPage.id,
        ldStateFlag: (_b = page.data) === null || _b === void 0 ? void 0 : _b.ldStateFlag,
    };
};
const addDebugSection = (sections) => {
    if (sections.length === 0)
        return sections;
    sections.splice(1, 0, debugSection());
};
export default bffPageMapper;
