import mParticle from '@mparticle/web-sdk';
import getEndCardImpressionAttributes from '../attributes/getEndCardImpressionAttributes';
const trackEndCardImpression = (type) => {
    return {
        getAttributes: getEndCardImpressionAttributes,
        getCategory: () => { var _a; return (_a = mParticle.EventType) === null || _a === void 0 ? void 0 : _a.UserContent; },
        type: 'End Card Impression',
        injectGlobals: true,
    };
};
export default trackEndCardImpression;
