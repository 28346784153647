import { Lightning } from '@lightningjs/sdk';
var Directions;
(function (Directions) {
    Directions["TopLeft"] = "topLeft";
    Directions["TopRight"] = "topRight";
    Directions["BottomLeft"] = "bottomLeft";
    Directions["BottomRight"] = "bottomRight";
})(Directions || (Directions = {}));
const DirectionCornerMap = {
    [Directions.TopLeft]: 180,
    [Directions.TopRight]: 270,
    [Directions.BottomLeft]: 90,
    [Directions.BottomRight]: 0,
};
const getXAndY = (direction, x, y, radius) => {
    const offset = radius;
    switch (direction) {
        case Directions.TopLeft:
            return [x + offset, y + offset];
        case Directions.TopRight:
            return [x - offset, y + offset];
        case Directions.BottomLeft:
            return [x + offset, y - offset];
        case Directions.BottomRight:
            return [x - offset, y - offset];
        default:
            return [0, 0];
    }
};
const idPrefix = 'InverseRoundedCorner';
export class InverseRoundedCornerTexture extends Lightning.textures.StaticCanvasTexture {
    constructor() {
        super(...arguments);
        this._radius = 3;
        this.createShader = (stage) => {
            const canvas = stage.platform.getDrawingCanvas();
            const ctx = canvas.getContext('2d');
            if (!ctx || !this._corners)
                return canvas;
            ctx.imageSmoothingEnabled = true;
            canvas.width = Math.max(...this._reduceKey('x'));
            canvas.height = Math.max(...this._reduceKey('y'));
            Object.keys(this._corners).forEach((key) => {
                var _a;
                const corner = (_a = this._corners) === null || _a === void 0 ? void 0 : _a[key];
                if (!corner)
                    return;
                const { x, y } = corner;
                const startAngleDeg = DirectionCornerMap[key];
                const startAngle = startAngleDeg * (Math.PI / 180);
                const endAngle = startAngle + Math.PI * 0.5;
                const [arcX, arcY] = getXAndY(key, x, y, this._radius);
                ctx.beginPath();
                ctx.moveTo(x, y);
                ctx.arc(arcX, arcY, this._radius, startAngle, endAngle, false);
                if (this._fillColor)
                    ctx.fillStyle = Lightning.StageUtils.getRgbaString(this._fillColor);
                ctx.closePath();
                ctx.fill();
            });
            return canvas;
        };
    }
    set fillColor(color) {
        this._fillColor = color;
    }
    set radius(radius) {
        this._radius = radius;
    }
    set corners(corners) {
        this._corners = corners;
        const factory = (cb, stage) => {
            cb(null, this.createShader(stage));
        };
        this.content = { factory, lookupId: this.internalId };
    }
    get internalId() {
        if (!this._corners)
            return idPrefix;
        return Object.keys(this._corners).reduce((acc, direction) => {
            var _a;
            const corner = (_a = this._corners) === null || _a === void 0 ? void 0 : _a[direction];
            return corner ? acc + `${corner.x}${corner.y}${this._radius}` : acc;
        }, idPrefix);
    }
    _reduceKey(key) {
        if (!this._corners)
            return [this._radius * 2];
        return [
            ...Object.keys(this._corners).map((k) => { var _a, _b; return ((_b = (_a = this._corners) === null || _a === void 0 ? void 0 : _a[k]) === null || _b === void 0 ? void 0 : _b[key]) || 0; }),
            this._radius * 2,
        ];
    }
}
