export class AdBreakTiming {
    constructor(endTime, startTime, startTimeOnUi, duration, accumulated) {
        this.endTime = Math.floor(endTime);
        this.startTime = Math.floor(startTime);
        this.startTimeOnUi = Math.floor(startTimeOnUi); // startTime without previous adbreak time
        this.duration = Math.floor(duration);
        this.accumulated = Math.floor(accumulated);
    }
}
export const reduceAdBreaks = (adbreaks) => {
    if (!adbreaks)
        return [];
    let accumulated = 0;
    return adbreaks.reduce((acc, pod) => {
        const { position = 0, expectedDuration = 0 } = pod;
        const startTime = Number(position) + accumulated;
        accumulated += expectedDuration;
        return [
            ...acc,
            new AdBreakTiming(startTime + expectedDuration, startTime, Number(position), expectedDuration, accumulated),
        ];
    }, []);
};
