import { Log } from '@lightningjs/sdk';
import { isEmpty } from 'lodash';
import * as LDClient from 'launchdarkly-js-client-sdk';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import MParticleApi from '../../api/MParticleApi';
import { formatAppVersion, getBrandName, getProduct, getMpid } from '../../helpers';
import version from '../../version';
import AuthenticationSingleton from '../../authentication/Authentication';
import { Subject } from 'rxjs';
class LaunchDarkly {
    constructor() {
        this._featureFlags = {};
        this._ldClient = null;
        this.events = new Subject();
    }
    set featureFlags(featureFlags) {
        var _a;
        const isObject = (val) => typeof val !== 'string';
        for (const [key, value] of Object.entries(featureFlags)) {
            const current = isObject(value) ? (value === null || value === void 0 ? void 0 : value.current) || value : value; // if the feature flag is from the 'change' event will come wrapped in current or previous
            featureFlags[key] = ((_a = current === null || current === void 0 ? void 0 : current.rules) === null || _a === void 0 ? void 0 : _a[0]) || current; // temp-pass can come in a rules property
        }
        this._featureFlags = {
            ...this._featureFlags,
            ...featureFlags,
        };
    }
    /**
     * Get and save all feature flags
     */
    async _saveFeatureFlags(featureFlags = {}) {
        const featureFlagsLoaded = !isEmpty(this._featureFlags);
        this.featureFlags = featureFlags;
        this.events.next(this._featureFlags);
        Log.info(`${LaunchDarkly.TAG} feature flags ${featureFlagsLoaded ? 'to update' : 'loaded'}`, featureFlags);
    }
    /**
     * Get user profile
     * @return User
     */
    async _getUser() {
        const mpAudiences = await MParticleApi.getAudiences();
        const isAuthenticated = AuthenticationSingleton.isAuthenticated();
        return {
            kind: 'user',
            key: getMpid(),
            'App Version': formatAppVersion(version),
            'Identity Auth': isAuthenticated,
            app: getBrandName(),
            Product: getProduct(),
            mpAudiences,
        };
    }
    /**
     * Initialize Launch Darkly SDK
     * @return Promise
     */
    async initialize() {
        const { clientSideId } = AppConfigFactorySingleton.config.launch_darkly;
        // Get and identify LD user with mParticle.
        const user = await this._getUser();
        Log.info(`${LaunchDarkly.TAG} Version::`, LDClient.version);
        Log.info(`${LaunchDarkly.TAG} initialize, user:`, user);
        // Initialize the LD client.
        this._ldClient = LDClient.initialize(clientSideId, user);
        this._ldClient.on('change', this._saveFeatureFlags.bind(this));
        await this._ldClient.waitUntilReady();
        // Wait for initial flags to be saved before resolving the promise
        await new Promise((resolve) => {
            const saveFlags = async () => {
                var _a;
                await this._saveFeatureFlags((_a = this._ldClient) === null || _a === void 0 ? void 0 : _a.allFlags());
                resolve();
            };
            saveFlags();
        });
        Log.info(`${LaunchDarkly.TAG} initialization complete`);
    }
    getFeatureFlag(name) {
        const flag = this._featureFlags[name];
        return typeof flag === 'object' && 'current' in flag && 'previous' in flag ? flag.current : flag;
    }
    getAllFlag() {
        return this._featureFlags;
    }
    async updateUserAuthContext(auth) {
        var _a;
        const mpAudiences = await MParticleApi.getAudiences();
        (_a = this._ldClient) === null || _a === void 0 ? void 0 : _a.identify({
            key: getMpid(),
            custom: {
                'Identity Auth': auth,
                'App Version': formatAppVersion(version),
                app: getBrandName(),
                Product: getProduct(),
                mpAudiences,
            },
        });
        Log.info(`${LaunchDarkly.TAG} context update, user:`, getMpid(), auth);
    }
}
LaunchDarkly.TAG = 'Launch Darkly';
const LaunchDarklySingleton = new LaunchDarkly();
export default LaunchDarklySingleton;
