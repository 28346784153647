import { Lightning } from '@lightningjs/sdk';
import { COLORS } from '../../constants';
export default class Loader extends Lightning.Component {
    static _template() {
        return {
            x: 825,
            Dots: {},
        };
    }
    _init() {
        this._dots = 3;
        this.createDots();
    }
    createDots() {
        const children = [];
        const rv = 0.001;
        const v = 60;
        const alpha = 0.4;
        for (let i = 0; i < this._dots; i++) {
            children.push({
                ref: `Dot${i}`,
                rect: true,
                h: v,
                w: v,
                scale: 0.001,
                x: v + i * 80,
                y: 40,
                mount: 0.5,
                // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                texture: Lightning.Tools.getRoundRect(v, v, v / 2, 0, 'transparent', true, COLORS.white3),
                alpha: alpha,
                transitions: { delay: 10 },
                animationfunc: this.animation({
                    duration: 1.8,
                    // @ts-expect-error TS(2345): Argument of type '{ duration: number; timingFuncti... Remove this comment to see the full error message
                    timingFunction: 'ease-in-out',
                    repeat: -1,
                    stopMethod: 'immediate',
                    delay: i * 0.13,
                    actions: [
                        { t: `Dot${i}`, p: 'scale', v: { 0: rv, 0.4: 1, 0.8: rv } },
                        { t: `Dot${i}`, p: 'alpha', v: { 0: alpha, 0.4: 0.8, 0.8: alpha } },
                    ],
                }),
            });
        }
        this.tag('Dots').children = children;
    }
    _active() {
        this.tag('Dot0').animationfunc.start();
        this.tag('Dot1').animationfunc.start();
        this.tag('Dot2').animationfunc.start();
    }
    _inactive() {
        this.tag('Dot0').animationfunc.stop();
        this.tag('Dot1').animationfunc.stop();
        this.tag('Dot2').animationfunc.stop();
    }
}
