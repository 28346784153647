import { bootApp } from '../../api/Api';
import { QueryName, getQuerySchema } from '../../api/requests/bffQuery';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { EntityPageType } from '../../constants';
export const getBff = () => {
    const bff = () => {
        var _a;
        const val = (_a = AppConfigFactorySingleton.get().config) === null || _a === void 0 ? void 0 : _a.bff;
        return val ? Promise.resolve(val) : Promise.reject();
    };
    return bff().catch(() => bootApp().then(bff));
};
export const getHeaders = () => ({
    'Content-Type': 'application/json',
    Accept: 'application/json',
});
export const getExtensions = (hash) => ({
    persistedQuery: {
        version: 1,
        sha256Hash: hash,
    },
});
export const getQueryName = (query, variables) => {
    const cacheableQueries = [
        [QueryName.featuredSection, EntityPageType.OlympicsPage],
    ];
    const shouldUseCached = !!cacheableQueries.find(([q, t]) => query === q && variables.type === t);
    return `${query}${shouldUseCached ? '_cached' : ''}`;
};
export const makeGetUrl = (uri, variables, extensions) => `${uri}?variables=${encodeURIComponent(JSON.stringify(variables))}&extensions=${JSON.stringify(extensions)}`;
export const persistedQueryUnavailable = (json) => { var _a; return !!((_a = json.errors) === null || _a === void 0 ? void 0 : _a.find(({ message }) => message === 'PersistedQueryNotFound')); };
export const postFallback = async (uri, headers, query, variables, extensions) => await (await fetch(uri, {
    method: 'POST',
    headers,
    body: JSON.stringify({
        query: getQuerySchema(query),
        variables,
        extensions,
    }),
})).json();
