import Slide from '../index';
import { addSponsorsBadge, setBadge } from './helpers';
export const sportSlideDecorator = (item) => {
    var _a, _b;
    if ((!((_a = item._item) === null || _a === void 0 ? void 0 : _a.isSportsVideoSlide) && ((_b = item._item) === null || _b === void 0 ? void 0 : _b.destination) !== 'sports') ||
        !(item instanceof Slide) ||
        !item._item)
        return;
    setBadge(item);
    if (!item._item.titleLogo) {
        const title = item._item.slideHeader || item._item.title;
        if (title)
            item.headerTitle = title;
    }
    addSponsorsBadge(item);
};
