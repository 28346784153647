import { Lightning } from '@lightningjs/sdk';
import { COLORS, EPG_SLOT_WIDTH } from '../../../../constants';
import { EpgGuideController, EpgGuideEvents } from '../../EpgGuideController';
class GuideProgressBar extends Lightning.Component {
    static getWidth(fillPercent) {
        return EPG_SLOT_WIDTH * ((fillPercent || EpgGuideController.progress) / 100);
    }
    constructor(stage) {
        super(stage);
        this._onProgress = (progress) => {
            this.patch({ w: GuideProgressBar.getWidth(progress) });
        };
        this._subscription = EpgGuideController.subscribe({
            type: EpgGuideEvents.PROGRESS,
            handler: this._onProgress,
        });
    }
    static _template() {
        return {
            rect: true,
            h: (h) => h,
            w: () => GuideProgressBar.getWidth(),
            color: GuideProgressBar.inactiveColor,
            shader: {
                type: Lightning.shaders.RoundedRectangle,
                radius: [6.375, 0, 0, 6.375],
            },
        };
    }
    _focus() {
        this.patch({ color: GuideProgressBar.activeColor });
    }
    _unfocus() {
        this.patch({ color: GuideProgressBar.inactiveColor });
    }
    _detach() {
        var _a;
        super._detach();
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
}
GuideProgressBar.activeColor = COLORS.lightGray11;
GuideProgressBar.inactiveColor = COLORS.mediumGray6;
export default GuideProgressBar;
