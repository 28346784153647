export const lowercaseFirst = (s) => {
    return (s === null || s === void 0 ? void 0 : s.charAt(0).toLowerCase()) + (s === null || s === void 0 ? void 0 : s.slice(1));
};
export const removeHtmlParams = (str) => {
    return str
        .replace(/<\/ *p>|<br *\/?>/g, '\n')
        .replace(/<a *href="(.*)">(.*)<\/ *a>/g, '$2 ($1)')
        .replace(/https?:\/\//g, '')
        .replace(/(<([^>]+)>)/gi, '');
};
export const splitString = (str, separator) => {
    return str.split(separator);
};
/**
 * Takes App Version and returns a formatted App Version string.
 * @param {string} App Version
 * @return {string} Formatted App Version to 7 digits (4.2.1 -> 4002001)
 */
export const formatAppVersion = (appVersion) => {
    return parseInt(appVersion
        .split('.')
        .map((num, i) => num.padStart(i === 0 ? 1 : 3, '0'))
        .join(''));
};
/**
 * Pipe separate text(s) with extra space and add space around existing pipes
 *
 * @param {string|array} texts 1 text item to add space to pipes or array of texts to pipe separate
 * @param {string} findPipe find text defaults pipe wrapped in single spaces
 * @param {string} replacePipe replace text + separator - defaults pipe wrapped w/3 spaces
 *
 * @return {string} new text with more space around pipes/pipe-concatenated (if array).
 */
export const pipeSeparate = (texts, findPipe = ' | ', replacePipe = '   |   ') => !texts
    ? ''
    : typeof texts === 'string'
        ? texts.replace(findPipe, replacePipe)
        : texts === null || texts === void 0 ? void 0 : texts.reduce((prev, curr) => prev + replacePipe + curr.replace(findPipe, replacePipe));
export const removeWhiteSpace = (str) => str === null || str === void 0 ? void 0 : str.replace(/\s/g, '');
export const createHash = (str) => {
    let h, i, l;
    for (h = 5381 | 0, i = 0, l = str.length | 0; i < l; i++) {
        h = (h << 5) + h + str.charCodeAt(i);
    }
    return h >>> 0;
};
export const splitTextToFitLength = (text, maxLength = 200) => {
    const sentences = text.split(/([.!?])\s+(?=[A-Z])|\n/);
    const chunks = [];
    let currentChunk = "";
    sentences.forEach(sentence => {
        if (!sentence)
            return;
        if ((currentChunk + sentence).length > maxLength) {
            chunks.push(currentChunk.trim());
            currentChunk = sentence;
        }
        else {
            currentChunk += (currentChunk ? " " : "") + sentence;
        }
    });
    const lastChunk = currentChunk.trim();
    if (lastChunk)
        chunks.push(lastChunk);
    return chunks;
};
