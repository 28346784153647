import Collection from './Collection';
import Tiles from '../tiles';
export default class EndTiles extends Collection {
    constructor(obj = {}) {
        var _a, _b;
        super(obj);
        this.obj.items = [];
        if (this.obj.tileOne) {
            this.obj.items.push(
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            new Tiles[(_a = this.obj.tileOne) === null || _a === void 0 ? void 0 : _a.component](this.obj.tileOne, this.obj.groupTagLine, true));
        }
        if (this.obj.tileTwo) {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            this.obj.items.push(new Tiles[(_b = this.obj.tileTwo) === null || _b === void 0 ? void 0 : _b.component](this.obj.tileTwo));
        }
    }
}
