import { createRequestConfig } from '../../lib/useRequest';
import { mainRequest, searchFeaturedRequest } from '../../api/requests';
import Variables from '../../graphql/Variables';
import { SHOWS_SHELF_TITLE } from '../../constants';
export const SearchFeaturedRequestConfig = () => createRequestConfig({
    query: searchFeaturedRequest,
    variables: Variables.searchPageVariables(),
    reject: [(response) => !(response === null || response === void 0 ? void 0 : response.data)],
});
export const SearchFallbackRequestConfig = () => createRequestConfig({
    query: mainRequest,
    variables: Variables.homePageVariables(),
    reject: [
        (data) => { var _a; return (_a = data === null || data === void 0 ? void 0 : data.errors) === null || _a === void 0 ? void 0 : _a.length; },
        (data) => {
            var _a;
            return !((_a = data === null || data === void 0 ? void 0 : data.sections) === null || _a === void 0 ? void 0 : _a.some((section) => section &&
                'data' in section &&
                'listTitle' in section.data &&
                typeof section.data.listTitle === 'string' &&
                section.data.listTitle.includes(SHOWS_SHELF_TITLE)));
        },
    ],
});
