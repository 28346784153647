import { PlayerControlsStates } from '../../../../constants';
import { SkipButtonStates } from '../../../../pages/Player/delegates/SkipButtonDelegate';
import { isUndefined } from 'lodash';
export const WithSkipButton = (base) => class extends base {
    constructor() {
        super(...arguments);
        this._isSkipButtonShown = false;
        this._skipButtonIndex = -1;
        this._onSkipButtonEvent = ({ status }) => {
            this._isSkipButtonShown = status !== SkipButtonStates.EMPTY_STATE && !isUndefined(status);
            this._updateKeyMap();
        };
        this._updateKeyMap = () => {
            if (this._isSkipButtonShown && this._skipButtonIndex === -1) {
                this._setState(PlayerControlsStates.SkipButton);
                this._skipButtonIndex = this._keyMap.insertAt(1, [
                    [],
                    [PlayerControlsStates.SkipButton],
                    [],
                ]);
            }
            else if (this._skipButtonIndex > -1) {
                this._keyMap.removeAt(this._skipButtonIndex);
                this._skipButtonIndex = -1;
                if (this._getState() === PlayerControlsStates.SkipButton)
                    this._setState(PlayerControlsStates.PlayOrPause);
            }
        };
    }
    static _states() {
        return [
            // @ts-expect-error super doesn't work with static methods
            ...super._states(),
            class SkipButton extends this {
                $enter() {
                    this.fireAncestors('$selectSkipButton');
                }
            },
        ];
    }
    _init() {
        var _a;
        super._init();
        (_a = this._skipButtonEvents) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._skipButtonEvents = this.fireAncestors('$subscribeToSkipButtonDelegate', this._onSkipButtonEvent);
    }
};
