import OnboardingActivation from './OnboardingActivation';
import { EVENTS } from '../../../lib/analytics/types';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { LoadingStateFactory } from './states/LoadingStateFactory';
import { NewCodeStateFacrory } from './states/NewCodeStateFacrory';
export default class ContentGatingActivation extends OnboardingActivation {
    constructor() {
        super(...arguments);
        this._titleTextKey = 'content_gating_activation_title';
        this._routerBackDisabled = false;
    }
    static _states() {
        return [
            LoadingStateFactory(this),
            NewCodeStateFacrory(this),
            class Cancel extends this {
                _getFocused() {
                    return this.tag('CancelButton');
                }
                _handleEnter() {
                    sendMetric(EVENTS.CLICK, {
                        name: 'Maybe Later',
                    });
                    super._handleBack();
                }
                _handleUp() {
                    this._setState('NewCode');
                }
            },
        ];
    }
}
