import moment from 'moment-timezone';
import { Log, Storage } from '@lightningjs/sdk';
import AppConfigFactorySingleton from '../../../config/AppConfigFactory';
import { getMpid } from '../../../helpers';
import WatchesLib from '../../../api/Watches';
import { WatchProgressFactorySingleton } from '../../../graphql/modifiers/WatchProgressFactory';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import UserInteractionsStoreSingleton from '../../../store/UserInteractions';
import { fetchUserInteractions, setWatch } from '../../../store/UserInteractions/actions';
import { STORAGE_KEYS } from '../../../constants';
export class WatchProgressDelegate {
    constructor(duration, watchId) {
        this._watchId = watchId;
        this._duration = duration;
        this._previousLogTime = 0;
        this._percentViewed = 0;
        this._interval = (AppConfigFactorySingleton.config.watchesPollTime || 15000) / 1000;
    }
    endSession() {
        var _a;
        if (this._metadata) {
            WatchProgressFactorySingleton.get().logWatchProgress(this._metadata, this._percentViewed);
        }
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    async setHasWatchedContent() {
        const watchProgressSingleton = WatchProgressFactorySingleton.get();
        if (watchProgressSingleton) {
            if (!watchProgressSingleton.hasWatchedContent) {
                watchProgressSingleton.hasWatchedContent = true;
                Storage.set(STORAGE_KEYS.IS_NOT_DAY_ZERO, true);
                await UserInteractionsStoreSingleton.dispatch(fetchUserInteractions(true));
            }
        }
    }
    async update(currentTime, { forceReport = false } = {}) {
        if (!forceReport && currentTime < this._previousLogTime + this._interval) {
            return;
        }
        this._previousLogTime = currentTime;
        const now = moment().format();
        const attributes = {
            created: now,
            percentViewed: Math.round((currentTime / this._duration) * 100000) / 100000,
            dateTimeWatched: moment().format(),
        };
        const { stream } = PlayerStoreSingleton;
        if (!stream)
            return;
        try {
            if ('v4ID' in stream && stream.v4ID) {
                UserInteractionsStoreSingleton.dispatch(setWatch({
                    videoId: stream.v4ID,
                    percentViewed: attributes.percentViewed,
                    dateTimeWatched: attributes.dateTimeWatched,
                    watchId: this._watchId,
                }));
            }
            if (!('mpxGuid' in stream))
                return;
            // If the video has a watchId, we can PATCH, else POST a new record
            if (this._watchId) {
                await WatchesLib.patch(getMpid(), this._watchId, attributes, stream.mpxGuid);
                this._percentViewed = attributes.percentViewed;
                Log.info(`Logging existing watch at ${currentTime}ms`);
            }
            else {
                const result = await WatchesLib.post({
                    mpid: getMpid(),
                    attributes,
                    guid: stream.mpxGuid,
                });
                if (result && result.data && result.data.id) {
                    this._percentViewed = attributes.percentViewed;
                    this._watchId = result.data.id;
                    Log.info(`Logging new watch at ${currentTime}ms`);
                }
                else {
                    Log.error(`Logging new watch failed at ${currentTime}ms`);
                }
            }
        }
        catch (e) {
            Log.error('Watch update ');
        }
    }
}
