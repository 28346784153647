import Router from '@lightningjs/sdk/src/Router';
/**
 * @Class for storing and retrieving Filter Position on a page
 */
class FilterPositionSingleton {
    constructor() {
        // filter position simple hash by page
        this._filterPositions = {};
    }
    _getCurrentHash() {
        // Router.getActiveHash() depends on the window hashchange event
        // it's better to poll window directly
        const hash = window.location.hash.replace('#', '');
        if (!hash) {
            // LightningJS stores page hash in current page
            // wrapped in a Symbol object, to retrieve this value
            // we must use the getOwnPropertySymbols util
            const page = Router.getActivePage();
            const symbol = Object.getOwnPropertySymbols(page)[0];
            return page[symbol] || '';
        }
        return hash;
    }
    /**
     * Assign positions to the current page.
     * @param {{ type: ScrollElementType, row: number, content: number}} position
     */
    setPositionForCurrentPage(rowIndex) {
        this._filterPositions[this._getCurrentHash()] = rowIndex;
    }
    /**
     * Get the positions for the current page.
     */
    getPositionForCurrentPage() {
        var _a;
        return ((_a = this._filterPositions) === null || _a === void 0 ? void 0 : _a[this._getCurrentHash()]) || 0;
    }
    clearPositionForCurrentPage() {
        var _a;
        const hash = this._getCurrentHash();
        if ((_a = this._filterPositions) === null || _a === void 0 ? void 0 : _a[hash])
            delete this._filterPositions[hash];
    }
}
const FilterPosition = new FilterPositionSingleton();
export default FilterPosition;
