import getVodAttributes from './getVodAttributes';
import { getEndCardCommonAttributes, getEndCardRecommendationsAttributes, } from './getEndCardGlobalAttributes';
import { setDefaultValue } from '../../../../helpers/analytics';
import { MPARTICLE_DEFAULT_ATTR_VALUE, ENTITLEMENT } from '../../../../constants';
const END_CARD_OUTCOMES = {
    AUTO_PLAY: 'Auto Play',
    PLAY_NOW: 'Play Now',
    CLOSE: 'Close',
    REPLAY: 'Replay',
};
const END_CARD_SELECTED = {
    DEFAULT: 'Default',
    ALTERNATE_1: 'Alternate 1',
};
const getEndCardAttributes = (params = {}) => {
    var _a;
    const { shelf, ccLanguage, previousVideo, previousVideoType, video, destination, recommendation, recommendationAlternate1, endCardTime, isAutoPlay, isEndCardSelected, isRestarting, isDefault, duration, } = params;
    const endCardOutcome = isEndCardSelected
        ? isAutoPlay
            ? END_CARD_OUTCOMES.AUTO_PLAY
            : END_CARD_OUTCOMES.PLAY_NOW
        : isRestarting
            ? END_CARD_OUTCOMES.REPLAY
            : END_CARD_OUTCOMES.CLOSE;
    const endCardSelected = isEndCardSelected
        ? isDefault
            ? END_CARD_SELECTED.DEFAULT
            : END_CARD_SELECTED.ALTERNATE_1
        : MPARTICLE_DEFAULT_ATTR_VALUE;
    return setDefaultValue({
        ...getVodAttributes(video, ccLanguage, previousVideo, previousVideoType),
        ...getEndCardRecommendationsAttributes({
            recommendation,
            recommendationAlternate1,
        }),
        ...getEndCardCommonAttributes({ video, endCardTime, shelf }),
        'End Card Outcome': endCardOutcome,
        'End Card Selected': endCardSelected,
        Duration: duration,
        'Destination Brand': (_a = destination === null || destination === void 0 ? void 0 : destination.brand) === null || _a === void 0 ? void 0 : _a.title,
        'Destination Show': destination === null || destination === void 0 ? void 0 : destination.series,
        'Destination Sport': destination === null || destination === void 0 ? void 0 : destination.destinationSport,
        'Destination League': destination === null || destination === void 0 ? void 0 : destination.destinationLeague,
        'Destination Video ID': destination === null || destination === void 0 ? void 0 : destination.mpxGuid,
        'Destination Video Type': destination === null || destination === void 0 ? void 0 : destination.programmingType,
        'Destination Episode Title': destination === null || destination === void 0 ? void 0 : destination.title,
        'Destination Entitlement': destination
            ? (destination === null || destination === void 0 ? void 0 : destination.locked)
                ? ENTITLEMENT.ENTITLED
                : ENTITLEMENT.FREE
            : MPARTICLE_DEFAULT_ATTR_VALUE,
    }, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getEndCardAttributes;
