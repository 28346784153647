import { setSmooth } from '../../../helpers';
import ContentPosition from '../../../util/contentPosition';
import { ShowsStates } from '../../../constants';
export const LinksSelectableStateFactory = (base) => class LinksSelectableGroup extends base {
    _handleUp() {
        this._setState(ShowsStates.BrandLabelsList);
        ContentPosition.clearPositionForCurrentPage();
    }
    _handleBack(e) {
        this.$focusedData(false);
        this._setState(ShowsStates.BrandLabelsList);
        e.preventDefault();
        e.stopPropagation();
    }
    _getFocused() {
        return this.linksSelectableGroup || this;
    }
    $focusedData(v) {
        this.widgets.menu.visible = !v;
        setSmooth(this, 'y', v ? -230 : 0, {
            duration: 0.3,
            delay: 0,
        });
    }
    $scrolledRow(rowId) {
        var _a;
        if (rowId === 1) {
            setSmooth(this.brandLabelsList, 'alpha', 0, {
                duration: 0.3,
                delay: 0,
            });
            this.linksSelectableGroup.showItemLabels(0);
        }
        else if (rowId === 0) {
            setSmooth(this.brandLabelsList, 'alpha', 1, {
                duration: 0.3,
                delay: 0,
            });
            this.linksSelectableGroup.showItemLabels(1);
        }
        else if (rowId > 1 && ((_a = this.brandLabelsList) === null || _a === void 0 ? void 0 : _a.alpha) === 1) {
            setSmooth(this.brandLabelsList, 'alpha', 0, {
                duration: 0,
                delay: 0,
            });
            this.linksSelectableGroup.showItemLabels(0);
        }
    }
};
