import { Router } from '@lightningjs/sdk';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import { ROUTE } from '../../constants';
import TVPlatform from '../../lib/tv-platform';
import { ErrorType } from '../../lib/tv-platform/types';
export function doAlgoliaRequest(url, params) {
    var _a;
    const baseUrl = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.api_service_url;
    const headers = {
        Accept: 'application/access-v1+json',
        'Content-Type': 'application/json',
    };
    return fetch(`${baseUrl}/${url}`, {
        method: 'POST',
        headers,
        body: JSON.stringify(params),
    })
        .then((response) => {
        return response.json();
    })
        .catch((error) => {
        TVPlatform.reportError({
            type: ErrorType.NETWORK,
            description: 'Algolia error',
            payload: error,
        });
        Router.navigate(ROUTE.error);
    });
}
