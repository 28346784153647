import LabelsList from './LabelsList';
import { EVENTS } from '../../lib/analytics/types';
import { sendMetric } from '../../lib/analytics/Analytics';
export default class FilterLabelsList extends LabelsList {
    selectFocused() {
        super.selectFocused();
        this.trackClick();
    }
    trackClick() {
        var _a, _b;
        const name = (_b = (_a = this._strategy) === null || _a === void 0 ? void 0 : _a.activeItem) === null || _b === void 0 ? void 0 : _b.label;
        sendMetric(EVENTS.CLICK, {
            name,
            shelf: { listTitle: 'None' },
        });
    }
}
