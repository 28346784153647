import { Lightning } from '@lightningjs/sdk';
import moment from 'moment-timezone';
import CountdownTimer from '../../../components/countdownTimer';
import LetterSpacingText from '../../../components/LetterSpacingText';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { EVENTS } from '../../../lib/analytics/types';
import { FastImg } from '../../../helpers';
import { buttonGroupType, getButtonGroup } from './helpers/buttonGroupHelper';
import { ALIGN_CONTENT, ALIGN_SELF, COLORS, FLEX_DIRECTION, FONT_FACE, JUSTIFY_CONTENT, MAX_LINES_SUFFIX, MPARTICLE_DEFAULT_ATTR_VALUE, OLYMPICS_FALLBACK_VALUE, ROUTE, ROUTE_ENTITY, SCREEN_SIZE, TEXT_ALIGN, } from '../../../constants';
import RouterUtil from '../../../util/RouterUtil';
import ModalManager from '../../../lib/ModalManager';
import { OLY_IMPOLICY } from '../../../constants/images';
export default class UpcomingModal extends Lightning.Component {
    static _template() {
        return {
            alpha: 1,
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            rect: true,
            ImageHolder: {
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                Image: {
                    w: SCREEN_SIZE.width,
                    h: SCREEN_SIZE.height,
                },
                Gradients: {
                    alpha: 0.7,
                    w: SCREEN_SIZE.width,
                    h: SCREEN_SIZE.height,
                    rect: true,
                    color: COLORS.black,
                },
            },
            Content: {
                flex: {
                    direction: FLEX_DIRECTION.column,
                    alignContent: ALIGN_CONTENT.center,
                    justifyContent: JUSTIFY_CONTENT.center,
                },
                x: 0,
                y: 0,
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                Timer: {
                    flexItem: { alignSelf: ALIGN_SELF.center, marginBottom: 49 },
                    type: CountdownTimer,
                    signals: {
                        countdownEnd: '_onCountdownEnd',
                        checkStreamAvailability: '_checkStreamAvailability',
                    },
                },
                Title: {
                    flexItem: { alignSelf: ALIGN_SELF.center },
                    type: LetterSpacingText,
                    fontSize: 26,
                    fontFace: FONT_FACE.semiBold,
                    textColor: COLORS.white,
                    space: 3,
                },
                Description: {
                    flexItem: { alignSelf: ALIGN_SELF.center, marginTop: 15 },
                    text: {
                        fontFace: FONT_FACE.light,
                        fontSize: 48,
                        verticalAlign: 'top',
                        textAlign: TEXT_ALIGN.center,
                        lineHeight: 68,
                        textColor: COLORS.white,
                        maxLines: 3,
                        wordWrapWidth: 1000,
                        maxLinesSuffix: MAX_LINES_SUFFIX,
                    },
                },
                ButtonHolder: {
                    flex: {
                        direction: FLEX_DIRECTION.column,
                        alignContent: ALIGN_CONTENT.center,
                        justifyContent: JUSTIFY_CONTENT.center,
                    },
                    flexItem: { alignSelf: ALIGN_SELF.center, marginTop: 30 },
                },
            },
        };
    }
    set modalData(data) {
        if (!data)
            return;
        Object.keys(data).forEach((key) => {
            const self = this;
            self[key] = data[key];
        });
    }
    set upcomingModal(upcomingModal) {
        this._upcomingModal = upcomingModal;
        this.patch({
            ImageHolder: {
                Image: {
                    texture: FastImg(this._upcomingModal.backgroundImage).cover(SCREEN_SIZE.width, SCREEN_SIZE.height, OLY_IMPOLICY.RECT_LARGE_1920_1080),
                },
            },
            Content: {
                Timer: {
                    startTime: this._upcomingModal.startTime,
                    countdownDayLabel: this._upcomingModal.countdownDayLabel,
                    countdownHourLabel: this._upcomingModal.countdownHourLabel,
                    countdownMinLabel: this._upcomingModal.countdownMinLabel,
                },
                Title: {
                    value: this._upcomingModal.title,
                },
                Description: {
                    text: {
                        text: this._upcomingModal.description,
                    },
                },
            },
        });
        this._checkStreamAvailability();
        sendMetric(EVENTS.MODAL_LOAD, this._getAnalyticsAttributes());
        const ttsString = `${this.tag('Timer.Days').value} ${this.tag('Timer.Days').label} ${this.tag('Timer.Hours').value} ${this.tag('Timer.Hours').label} ${this.tag('Timer.Minutes').value} ${this.tag('Timer.Minutes').label} ${this._upcomingModal.title}  ${this._upcomingModal.description}`;
        this.announce = ttsString;
    }
    set shelf(shelf) {
        this._shelf = shelf;
    }
    get shelf() {
        return this._shelf;
    }
    _checkStreamAvailability() {
        const customerPlayableDate = this._upcomingModal.customerPlayableDate || this.customerPlayableDate;
        const newState = customerPlayableDate && !moment().isBefore(customerPlayableDate)
            ? 'StreamAvailable'
            : 'StreamNotAvailable';
        if (newState !== this._getState())
            this._setState(newState);
    }
    _getAnalyticsAttributes() {
        return {
            modalName: this._upcomingModal.modalName,
            modalType: this._upcomingModal.modalType,
        };
    }
    $trackCtaAnalytics(ctaLabel) {
        sendMetric(EVENTS.CLICK, {
            name: ctaLabel,
            shelf: this.shelf,
            ...(this._upcomingModal.isOlympics
                ? {
                    show: OLYMPICS_FALLBACK_VALUE,
                    mvpdBypass: MPARTICLE_DEFAULT_ATTR_VALUE,
                }
                : {}),
        });
    }
    _disable() {
        this.tag('ImageHolder.Image').texture = null;
    }
    _handleBack() {
        ModalManager.close();
    }
    _handleRight(event) {
        event.stopPropagation();
    }
    _handleLeft(event) {
        event.stopPropagation();
    }
    _handleUp(event) {
        event.stopPropagation();
    }
    _handleDown(event) {
        event.stopPropagation();
    }
    _onCountdownEnd() {
        if (this._upcomingModal.pid) {
            RouterUtil.navigateToRoute(ROUTE.watch, {
                entity: ROUTE_ENTITY.pid,
                value: this._upcomingModal.pid,
            });
        }
        else {
            RouterUtil.navigateToLive(this.fireAncestors.bind(this), {
                channelId: this._upcomingModal.channelId,
                streamAccessName: '',
            });
        }
    }
    static _states() {
        return [
            class StreamNotAvailable extends this {
                $enter() {
                    this.tag('ButtonHolder').children = [
                        {
                            ref: 'StreamNotAvailable',
                            ...getButtonGroup(buttonGroupType.streamNotAvailable),
                        },
                    ];
                }
                _getFocused() {
                    return this.tag('ButtonHolder.StreamNotAvailable') || this;
                }
            },
            class StreamAvailable extends this {
                $enter() {
                    this.tag('ButtonHolder').children = [
                        {
                            ref: 'StreamAvailable',
                            ...getButtonGroup(buttonGroupType.streamAvailable, {
                                ctaText: this._upcomingModal.ctaText,
                                dismissText: this._upcomingModal.dismissText,
                                channelId: this._upcomingModal.channelId,
                                pid: this._upcomingModal.pid,
                                startTime: this._upcomingModal.startTime,
                            }),
                        },
                    ];
                }
                _getFocused() {
                    return this.tag('ButtonHolder.StreamAvailable') || this;
                }
            },
        ];
    }
}
