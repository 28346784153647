import { Language } from '@lightningjs/sdk';
import LiveBadge from '../../../badges/LiveBadge';
import { FLEX_DIRECTION, MAX_LINES_SUFFIX } from '../../../../constants';
import { getCurrentProgressWidth } from '../../../../helpers/metadata';
export const getLiveStateClass = (context) => class LiveState extends context {
    $enter() {
        const currentProgressWidth = getCurrentProgressWidth({
            startTime: this._item.startTime,
            endTime: this._item.endTime,
            totalProgressWidth: context.liveProgressBarWidth,
        });
        if (this._item.liveTuneIn)
            this.tuneInTitle = this._item.liveTuneIn;
        else
            this.tag('Border').alpha = 0;
        if (this._item.liveCtaText)
            this.buttonText = this._item.liveCtaText.toUpperCase();
        this.tag('Content').patch({
            BadgeAndLock: {
                Badge: undefined,
                LiveBadgeContainer: {
                    visible: !this._item.sponsorName,
                    type: LiveBadge,
                    h: 30,
                    fontSize: 20,
                    label: this._item.liveBadge || Language.translate('live'),
                },
                AudioLanguageContainer: undefined,
                LiveAudioLanguageContainer: {
                    flex: { direction: FLEX_DIRECTION.row },
                },
            },
            TitleBlock: {
                SecondaryTitle: {
                    text: { maxLines: 1, maxLinesSuffix: MAX_LINES_SUFFIX },
                },
            },
            LiveProgressBarContainer: {
                visible: true,
                LiveProgressBar: {
                    currentProgressWidth: currentProgressWidth,
                },
            },
        });
        this.secondaryTitleLines = 1;
        this.announce = this._item.liveAriaLabel;
    }
    _updateButtonColor() {
        if (this._item.liveCtaText) {
            this.buttonColor = `0xff${this._item.liveCtaColor.replace('#', '')}`;
        }
    }
};
