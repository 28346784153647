import Collection from './Collection';
export default class LazyCollection extends Collection {
    get entryField() {
        return this.obj.entryField;
    }
    get path() {
        return this.obj.path;
    }
    get queryVariables() {
        return this.obj.queryVariables;
    }
    get queryName() {
        return this.obj.queryName;
    }
    set queryData(queryData) {
        this.obj.queryData = queryData;
    }
    get queryData() {
        return this.obj.queryData;
    }
}
