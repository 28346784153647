import getIDMConversionAttributes from '../attributes/getIDMConverstionAttributes';
import MParticleInterface from '../mParticleInterface';
import mParticle from '@mparticle/web-sdk';
export const preTrackEvent = () => (properties) => {
    MParticleInterface.setAuthAttributes(properties);
    MParticleInterface.setConversionAttribute(true);
};
const getEvent = () => ({
    getAttributes: getIDMConversionAttributes,
    getCategory: () => mParticle.EventType.UserPreference,
    type: 'Conversion',
    injectGlobals: true,
    preTrackEvent: preTrackEvent(),
});
export default getEvent;
