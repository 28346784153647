import Variables from '../../graphql/Variables';
import { EntityPageType } from '../../graphql/generated/types';
import { createRequestConfig } from '../../lib/useRequest';
import { featuredRequest, mainRequest } from '../../api/requests';
export const ShowHomeRequestConfig = (urlAlias) => createRequestConfig({
    query: mainRequest,
    variables: Variables.showPageVariables(EntityPageType.TitleV2, urlAlias),
    reject: [(data) => !(data === null || data === void 0 ? void 0 : data.analytics) || !(data === null || data === void 0 ? void 0 : data.metadata) || !(data === null || data === void 0 ? void 0 : data.sections)],
});
export const ShowHomeFeaturedRequestConfig = (urlAlias) => createRequestConfig({
    query: featuredRequest,
    variables: Variables.showPageVariables(EntityPageType.TitleV2, urlAlias),
    reject: [(data) => !data],
});
