import { Language, Lightning, Storage, Utils } from '@lightningjs/sdk';
import Item from '../Item';
import SponsorBadge from '../../badges/SponsorBadge';
import { fullEventReplayDecorator, sportSlideDecorator, videoTitleDecorator, slideHeaderDecorator, } from './decorators';
import { olympicsMedalDecorator, audioLanguageDecorator } from '../decorators';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { EVENTS } from '../../../lib/analytics/types';
import { FastImg, pipeSeparate } from '../../../helpers';
import { ALIGN_SELF, COLORS, ENTITY_TYPES, FLEX_DIRECTION, FONT_FACE, OLY_IMPOLICY, PROGRAMMING_TYPES, SCREEN_SIZE, STORAGE_KEYS, VERTICAL_ALIGN, } from '../../../constants';
import ContentPosition, { ScrollElementType } from '../../../util/contentPosition';
import RouterUtil from '../../../util/RouterUtil';
import AuthenticationSingleton from '../../../authentication/Authentication';
import UserInteractionsStoreSingleton from '../../../store/UserInteractions';
import { LiveStreamManager } from '../../../lib/LiveStreamManager';
// @ts-expect-error TS(2417): Class static side 'typeof Slide' incorrectly exten... Remove this comment to see the full error message
class Slide extends Item {
    constructor() {
        super(...arguments);
        this.buttonColor = '';
        this.buttonText = '';
        this.labelBadge = '';
        this.secondaryTitle = '';
        this.tertiaryTitle = '';
        this.tuneInTitle = '';
        this.percentViewed = 0;
        this.secondaryTitleLines = 1;
        this.headerMaxLines = 2;
    }
    static _template() {
        const timingFunction = 'cubic-bezier(0.20, 1.00, 0.80, 1.00)';
        return {
            rtt: true,
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            transitions: {
                scale: { duration: 0.6, timingFunction },
            },
            ImageHolder: {
                scale: 1.02,
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                transitions: {
                    scale: { duration: 1.2, timingFunction },
                },
                Image: {
                    alpha: 0.001,
                    w: SCREEN_SIZE.width,
                    h: SCREEN_SIZE.height,
                    transitions: {
                        alpha: { duration: 0.6, timingFunction },
                    },
                },
                Gradients: {
                    Left: {
                        w: 885,
                        h: SCREEN_SIZE.height,
                        rect: true,
                        colorLeft: COLORS.black,
                        colorRight: COLORS.transparent,
                    },
                    Bottom: {
                        w: SCREEN_SIZE.width,
                        h: 347,
                        y: 733,
                        rect: true,
                        colorBottom: COLORS.black,
                        colorTop: COLORS.transparent,
                    },
                },
            },
            Content: {
                x: 83,
                alpha: 0.001,
                transitions: {
                    alpha: { duration: 0.6, timingFunction },
                    x: { duration: 0.6, timingFunction },
                },
                LogoArea: {
                    y: 194,
                    w: 800,
                    h: 200,
                    flex: {},
                    Logo: {
                        alpha: 0.001,
                        transitions: {
                            alpha: { duration: 0.6, timingFunction },
                            y: { duration: 0.6, timingFunction },
                            scale: { duration: 0.6, timingFunction },
                        },
                    },
                    FallbackText: {
                        visible: false,
                        flexItem: {
                            alignSelf: ALIGN_SELF.center,
                            margin: 0,
                        },
                        text: {
                            fontSize: 64,
                            textColor: COLORS.white,
                            lineHeight: 71,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            text: this.bindProp('headerTitle'),
                            maxLines: this.bindProp('headerMaxLines'),
                            wordWrapWidth: 800,
                            fontFace: FONT_FACE.light,
                        },
                    },
                },
                TuneIn: {
                    text: {
                        fontSize: 24,
                        fontFace: FONT_FACE.light,
                        wordWrapWidth: 800,
                        maxLines: 1,
                        text: this.bindProp('tuneInTitle'),
                    },
                    y: 391,
                    Border: {
                        y: 42,
                        w: 50,
                        h: 2,
                        rect: true,
                        color: COLORS.white,
                        alpha: 0,
                    },
                },
                SponsorBadge: {
                    y: 391,
                    visible: false,
                },
                BadgeAndLock: {
                    y: 457,
                    flex: { direction: FLEX_DIRECTION.row },
                    Badge: {
                        BadgeText: {
                            text: {
                                fontSize: 24,
                                fontFace: FONT_FACE.semiBold,
                                text: this.bindProp('labelBadge'),
                            },
                        },
                    },
                    AudioLanguageContainer: {
                        flex: { direction: FLEX_DIRECTION.row },
                    },
                    Lock: {
                        flexItem: {
                            marginTop: 5,
                        },
                        w: 40,
                        h: 21,
                        visible: false,
                        color: COLORS.white1,
                        texture: Lightning.Tools.getSvgTexture(Utils.asset('images/key.svg'), 40, 21),
                    },
                },
                TitleBlock: {
                    y: 498,
                    flex: { direction: FLEX_DIRECTION.column },
                    VideoTitleContainer: {
                        flex: { direction: FLEX_DIRECTION.row },
                        VideoTitle: {
                            text: {
                                fontSize: 32,
                                fontFace: FONT_FACE.regular,
                                text: this.bindProp('videoTitle'),
                            },
                        },
                    },
                    SecondaryTitle: {
                        alpha: 0.9,
                        text: {
                            fontSize: 32,
                            fontFace: FONT_FACE.light,
                            lineHeight: 36,
                            maxLines: this.bindProp('secondaryTitleLines'),
                            wordWrapWidth: 600,
                            text: this.bindProp('secondaryTitle'),
                        },
                    },
                    TertiaryTitle: {
                        alpha: 0.9,
                        text: {
                            fontSize: 32,
                            fontFace: FONT_FACE.light,
                            lineHeight: 36,
                            maxLines: 2,
                            wordWrapWidth: 600,
                            text: this.bindProp('tertiaryTitle'),
                        },
                    },
                },
                WatchProgress: {
                    backgroundColor: COLORS.white,
                    y: 610,
                    h: 10,
                    w: 322,
                    rect: true,
                    alpha: this.bindProp('watchProgressAlpha'),
                },
                ProgressBar: {
                    y: 610,
                    backgroundColor: COLORS.white,
                    w: this.bindProp('watchProgressWidth'),
                    h: 10,
                    rect: true,
                    zIndex: 1,
                },
                ButtonHolder: {
                    y: 653,
                    Button: {
                        flex: {},
                        rtt: true,
                        h: 58,
                        rect: true,
                        color: this.bindProp('buttonColor'),
                        shader: { type: Lightning.shaders.RoundedRectangle, radius: 29 },
                        Label: {
                            flexItem: { marginLeft: 50, marginRight: 50 },
                            mountY: 0.5,
                            y: 31,
                            color: this.bindProp('buttonFontColor'),
                            text: {
                                fontSize: 30,
                                fontFace: FONT_FACE.regular,
                                text: this.bindProp('buttonText'),
                            },
                        },
                    },
                },
                BrandLogoHolder: {
                    mount: 1,
                    y: 840,
                    x: 1757,
                    Shadow: {
                        mount: 1,
                        y: 15,
                        x: 15,
                        type: Lightning.components.FastBlurComponent,
                        amount: 2,
                        content: {},
                    },
                    BrandLogo: {
                        mount: 1,
                        alpha: 0.001,
                        transitions: {
                            alpha: { duration: 0.6, timingFunction },
                        },
                    },
                },
            },
        };
    }
    _init() {
        const image = this.tag('Image');
        image.on('txLoaded', () => {
            image.setSmooth('alpha', 1);
            this.focusImage(true);
        });
        image.on('txError', () => {
            const background = Utils.asset('images/background.png');
            //Don't just repeatedly try to set a failed image
            image.src = image.src !== background ? background : '';
            this.focusImage(true);
        });
        const logoTag = this.tag('LogoArea.Logo');
        logoTag.on('txLoaded', () => {
            logoTag.setSmooth('alpha', 1);
            this._repositionContent();
        });
        logoTag.on('txError', () => {
            this.showHeaderTitle();
            this._onLogoTxError();
        });
        if (!logoTag.children.length) {
            this._onLogoTxError();
        }
        this.tag('BrandLogo').on('txLoaded', () => {
            const logo = this.tag('BrandLogo');
            logo.setSmooth('alpha', 1);
            this.tag('Shadow').patch({
                h: logo.renderHeight + 30,
                w: logo.renderWidth + 30,
                content: {
                    x: 15,
                    y: 15,
                    color: COLORS.black5,
                    texture: logo.texture,
                },
            });
        });
        if (!this._item.titleLogo)
            this.showHeaderTitle();
    }
    set item(v) {
        this._item = v;
        this.percentViewed = UserInteractionsStoreSingleton.getPercentViewed(this._item.v4ID);
        const watchProgressWidth = 322;
        // todo: see if propertyBinding can also work with images / textures
        this.patch({
            ImageHolder: {
                Image: {
                    texture: FastImg(v.image).cover(SCREEN_SIZE.width, SCREEN_SIZE.height, OLY_IMPOLICY.RECT_LARGE_1920_1080),
                },
            },
            Content: {
                LogoArea: {
                    Logo: {
                        texture: FastImg(v.titleLogo).contain(600, 160, OLY_IMPOLICY.RECT_SMEDIUM_640_360),
                    },
                },
                BrandLogoHolder: {
                    BrandLogo: {
                        texture: FastImg(v.whiteBrandLogo).contain(120, 50, OLY_IMPOLICY.RECT_SMALL_384_216),
                    },
                },
            },
        });
        if (v.buttonText) {
            this.buttonText = v.buttonText.toUpperCase();
            const color = v.buttonColor || '#B4A074';
            this.buttonColor = `0xff${color.replace('#', '')}`;
            this.buttonFontColor = COLORS.lightGray3;
        }
        if (v.tuneIn) {
            this.tuneInTitle = pipeSeparate(v.tuneIn);
            this.tag('Border').alpha = 0.45;
        }
        if (v.secondaryTitle)
            this.secondaryTitle = pipeSeparate(v.secondaryTitle);
        if (v.tertiaryTitle)
            this.tertiaryTitle = v.tertiaryTitle;
        if (v.labelBadge) {
            this.tag('Badge.BadgeText').on('txLoaded', (texture) => {
                this.tag('Badge').patch({ w: texture.source.w / texture.precision, h: texture.source.h });
                this.tag('Lock').patch({ flexItem: { marginLeft: 30 } });
            });
            this.labelBadge = v.labelBadge;
        }
        else {
            this.tag('Badge').visible = false;
        }
        if (v.programmingType === PROGRAMMING_TYPES.SFVOD) {
            this.headerMaxLines = 3;
        }
        this._addDecorators([
            videoTitleDecorator,
            sportSlideDecorator,
            slideHeaderDecorator,
            fullEventReplayDecorator,
            olympicsMedalDecorator(10),
            audioLanguageDecorator,
        ]);
        this.watchProgressWidth = this.percentViewed * watchProgressWidth;
        this.watchProgressAlpha = this.watchProgressWidth ? 0.45 : 0;
        this._refocus();
    }
    _active() {
        if (AuthenticationSingleton.isAuthenticated() && !AuthenticationSingleton.isTempPassActive()) {
            this.tag('Lock').visible = false;
        }
        else {
            this.tag('Lock').visible = this._item && this._item.isLocked;
        }
    }
    focusImage(v) {
        this.patch({
            ImageHolder: {
                smooth: { scale: v ? 1 : 1.02 },
            },
        });
    }
    _repositionContent() {
        this.patch({
            Content: {
                smooth: { alpha: 1 },
            },
        });
    }
    _onLogoTxError() {
        this._repositionContent();
    }
    _addSponsorBadge() {
        var _a;
        this.tag('SponsorBadge').patch({
            SponsorBadgeSponsorBadge: {
                type: SponsorBadge,
                sponsorLogo: this._item.sponsorLogo,
                sponsorFallbackText: ((_a = this._item.sponsorLogoAltText) !== null && _a !== void 0 ? _a : this._item.sponsorName)
                    ? `${Language.translate('presented_by')} ${this._item.sponsorName}`
                    : '',
            },
            visible: true,
        });
        this.tag('Badge').visible = false;
        this.tag('TuneIn').visible = false;
    }
    setInactive(v) {
        this.patch({
            Content: {
                ButtonHolder: {
                    Button: {
                        smooth: { alpha: v ? 1 : 0.45 },
                    },
                },
            },
        });
    }
    _getAnalytics() {
        var _a;
        return {
            ...this._item.ctaAnalytics,
            ...this._item.analytics,
            title: (_a = this._item) === null || _a === void 0 ? void 0 : _a.title,
            contentType: this === null || this === void 0 ? void 0 : this._item.contentType,
        };
    }
    _getTrackParams() {
        const { smartDynamicCTA, smartTileLabel, smartTileLogic, smartTileScenario, videoTitle, mpxGuid, } = this._getAnalytics();
        if (!smartDynamicCTA)
            return {};
        return {
            smartDynamicCTA,
            smartTileLabel,
            smartTileLogic,
            smartTileScenario,
            smartTileEpisodeTitle: videoTitle,
            smartTileVideoId: mpxGuid,
        };
    }
    trackContentClick() {
        var _a, _b;
        const params = {
            entity: {
                analytics: this._getAnalytics(),
                entityType: this._getEntityType(),
                dynamicSmartLeadLogic: this._item.dynamicSmartLeadLogic,
            },
            shelf: {
                machineName: (_a = this._item.shelf) === null || _a === void 0 ? void 0 : _a.machineName,
                tileIndex: ((_b = this._item.shelf) === null || _b === void 0 ? void 0 : _b.tileIndex) + 1,
                shelfIndex: 1,
                listTitle: 'Dynamic Lead',
            },
            tileParams: this._getTrackParams(),
        };
        Storage.set(STORAGE_KEYS.REFERRING_SHELF, params.shelf);
        Storage.set(STORAGE_KEYS.SMART_TILE, this._getTrackParams());
        sendMetric(EVENTS.CONTENT_CLICK, params);
    }
    trackContentPosition() {
        const position = this.fireAncestors('$getCurrentScrollPosition');
        if (position)
            ContentPosition.setPositionForCurrentPage({
                ...position,
                type: ScrollElementType.FEATURED,
            });
    }
    _handleEnter() {
        this.trackContentPosition();
        this.trackContentClick();
        if (Slide.isLiveSlide(this._item)) {
            LiveStreamManager.set('', this._item.destination, '');
        }
        Slide.navigateDestination(this._item);
    }
    showHeaderTitle() {
        this.tag('FallbackText').visible = true;
    }
    static navigateDestination(item) {
        RouterUtil.navigateByDestinationType(item);
    }
    _getEntityType() {
        return ENTITY_TYPES.SLIDE;
    }
    static isLiveSlide({ destinationType, isLive }) {
        return destinationType === Slide.DestinationTypes.internalPage && isLive;
    }
    get olympicsMedalContainer() {
        return this.tag('VideoTitleContainer');
    }
    static get tag() {
        return 'Slide';
    }
}
Slide.DestinationTypes = {
    video: 'video',
    series: 'series',
    internalPage: 'internalPage',
    playlist: 'playlist',
    singleEvent: 'singleEvent',
};
export default Slide;
