import BasePlayerControls from './BasePlayerControls';
import LinearStrategy from './strategies/LinearStrategy';
import { KeyMap } from '../../../lib/KeyMap';
import { TimeChangeEvent } from '../../../player/model/event';
import { PlayerControlsStates, TRICK_PLAY_ELEMENTS } from '../../../constants';
import moment from 'moment-timezone';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { LiveToVodButtonStates } from '../../../pages/Player/delegates/LiveToVodDelegate';
import LiveToVodButton from '../../buttons/LiveToVodButton';
import { LiveBadgeV1 } from './LiveBadgeV1';
import LiveControlsV1 from './LiveControlsV1';
import { LiveControlsStateFactory } from './states/LiveControls';
import { MediaOptionsStateFactory } from './states/MediaOptions';
import Announcer from '../../../lib/tts/Announcer';
class LinearPlayerControlsV1 extends BasePlayerControls {
    constructor() {
        super(...arguments);
        this._strategy = new LinearStrategy(this);
        this._keyMap = KeyMap(this, [
            [
                [PlayerControlsStates.QOSPanel, PlayerControlsStates.MoreInfo],
                [PlayerControlsStates.MediaOptions, PlayerControlsStates.MoreInfo],
                [PlayerControlsStates.QOSPanel],
            ],
            [[], PlayerControlsStates.LiveControls, []],
            [[], PlayerControlsStates.LiveToVod, []],
            [[], PlayerControlsStates.PlayOrPause, []],
        ]);
        this._sync = ({ status }) => {
            const button = this.tag('LiveToVod');
            if (status === LiveToVodButtonStates.EMPTY_STATE) {
                button.visible = false;
                if (this._getState() === PlayerControlsStates.LiveToVod) {
                    this._setState(PlayerControlsStates.PlayOrPause);
                }
            }
            else if (!button.visible) {
                button.visible = true;
            }
        };
    }
    static _template() {
        return super._template({
            LiveToVod: {
                type: LiveToVodButton,
            },
        });
    }
    setTitle(text) {
        this._title.patch({ text: { text } });
    }
    setSecondaryTitle(text) {
        this._secondaryTitle.patch({ text: { text } });
    }
    _init() {
        super._init();
        this.tag('PlayerControls.TitleContainer.HeadingContainer').childList.addAt({ ref: 'Live', type: LiveBadgeV1 }, 1);
        this.showLiveControls();
    }
    showLiveControls() {
        this.tag('PlayerControls').patch({
            LiveControls: {
                type: LiveControlsV1,
            },
        });
        this._liveControls = this.tag('LiveControls');
    }
    _enable() {
        var _a;
        super._enable();
        (_a = this._liveToVodEvents) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._liveToVodEvents = this.fireAncestors('$subscribeToLiveToVodDelegate', this._sync);
        this._setState(PlayerControlsStates.PlayOrPause);
    }
    _disable() {
        var _a;
        super._disable();
        (_a = this._liveToVodEvents) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    _updateProgress(progress) {
        const [currentTime, duration] = progress;
        // Workaround for race condition issue on program boundary change
        if (duration && currentTime > duration) {
            const stream = PlayerStoreSingleton.stream;
            const program = PlayerStoreSingleton.program;
            if (moment().isBefore(moment(stream === null || stream === void 0 ? void 0 : stream.endTime))) {
                this._strategy.init(stream, program);
            }
        }
        else {
            super._updateProgress(progress);
        }
    }
    _playerSubscriptionHandler(event) {
        super._playerSubscriptionHandler(event);
        if (event instanceof TimeChangeEvent) {
            /**
             * Toggle "back to live" button based on isAtLiveEdge property from player
             * Sometimes isAtLiveEdge is undefined even if we are on the live edge, so check it for the boolean type.
             */
            if (this.active && typeof event._isAtLiveEdge === 'boolean')
                this._liveControls.toggleBackToLive(!event._isAtLiveEdge);
        }
    }
    static _states() {
        return [
            ...super._states(),
            class PlayOrPause extends this {
                _active() {
                    super._active();
                    Announcer.announce(this.fireAncestors('$isPlaying')
                        ? TRICK_PLAY_ELEMENTS.pauseButton
                        : TRICK_PLAY_ELEMENTS.playButton);
                }
                _handleEnter() {
                    this.fireAncestors('$playOrPause');
                }
            },
            class LiveToVod extends this {
                _getFocused() {
                    return this.tag('LiveToVod') || this;
                }
            },
            LiveControlsStateFactory(this, 'PlayerControls.LiveControls'),
            MediaOptionsStateFactory(this),
        ];
    }
    _setRating() {
        this._tvRating.visible = false;
    }
    // AdControls not used for linear
    showAdControls() { }
}
LinearPlayerControlsV1.TITLE_CONTAINER_Y = 720;
export default LinearPlayerControlsV1;
