import { Lightning } from '@lightningjs/sdk';
import Item from './Item';
import { COLORS, FONT_FACE, TEXT_ALIGN } from '../../constants';
import { FastImg } from '../../helpers';
import { OLY_IMPOLICY } from '../../constants/images';
export default class BrandItemLabel extends Lightning.Component {
    static _template() {
        const duration = Item.duration;
        const timingFunction = Item.timingFunction;
        return {
            w: 150,
            h: 150,
            OuterCircle: {
                alpha: 0,
                x: 75,
                y: 75,
                mount: 0.5,
                texture: Lightning.Tools.getRoundRect(150, 150, 75, 6, COLORS.white, false, 0),
            },
            InnerCircle: {
                x: 75,
                y: 75,
                mount: 0.5,
                texture: Lightning.Tools.getRoundRect(138, 138, 69, 1.2, COLORS.warmGray, false, 0),
            },
            BrandName: {
                x: 75,
                y: 75,
                mount: 0.5,
                alpha: 0,
                text: {
                    fontSize: 36,
                    fontFace: FONT_FACE.light,
                    textAlign: TEXT_ALIGN.center,
                    maxLines: 1,
                    textColor: COLORS.lightGray2,
                },
            },
            White: {
                x: 75,
                y: 75,
                mount: 0.5,
                transitions: {
                    alpha: { duration, timingFunction },
                },
            },
            Colored: {
                x: 75,
                y: 75,
                mount: 0.5,
                transitions: {
                    alpha: { duration, timingFunction },
                },
            },
        };
    }
    get announce() {
        var _a;
        return (_a = this._item) === null || _a === void 0 ? void 0 : _a.ariaLabel;
    }
    set item(v) {
        this._item = v;
        if (this._item.colorBrandLogo) {
            this.patch({
                BrandName: {
                    alpha: 0,
                },
                White: { texture: FastImg(this._item.whiteBrandLogo).contain(60, 60, OLY_IMPOLICY.SQUARE) },
                Colored: {
                    alpha: 0,
                    texture: FastImg(this._item.colorBrandLogo).contain(60, 60, OLY_IMPOLICY.SQUARE),
                },
            });
        }
        else {
            this.patch({
                BrandName: {
                    alpha: 1,
                    text: {
                        text: this._item.displayTitle,
                    },
                },
                White: {
                    alpha: 0,
                },
                Colored: {
                    alpha: 0,
                },
            });
        }
        this._focusAnimation();
    }
    // @ts-expect-error TS(2611): 'active' is defined as a property in class 'Compon... Remove this comment to see the full error message
    set active(v) {
        this._item.active = v;
        this._focusAnimation();
    }
    _focus() {
        this._focusAnimation();
    }
    _unfocus() {
        this._focusAnimation();
    }
    _focusAnimation() {
        const hasFocus = this.hasFocus();
        if (hasFocus) {
            this.patch({
                OuterCircle: {
                    alpha: 1,
                },
                InnerCircle: {
                    texture: Lightning.Tools.getRoundRect(138, 138, 69, 0, 0, true, COLORS.white),
                },
            });
            if (this._item.colorBrandLogo) {
                this.patch({
                    White: {
                        alpha: 0,
                    },
                    Colored: {
                        alpha: 1,
                    },
                });
            }
            else {
                this.patch({
                    BrandName: {
                        text: {
                            textColor: COLORS.thunder3,
                        },
                    },
                });
            }
        }
        else {
            this.patch({
                OuterCircle: {
                    alpha: 0,
                },
                InnerCircle: {
                    texture: this._item.active
                        ? Lightning.Tools.getRoundRect(138, 138, 69, 0, 0, true, COLORS.white)
                        : Lightning.Tools.getRoundRect(138, 138, 69, 1.2, COLORS.warmGray, false, 0),
                },
            });
            if (this._item.colorBrandLogo) {
                this.patch({
                    White: {
                        alpha: this._item.active ? 0 : 1,
                    },
                    Colored: {
                        alpha: this._item.active ? 1 : 0,
                    },
                });
            }
            else {
                this.patch({
                    BrandName: {
                        text: {
                            textColor: this._item.active ? COLORS.thunder3 : COLORS.lightGray2,
                        },
                    },
                });
            }
        }
    }
    static get tag() {
        return 'BrandItemLabel';
    }
}
