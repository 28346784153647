import { cloneDeep } from 'lodash';
import { Subject, take } from 'rxjs';
var PlaceholderRequestStatus;
(function (PlaceholderRequestStatus) {
    PlaceholderRequestStatus["SUCCESS"] = "success";
    PlaceholderRequestStatus["REJECT"] = "reject";
})(PlaceholderRequestStatus || (PlaceholderRequestStatus = {}));
const UPDATE_DELTA = 50;
export class PlaceholderRequestController {
    constructor(client) {
        this._subject = new Subject();
        this.fetch = (data) => {
            const config = data.variables.componentConfigs.join(',');
            if (!this._data) {
                this._data = data;
                this._setTimeout();
            }
            const index = this._data.variables.componentConfigs.findIndex((c) => c === config);
            if (index === -1) {
                this._data.variables.componentConfigs.push(config);
                return fetchPlaceholder(this, this._data.variables.componentConfigs.length - 1);
            }
            return fetchPlaceholder(this, index);
        };
        this._client = client;
    }
    _setTimeout() {
        setTimeout(() => {
            const data = cloneDeep(this._data);
            this._data = undefined;
            this._client
                .handleQuery(data)
                .then((res) => {
                this._subject.next({ status: PlaceholderRequestStatus.SUCCESS, payload: res });
            })
                .catch((res) => {
                this._subject.next({ status: PlaceholderRequestStatus.REJECT, payload: res });
            });
        }, UPDATE_DELTA);
    }
}
const fetchPlaceholder = (controller, index) => {
    const transformResponse = (r) => {
        const response = r.data.componentsForPlaceholders;
        const range = response.placeholderRanges[index];
        const [start, end] = range.range;
        const components = response.components.slice(start, end);
        return {
            data: {
                componentsForPlaceholders: {
                    components,
                    placeholderRanges: {
                        ...range,
                        range: [0, components.length],
                    },
                },
            },
        };
    };
    return new Promise((res, rej) => {
        controller._subject.pipe(take(1)).subscribe((r) => {
            if (r.status === PlaceholderRequestStatus.SUCCESS) {
                res(transformResponse(r.payload));
            }
            rej(r.payload);
        });
    });
};
