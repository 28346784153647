import { Language, Lightning } from '@lightningjs/sdk';
import Loader from '../loader/Loader';
import LabelsList, { LabelsListRenderingType } from '../labelsList/LabelsList';
import { setSmooth } from '../../helpers';
import { sendMetric } from '../../lib/analytics/Analytics';
import { EVENTS } from '../../lib/analytics/types';
import { COLORS, ENTITY_TYPES, FONT_FACE, TEXT_ALIGN, VERTICAL_ALIGN } from '../../constants';
export default class LinksSelectableGroup extends Lightning.Component {
    static _template() {
        return {
            Title: {
                alpha: 0,
                y: 25,
                mountY: 0.5,
                text: {
                    textColor: COLORS.white,
                    verticalAlign: VERTICAL_ALIGN.middle,
                    textAlign: TEXT_ALIGN.center,
                    fontSize: 40,
                    fontFace: FONT_FACE.light,
                },
            },
            LabelsList: {
                type: LabelsList,
                mode: LabelsListRenderingType.HORIZONTAL,
                x: 922.5,
                mountX: 0.5,
            },
            Data: {},
            ErrorMessage: {
                alpha: 0,
                x: 960,
                mountX: 0.5,
                y: 264,
                text: {
                    textColor: COLORS.white,
                    verticalAlign: VERTICAL_ALIGN.middle,
                    textAlign: TEXT_ALIGN.center,
                    fontSize: 48,
                    fontFace: FONT_FACE.light,
                    text: Language.translate('noContentAvailable'),
                },
            },
            Loader: {
                alpha: 10,
                type: Loader,
                y: 264,
            },
        };
    }
    _build() {
        this._labelsList = this.tag('LabelsList');
    }
    get defaultState() {
        return this._dataItemsAvailable ? 'Data' : '';
    }
    get initialFocusState() {
        return this._initialFocusState ? this._initialFocusState : this.defaultState;
    }
    set initialFocusState(focusState) {
        this._initialFocusState = focusState;
    }
    set spawner(v) {
        this._spawner = v;
    }
    get spawner() {
        return this._spawner;
    }
    set data(v) {
        var _a;
        this._selectedIndex = this._spawner.initiallySelected;
        this.title = this._spawner.itemLabelsTitle;
        this.setLabelsList(this._spawner.createItemLabels((_a = v.obj) === null || _a === void 0 ? void 0 : _a.fontSize), this._selectedIndex);
        this.loadDataItems().then(() => this._setState(this.initialFocusState));
    }
    async loadDataItems() {
        setSmooth(this.tag('Loader'), 'alpha', 1);
        setSmooth(this.tag('Data'), 'alpha', 0);
        setSmooth(this.tag('ErrorMessage'), 'alpha', 0);
        this.dataItems = await this._spawner.createData(this.stage, this._selectedIndex);
    }
    async getMoreItems(queryVariables) {
        const { items, moreItems } = await this._spawner.getMoreItems(queryVariables);
        this.tag('Data').moreItems = moreItems;
        this.tag('Data').addItems(items);
    }
    getDataElement() {
        return this.tag('Data');
    }
    set title(v) {
        if (v) {
            this.patch({
                Title: {
                    alpha: 1,
                    text: { text: v },
                },
            });
            this.tag('Title').loadTexture();
            const titleWidth = this.tag('Title').finalW;
            this.patch({
                LabelsList: {
                    x: titleWidth + 47,
                    mountX: 0,
                },
            });
        }
        else {
            this.patch({
                Title: {
                    alpha: 0,
                },
                LabelsList: {
                    x: 922.5,
                    mountX: 0.5,
                },
            });
        }
    }
    setLabelsList(itemsLabelsList, selectedIndex) {
        this._itemLablesCount = itemsLabelsList.length;
        if (this._labelsList) {
            this._labelsList.items = itemsLabelsList;
            this._labelsList.activeIndex = selectedIndex;
        }
    }
    set dataItems(v) {
        if (v) {
            this._dataItemsAvailable = true;
            v.y = 100;
            this.patch({
                Data: v,
            });
            setSmooth(this.tag('Data'), 'alpha', 1);
        }
        else {
            this._dataItemsAvailable = false;
            setSmooth(this.tag('ErrorMessage'), 'alpha', 1);
        }
        setSmooth(this.tag('Loader'), 'alpha', 0);
    }
    showItemLabels(v) {
        setSmooth(this._labelsList, 'alpha', v, {
            duration: 0.3,
            delay: 0,
        });
    }
    _focus() {
        // We only need to update the init focus state when
        // no prev state has been set
        if (!this.state) {
            this._setState(this.initialFocusState);
        }
    }
    /**
     * Toggle display (opacity) of title
     *
     * When this lists slides, currently only SNL seasons,
     * This is required so season nums don't collide with title
     * as they scroll out. When seasons scroll back, show it again.
     *
     * @param {number} v alpha value (1,0) to show hide
     */
    $toggleTitleSeason(v) {
        this.tag('Title').setSmooth('alpha', v, { duration: 0.3 });
    }
    $getMoreItems(queryVariables) {
        this.getMoreItems(queryVariables);
    }
    static _states() {
        return [
            class LabelsList extends this {
                _handleDown() {
                    if (this._dataItemsAvailable) {
                        this._setState('Data');
                    }
                }
                _getFocused() {
                    return this._labelsList || this;
                }
                trackContentClick() {
                    const params = {
                        entity: { analytics: {}, entityType: ENTITY_TYPES.BRAND },
                        shelf: {
                            position: this._selectedIndex + 1,
                            customPosition: 1,
                            analytics: {},
                        },
                    };
                    sendMetric(EVENTS.CONTENT_CLICK, params);
                }
                _handleEnter() {
                    var _a;
                    this.trackContentClick();
                    // Store So we can use it later.
                    const prevIndex = this._selectedIndex;
                    this._selectedIndex = (_a = this._labelsList) === null || _a === void 0 ? void 0 : _a.focusIndex;
                    this.fireAncestors('$selectedLinkChanged', {
                        prev: this._spawner.getLabelByIndex(prevIndex),
                        new: this._spawner.getLabelByIndex(this._selectedIndex),
                    });
                    this.loadDataItems();
                }
            },
            class Data extends this {
                $enter() {
                    this.fireAncestors('$focusedData', true);
                }
                $exit() {
                    this.fireAncestors('$focusedData', false);
                }
                _handleUp() {
                    if (this._itemLablesCount === 1) {
                        return false;
                    }
                    else {
                        this._setState('LabelsList');
                    }
                }
                _getFocused() {
                    return this.tag('Data') || this;
                }
            },
        ];
    }
}
