import { Storage, Settings } from '@lightningjs/sdk';
class Preferences {
    static store(property, value) {
        Storage.set(Preferences.prefix + property, value);
    }
    static get(property) {
        return Storage.get(Preferences.prefix + property);
    }
    static remove(property) {
        Storage.remove(Preferences.prefix + property);
    }
    static get prefix() {
        return Settings.get('platform', 'config.appName', 'nbc').toUpperCase() + '.';
    }
}
Preferences.ACCESS_TOKEN = 'accessToken';
Preferences.DEVICE_ID = 'deviceId';
Preferences.CLOSED_CAPTION = 'closedCaptions';
Preferences.CLOSED_CAPTION_FONT_STYLE = 'CCFontStyle';
Preferences.CLOSED_CAPTION_FONT_OPACITY = 'CCFontOpacity';
Preferences.CLOSED_CAPTION_FONT_SIZE = 'CCFontSize';
Preferences.CLOSED_CAPTION_FONT_COLOR = 'CCFontColor';
Preferences.CLOSED_CAPTION_BACKGROUND_OPACITY = 'CCBackgroundOpacity';
Preferences.CLOSED_CAPTION_BACKGROUND_COLOR = 'CCBackgroundColor';
Preferences.CLOSED_CAPTION_WINDOW_OPACITY = 'CCWindowOpacity';
Preferences.CLOSED_CAPTION_WINDOW_COLOR = 'CCWindowColor';
Preferences.CLOSED_CAPTION_EDGE_OPACITY = 'CCEdgeOpacity';
Preferences.CLOSED_CAPTION_EDGE_STYLE = 'CCEdgeStyle';
Preferences.CLOSED_CAPTION_EDGE_COLOR = 'CCEdgeColor';
export default Preferences;
