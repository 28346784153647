import { IPlayerEvent } from './IPlayerEvent';
export class AudioTracksEvent extends IPlayerEvent {
    constructor(data) {
        super();
        this._data = data;
    }
    get data() {
        return this._data;
    }
}
