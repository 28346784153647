import List from './List';
export default class TrickPlayImageList extends List {
    _init() {
        super._init();
        this._index = 3;
    }
    _handleLeft() {
        return false;
    }
    _handleRight() {
        return false;
    }
    _handleBack(e) {
        return false;
    }
}
