export default class MvpdData {
    constructor(mvpd, userId, expires, adobePassErrorMappings, mvpdProviderData, metadata, preAuthData) {
        this._mvpd = mvpd;
        this._userId = userId;
        this._expires = expires;
        this._adobePassErrorMappings = adobePassErrorMappings;
        this._mvpdProviderData = mvpdProviderData;
        this._metaData = metadata;
        this._preAuthData = preAuthData;
    }
    set metaData(metaData) {
        this._metaData = metaData;
    }
    get zip() {
        var _a, _b;
        return (_b = (_a = this._metaData) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.zip;
    }
    get encryptedZip() {
        var _a, _b;
        return (_b = (_a = this._metaData) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.encryptedZip;
    }
    get mvpd() {
        return this._mvpd;
    }
    get mvpdDisplayName() {
        var _a;
        return (_a = this._mvpdProviderData) === null || _a === void 0 ? void 0 : _a.mvpd_display_name;
    }
    get mvpdAdvertisingKey() {
        var _a;
        return (_a = this._mvpdProviderData) === null || _a === void 0 ? void 0 : _a.advertisingKey;
    }
    get mvpdProviderData() {
        return this._mvpdProviderData;
    }
    get expires() {
        return this._expires;
    }
    get userId() {
        return this._userId;
    }
    get preAuthData() {
        return this._preAuthData;
    }
    get adobePassErrorMappings() {
        return this._adobePassErrorMappings;
    }
}
