import Collection from './Collection';
import { GuideProgram } from '../index';
export default class GuideSchedule extends Collection {
    constructor(obj = {}) {
        super(obj);
        this.obj.programs = this.mapPrograms(this.obj.programs);
    }
    mapPrograms(programs) {
        return programs.map((program) => {
            return new GuideProgram(program);
        });
    }
    get programs() {
        return this.obj.programs;
    }
}
