import { Lightning } from '@lightningjs/sdk';
import { COLORS, FLEX_DIRECTION, FONT_FACE } from '../../../constants';
import { CMPPageSelectedTabIndex } from './hook';
class Tab extends Lightning.Component {
    constructor() {
        super(...arguments);
        this.index = 0;
        this._selectedTab = CMPPageSelectedTabIndex(this._onSelectedTabChange.bind(this));
    }
    static _template() {
        return {
            Bg: {
                rect: true,
                color: COLORS.lightGray13,
                w: 674,
            },
            Label: {
                y: 38,
                x: 72,
                color: COLORS.dark11,
                text: {
                    fontFace: FONT_FACE.regular,
                    fontSize: 33,
                    wordWrapWidth: 570,
                    text: this.bindProp('label'),
                },
            },
        };
    }
    _init() {
        this.tag('Label').on('txLoaded', () => {
            const h = this.tag('Label').renderHeight + 76;
            this.patch({ h, Bg: { h } });
        });
    }
    _onSelectedTabChange(index) {
        const el = this.tag('Bg');
        if (index !== this.index) {
            el.color = COLORS.lightGray13;
        }
    }
    _focus() {
        this.patch({ Bg: { color: COLORS.dark11 }, Label: { color: COLORS.white } });
    }
    _unfocus() {
        const current = this._selectedTab.value === this.index;
        this.patch({
            Bg: { color: current ? COLORS.lightGray14 : COLORS.lightGray13 },
            Label: { color: COLORS.dark11 },
        });
    }
}
export class VerticalTabs extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._tabIndex = 0;
        this._selectedTab = CMPPageSelectedTabIndex();
        this._tabs = [];
    }
    static _template() {
        return {
            rect: true,
            color: COLORS.lightGray13,
            w: 674,
            h: 776,
            Content: {
                flex: {
                    direction: FLEX_DIRECTION.column,
                },
            },
            Line: {
                x: 674,
                y: 0,
                rect: true,
                w: 2,
                h: 776,
                color: COLORS.dark11,
            },
        };
    }
    set tabs(tabs) {
        if (!tabs.length)
            return;
        this._tabs = tabs;
        this.tag('Content').patch(tabs.reduce((acc, item, index) => ({
            ...acc,
            [`Tab${index}`]: {
                type: Tab,
                index,
                label: item.label,
            },
        }), {}));
        this._setState('Ready'); // Focus issue fix
    }
    _handleUp() {
        if (this._tabIndex > 0)
            this._tabIndex--;
    }
    _handleDown() {
        if (this._tabIndex < this._tabs.length - 1) {
            this._tabIndex++;
        }
        else {
            return false;
        }
    }
    _handleEnter() {
        this._selectedTab.set(this._tabIndex);
    }
    static _states() {
        return [
            class Ready extends this {
                _getFocused() {
                    return this.tag(`Tab${this._tabIndex}`) || this;
                }
            },
        ];
    }
}
