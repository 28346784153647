import { Lightning, Utils } from '@lightningjs/sdk';
import { FONT_FACE } from '../../constants';
export default class SubMenuItem extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._isActive = false;
    }
    static _template() {
        return {
            h: 68,
            alpha: 0.6,
            Item: {
                h: (h) => h,
                ItemText: {
                    x: 0,
                    text: {
                        fontFace: FONT_FACE.semiBold,
                        fontSize: 35,
                    },
                },
                Icon: {
                    x: 0,
                    y: 25,
                    mountY: 0.5,
                },
            },
        };
    }
    _build() {
        this._itemText = this.tag('Item.ItemText');
        this._icon = this.tag('Item.Icon');
    }
    set item(v) {
        this._item = v;
        this._isActive = v.active;
        if (v.label) {
            this.patch({
                Item: {
                    y: 10,
                    ItemText: {
                        text: {
                            paddingLeft: v.iconWidth + 20,
                            text: v.label,
                        },
                    },
                    Icon: {
                        w: v.iconWidth,
                        src: Utils.asset(`images/ItemHome/${v.icon}.png`),
                    },
                },
            });
            this.announce = v.label;
            this._itemText.loadTexture();
            if (this._width && this._width < this._itemText.finalW) {
                this._width = this._itemText.finalW;
                this.fireAncestors('$updateSubMenu');
            }
            else {
                this._width = this._itemText.finalW;
            }
        }
        this._focusAnimation();
    }
    get item() {
        return this._item;
    }
    set current(v) {
        if (v === this._current || this.item.icon)
            return;
        this._current = v;
        this._itemText.patch({ text: { fontFace: v ? 'SemiBold' : 'Regular' } });
    }
    get width() {
        return this._width;
    }
    set index(v) {
        this._index = v;
    }
    _focus() {
        this._focusAnimation();
    }
    _unfocus() {
        this._focusAnimation();
    }
    _focusAnimation() {
        if (this.item.label) {
            this.tag('Item').patch({
                delay: this.hasFocus() ? 0.5 : 0,
            });
        }
        if (this.item.icon && this.item.active) {
            this._icon.patch({
                src: Utils.asset(`images/${this._isActive ? this.item.active : this.item.icon}`),
            });
        }
    }
}
