import { Lightning } from '@lightningjs/sdk';
import { BUTTON_PROPS, GROUP_PROPS } from './helpers/buttonGroupHelper';
import ModalManager from '../../../lib/ModalManager';
const LABEL_NOT_AVAILABLE = 'OK';
export default class StreamNotAvailableButtonGroup extends Lightning.Component {
    static _template() {
        return {
            ...GROUP_PROPS,
            ConfirmButton: {
                label: LABEL_NOT_AVAILABLE,
                ...BUTTON_PROPS,
            },
        };
    }
    _getFocused() {
        return this.tag('ConfirmButton') || this;
    }
    _handleEnter() {
        this.fireAncestors('$trackCtaAnalytics', LABEL_NOT_AVAILABLE);
        ModalManager.close();
    }
}
