import { LiveContentType, VideoAnalyticsEntitlement } from '../generated/types';
// Debug hardcoded assets array
const debugAssets = () => {
    return [...replayTileDebugAssets(), ...videoTileDebugAssets(), ...onAirNowTileDebugAssets()];
};
const replayTileDebugAssets = () => {
    return [
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdhdliveextra.akamaized.net/out/v1/0037a25f87fb40b3b65c29b393cc72c9/6272771d11ba4d55b7141925c6aae196/df4d5d800eab4f0c92857bc30e6acafa/index.m3u8',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'Harrington feels validation in HOF induction',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                offerType: 'free',
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdhdliveextra.akamaized.net/out/v1/89c22069844f400e8f8c2a4f45869228/6272771d11ba4d55b7141925c6aae196/df4d5d800eab4f0c92857bc30e6acafa/index.m3u8',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'Highlights: Valspar Championship, Round 2',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                offerType: 'free',
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdhdliveextra.akamaized.net/out/v1/c8e2d9b8c8ef4fb196e33e8300d3c31b/6272771d11ba4d55b7141925c6aae196/df4d5d800eab4f0c92857bc30e6acafa/index.m3u8',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'AAMP suggested stream 1',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                offerType: 'free',
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdtlmdeportes.akamaized.net/out/v1/b14f144be5bc488e973a8f12fee89b09/b3ae96cf68224b8aa7c57264179ab7f1/6bad25ccd9854963ba64c84b6c0dc984/index.m3u8?audio=all&subtitle=all&forcedNarrative=true',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'AAMP suggested stream 2',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                offerType: 'free',
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdhdliveextra.akamaized.net/out/v1/c8e2d9b8c8ef4fb196e33e8300d3c31b/6272771d11ba4d55b7141925c6aae196/df4d5d800eab4f0c92857bc30e6acafa/index.m3u8',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'Where are Foxes Premier League champions now?',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                offerType: 'free',
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdhdliveextra.akamaized.net/out/v1/92c5d1b4bf5d4b8ab89cb3362c05a405/6272771d11ba4d55b7141925c6aae196/df4d5d800eab4f0c92857bc30e6acafa/index.m3u8',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'Reynolds: Ohtani wont be an Angel next year',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdolymprio.akamaized.net/out/v1/55d12f557aeb4d658e90ef4577341359/2018cc0640224ac78d406b1c6d5278f3/8f005a4ac82248b58f81807c2e2c356c/index.m3u8',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'Miller-Uibo claims back-to-back golds, Felix wins 10th medal',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdolymprio.akamaized.net/out/v1/e7d77d3d9a634338a8e0101862402423/2018cc0640224ac78d406b1c6d5278f3/8f005a4ac82248b58f81807c2e2c356c/index.m3u8',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'Rio 2016: Team USA claims show jumping silver',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdtlmdeportes.akamaized.net/out/v1/b14f144be5bc488e973a8f12fee89b09/b3ae96cf68224b8aa7c57264179ab7f1/6bad25ccd9854963ba64c84b6c0dc984/index.m3u8',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'Álex Palou: "Ha sido un poco frustrante para nosotros"',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
        {
            component: 'ReplayTile',
            meta: null,
            treatments: [],
            __typename: 'ReplayTile',
            replayTileData: {
                HARDCODEDSTREAM: 'https://stageamdtlmdeportes.akamaized.net/out/v1/6c11d952a6a642258e5cd19ed8ec8ba1/b3ae96cf68224b8aa7c57264179ab7f1/6bad25ccd9854963ba64c84b6c0dc984/index.m3u8',
                instanceID: '3a39a283-a3f2-42a7-adf0-161f830adc50',
                __typename: 'ReplayTileData',
                v4ID: 'f570a3a08c88df12cf44c653b78be2a87b551858',
                ariaLabel: 'NEW, NHL, RSN.Penguins at Capitals 3.9.AM, Aired on 03/09/23',
                brandDisplayTitle: 'NBC Sports Washington',
                colorBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-Color-392x300.png',
                image: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2023/3/09/8001059_1920x1080.jpg',
                pid: 8001059,
                relativePath: 'watch/nhl/rsnpenguins-at-capitals-39am/8001059',
                resourceId: 'CSNMidAtlantic',
                channelId: 'nbcswashington',
                secondaryTitle: 'Anthony Hudson: "Tenemos una gran oportunidad"',
                startDate: '2023-03-09T17:00:00.000Z',
                title: 'Replay Tile',
                whiteBrandLogo: 'https://stage-img.nbc.com/sites/nbcunbc/files/images/2022/9/12/NBCSports-Washington-Logo-White-392x300.png',
                tertiaryTitle: '03/09/23',
                labelBadge: 'NEW',
                programmingType: 'Full Event Replay',
                genre: 'Sports',
                secondaryGenre: 'Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
            },
            analytics: {
                analyticsType: 'ReplayTileAnalyticsAttributes',
                title: 'RSN.Penguins at Capitals 3.9.AM',
                brand: {
                    title: 'NBC Sports Washington',
                    __typename: 'BrandAnalytics',
                },
                genre: 'Sports',
                sport: 'Hockey',
                league: 'NHL',
                event: 'RSN.Penguins at Capitals 3.9.AM',
                programmingType: 'Full Event Replay',
                secondaryGenre: 'Hockey',
                listOfGenres: 'Sports, Hockey',
                liveEntitlement: 'Entitled',
                entitlement: VideoAnalyticsEntitlement.Entitled,
                locked: true,
                __typename: 'ReplayTileAnalyticsAttributes',
            },
        },
    ];
};
const videoTileDebugAssets = () => {
    return [
        {
            component: 'VideoTile',
            meta: null,
            treatments: [],
            __typename: 'VideoTile',
            data: {
                HARDCODEDSTREAM: 'https://vod-lf-oneapp2-stg.akamaized.net/stage/e/ABr/VU8/9000102101/1676999317633-GYp3Y/cmaf/mpeg_cenc_2sec/master_cmaf.m3u8',
                instanceID: '317c5362-2208-4fcb-904c-f93ae4781613',
                __typename: 'VideoItem',
                v4ID: '88fd6a964fec7aafb622a33e5b918920bc56b8a2',
                title: 'Asset that has been hard coded into the app',
                secondaryTitle: 'Hard Coded Secondary',
                tertiaryTitle: null,
                description: null,
                image: 'https://img.nbc.com/sites/nbcunbc/files/images/2023/2/24/BelAirS2.jpg',
                gradientStart: '#b78623',
                gradientEnd: '#430c0c',
                labelBadge: null,
                lastModified: '2023-02-25T07:34:48.000Z',
                secondaryTitleTag: null,
                locked: false,
                mpxGuid: 'PCKN828805879',
                programmingType: 'Trailer',
                episodeNumber: null,
                seasonNumber: '0',
                airDate: '2023-02-23T14:00:00.000Z',
                percentViewed: null,
                permalink: 'http://www.nbc.com/peacock-trailers/video/bel-air/PCKN828805879',
                lastWatched: null,
                duration: 60,
                genre: 'Drama',
                rating: 'TV-MA',
                lightPrimaryColor: '#b78623',
                darkPrimaryColor: '#430c0c',
                seriesShortTitle: 'Video Tile',
                movieShortTitle: null,
                whiteBrandLogo: 'https://img.nbc.com/sites/nbcunbc/files/images/2020/7/06/Peacock-Logo-White-450x137.png',
                colorBrandLogo: 'https://img.nbc.com/sites/nbcunbc/files/images/2020/7/06/Peacock-Logo-Color-450x137.png',
                brandDisplayTitle: 'Peacock',
                mpxAccountId: '2708904471',
                mpxAdPolicy: '189593252',
                resourceId: 'peacock',
                channelId: 'peacock',
                externalAdvertiserId: 'PCK_MPX_PCKN828805879',
                ariaLabel: 'Trailers, Bel-Air, Clip, Aired on 02/23/23',
                longDescription: null,
                ctaText: null,
                ctaTextColor: null,
                brandMachineName: 'peacock',
                durationBadge: null,
            },
            analytics: {
                brand: {
                    title: 'Asset that has been hard coded into the app',
                    __typename: 'BrandAnalytics',
                },
                series: 'Peacock',
                title: 'Asset that has been hard coded into the app',
                programmingType: 'Trailer',
                episodeNumber: null,
                seasonNumber: '0',
                mpxGuid: 'PCKN828805879',
                locked: false,
                duration: 60,
                movie: null,
                genre: 'Drama',
                sport: null,
                league: null,
                language: null,
                event: null,
                __typename: 'VideoTileAnalyticsAttributes',
            },
        },
        {
            component: 'VideoTile',
            meta: null,
            treatments: [],
            __typename: 'VideoTile',
            data: {
                HARDCODEDSTREAM: 'https://vod-sf-oneapp2-stg.akamaized.net/stage/accesshollywood/uKv/AjE/ACCN888709718/1676999194330-D_bMf/cmaf/mpeg_2sec/master_cmaf.m3u8',
                instanceID: '317c5362-2208-4fcb-904c-f93ae4781613',
                __typename: 'VideoItem',
                v4ID: '88fd6a964fec7aafb622a33e5b918920bc56b8a2',
                title: 'Second asset that has been hard coded into the app',
                secondaryTitle: 'Second Hard Coded Secondary',
                tertiaryTitle: null,
                description: null,
                image: 'https://img.nbc.com/sites/nbcunbc/files/images/2023/2/24/BelAirS2.jpg',
                gradientStart: '#b78623',
                gradientEnd: '#430c0c',
                labelBadge: null,
                lastModified: '2023-02-25T07:34:48.000Z',
                secondaryTitleTag: null,
                locked: false,
                mpxGuid: 'PCKN828805879',
                programmingType: 'Trailer',
                episodeNumber: null,
                seasonNumber: '0',
                airDate: '2023-02-23T14:00:00.000Z',
                percentViewed: null,
                permalink: 'http://www.nbc.com/peacock-trailers/video/bel-air/PCKN828805879',
                lastWatched: null,
                duration: 60,
                genre: 'Drama',
                rating: 'TV-MA',
                lightPrimaryColor: '#b78623',
                darkPrimaryColor: '#430c0c',
                seriesShortTitle: 'Video Tile',
                movieShortTitle: null,
                whiteBrandLogo: 'https://img.nbc.com/sites/nbcunbc/files/images/2020/7/06/Peacock-Logo-White-450x137.png',
                colorBrandLogo: 'https://img.nbc.com/sites/nbcunbc/files/images/2020/7/06/Peacock-Logo-Color-450x137.png',
                brandDisplayTitle: 'Peacock',
                mpxAccountId: '2708904471',
                mpxAdPolicy: '189593252',
                resourceId: 'peacock',
                channelId: 'peacock',
                externalAdvertiserId: 'PCK_MPX_PCKN828805879',
                ariaLabel: 'Trailers, Bel-Air, Clip, Aired on 02/23/23',
                longDescription: null,
                ctaText: null,
                ctaTextColor: null,
                brandMachineName: 'peacock',
                durationBadge: null,
            },
            analytics: {
                brand: {
                    title: 'Asset that has been hard coded into the app',
                    __typename: 'BrandAnalytics',
                },
                series: 'Peacock',
                title: 'Asset that has been hard coded into the app',
                programmingType: 'Trailer',
                episodeNumber: null,
                seasonNumber: '0',
                mpxGuid: 'PCKN828805879',
                locked: false,
                duration: 60,
                movie: null,
                genre: 'Drama',
                sport: null,
                league: null,
                language: null,
                event: null,
                __typename: 'VideoTileAnalyticsAttributes',
            },
        },
    ];
};
const onAirNowTileDebugAssets = () => {
    return [
        {
            component: 'OnAirNowTile',
            meta: null,
            treatments: [],
            __typename: 'OnAirNowTile',
            onAirNowTileData: {
                HARDCODEDSTREAM: 'https://vod-lf-oneapp2-stg.akamaized.net/stage/e/ABr/VU8/9000102101/1676999317633-GYp3Y/cmaf/mpeg_cenc_2sec/master_cmaf.m3u8',
                instanceID: '4b8c5fe4-78ed-4087-a0a4-da96554cd1ad',
                __typename: 'OnAirNowItem',
                v4ID: 'd501669f9b2287757721827fad0560f44adbda74',
                image: null,
                switchToNationalStream: false,
                nationalStreamQueryParameters: null,
                title: 'OnAirNowTile',
                secondaryTitle: null,
                startTime: '2023-03-10T17:00:00.000Z',
                endTime: '2023-03-10T18:00:00.000Z',
                brandV4ID: 'a3feae59c99ff18a55bbb2c1087f20c49bb6aa24',
                machineName: 'nbc',
                whiteBrandLogo: 'https://img.nbc.com/sites/nbcunbc/files/images/2023/1/05/NBC-Logo-Stacked-White-318x300.png',
                brandDisplayTitle: 'NBC',
                brandLightPrimary: '#302b63',
                brandDarkPrimary: '#0f0f1c',
                isNew: true,
                audioDescription: false,
                ratingWithAdvisories: null,
                badge: 'LIVE',
                resourceId: 'nbcentertainment',
                channelId: 'nbc',
                mpxGuid: null,
                nextEpisodeMpxGuid: null,
                relativePath: null,
                nextEpisodeRelativePath: null,
                watchTagline: null,
                ariaLabel: 'Currently Streaming, NBC News Daily, On NBC',
                streamAccessName: 'nbc',
                stationId: '9137152191577740117',
                contentType: LiveContentType.Linear,
                notification: null,
            },
            analytics: {
                isLive: true,
                episodeNumber: null,
                seasonNumber: null,
                programTitle: 'NBC News Daily',
                episodeTitle: null,
                tmsId: 'SH044094680000',
                liveEntitlement: 'Entitled',
                adobeVideoResearchTitle: '230310 - nbc - NBC News Daily',
                league: null,
                isOlympics: false,
                sport: null,
                nielsenSfCode: 'dcr',
                nielsenChannel: 'NBC Entertainment One App',
                nielsenClientId: 'us-800148',
                videoBroadcast: 'Broadcast',
                brand: {
                    title: 'NBC',
                    __typename: 'BrandAnalytics',
                },
                __typename: 'OnAirNowTileAnalyticsAttributes',
            },
        },
        {
            component: 'OnAirNowTile',
            meta: null,
            treatments: [],
            __typename: 'OnAirNowTile',
            onAirNowTileData: {
                HARDCODEDSTREAM: 'https://vod-sf-oneapp2-stg.akamaized.net/stage/accesshollywood/uKv/AjE/ACCN888709718/1676999194330-D_bMf/cmaf/mpeg_2sec/master_cmaf.m3u8',
                instanceID: '4b8c5fe4-78ed-4087-a0a4-da96554cd1ad',
                __typename: 'OnAirNowItem',
                v4ID: 'd501669f9b2287757721827fad0560f44adbda74',
                image: null,
                switchToNationalStream: false,
                nationalStreamQueryParameters: null,
                title: 'OnAirNowTile',
                secondaryTitle: null,
                startTime: '2023-03-10T17:00:00.000Z',
                endTime: '2023-03-10T18:00:00.000Z',
                brandV4ID: 'a3feae59c99ff18a55bbb2c1087f20c49bb6aa24',
                machineName: 'nbc',
                whiteBrandLogo: 'https://img.nbc.com/sites/nbcunbc/files/images/2023/1/05/NBC-Logo-Stacked-White-318x300.png',
                brandDisplayTitle: 'NBC',
                brandLightPrimary: '#302b63',
                brandDarkPrimary: '#0f0f1c',
                isNew: true,
                audioDescription: false,
                ratingWithAdvisories: null,
                badge: 'LIVE',
                resourceId: 'nbcentertainment',
                channelId: 'nbc',
                mpxGuid: null,
                nextEpisodeMpxGuid: null,
                relativePath: null,
                nextEpisodeRelativePath: null,
                watchTagline: null,
                ariaLabel: 'Currently Streaming, NBC News Daily, On NBC',
                streamAccessName: 'nbc',
                stationId: '9137152191577740117',
                contentType: LiveContentType.Linear,
                notification: null,
            },
            analytics: {
                isLive: true,
                episodeNumber: null,
                seasonNumber: null,
                programTitle: 'NBC News Daily',
                episodeTitle: null,
                tmsId: 'SH044094680000',
                liveEntitlement: 'Entitled',
                adobeVideoResearchTitle: '230310 - nbc - NBC News Daily',
                league: null,
                isOlympics: false,
                sport: null,
                nielsenSfCode: 'dcr',
                nielsenChannel: 'NBC Entertainment One App',
                nielsenClientId: 'us-800148',
                videoBroadcast: 'Broadcast',
                brand: {
                    title: 'NBC',
                    __typename: 'BrandAnalytics',
                },
                __typename: 'OnAirNowTileAnalyticsAttributes',
            },
        },
    ];
};
export default debugAssets;
