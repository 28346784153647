import SlideTile from '../SlideTile/SlideTile';
import { lockDecorator } from '../decorators';
import { getLiveStateClass, getUpcomingStateClass } from './states';
import { ENTITY_TYPES, OLY_IMPOLICY } from '../../../constants';
import { navigateUpcomingLiveItems } from '../helpers/upcomingItems';
import { FastImg } from '../../../helpers';
import TVPlatform from '../../../lib/tv-platform';
// @ts-expect-error TS(2417): Class static side 'typeof UpcomingLiveSlideTile' i... Remove this comment to see the full error message
export default class UpcomingLiveSlideTile extends SlideTile {
    set item(v) {
        this._item = v;
        this._initSlide();
        if (this._item.isUpcoming) {
            this._setState('UpcomingState');
        }
        else {
            this._setState('LiveState');
        }
    }
    _initSlide() {
        this.patch({
            ImageHolder: {
                h: 416,
                w: 741,
                Image: {
                    texture: FastImg(this._item.image).contain(741, 416, OLY_IMPOLICY.RECT_MEDIUM_1024_576),
                },
            },
            Content: {
                Logo: {
                    texture: FastImg(this._item.whiteBrandLogo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216),
                },
            },
        });
        if (this._item.title)
            this.title = this._item.title;
        this.videoTitle = this._item.videoTitle || this._item.secondaryTitle || '';
    }
    _active() {
        this._addDecorators([lockDecorator]);
    }
    _inactive() {
        if (TVPlatform.getForceGC())
            this.stage.gc();
    }
    _handleEnter() {
        this.trackContentPosition();
        this.trackContentClick(ENTITY_TYPES.UPCOMING_LIVE_SLIDE_TILE);
        navigateUpcomingLiveItems({
            item: this._item,
            fireAncestors: this.fireAncestors.bind(this),
            preventClickMetric: true,
        });
    }
    static _states() {
        return [getUpcomingStateClass(this), getLiveStateClass(this)];
    }
    static get liveProgressBarWidth() {
        return 741;
    }
    static get tag() {
        return 'UpcomingLiveSlideTile';
    }
}
