import EventsWithFilters from './AllEvents/EventsWithFilters';
import { sendMetric } from '../lib/analytics/Analytics';
import { EVENTS } from '../lib/analytics/types';
import { CUSTOM_LEAGUE } from '../constants';
import { Router } from '@lightningjs/sdk';
export default class OlympicsEventsWithFilters extends EventsWithFilters {
    _firstActive() {
        super._firstActive();
        sendMetric(EVENTS.PAGE_LOAD, {
            league: CUSTOM_LEAGUE.olympics,
            path: Router.getActiveRoute(),
        });
    }
}
