import TextBorderButton from './TextBorderButton';
export default class FocusScaledButton extends TextBorderButton {
    constructor() {
        super(...arguments);
        this._enterCallback = () => { };
    }
    set focusFontSize(focusFontSize) {
        this._focusFontSize = focusFontSize;
    }
    set focusHeight(focusHeight) {
        this.h = focusHeight;
        this._focusHeight = focusHeight;
    }
    set focusWidth(focusWidth) {
        this.w = focusWidth;
        this._focusWidth = focusWidth;
    }
    set unfocusHeight(unfocusHeight) {
        this.h = unfocusHeight;
        this._unfocusHeight = unfocusHeight;
    }
    set unfocusWidth(unfocusWidth) {
        this.w = unfocusWidth;
        this._unfocusWidth = unfocusWidth;
    }
    set enterCallback(enterCallback) {
        if (typeof enterCallback !== 'function')
            return;
        this._enterCallback = enterCallback;
    }
    _handleEnter() {
        this._enterCallback();
    }
    _captureEnter() {
        this._enterCallback();
    }
    _setAnnounce() {
        this.announce = `${this._label}, Button`;
    }
    _updateButton() {
        const [height, fontSize] = this._isFocus
            ? [this._focusHeight, this._focusFontSize]
            : [this._unfocusHeight, this._fontSize];
        if (!this._autoWidth || (this._focusWidth && this._unfocusWidth)) {
            this.w = this._isFocus ? this._focusWidth : this._unfocusWidth;
        }
        this.h = height;
        super._updateButton();
        this.patch({
            Label: {
                text: {
                    fontSize: fontSize,
                },
            },
        });
    }
}
