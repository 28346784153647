import { IPlayerEvent } from './IPlayerEvent';
export class AdBreakEvent extends IPlayerEvent {
    constructor(adBreak) {
        super();
        this._adBreak = adBreak;
    }
    get adBreak() {
        return this._adBreak;
    }
}
