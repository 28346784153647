import { Lightning } from '@lightningjs/sdk';
import VideoTile from '../VideoTile/VideoTile';
import { getLiveStateClass, getUpcomingStateClass } from './states';
import { audioLanguageDecorator, freeBadgeDecorator, lockDecorator, olympicsMedalDecorator, } from '../decorators';
import { COLORS, ENTITY_TYPES, FONT_FACE, FLEX_DIRECTION, MAX_LINES_SUFFIX, OLY_IMPOLICY, } from '../../../constants';
import { navigateUpcomingLiveItems } from '../helpers/upcomingItems';
import { FastImg } from '../../../helpers';
import TVPlatform from '../../../lib/tv-platform';
// @ts-expect-error TS(2417): Class static side 'typeof UpcomingLiveTile' incorr... Remove this comment to see the full error message
export default class SportsLiveGuideTile extends VideoTile {
    _init() {
        super._init();
        this.containerHeight = 258;
        this.containerWidth = 1268;
        this._addDecorators([
            lockDecorator,
            freeBadgeDecorator(6),
            olympicsMedalDecorator(13),
            audioLanguageDecorator,
        ]);
    }
    static _template() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        const duration = super.duration;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        const timingFunction = super.timingFunction;
        const template = super._template();
        return {
            ...template,
            ShadowBlur: {
                mount: 0.5,
                x: 210,
                y: 120,
                w: 460,
                h: 258,
                alpha: 0.8,
                type: Lightning.components.FastBlurComponent,
                amount: 1,
                content: {
                    Shadow: {
                        color: COLORS.black,
                        mount: 0.5,
                        x: 260,
                        y: 150,
                        texture: Lightning.Tools.getRoundRect(460, 258, 0),
                    },
                },
                transitions: {
                    y: { duration, timingFunction },
                    scale: { duration, timingFunction },
                    alpha: { duration, timingFunction },
                    amount: { duration, timingFunction },
                },
            },
            ImageHolder: {
                rect: true,
                colorLeft: COLORS.brightGrey,
                colorRight: COLORS.thunder2,
                Image: {
                    pivotX: 0,
                    transitions: {
                        scale: { duration, timingFunction },
                    },
                },
                WatchedOverly: {
                    alpha: 1,
                    color: COLORS.black7,
                    rect: true,
                    Watched: {
                        x: (w) => w / 2,
                        y: 214,
                        mount: 0.5,
                        alpha: 0.7,
                        text: {
                            fontFace: FONT_FACE.regular,
                            fontSize: 50,
                            wordWrapWidth: 420,
                            maxLines: 1,
                            textColor: COLORS.white,
                            text: '',
                        },
                    },
                },
            },
            Content: {
                y: 24,
                x: 540,
                w: 618,
                h: 234,
                TitleCard: {
                    flex: { direction: FLEX_DIRECTION.column },
                    ShortTitleContainer: {
                        flex: { direction: FLEX_DIRECTION.row },
                        ShortTitle: {
                            text: {
                                fontFace: FONT_FACE.regular,
                                fontSize: 36,
                                lineHeight: 45,
                                maxLines: 3,
                                wordWrapWidth: 560,
                            },
                        },
                    },
                    Title: {
                        flexItem: { marginTop: -8 },
                        text: {
                            fontFace: FONT_FACE.light,
                            fontSize: 30,
                            lineHeight: 40,
                            wordWrapWidth: 560,
                            maxLines: 2,
                            maxLinesSuffix: MAX_LINES_SUFFIX,
                        },
                    },
                    transitions: {
                        y: { duration, timingFunction },
                    },
                    SmartTimeStamp: {
                        flex: {
                            direction: FLEX_DIRECTION.row,
                        },
                        flexItem: { marginTop: -8 },
                        ShortTitle: {
                            visible: false,
                            flexItem: { marginRight: 10 },
                            text: {
                                fontSize: 30,
                                fontFace: FONT_FACE.light,
                                lineHeight: 40,
                            },
                        },
                        AudioLanguageContainer: {
                            flex: { direction: FLEX_DIRECTION.row },
                            flexItem: { marginTop: 4 },
                        },
                    },
                },
                Logo: {
                    y: 188,
                    x: 708,
                    mountX: 1,
                    transitions: {
                        y: { duration, timingFunction },
                    },
                },
            },
        };
    }
    set item(v) {
        var _a, _b, _c, _d;
        this._item = v;
        this.patch({
            ImageHolder: {
                w: 460,
                h: 258,
                Image: { texture: FastImg(v.image).contain(460, 258, OLY_IMPOLICY.RECT_SMEDIUM_640_360) },
            },
            Content: {
                TitleCard: {
                    ShortTitleContainer: {
                        ShortTitle: {
                            text: {
                                text: this._getMainTitle(),
                                maxLines: 1,
                                maxLinesSuffix: MAX_LINES_SUFFIX,
                            },
                        },
                    },
                    Title: {
                        text: {
                            text: this._getSubTitle(),
                            maxLines: 2,
                            maxLinesSuffix: MAX_LINES_SUFFIX,
                        },
                    },
                },
                Logo: { texture: FastImg(v.logo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216) },
            },
        });
        if ((_a = this._item) === null || _a === void 0 ? void 0 : _a.tertiaryTitle)
            this.tag('SmartTimeStamp.ShortTitle').patch({
                visible: true,
                text: { text: this._item.tertiaryTitle },
            });
        if (v.isUpcoming)
            this._setState('UpcomingState');
        // The expired tiles should remain with the live bar and badges, so the items that are not upcoming are Live and Expired items
        if (!v.isUpcoming)
            this._setState('LiveState');
        (_b = this.tag('LiveBadge')) === null || _b === void 0 ? void 0 : _b.patch({
            y: 203,
        });
        const progressPatch = ((_c = v.analytics) === null || _c === void 0 ? void 0 : _c.isOlympics) ? { alpha: 0 } : { totalProgressWidth: 460 };
        (_d = this.tag('ProgressBar')) === null || _d === void 0 ? void 0 : _d.patch(progressPatch);
    }
    _getMainTitle() {
        var _a;
        return (_a = this._item) === null || _a === void 0 ? void 0 : _a.title;
    }
    _getSubTitle() {
        var _a;
        return (_a = this._item) === null || _a === void 0 ? void 0 : _a.secondaryTitle;
    }
    _inactive() {
        if (TVPlatform.getForceGC())
            this.stage.gc();
    }
    _handleEnter() {
        this.trackContentPosition();
        this.trackContentClick(ENTITY_TYPES.UPCOMING_LIVE_TILE);
        navigateUpcomingLiveItems({
            item: this._item,
            fireAncestors: this.fireAncestors.bind(this),
            preventClickMetric: true,
            routerBackDisabled: true,
            openLoader: true,
        });
    }
    _getTrackParams() {
        return {
            isUpcoming: this._item.isUpcoming,
            isLive: this._item.isLive,
        };
    }
    static _states() {
        return [getUpcomingStateClass(this), getLiveStateClass(this)];
    }
    get olympicsMedalContainer() {
        return this.tag('ShortTitleContainer');
    }
    static get tag() {
        return 'SportsLiveGuideTile';
    }
    static get width() {
        return 460;
    }
}
