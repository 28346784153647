import { Registry } from '@lightningjs/sdk';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { isEpgProgram } from '../../../store/PlayerStore/actions/epg';
import { PlayerStates } from '../../../constants';
export const PlayerLiveToVodStateFactory = (base) => class LiveToVod extends base {
    $enter() {
        var _a;
        if (this._liveToVodFixed && isEpgProgram(PlayerStoreSingleton.program)) {
            this._liveToVodFixed.visible = true;
            const duration = ((_a = PlayerStoreSingleton.program) === null || _a === void 0 ? void 0 : _a.liveToVodButtonDuration) || {};
            this._liveToVodTimeout = Registry.setTimeout(() => {
                if (this._getState() === PlayerStates.LPPlayer_LiveToVod) {
                    this._setState(PlayerStates.LPPlayer);
                }
            }, duration);
        }
    }
    $exit() {
        if (this._liveToVodFixed) {
            this._liveToVodFixed.visible = false;
            this._liveToVodDismissed = true;
        }
        if (this._liveToVodTimeout) {
            Registry.clearTimeout(this._liveToVodTimeout);
        }
    }
    _captureEnter() {
        return false;
    }
    _getFocused() {
        var _a;
        return (_a = this._liveToVodFixed) !== null && _a !== void 0 ? _a : (this._controls || this);
    }
};
