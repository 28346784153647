import { Img, Lightning } from '@lightningjs/sdk';
import { COLORS } from '../../../constants';
// @ts-expect-error TS(2417): Class static side 'typeof BrandLogo' incorrectly e... Remove this comment to see the full error message
export default class BrandLogo extends Lightning.Component {
    static _template() {
        const timingFunction = 'cubic-bezier(0.20, 1.00, 0.80, 1.00)';
        return {
            mount: 1,
            y: this.bindProp('positionY'),
            x: this.bindProp('positionX'),
            Shadow: {
                mount: 1,
                y: 15,
                x: 15,
                type: Lightning.components.FastBlurComponent,
                amount: 2,
                content: {},
            },
            BrandLogo: {
                mount: 1,
                alpha: 0.001,
                transitions: {
                    alpha: { duration: 0.6, timingFunction },
                },
            },
        };
    }
    _init() {
        this.tag('BrandLogo').on('txLoaded', () => {
            const logo = this.tag('BrandLogo');
            logo.setSmooth('alpha', 1);
            this.tag('Shadow').patch({
                h: logo.renderHeight + 30,
                w: logo.renderWidth + 30,
                content: {
                    x: 15,
                    y: 15,
                    color: COLORS.black5,
                    texture: logo.texture,
                },
            });
        });
    }
    set logo(logo) {
        if (!logo || !(logo === null || logo === void 0 ? void 0 : logo.path))
            return;
        this.tag('BrandLogo').patch({
            texture: Img(logo === null || logo === void 0 ? void 0 : logo.path).contain(120, 50),
        });
    }
}
