import { GraphQlClient } from '../client';
import { BonanzaPage } from '../generated/types';
import Variables from '../Variables';
const getVideoHomePageCache = (urlalias, type) => {
    const variables = Variables.showPageVariables(type, urlalias);
    try {
        return {
            data: GraphQlClient.readQuery({
                query: BonanzaPage,
                variables,
            }),
        };
    }
    catch (err) {
        return {};
    }
};
export default getVideoHomePageCache;
