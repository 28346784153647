export class IErrorViewModel {
    constructor(title, message, retryable) {
        this._title = title;
        this._message = message;
        this._retryable = retryable;
    }
    get title() {
        return this._title;
    }
    get message() {
        return this._message;
    }
    get retryable() {
        return this._retryable;
    }
}
