import { setDefaultValue, setSLEDefaultParams } from '../../../../helpers/analytics';
import { MPARTICLE_DEFAULT_ATTR_VALUE, SINGLE_LIVE_EVENT } from '../../../../constants';
const getModalLoadAttributes = (params) => {
    if (!params) {
        return {};
    }
    let defaultValueParams = {
        'Modal Name': params.modalName,
        'Modal Type': params.modalType,
        'First Visit': params === null || params === void 0 ? void 0 : params.firstVisit,
    };
    if (params.modalType.includes(SINGLE_LIVE_EVENT))
        defaultValueParams = setSLEDefaultParams(defaultValueParams);
    return setDefaultValue(defaultValueParams, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getModalLoadAttributes;
