import { Registry } from '@lightningjs/sdk';
import { createProgressHookEvent, useProgress, } from '../../../components/player/PlayerControls/hooks/useProgress';
import { inRange } from 'lodash';
import { SeekDirection } from './WithSeeking';
export const WithSeekingV2 = (component) => class extends component {
    constructor() {
        super(...arguments);
        this.TIME_STEPS = 10;
        this.multiplier = 0;
        this._lastTime = 0;
        this._seekDirection = SeekDirection.NONE;
        this._updateTimeOffset = (offset, recurseFactor) => {
            var _a, _b, _c;
            if (this._timeout)
                Registry.clearTimeout(this._timeout);
            if ((_a = this._player) === null || _a === void 0 ? void 0 : _a.isPlaying())
                (_b = this._player) === null || _b === void 0 ? void 0 : _b.pause();
            const [current, duration, seekableRange] = this._progress.value;
            const time = current + this.TIME_STEPS * offset;
            if (!inRange(time, (seekableRange === null || seekableRange === void 0 ? void 0 : seekableRange[0]) || 0, (seekableRange === null || seekableRange === void 0 ? void 0 : seekableRange[1]) || duration)) {
                this.multiplier = 0;
                if (time !== 0) {
                    // Start playing if we have reached live position
                    this.$seek();
                    (_c = this._player) === null || _c === void 0 ? void 0 : _c.play();
                }
                return;
            }
            this._progress.set(createProgressHookEvent(time));
            if (recurseFactor !== 0) {
                if ('seekIconV2' in this._controls)
                    this._controls.updateSeekBarTimerV2();
                this._timeout = Registry.setTimeout(() => this._updateTimeOffset(offset, recurseFactor), 1000 / Math.abs(recurseFactor));
            }
        };
    }
    _init() {
        super._init();
        this._progress = useProgress();
    }
    _handleTimeChange(direction) {
        var _a, _b;
        const factor = direction === SeekDirection.FF ? 1 : -1;
        if (this._seekDirection === SeekDirection.NONE) {
            this._lastTime = this._progress.value[0];
            this.multiplier = 0;
            (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireClick(direction === SeekDirection.FF ? 'Forward' : 'Rewind');
            (_b = this._analyticsDelegate) === null || _b === void 0 ? void 0 : _b.fireSeekStart();
            if ('seekIconV2' in this._controls)
                this._controls.seekIconV2 = this.TIME_STEPS * factor;
        }
        else {
            if (this._seekDirection !== direction) {
                this.multiplier = factor;
            }
            else if (Math.abs(this.multiplier) !== 3) {
                this.multiplier += factor;
            }
            if ('seekIconV2' in this._controls)
                this._controls.seekIconV2 = this.multiplier;
        }
        // Recalculate with updated multiplier - only reset timeout if we haven't reached max speed
        if (Math.abs(this.multiplier) !== 3)
            this._updateTimeOffset(factor, this.multiplier);
        this._seekDirection = direction;
    }
    $forward() {
        this._handleTimeChange(SeekDirection.FF);
    }
    $rewind() {
        this._handleTimeChange(SeekDirection.REW);
    }
    $seek() {
        var _a;
        if (this._timeout)
            Registry.clearTimeout(this._timeout);
        const currentTime = this._progress.value[0];
        // seek adjust is used for SLEs currently.
        // add time required to get the player in line with the seek bar (seconds).
        const seekAdjust = this._progress.value[3] || 0;
        (_a = this._player) === null || _a === void 0 ? void 0 : _a.seek(currentTime + seekAdjust);
    }
    /**
     * Rewinds content 5 seconds before the end.
     * Used in non-production environments.
     */
    $seekToTheEnd() {
        var _a;
        (_a = this._player) === null || _a === void 0 ? void 0 : _a.pause();
        if ('seekIconV2' in this._controls)
            this._controls.seekIconV2 = 0;
        const duration = this._progress.value[1];
        this._progress.set(createProgressHookEvent(duration - 5));
        this.$seek();
    }
    _onSeekEnd() {
        var _a;
        super._onSeekEnd();
        if ('seekIconV2' in this._controls)
            this._controls.seekIconV2 = 0;
        this._seekDirection = SeekDirection.NONE;
        (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireSeekEnd();
    }
    _handleBack(e) {
        var _a;
        switch (this._seekDirection) {
            case SeekDirection.NONE:
                return super._handleBack(e);
            case SeekDirection.REW:
                if (this._timeout)
                    Registry.clearTimeout(this._timeout);
                this._progress.set(createProgressHookEvent(this._lastTime));
                this.$seek();
                (_a = this._player) === null || _a === void 0 ? void 0 : _a.play();
                break;
            case SeekDirection.FF:
                if (this._timeout)
                    Registry.clearTimeout(this._timeout);
                this.$rewind();
                break;
            default:
                break;
        }
    }
};
