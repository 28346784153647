export var PLATFORM_TYPES;
(function (PLATFORM_TYPES) {
    PLATFORM_TYPES["XCLASS"] = "XClass";
    PLATFORM_TYPES["TIZEN"] = "tizen";
    PLATFORM_TYPES["VIZIO"] = "vizio";
    PLATFORM_TYPES["XBOX"] = "xbox";
    PLATFORM_TYPES["XBOXDEV"] = "xboxdev";
    PLATFORM_TYPES["KEPLER"] = "kepler";
})(PLATFORM_TYPES || (PLATFORM_TYPES = {}));
export var PLATFORM_TYPES_OMNITURE;
(function (PLATFORM_TYPES_OMNITURE) {
    PLATFORM_TYPES_OMNITURE["XClass"] = "XClass";
    PLATFORM_TYPES_OMNITURE["tizen"] = "Samsung TV";
    PLATFORM_TYPES_OMNITURE["vizio"] = "Vizio TV";
    PLATFORM_TYPES_OMNITURE["xbox"] = "Xbox One";
    PLATFORM_TYPES_OMNITURE["xboxdev"] = "Xbox One";
    PLATFORM_TYPES_OMNITURE["kepler"] = "AmazonFireTv";
})(PLATFORM_TYPES_OMNITURE || (PLATFORM_TYPES_OMNITURE = {}));
export var PLATFORM_TYPES_ACTIVATION;
(function (PLATFORM_TYPES_ACTIVATION) {
    PLATFORM_TYPES_ACTIVATION["XCLASS"] = "XClass";
    PLATFORM_TYPES_ACTIVATION["TIZEN"] = "samsung";
    PLATFORM_TYPES_ACTIVATION["VIZIO"] = "vizio";
    PLATFORM_TYPES_ACTIVATION["XBOX"] = "xbox";
    PLATFORM_TYPES_ACTIVATION["XBOXDEV"] = "xbox";
    PLATFORM_TYPES_ACTIVATION["KEPLER"] = "AmazonFireTv";
})(PLATFORM_TYPES_ACTIVATION || (PLATFORM_TYPES_ACTIVATION = {}));
/**
 * Returns platform type
 */
export const getPlatformName = () => DEVICE_PLATFORM || PLATFORM_TYPES.XCLASS;
export const getPlatFromNameOmniture = () => PLATFORM_TYPES_OMNITURE[DEVICE_PLATFORM] || PLATFORM_TYPES_OMNITURE.XClass;
/**
 * Check that current user agent has `WPE` (Web Platform for Embedded)
 * @returns {boolean}
 */
export const isXclass = () => / WPE/.test(window.navigator.userAgent);
export const isXbox = () => getPlatformName() === PLATFORM_TYPES.XBOX;
export const isSamsung = () => getPlatformName() === PLATFORM_TYPES.TIZEN;
export const isVizio = () => getPlatformName() === PLATFORM_TYPES.VIZIO;
// To Do get this info when there are other devices
export const getDeviceModel = () => 'None';
export const getProxyPath = () => {
    return window.PROXY_PATH || PROXY_PATH || 'static';
};
