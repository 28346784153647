import { labelBadgeDecorator } from '../../decorators';
import { getTimerToEndTile } from '../../../../helpers';
export const getUpcomingStateClass = (context) => class UpcomingState extends context {
    $enter() {
        var _a;
        if (this._item.upcomingBadge) {
            this._addDecorators([labelBadgeDecorator((_a = this._item) === null || _a === void 0 ? void 0 : _a.upcomingBadge)]);
        }
        this._timerChangeToLive = getTimerToEndTile(this._item.startTime, () => {
            this._setState('LiveState');
        });
    }
    get announce() {
        return `${this._item.upcomingAriaLabel}, press OK to select`;
    }
};
