var PlayerError;
(function (PlayerError) {
    PlayerError[PlayerError["GEO"] = 0] = "GEO";
    PlayerError[PlayerError["AUTHZ"] = 1] = "AUTHZ";
    PlayerError[PlayerError["LEMONADE"] = 2] = "LEMONADE";
    PlayerError[PlayerError["MISSING_LIVE_DATA"] = 3] = "MISSING_LIVE_DATA";
    PlayerError[PlayerError["PARENTAL_CONTROLS"] = 4] = "PARENTAL_CONTROLS";
    PlayerError[PlayerError["UNKNOWN"] = 5] = "UNKNOWN";
    PlayerError[PlayerError["RETRANS"] = 6] = "RETRANS";
    PlayerError[PlayerError["EXPIRED"] = 7] = "EXPIRED";
    PlayerError[PlayerError["SLE_ENDED"] = 8] = "SLE_ENDED";
})(PlayerError || (PlayerError = {}));
var RestrictionCodes;
(function (RestrictionCodes) {
    RestrictionCodes["geo"] = "321";
    RestrictionCodes["blacklist"] = "345";
    RestrictionCodes["blackout"] = "338";
    RestrictionCodes["retrans"] = "354";
})(RestrictionCodes || (RestrictionCodes = {}));
const RESTRICTION_MAP = {
    [RestrictionCodes.geo]: PlayerError.GEO,
    [RestrictionCodes.blacklist]: PlayerError.AUTHZ,
    [RestrictionCodes.blackout]: PlayerError.AUTHZ,
    [RestrictionCodes.retrans]: PlayerError.RETRANS,
};
const getRestrictionError = (restrictionCode) => RESTRICTION_MAP[restrictionCode] || PlayerError.UNKNOWN;
const hasGeoError = (restrictionCode) => restrictionCode === RestrictionCodes.geo;
export { PlayerError, RestrictionCodes, getRestrictionError, hasGeoError };
