import { getCurrentProgressWidth } from '../../../helpers/metadata';
import { COLORS } from '../../../constants';
export default (initialPositionY) => {
    return (tile) => {
        const currentProgressWidth = getCurrentProgressWidth({
            startTime: tile._item.startTime,
            endTime: tile._item.endTime,
            totalProgressWidth: tile.constructor.width,
        });
        tile._addProgress({
            y: initialPositionY - 12,
            totalProgressWidth: tile.constructor.width,
            totalProgressColor: COLORS.charcoalGrey,
            currentProgressWidth,
            currentProgressColor: COLORS.red,
        });
    };
};
