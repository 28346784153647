import { setSmooth } from '../../../../helpers';
import { ClosedCaptionsUtils } from '../../../../lib/ClosedCaptions/ClosedCaptionsUtils';
export const MainStateFactory = (base) => class Main extends base {
    $enter() {
        setSmooth(this._main, 'alpha', 1);
        setSmooth(this._captionsSettingsContainer, 'alpha', 0);
    }
    static _states() {
        return [
            class CCLanguagesList extends Main {
                get focusItem() {
                    return this.getChildItem(this._languageListIndex);
                }
                getChildItem(index = 0) {
                    return this._captionsLanguageList.children[index];
                }
                _getFocused() {
                    return this.focusItem || this;
                }
                _handleUp() {
                    if (this._languageListIndex > 0) {
                        this._languageListIndex--;
                    }
                }
                _handleDown() {
                    var _a, _b;
                    if (this._languageListIndex < this._captionsLanguageList.children.length - 1) {
                        this._languageListIndex++;
                    }
                    else if ((_b = (_a = this._audioLanguageList) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.length) {
                        this._setState('Main.AudioLanguagesList');
                    }
                    else if (this._captionsAppearanceButton.alpha) {
                        this._setState('Main.CaptionAppearanceButton');
                    }
                }
                $valueChanged(value) {
                    if (ClosedCaptionsUtils.getCCType !== value) {
                        ClosedCaptionsUtils.setCCType(value);
                        this._captionsLanguageList.children.forEach((button) => {
                            button.selected = button.optionValue === value;
                        });
                        this.fireAncestors('$ccLanguageUpdated', { value });
                    }
                }
            },
            class AudioLanguagesList extends this {
                get focusItem() {
                    return this.getChildItem(this._audioLanguageListIndex);
                }
                getChildItem(index = 0) {
                    return this._audioLanguageList.children[index];
                }
                _getFocused() {
                    return this.focusItem || this;
                }
                _handleUp() {
                    if (this._audioLanguageListIndex > 0) {
                        this._audioLanguageListIndex--;
                    }
                    else {
                        this._setState('Main.CCLanguagesList');
                    }
                }
                _handleDown() {
                    if (this._audioLanguageListIndex < this._audioLanguageList.children.length - 1) {
                        this._audioLanguageListIndex++;
                    }
                    else if (this._captionsAppearanceButton.alpha) {
                        this._setState('Main.CaptionAppearanceButton');
                    }
                }
                $valueChanged(track) {
                    this._updateAudioLanguage(track);
                }
            },
            class CaptionAppearanceButton extends Main {
                _getFocused() {
                    return this._captionsAppearanceButton || this;
                }
                _handleUp() {
                    var _a, _b;
                    if ((_b = (_a = this._audioLanguageList) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.length) {
                        this._setState('Main.AudioLanguagesList');
                    }
                    else {
                        this._setState('Main.CCLanguagesList');
                    }
                }
                _handleEnter() {
                    this._setState('CaptionsSettings.Appearance.List');
                }
            },
        ];
    }
};
