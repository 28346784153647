import { Lightning } from '@lightningjs/sdk';
import { arrayMergeSplit } from '../helpers/collection';
export default class Paragraph extends Lightning.Component {
    set text(v) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        super.text = v;
    }
    get text() {
        return this._text;
    }
    set paragraph(v) {
        this._text = v.text;
        const proto = new Lightning.textures.TextTexture(this.stage);
        proto.w = this.w;
        // proto.h = this.h;
        // @ts-expect-error TS(2339): Property 'cloneArgs' does not exist on type 'TextT... Remove this comment to see the full error message
        const settings = { ...proto.cloneArgs(), ...v };
        // Inherit font face from stage.
        if (settings.fontFace === null) {
            settings.fontFace = this.stage.getOption('defaultFontFace');
        }
        const canvas = this.stage.platform.getDrawingCanvas();
        // @ts-expect-error TS(2445): Property 'renderer' is protected and only accessib... Remove this comment to see the full error message
        const renderer = Lightning.textures.TextTexture.renderer(this.stage, canvas, settings);
        const info = renderer._calculateRenderInfo();
        const linesPerTexture = this.linesPerTexture || 5;
        this.children = arrayMergeSplit(info.lines, linesPerTexture)
            .map((ch, index) => {
            return {
                y: (index * info.lineHeight * linesPerTexture) / info.precision,
                w: info.w / info.precision,
                text: { ...settings, text: ch },
            };
        })
            // // Filter out empty spaces
            .filter((ch) => ch.text.text !== ' ');
        this.h = info.height / info.precision;
    }
}
