var RequestState;
(function (RequestState) {
    RequestState["INIT"] = "init";
    RequestState["FETCHING"] = "fetching";
    RequestState["SUCCESS"] = "success";
    RequestState["FAIL"] = "fail";
})(RequestState || (RequestState = {}));
export class RejectedResponse extends Error {
    constructor(data) {
        super('Rejected Response');
        // Needed because of babel es5 compilation issues
        Object.setPrototypeOf(this, RejectedResponse.prototype);
        this.data = data;
    }
}
export class EmptyResponse extends Error {
    constructor() {
        super('Empty Response');
        // Needed because of babel es5 compilation issues
        Object.setPrototypeOf(this, EmptyResponse.prototype);
    }
}
export class FailedResponse extends Error {
    constructor(err) {
        super('Failed Response');
        this.err = err;
        // Needed because of babel es5 compilation issues
        Object.setPrototypeOf(this, FailedResponse.prototype);
    }
}
export const createRequestConfig = (config) => config;
export const useRequest = (config) => {
    let state = RequestState.INIT;
    return {
        get state() {
            return state;
        },
        async fetch() {
            var _a, _b, _c;
            try {
                if (!config.query || !config.variables) {
                    throw new Error('Unable to make request due to missing parameters');
                }
                state = RequestState.FETCHING;
                const response = await config.query(config.variables, config.options);
                const data = config.mapper ? config.mapper(response) : response;
                if ((_a = config.reject) === null || _a === void 0 ? void 0 : _a.length) {
                    for (let i = 0; i < config.reject.length; i++) {
                        if ((_c = (_b = config.reject)[i]) === null || _c === void 0 ? void 0 : _c.call(_b, data)) {
                            throw new RejectedResponse(data);
                        }
                    }
                }
                if (!data)
                    throw new EmptyResponse();
                state = RequestState.SUCCESS;
                return data;
            }
            catch (err) {
                state = RequestState.FAIL;
                if (err instanceof RejectedResponse || err instanceof EmptyResponse)
                    throw err;
                throw new FailedResponse(err);
            }
        },
    };
};
