import IPlayerControlsStrategy from './IPlayerControlsStrategy';
import { formatTime, getUnixDate } from '../../../../helpers';
import { Language } from '@lightningjs/sdk';
import { getSubtitleForLive } from '../../helpers/metadata';
import { PlayerControlsStates } from '../../../../constants';
import { createProgressHookEvent } from '../hooks/useProgress';
export default class LinearStrategy extends IPlayerControlsStrategy {
    init(stream, program) {
        if (this.parent._getState() !== PlayerControlsStates.PlayOrPause) {
            this.parent._keyMap.reset();
            this.parent._setState(PlayerControlsStates.PlayOrPause);
        }
        const { startTime, endTime } = (program === null || program === void 0 ? void 0 : program.startTime) ? program : stream || {};
        this._startTimeUnix = getUnixDate(startTime);
        this._endTimeUnix = getUnixDate(endTime);
        this._totalTime = this._endTimeUnix - this._startTimeUnix;
        this.parent.setTitle((program === null || program === void 0 ? void 0 : program.programTitle) || Language.translate('program_unavailable'));
        this.parent.setSecondaryTitle(getSubtitleForLive(program, stream));
        this.parent._timer.aTime = formatTime(startTime);
        this.parent._timer.bTime = formatTime(endTime);
        this._progress.set(createProgressHookEvent({
            currentTime: 0,
            duration: this._totalTime,
        }));
    }
    update(e) {
        const event = { currentTime: e.time, seekableRange: e.seekableRange, seekAdjust: 0 };
        if (e.seekableRange) {
            const current = getUnixDate();
            const msViewed = current - this._startTimeUnix;
            const seekAdjust = e.seekableRange.end - msViewed;
            event.currentTime = e.time - seekAdjust;
            event.seekableRange = {
                start: e.seekableRange.start - seekAdjust,
                end: e.seekableRange.end - seekAdjust,
            };
            event.seekAdjust = seekAdjust;
        }
        this._progress.set(createProgressHookEvent(event));
    }
}
