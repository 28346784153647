import getVODAttributes from './getVodAttributes';
import getLinearAttributes, { getLinearDuration } from './getLinearAttributes';
import { setDefaultValue } from '../../../../helpers/analytics';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
const getAdBreakAttributes = ({ video = {}, adBreak = {}, ccLanguage = '', previousVideo = '', previousVideoType = '', isLive, }) => {
    var _a;
    const shelf = (video === null || video === void 0 ? void 0 : video.shelf) || {};
    const { position } = adBreak;
    return setDefaultValue({
        ...(isLive
            ? getLinearAttributes(video)
            : getVODAttributes(video, ccLanguage, previousVideo, previousVideoType)),
        'Video Duration': getLinearDuration(video.duration),
        'Ad Pod Type': isLive
            ? 'midroll'
            : typeof position === 'number'
                ? position === 0
                    ? 'preroll'
                    : 'midroll'
                : position,
        'Ad Pod Duration': adBreak.duration,
        'Ad Pod Quantity': (_a = adBreak.ads) === null || _a === void 0 ? void 0 : _a.length,
        Casting: MPARTICLE_DEFAULT_ATTR_VALUE,
        Sponsor: shelf === null || shelf === void 0 ? void 0 : shelf.sponsorName,
        'Playlist Name': shelf === null || shelf === void 0 ? void 0 : shelf.playlistMachineName,
        'Playlist Position': shelf === null || shelf === void 0 ? void 0 : shelf.playlistPosition,
    }, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getAdBreakAttributes;
