import Tile from './Tile';
export default class OnAirNowTile extends Tile {
    get audioDescription() {
        return this.onAirNowTileData.audioDescription;
    }
    get badge() {
        return this.onAirNowTileData.badge;
    }
    get channelId() {
        return this.onAirNowTileData.channelId;
    }
    get streamAccessName() {
        return this.onAirNowTileData.streamAccessName;
    }
    get endTime() {
        return this.onAirNowTileData.endTime;
    }
    get image() {
        return this.onAirNowTileData.image;
    }
    get machineName() {
        return this.onAirNowTileData.machineName;
    }
    get secondaryTitle() {
        return this.onAirNowTileData.secondaryTitle;
    }
    get startTime() {
        return this.onAirNowTileData.startTime;
    }
    get title() {
        return this.onAirNowTileData.title || {};
    }
    get whiteBrandLogo() {
        return this.onAirNowTileData.whiteBrandLogo;
    }
    get HARDCODEDSTREAM() {
        return this.onAirNowTileData.HARDCODEDSTREAM;
    }
    get liveAriaLabel() {
        return this.onAirNowTileData.ariaLabel;
    }
    get callSign() {
        return this.onAirNowTileData.callSign;
    }
}
