import TVPlatform from '../lib/tv-platform';
import { SCREEN_SIZE } from '../constants';
const calculateRenderPrecision = () => {
    return ((screen === null || screen === void 0 ? void 0 : screen.width) || window.innerWidth) / SCREEN_SIZE.width;
};
export const getRenderPrecision = () => TVPlatform.getStageSettings().precision || calculateRenderPrecision();
export const setHeightOfChildrenItems = (element) => {
    var _a;
    if ((_a = element.children) === null || _a === void 0 ? void 0 : _a.length) {
        element.h = element.children.reduce((acc, item) => acc + item.h, 0);
    }
    else {
        element.visible = false;
    }
};
