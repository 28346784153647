import { IPlayerEvent } from './IPlayerEvent';
export class LevelChangeEvent extends IPlayerEvent {
    constructor(level, bitrate) {
        super();
        this._level = level;
        this._bitrate = bitrate;
    }
    get level() {
        return this._level;
    }
    get bitrate() {
        return this._bitrate;
    }
}
