import { Language, Router } from '@lightningjs/sdk';
import ConfirmationDialog from './ConfirmationDialog';
import { getBrandName } from '../helpers';
import BuildScheme from '../lib/BuildScheme';
import { sendMetric } from '../lib/analytics/Analytics';
import { EVENTS } from '../lib/analytics/types';
import ModalManager, { isBlockingModalOpen } from '../lib/ModalManager';
import { ModalTypeTagMap } from './Modals';
export default class ExitDialog extends ConfirmationDialog {
    static _template() {
        return super._template();
    }
    set isTempPass(isTempPass) {
        this._isTempPass = isTempPass;
    }
    set program(program) {
        this._program = program;
    }
    _active() {
        this._exitAppMessage = this._isTempPass
            ? Language.translate('tempPass-exitDialog-exitApp', BuildScheme[getBrandName()].displayName)
            : Language.translate('exit_app_msg', BuildScheme[getBrandName()].displayName);
        super._setMessage(this._exitAppMessage, Language.translate('cancel'), Language.translate('exit'));
        this._setState('Cancel');
    }
    stopEventBubbling(e) {
        e.preventDefault();
        e.stopPropagation();
    }
    // @ts-expect-error TS(2416): Property '_handleBack' in type 'ExitDialog' is not... Remove this comment to see the full error message
    _handleBack(e) {
        Router.focusWidget('Menu');
        this.visible = false;
        this._isTempPass = false;
        this.stopEventBubbling(e);
    }
    // @ts-expect-error TS(2416): Property '_handleUp' in type 'ExitDialog' is not a... Remove this comment to see the full error message
    _handleUp(e) {
        this.stopEventBubbling(e);
    }
    // @ts-expect-error TS(2416): Property '_handleDown' in type 'ExitDialog' is not... Remove this comment to see the full error message
    _handleDown(e) {
        this.stopEventBubbling(e);
    }
    _handleLeft(e) {
        this.stopEventBubbling(e);
    }
    _handleRight(e) {
        this.stopEventBubbling(e);
    }
    static _states() {
        return [
            class Exit extends this {
                _getFocused() {
                    return this.tag('ConfirmButton') || this;
                }
                _handleLeft() {
                    this._setState('Cancel');
                }
                _handleEnter() {
                    var _a, _b, _c;
                    if (this._isTempPass) {
                        const params = {
                            name: 'TempPass - Stream on Peacock',
                            destinationURL: 'Peacock',
                            destinationType: 'Peacock',
                            notificationTitle: this._exitAppMessage,
                            brand: (_a = this._program) === null || _a === void 0 ? void 0 : _a.brand.title,
                            show: (_b = this._program) === null || _b === void 0 ? void 0 : _b.programTitle,
                            season: (_c = this._program) === null || _c === void 0 ? void 0 : _c.seasonNumber,
                        };
                        sendMetric(EVENTS.EXIT, params);
                    }
                    this.fireAncestors(this._isTempPass ? '$exitToPeacock' : '$close');
                    this.visible = false;
                    this._isTempPass = false;
                }
            },
            class Cancel extends this {
                _getFocused() {
                    return this.tag('CancelButton') || this;
                }
                _handleRight() {
                    this._setState('Exit');
                }
                _handleEnter() {
                    if (this._isTempPass) {
                        if (isBlockingModalOpen()) {
                            const activeModal = ModalManager.getActiveModal();
                            if (activeModal) {
                                const { key } = ModalTypeTagMap[activeModal];
                                Router.focusWidget(key);
                            }
                        }
                        else
                            Router.focusPage();
                    }
                    else
                        Router.focusWidget('Menu');
                    this._isTempPass = false;
                    this.visible = false;
                }
            },
        ];
    }
}
