import { NonLinearAdEvent } from '../../../player/model/event/NonLinearAdEvent';
import LaunchDarklySingleton from '../../../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../../lib/launchDarkly/LaunchDarklyFeatureFlags';
export const WithPauseAds = (component) => class extends component {
    _playerEventsHandler(event) {
        super._playerEventsHandler(event);
        if (event instanceof NonLinearAdEvent) {
            this._onNonLinearAdEvent(event);
        }
    }
    _onNonLinearAdEvent(event) {
        const pauseAdEnabled = (LaunchDarklySingleton === null || LaunchDarklySingleton === void 0 ? void 0 : LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.enablePauseAdsRefresh)) ||
            true;
        const { refreshMax = 3, refreshRate = 10, } = (LaunchDarklySingleton === null || LaunchDarklySingleton === void 0 ? void 0 : LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.pauseAdsRefreshCount)) || {};
        if (pauseAdEnabled && refreshRate !== null) {
            this._controls.setPauseAdsEventData(event, {
                refreshMax,
                refreshRate,
            });
        }
    }
};
