import { Lightning } from '@lightningjs/sdk';
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags';
export default class ProgressBar extends Lightning.Component {
    static _template() {
        const removePersonalization = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.removePersonalization);
        return {
            TotalProgress: {
                w: !removePersonalization ? this.bindProp('totalProgressWidth') : 0,
                h: this.bindProp('h'),
                rect: true,
                color: this.bindProp('totalProgressColor'),
            },
            CurrentProgress: {
                w: !removePersonalization ? this.bindProp('currentProgressWidth') : 0,
                h: this.bindProp('h'),
                rect: true,
                color: this.bindProp('currentProgressColor'),
            },
        };
    }
}
