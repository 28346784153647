import { Language, Lightning, Utils } from '@lightningjs/sdk';
import { COLORS, FONT_FACE, TEXT_ALIGN, VERTICAL_ALIGN } from '../../constants';
export default class SpinnerLoader extends Lightning.Component {
    static _template() {
        return {
            w: 204,
            h: 204,
            LoaderCircle: {
                x: (w) => w / 2,
                y: (h) => h / 2,
                mount: 0.5,
                src: Utils.asset('images/loader.png'),
            },
            LoaderText: {
                x: (w) => w / 2,
                y: (h) => h / 2,
                mount: 0.5,
                text: {
                    textColor: COLORS.white,
                    verticalAlign: VERTICAL_ALIGN.middle,
                    textAlign: TEXT_ALIGN.center,
                    fontSize: 26,
                    fontFace: FONT_FACE.light,
                    maxLines: 1,
                    wordWrapWidth: 194,
                    text: Language.translate('loading'),
                },
            },
        };
    }
    set loaderText(loaderText) {
        this.tag('LoaderText').text.text = loaderText;
        this.announce = loaderText;
    }
    _setup() {
        this._loader = this.tag('LoaderCircle').animation({
            duration: 4,
            repeat: -1,
            stopMethod: 'fade',
            actions: [
                {
                    p: 'rotation',
                    v: { 0: { v: 0, sm: 0 }, 1: { v: 2 * Math.PI, sm: 0 } },
                },
            ],
        });
    }
    _active() {
        this._loader.start();
    }
    _inactive() {
        this._loader.stop();
    }
}
