import List from './List';
import ItemWrapper from './items/ItemWrapper';
import { END_OF_ROW, FONT_FACE } from '../constants';
import { setSmooth, templateDeepMerge } from '../helpers';
import Announcer from '../lib/tts/Announcer';
export default class Stack extends List {
    _init() {
        var _a;
        super._init();
        this._initialYPosition = ((_a = this._itemsHolder) === null || _a === void 0 ? void 0 : _a.y) || 0;
    }
    static _template() {
        const template = super._template();
        return templateDeepMerge(template, {
            TitleHolder: {
                Title: {
                    text: { fontFace: FONT_FACE.regular, fontSize: 40 },
                },
            },
            Items: {
                y: 115,
            },
        });
    }
    create({ items }, initialIndex = 0) {
        const style = this.getStyle();
        if (!style) {
            console.warn(`List.js ${items} missing style. Check ./src/lib/style.js`);
            return;
        }
        this.noOfItemsToScroll = style.noOfListItemsToScroll;
        return items.map((item, index) => {
            const indexToRender = index + initialIndex;
            return {
                type: ItemWrapper,
                itemType: item.itemType,
                item: item.item,
                index: indexToRender,
                y: indexToRender * style.item.h,
            };
        });
    }
    setIndex(index) {
        this._index = index;
        const style = this.getStyle();
        setSmooth(this._itemsHolder, 'y', this._initialYPosition - index * style.item.h, {
            duration: 0.3,
            delay: 0,
        });
        this.fireAncestors('$scrolledListItemIndex', { index });
        this._refocus();
    }
    changeTitleAlpha(value) {
        setSmooth(this._titleHolder, 'alpha', value);
    }
    _handleUp() {
        if (this._index > 0) {
            this.setIndex(this._index - 1);
        }
        else {
            return false;
        }
    }
    _handleDown() {
        if (this._index < this._itemsHolder.children.length - 1) {
            this.setIndex(this._index + 1);
        }
        else {
            Announcer.announce(END_OF_ROW);
            return false;
        }
    }
    _handleLeft() {
        return false;
    }
    _handleRight() {
        return false;
    }
}
