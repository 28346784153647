import { Language } from '@lightningjs/sdk';
import moment from 'moment-timezone';
import { ALGOLIA_ENTITY_TYPES, getTimezone } from '../../../helpers';
import { adjustTimestampByOffset, isCurrentDay } from '../helpers';
const checkExpiredSle = (date) => {
    var _a;
    const currentDate = moment().format('YYYY-MM-DD');
    return moment((_a = date === null || date === void 0 ? void 0 : date.algoliaProperties) === null || _a === void 0 ? void 0 : _a.eventDate).isSameOrAfter(moment(currentDate));
};
const reduceDates = (dates, page) => {
    const zone = moment.tz.zone(getTimezone());
    const currentTimeZoneOffset = zone === null || zone === void 0 ? void 0 : zone.parse(Date.now());
    return dates === null || dates === void 0 ? void 0 : dates.reduce((acc, date) => {
        if (!(date === null || date === void 0 ? void 0 : date.algoliaProperties) ||
            ((date === null || date === void 0 ? void 0 : date.algoliaProperties.entityType) === ALGOLIA_ENTITY_TYPES.SLE && checkExpiredSle(date))) {
            return acc;
        }
        const { eventDate } = (date === null || date === void 0 ? void 0 : date.algoliaProperties) || {};
        let { localStartTimestamp, localEndTimestamp } = (date === null || date === void 0 ? void 0 : date.algoliaProperties) || {};
        if (zone && currentTimeZoneOffset) {
            const dateTimezoneOffset = zone.parse(localStartTimestamp * 1000);
            if (dateTimezoneOffset !== currentTimeZoneOffset) {
                const offsetTime = dateTimezoneOffset - currentTimeZoneOffset;
                localStartTimestamp = adjustTimestampByOffset(localStartTimestamp, offsetTime);
                localEndTimestamp = adjustTimestampByOffset(localEndTimestamp, offsetTime);
            }
        }
        const formattedDate = isCurrentDay(eventDate)
            ? Language.translate('today')
            : moment(eventDate).format('ddd MM/DD');
        return [
            ...acc,
            {
                label: formattedDate,
                value: eventDate,
                localStartTimestamp,
                localEndTimestamp,
            },
        ];
    }, page === 0
        ? [
            {
                label: Language.translate('all'),
                value: 'all',
            },
        ]
        : []);
};
export const dateMapper = (datesData) => ({
    dates: reduceDates(datesData === null || datesData === void 0 ? void 0 : datesData.hits, datesData === null || datesData === void 0 ? void 0 : datesData.page),
    page: datesData === null || datesData === void 0 ? void 0 : datesData.page,
    totalPages: datesData === null || datesData === void 0 ? void 0 : datesData.nbPages,
});
