import { Log } from '@lightningjs/sdk';
import get from 'lodash/get';
import mParticle from '@mparticle/web-sdk';
import getGlobalAttributes from './attributes/getGlobalAttributes';
import { MPARTICLE_TAG } from './mParticleInterface';
import TVPlatform from '../../../lib/tv-platform';
const trackEvent = async (event, params, doubleClickCounter = 'standard') => {
    const properties = {
        ...(event.injectGlobals ? { ...getGlobalAttributes(params) } : {}),
        ...event.getAttributes(params),
        'Device Model': await TVPlatform.getModelNumber(),
    };
    const type = get(params, 'type', event.type);
    Log.info(MPARTICLE_TAG, { eventName: type, properties });
    if (event.preTrackEvent)
        event.preTrackEvent(properties);
    const customFlags = { 'DoubleClick.Counter': doubleClickCounter };
    mParticle.logEvent(type, event.getCategory(), properties, customFlags);
    if (event.postTrackEvent)
        event.postTrackEvent(properties);
};
export default trackEvent;
