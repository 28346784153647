import { WithEndCard } from './WithEndCard';
import moment from 'moment-timezone';
import { getAdjustedReferringShelf } from '../../../../helpers';
import { END_CARD_FER_AUTOPLAY_TIME, PROGRAMMING_TYPES } from '../../../../constants';
import { BingeEndcardEvent } from '../../../../player/model/event/BingeEndcardEvent';
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore';
export const WithEndCardFER = (component) => class extends WithEndCard(component) {
    constructor() {
        super(...arguments);
        this._autoplayTime = END_CARD_FER_AUTOPLAY_TIME;
    }
    _construct() {
        super._construct();
        this._autoplayTime = END_CARD_FER_AUTOPLAY_TIME;
    }
    _onStreamEnd() {
        var _a;
        (_a = super._onStreamEnd) === null || _a === void 0 ? void 0 : _a.call(this);
        this._endCard.transparentBG = false;
        this._mediaPlayerEnded = true;
    }
    _playerEventsHandler(event) {
        super._playerEventsHandler(event);
        if (event instanceof BingeEndcardEvent) {
            const { stream } = PlayerStoreSingleton;
            const videoId = stream && 'pid' in stream && stream.pid;
            if (videoId) {
                this._endCard.queryEndCardsSLE(videoId, PROGRAMMING_TYPES.SLE, 'FER').then((endCards) => {
                    if (endCards)
                        this._setState('PlayerEndCardFER');
                });
            }
        }
    }
    static _states() {
        return [
            // @ts-expect-error can't type a static method in an interface
            ...super._states(),
            class PlayerEndCardFER extends this {
                $enter() {
                    var _a;
                    this._endCardImpressionTimeStamp = moment();
                    (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireEndCardImpression({
                        endCardTime: this._autoplayTime,
                        ...this._endCard.getAnalyticsData(),
                        shelf: getAdjustedReferringShelf(),
                    });
                    this._additionalUIUpdates({
                        endCardBGTransparency: true,
                        endCardTransparency: false,
                    });
                }
                $exit() {
                    this._endCardImpressionTimeStamp = null;
                }
                _getFocused() {
                    return this._endCard || this;
                }
                _captureBack(_) {
                    this._closeEndCard();
                }
                _handleUp() {
                    return true;
                }
                _handleDown() {
                    return true;
                }
            },
        ];
    }
};
