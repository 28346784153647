export const isNumberLike = (input) => typeof input === 'number' || (![undefined, null].includes(input) && !Number.isNaN(Number(input)));
export const looseStringCompare = (...arr) => {
    for (let i = 0, previous; i < arr.length; i++) {
        const current = arr[i];
        const equal = (previous === null || previous === void 0 ? void 0 : previous.toLowerCase()) === (current === null || current === void 0 ? void 0 : current.toLowerCase());
        if (i > 0 && !equal)
            return false;
        previous = current;
    }
    return true;
};
