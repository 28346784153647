import { Language } from '@lightningjs/sdk';
import { createFlexText } from '../../../helpers';
import { FLEX_DIRECTION, FONT_FACE, TEXT_ALIGN, VERTICAL_ALIGN, ALIGN_ITEMS, JUSTIFY_CONTENT, } from '../../../constants';
import Activation from './Activation';
class TempPassActivation extends Activation {
    constructor() {
        super(...arguments);
        this._setTranslation = () => {
            var _a, _b;
            (_a = this.tag('Content.Line2')) === null || _a === void 0 ? void 0 : _a.patch({
                text: { text: Language.translate('tempPass-activation_line2') },
            });
            (_b = this.tag('Content.GenerateButton')) === null || _b === void 0 ? void 0 : _b.patch({
                label: Language.translate('tempPass-activation_refresh_code'),
            });
        };
    }
    static _template() {
        return super._template({
            ActivationScreen: {
                Content: {
                    y: 228,
                    CodeContent: {
                        flex: {
                            direction: FLEX_DIRECTION.column,
                            alignItems: ALIGN_ITEMS.center,
                            justifyContent: JUSTIFY_CONTENT.center,
                        },
                        Line2: {
                            y: 5,
                            textAlign: TEXT_ALIGN.center,
                            text: {
                                textAlign: TEXT_ALIGN.center,
                                text: Language.translate('tempPass-activation_line2'),
                            },
                        },
                        CodeBackground: {
                            y: 120,
                        },
                        GenerateButton: {
                            y: 227,
                            label: Language.translate('tempPass-activation_refresh_code'),
                        },
                    },
                    DividerContent: {
                        x: 720,
                    },
                    QRContent: {
                        flex: {
                            direction: FLEX_DIRECTION.column,
                            alignItems: ALIGN_ITEMS.center,
                            justifyContent: JUSTIFY_CONTENT.center,
                        },
                        QRText: {
                            text: {
                                text: Language.translate('tempPass-activation_qr_text'),
                            },
                        },
                        QRPlaceHolder: undefined,
                        UsePhone: undefined,
                    },
                },
            },
        });
    }
    _drawText() {
        var _a, _b, _c, _d;
        const activationTexts = [
            Language.translate('tempPass-activation_line1'),
            this._getActivationUrl(),
        ];
        this.tag('Line1').children = createFlexText([
            activationTexts[0],
            {
                text: activationTexts[1],
                fontFace: FONT_FACE.semiBold,
            },
        ], {
            fontSize: 34,
            verticalAlign: VERTICAL_ALIGN.middle,
        });
        // @ts-expect-error TODO: fix announce
        this.announce = [
            ...this._strategy.drawText(),
            ...activationTexts,
            (_b = (_a = this.tag('Line2')) === null || _a === void 0 ? void 0 : _a.text) === null || _b === void 0 ? void 0 : _b.text,
            (_d = (_c = this.tag('Code')) === null || _c === void 0 ? void 0 : _c.text) === null || _d === void 0 ? void 0 : _d.text,
        ].filter((item) => !!item);
    }
}
export default class extends TempPassActivation {
}
