import { Registry } from '@lightningjs/sdk';
import { HasEndCard, WithEndCard } from './WithEndCard';
import moment from 'moment-timezone';
import { END_CARD_AUTOPLAY_TIME, SCREEN_SIZE, PlayerStates, PROGRAMMING_TYPES, } from '../../../../constants';
import { getAdjustedReferringShelf } from '../../../../helpers';
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore';
import { isSingleProgram } from '../../../../store/PlayerStore/actions';
class HasEndCardVOD extends HasEndCard {
}
const SFVODEndcardTimer = 2000;
export const WithEndCardVOD = (component) => class extends WithEndCard(component) {
    constructor() {
        super(...arguments);
        this._shouldUseSFVODEndcard = false;
        this._isSFVOD = false;
        this._autoplayTime = END_CARD_AUTOPLAY_TIME;
    }
    // Making the BFF call to get the end card for VOD since there wont be any event trigger from the player
    async _startStream() {
        var _a;
        await super._startStream();
        const { stream } = PlayerStoreSingleton;
        // @ts-expect-error - stream type  missing programmingType
        if ((stream === null || stream === void 0 ? void 0 : stream.programmingType) === PROGRAMMING_TYPES.SFVOD && ((_a = this === null || this === void 0 ? void 0 : this._params) === null || _a === void 0 ? void 0 : _a.isOlympicsVideo)) {
            //Nested in case we need to expand to other svod video types
            this._shouldUseSFVODEndcard = true;
            this._autoplayTime = SFVODEndcardTimer;
        }
        else if (stream && 'mpxGuid' in stream && 'programmingType' in stream) {
            this._endCard
                .queryEndCards(stream === null || stream === void 0 ? void 0 : stream.mpxGuid, stream === null || stream === void 0 ? void 0 : stream.programmingType)
                .then((endCards) => {
                this._hasEndCards = endCards;
            });
        }
    }
    _onStreamEnd() {
        var _a;
        (_a = super._onStreamEnd) === null || _a === void 0 ? void 0 : _a.call(this);
        this._image.alpha = 1;
        this._mediaPlayerEnded = true;
        if (!this._hasEndCards) {
            return this._closeMediaPlayer(true);
        }
        if (this._shouldUseSFVODEndcard) {
            return this._setState(PlayerStates.SFVODEndCard);
        }
        return this._setState(PlayerStates.PlayerEndCard);
    }
    static _states() {
        return [
            // @ts-expect-error TODO fix super not working with HOCs
            ...super._states(),
            class SFVODEndCard extends this {
                _closeSFVODEndcard() {
                    this._closeMediaPlayer(true);
                    this._closeEndCard();
                }
                $enter() {
                    this._hideUI();
                    this._additionalUIUpdates({
                        endCardOlyTransparency: true,
                    });
                    Registry.setTimeout(() => {
                        this._closeSFVODEndcard();
                    }, this._autoplayTime);
                }
                _handleKey() {
                    this._closeSFVODEndcard();
                }
            },
            class PlayerEndCard extends this {
                $enter() {
                    this._endCardImpressionTimeStamp = moment();
                    this._analyticsDelegate.fireEndCardImpression({
                        endCardTime: this._autoplayTime,
                        ...this._endCard.getAnalyticsData(),
                        shelf: getAdjustedReferringShelf(),
                    });
                    this._mediaPlayer._setVideoArea([90, 90, 530, 326]);
                    this._additionalUIUpdates({
                        endCardBGTransparency: false,
                        endCardTransparency: true,
                    });
                }
                _detach() {
                    super._detach();
                    this._closeEndCard();
                }
                $exit() {
                    this._endCardImpressionTimeStamp = null;
                    this._endCard.alpha = 0;
                    this._image.alpha = 0;
                    this._endCard.transparent = false;
                }
                _updateActiveCues() {
                    //Nothing do here
                }
                _getFocused() {
                    return this._endCard || this;
                }
                _handleUp() {
                    this._setState(PlayerStates.PlayerEndCard_MiniPlayer);
                }
                static _states() {
                    return [
                        class MiniPlayer extends PlayerEndCard {
                            $enter() {
                                this._miniPlayerFocusRect.alpha = 1;
                            }
                            $exit() {
                                this._miniPlayerFocusRect.alpha = 0;
                            }
                            _handleDown() {
                                this._setState(PlayerStates.PlayerEndCard);
                            }
                            _handleEnter() {
                                const { stream, program } = PlayerStoreSingleton;
                                if (isSingleProgram(program)) {
                                    this._trackEndCard({
                                        isRestarting: true,
                                        // @ts-expect-error TS(2322): Type '{ brand: any; series: any; destinationSport:... Remove this comment to see the full error message
                                        destination: {
                                            brand: program === null || program === void 0 ? void 0 : program.brand,
                                            series: program === null || program === void 0 ? void 0 : program.series,
                                            destinationSport: program === null || program === void 0 ? void 0 : program.sport,
                                            destinationLeague: program === null || program === void 0 ? void 0 : program.league,
                                            mpxGuid: program === null || program === void 0 ? void 0 : program.mpxGuid,
                                            programmingType: program === null || program === void 0 ? void 0 : program.programmingType,
                                            title: program === null || program === void 0 ? void 0 : program.title,
                                            locked: program === null || program === void 0 ? void 0 : program.locked,
                                        },
                                    });
                                }
                                if (this._mediaPlayerEnded) {
                                    this._load();
                                }
                                else {
                                    this._mediaPlayer._setVideoArea([0, 0, SCREEN_SIZE.width, SCREEN_SIZE.height]);
                                    this._setState(PlayerStates.LPPlayer, [{ hideControls: true }]);
                                }
                            }
                            // @ts-expect-error Type error on returned component Need to resolve soon
                            //Property '_getFocused' in type 'MiniPlayer' is not assignable to the same property in base type
                            _getFocused() {
                                return this;
                            }
                            _captureBack(_) {
                                this._trackBack(_);
                            }
                        },
                    ];
                }
            },
        ];
    }
};
