import { Language } from '@lightningjs/sdk';
import BaseAllEvents from './BaseAllEvents';
import assets from '../../api/algolia/spawners/assets';
import { isCurrentDay } from '../../api/algolia/helpers';
import { setSmooth } from '../../helpers';
import { getSLEAssets, getSLEDates } from '../../api/requests/liveAndUpcoming';
import { ROUTE } from '../../constants';
export default class LiveUpcoming extends BaseAllEvents {
    constructor() {
        super(...arguments);
        this._parentRoute = ROUTE.sports;
        this._pageTitle = Language.translate('live_and_upcoming');
        // @ts-expect-error TS(2416): Property '_assetsFetchFunction' in type 'LiveUpcom... Remove this comment to see the full error message
        this._assetsFetchFunction = (timeParams = null, page = 0) => {
            this._timeParams = timeParams;
            return getSLEAssets({ timeParams, page });
        };
        // @ts-expect-error TS(2416): Property '_assetsFetchByIndexFunction' in type 'LiveUpcom... Remove this comment to see the full error message
        this._assetsFetchByIndexFunction = (index, timeParams = null, page = 0) => {
            return getSLEAssets({ timeParams, page });
        };
        // @ts-expect-error TS(2416): Property '_datesFetchFunction' in type 'LiveUpcomi... Remove this comment to see the full error message
        this._datesFetchFunction = getSLEDates;
    }
    static _template() {
        return super._template();
    }
    // @ts-expect-error TS(2611): 'dates' is defined as a property in class 'BaseAll... Remove this comment to see the full error message
    set dates(v) {
        var _a;
        if (!((_a = v === null || v === void 0 ? void 0 : v.dates) === null || _a === void 0 ? void 0 : _a.length))
            return;
        this._setDatesPage(v);
        this._dates = v.dates;
        let activeIndex = 0;
        const todayIndex = this._dates.findIndex(({ localStartTimestamp, value }) => localStartTimestamp && isCurrentDay(value));
        if (todayIndex > 0) {
            // First item is 'All', hence splice starts at 1
            // Then, remove date filters for events that already happened
            this._dates.splice(1, todayIndex - 1);
        }
        if (this._initialDate)
            activeIndex = this._fillInitialDate();
        this._filters.items = this._createDatesPatchObject(this._dates, activeIndex);
        if (activeIndex !== 0)
            this._filters.selectItem(activeIndex);
        setSmooth(this.widgets.loader, 'visible', 0);
        this._setState('Filters');
    }
    // @ts-expect-error TS(2611): 'apiData' is defined as a property in class 'BaseA... Remove this comment to see the full error message
    set apiData(v) {
        if (!v.assets.length)
            return;
        assets(this.stage, v.assets).then((assets) => {
            this.results = assets;
            this._setResultsPage(v);
        });
    }
}
