import Slide from '../index';
import { FONT_FACE } from '../../../../constants';
import { pipeSeparate } from '../../../../helpers';
export const videoTitleDecorator = (item) => {
    let title;
    if (Slide.isLiveSlide(item._item)) {
        item.tag('Content.TitleBlock.VideoTitle').patch({
            text: {
                fontFace: FONT_FACE.light,
                wordWrapWidth: 600,
                maxLines: 3,
            },
        });
        title = item._item.description;
    }
    else {
        if (item._item.videoTitle || item._item.title)
            title = pipeSeparate(item._item.videoTitle || item._item.title);
    }
    item.videoTitle = title;
};
