import whiteLabelBrand from '../whiteLabelBrand';
import { BRAND, CALL_SIGNS, WHITE_LABEL_BRANDS } from '../constants';
import AppConfigFactorySingleton from '../config/AppConfigFactory';
import { CHANNEL_IDS, telemundoAffiliateName } from '../api/Live';
export const getBrandName = () => window.WHITE_LABEL_BRAND || whiteLabelBrand;
export const getConfigName = () => { var _a; return ((_a = WHITE_LABEL_BRANDS[getBrandName()]) === null || _a === void 0 ? void 0 : _a.configName) || getBrandName(); };
export const getDefaultChannelId = () => {
    var _a;
    const brandName = getBrandName();
    if (brandName)
        return (CHANNEL_IDS[brandName] ||
            ((_a = WHITE_LABEL_BRANDS[brandName]) === null || _a === void 0 ? void 0 : _a.configName) ||
            brandName);
    return getConfigName();
};
export const getDefaultCallSign = () => {
    var _a;
    const brand = getBrandName();
    if (brand === BRAND.telemundo && telemundoAffiliateName())
        return telemundoAffiliateName();
    return ((_a = WHITE_LABEL_BRANDS[brand]) === null || _a === void 0 ? void 0 : _a.liveCallSign) || CALL_SIGNS.nbcnews;
};
export const getAppId = () => { var _a, _b; return window.APP_ID || ((_b = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.identity) === null || _b === void 0 ? void 0 : _b.app_id) || ''; };
