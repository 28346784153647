import { OUTGOING_KEPLER_MESSAGE_TYPE } from '../../tv-platform/kepler/types';
import Announce from './Announce';
export default class KeplerAnnounce extends Announce {
    async speak() {
        return new Promise((resolve) => {
            var _a;
            const message = {
                type: OUTGOING_KEPLER_MESSAGE_TYPE.TTS_SPEAK,
                data: this.phrase,
            };
            (_a = window === null || window === void 0 ? void 0 : window.ReactNativeWebView) === null || _a === void 0 ? void 0 : _a.postMessage(JSON.stringify(message));
            resolve();
        });
    }
}
