export default {
    brandLanding: 'BRAND_LANDING',
    liveSchedule: 'LIVE_SCHEDULE',
};
export var ContentGatingType;
(function (ContentGatingType) {
    ContentGatingType["HARD"] = "Hard";
    ContentGatingType["SOFT"] = "Soft";
    ContentGatingType["NOT_APPLICABLE"] = "N/A";
})(ContentGatingType || (ContentGatingType = {}));
