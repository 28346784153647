import AuthenticationSingleton from '../../../authentication/Authentication';
import { COLORS } from '../../../constants';
import KeyBadge from '../../badges/KeyBadge';
export default (item) => {
    var _a, _b;
    if (item.isLocked) {
        if (AuthenticationSingleton.isAuthenticated() && !AuthenticationSingleton.isTempPassActive()) {
            (_a = item.lockContainer) === null || _a === void 0 ? void 0 : _a.patch({
                LockBackground: {
                    alpha: 0,
                },
            });
        }
        else {
            (_b = item.lockContainer) === null || _b === void 0 ? void 0 : _b.patch({
                LockBackground: {
                    type: KeyBadge,
                    positionX: item.keyBadgePosition,
                    color: item.lockTransparent ? COLORS.transparent : COLORS.black4,
                },
            });
        }
    }
};
