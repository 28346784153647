import moment from 'moment-timezone';
import { CONTENT_TYPE, MPARTICLE_DEFAULT_ATTR_VALUE, TOKEN_TYPE, XY_FALLBACK_TYPES, } from '../../../../constants';
import { setDefaultValue } from '../../../../helpers';
import { Language } from '@lightningjs/sdk';
import LaunchDarklySingleton from '../../../launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../../launchDarkly/LaunchDarklyFeatureFlags';
import AuthenticationSingleton from '../../../../authentication/Authentication';
import { StreamAuthorizationType } from '../../../../pages/Player/StreamLoader/request';
export const getCallsign = (data) => { var _a, _b; return data.callSign || ((_a = data.assetInfo) === null || _a === void 0 ? void 0 : _a.callSign) || ((_b = data.geoChannel) === null || _b === void 0 ? void 0 : _b.toUpperCase()); };
const getEpisodeTitle = (data) => data.episodeTitle || data.videoTitle || data.programTitle;
const getVideoType = (params) => {
    return !params.videoType && params.adobeContentType === CONTENT_TYPE.LINEAR
        ? 'Live'
        : params.videoType || params.programmingType;
};
export const getStreamType = (xyFallback) => {
    if (!xyFallback)
        return MPARTICLE_DEFAULT_ATTR_VALUE;
    const hasXyFallback = xyFallback === XY_FALLBACK_TYPES.x || xyFallback === XY_FALLBACK_TYPES.y;
    return hasXyFallback ? xyFallback : MPARTICLE_DEFAULT_ATTR_VALUE;
};
const getTokenType = (authKill, authType) => {
    if (authType === StreamAuthorizationType.TEMP_PASS)
        return TOKEN_TYPE.TEMP_PASS;
    const mvpd = AuthenticationSingleton.isAuthenticated();
    return (mvpd && TOKEN_TYPE.MVPD) || (authKill && TOKEN_TYPE.AUTH_KILL);
};
const getMvpdId = (authType, initialTempPassRequest) => {
    if (authType === StreamAuthorizationType.TEMP_PASS) {
        const { initialTempPassName, secondaryTempPassName } = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass);
        return initialTempPassRequest ? initialTempPassName : secondaryTempPassName;
    }
    return AuthenticationSingleton.getMvpdDataMvpdId();
};
export const getLinearDuration = (length = 0) => moment.duration(length, 'seconds').asMinutes();
const getLinearAttributes = (params = {}) => {
    var _a, _b, _c, _d;
    if (!params)
        return {};
    return setDefaultValue({
        Casting: params.casting,
        Brand: (_a = params.brand) === null || _a === void 0 ? void 0 : _a.title,
        Show: params.programTitle,
        Season: params.seasonNumber,
        'Episode Title': getEpisodeTitle(params),
        'Episode Number': params.episodeNumber,
        'Video ID': params.pid || params.tmsId || params.mpxGuid,
        'Video Type': getVideoType(params),
        Entitlement: params.authKill ? 'Auth Kill' : params.entitlement || params.liveEntitlement,
        Genre: params.listOfGenres || params.genre,
        'Token Type': getTokenType(params.authKill, params.authType),
        'Geo Station': (_b = params.geoChannel) === null || _b === void 0 ? void 0 : _b.toUpperCase(),
        'Home Station': (_c = params.homeChannel) === null || _c === void 0 ? void 0 : _c.toUpperCase(),
        Callsign: getCallsign(params),
        'CC Language': params.ccLang,
        'Stream Type': getStreamType(params === null || params === void 0 ? void 0 : params.xyFallback),
        Sport: params.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
        Sponsor: ((_d = params.shelf) === null || _d === void 0 ? void 0 : _d.sponsorName) || MPARTICLE_DEFAULT_ATTR_VALUE,
        League: params.league || MPARTICLE_DEFAULT_ATTR_VALUE,
        Language: Language.get(),
        MVPD: getMvpdId(params.authType, params.initialTempPassRequest),
    }, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getLinearAttributes;
