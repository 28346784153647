import { Language, Lightning, Utils } from '@lightningjs/sdk';
import { COLORS, FLEX_DIRECTION, FONT_FACE, MAX_LINES_SUFFIX, TEXT_ALIGN, EPG_SLOT_WIDTH, EPG_SLOT_PADDING, PROGRAMMING_TYPES, VERTICAL_ALIGN, } from '../../../../constants';
import formatSeasonEpisode from '../../../../util/formatSeasonEpisode';
import Item from '../../../items/Item';
import GuideProgressBar from '../GuideProgressBar/GuideProgressBar';
import { useLiveGuideXOffset } from '../../hooks/useLiveGuideXOffset';
export default class GuideProgram extends Item {
    constructor() {
        super(...arguments);
        this._isFocused = false;
        this.visibleWidth = 0;
        // #endregion
        this._checkMargin = ([offset, index]) => {
            var _a;
            const x = (((_a = this.parent) === null || _a === void 0 ? void 0 : _a.finalX) || 0) + offset;
            const { startSlot, endSlot, slotSpan } = this._item;
            const firstIndex = index;
            const isAtScreenStart = endSlot > firstIndex && startSlot < firstIndex;
            const margin = isAtScreenStart && index > 2 ? firstIndex - startSlot : 0;
            const widthSpan = isAtScreenStart && index > 2 ? endSlot - firstIndex : x > 1000 ? 1 : slotSpan;
            const wordWrapWidth = widthSpan * EPG_SLOT_WIDTH - 60;
            this.patch({
                ProgramInfo: {
                    x: margin * (EPG_SLOT_WIDTH + EPG_SLOT_PADDING) + 30,
                    Title: {
                        text: {
                            wordWrapWidth,
                        },
                    },
                    Description: {
                        text: {
                            wordWrapWidth,
                        },
                    },
                },
            });
        };
    }
    static _template() {
        const template = super._template();
        return {
            ...template,
            w: (w) => w,
            h: (h) => h,
            rect: true,
            color: COLORS.dark7,
            shader: {
                type: Lightning.shaders.RoundedRectangle,
                radius: 6.375,
            },
            ProgramInfo: {
                x: 30,
                w: (w) => (w > 1820 ? 1820 : w),
                Title: {
                    w: (w) => w - 60,
                    y: 24,
                    text: {
                        fontFace: FONT_FACE.regular,
                        fontSize: 30,
                        lineHeight: 38,
                        maxLinesSuffix: MAX_LINES_SUFFIX,
                        maxLines: 1,
                        textColor: COLORS.white,
                    },
                },
                Description: {
                    w: (w) => w - 60,
                    y: 64,
                    alpha: 1,
                    text: {
                        fontFace: FONT_FACE.regular,
                        fontSize: 24,
                        maxLinesSuffix: MAX_LINES_SUFFIX,
                        maxLines: 2,
                        textColor: COLORS.white,
                    },
                },
                RatingInfo: {
                    y: 140,
                    alpha: 1,
                    flex: { direction: FLEX_DIRECTION.row },
                    RatingContainer: {
                        Border: {
                            rect: true,
                            x: 0,
                            y: 0,
                        },
                        Rating: {
                            x: 0,
                            y: -1,
                            text: {
                                fontFace: FONT_FACE.regular,
                                fontSize: 20,
                                maxLinesSuffix: MAX_LINES_SUFFIX,
                                maxLines: 1,
                                lineHeight: 26,
                                textColor: COLORS.white,
                                verticalAlign: VERTICAL_ALIGN.middle,
                                textAlign: TEXT_ALIGN.center,
                            },
                        },
                    },
                    AudioDescription: {
                        flexItem: { marginLeft: 20 },
                        alpha: 0,
                        src: Utils.asset('images/AudioDescription_unfocus.png'),
                    },
                },
            },
        };
    }
    // #region Lifecycle
    _init() {
        super._init();
        this._spawnProgressItem();
        // Should be ideal to subscribe on viewport enter
        // but we don't know that since the row is rendered in full
        this._xOffset = useLiveGuideXOffset(this._checkMargin);
    }
    _detach() {
        super._detach();
        this._xOffset.unsubscribe();
    }
    _focus() {
        this._isFocused = true;
        this._updateGuideProgram(COLORS.dark2, COLORS.lightGray1);
    }
    _unfocus() {
        this._isFocused = false;
        this._updateGuideProgram(COLORS.white, this._activeProgram ? COLORS.dark9 : COLORS.dark7);
    }
    // #endregion
    // #region Getters/Setters
    set activeProgram(v) {
        this._activeProgram = v;
        this.color = v ? COLORS.dark9 : COLORS.dark7;
    }
    set item(item) {
        this._activeProgram = false;
        this._item = item;
        this.tag('Title').on('txLoaded', (obj) => {
            this.tag('Description').y = obj._source.renderInfo.lines.length === 2 ? 97 : 64;
        });
        const seasonEpisode = formatSeasonEpisode(item.seasonNumber, item.episodeNumber);
        const programTitle = seasonEpisode && item.series ? item.series : item.programTitle || '';
        const description = item.episodeTitle ||
            (item.programmingType === PROGRAMMING_TYPES.SLE &&
                item.videoTitle &&
                !programTitle.includes(item.videoTitle) &&
                !item.videoTitle.includes(programTitle)
                ? item.videoTitle
                : item.programDescription || '');
        const programDescription = seasonEpisode ? `${seasonEpisode} · ${description}` : description;
        const ratingWithAdvisories = item.ratingWithAdvisories || '';
        const audioDescription = item.audioDescription;
        this.tag('Title').text.text = programTitle || Language.translate('program_unavailable');
        this.tag('Description').text.text = programDescription;
        if (ratingWithAdvisories) {
            this.tag('Rating').text.text = ratingWithAdvisories;
            this.tag('Rating').on('txLoaded', (_) => {
                const widthText = this.tag('Rating').renderWidth;
                const strokeWidth = 1.2;
                // Stroke is drawn outside of the rect,
                // so we have to account its width to make it fit into 29px
                const yOffset = -strokeWidth / 2;
                const adjustedHeight = 29 + (-strokeWidth + yOffset);
                this.tag('RatingContainer.Border').patch({
                    x: -6,
                    y: yOffset,
                    texture: Lightning.Tools.getRoundRect(widthText + 10, adjustedHeight, 4, strokeWidth, this._isFocused ? COLORS.dark2 : COLORS.white, false, 0),
                });
                this.tag('RatingInfo.AudioDescription').patch({
                    x: widthText,
                });
            });
        }
        const aDAnnounce = audioDescription ? Language.translate('audio_description_available') : '';
        this.announce = [programTitle, programDescription, ratingWithAdvisories, aDAnnounce];
        this.tag('AudioDescription').alpha = audioDescription;
    }
    _spawnProgressItem() {
        if (this.index === 0) {
            this._progressItem = this.childList.a({
                type: GuideProgressBar,
            });
            this.childList.addAt(this._progressItem, 0);
        }
    }
    _updateGuideProgram(textColor, backgroundColor) {
        const audioDescriptionType = textColor === COLORS.white ? 'unfocus' : 'focus';
        this.patch({
            color: backgroundColor,
            ProgramInfo: {
                Title: {
                    text: {
                        textColor: textColor,
                    },
                },
                Description: {
                    text: {
                        textColor: textColor,
                    },
                },
                RatingInfo: {
                    RatingContainer: {
                        Rating: {
                            text: {
                                textColor: textColor,
                            },
                        },
                    },
                    AudioDescription: {
                        src: Utils.asset(`images/AudioDescription_${audioDescriptionType}.png`),
                    },
                },
            },
        });
    }
    _getFocused() {
        var _a;
        return (_a = this._progressItem) !== null && _a !== void 0 ? _a : this;
    }
}
