import Tile from './Tile';
export default class EventTile extends Tile {
    get audioDescription() {
        return this.eventTileData.audioDescription;
    }
    get badge() {
        return this.eventTileData.badge;
    }
    get channelId() {
        return this.eventTileData.channelId;
    }
    set channelId(v) {
        this.eventTileData.channelId = v;
    }
    get endTime() {
        return this.eventTileData.endTime;
    }
    get image() {
        return this.eventTileData.image;
    }
    get machineName() {
        return this.eventTileData.machineName;
    }
    get secondaryTitle() {
        return this.eventTileData.secondaryTitle;
    }
    get startTime() {
        return this.eventTileData.startTime;
    }
    get title() {
        return this.eventTileData.title || {};
    }
    get whiteBrandLogo() {
        return this.eventTileData.whiteBrandLogo;
    }
    get pid() {
        return this.eventTileData.pid;
    }
}
