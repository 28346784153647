import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
import getAdAttributes from './getAdAttributes';
const getAdEndAttributes = (params) => {
    var _a, _b;
    const { adSecondsWatched, isAbandoned, video } = params;
    // Core Video reports duration as `expectedDuration`
    const duration = ((_a = params.ad) === null || _a === void 0 ? void 0 : _a.duration) || ((_b = params.ad) === null || _b === void 0 ? void 0 : _b.expectedDuration) || 0;
    return {
        ...getAdAttributes(params),
        'Duration Watched': `${isAbandoned ? adSecondsWatched : duration}`,
        '% Complete': `${isAbandoned ? Math.round((adSecondsWatched / duration) * 100) : 100}`,
        'End Type': isAbandoned ? 'Abandon' : 'Complete',
        Sport: (video === null || video === void 0 ? void 0 : video.sport) || MPARTICLE_DEFAULT_ATTR_VALUE,
        League: (video === null || video === void 0 ? void 0 : video.league) || MPARTICLE_DEFAULT_ATTR_VALUE,
    };
};
export default getAdEndAttributes;
