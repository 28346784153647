import { Log } from '@lightningjs/sdk';
import AuthenticationSingleton from '../../../../authentication/Authentication';
import { StreamAuthorizationType } from '../request';
import { tempPassExpiredError, tempPassFailedError, unauthenticatedError } from '../error';
import { TEMP_PASS_ERROR } from '../../../../authentication/authConstants';
import { CONTENT_TYPE, PROGRAMMING_TYPES } from '../../../../constants';
import LaunchDarklySingleton from '../../../../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import { getUserProfile } from '../../../../api/Identity';
const tempPassRequest = async (initialRequest) => {
    const auth = {
        error: false,
        authType: null,
    };
    try {
        await AuthenticationSingleton.createTempPass({ initialRequest });
        auth.authType = StreamAuthorizationType.TEMP_PASS;
    }
    catch (err) {
        Log.warn('LinearSLEFERLoader :: runTempPassFlow initial request failed', err);
        auth.error =
            err === TEMP_PASS_ERROR.SESSION_EXPIRED ? tempPassExpiredError : tempPassFailedError;
    }
    return auth;
};
const tempPassAuthenticate = async (request, program, stream, initialRequest = true) => {
    if (!AuthenticationSingleton.isProgramTempPassEligible(program, stream)) {
        request.error = unauthenticatedError('Program not eligible for temp pass');
        throw request;
    }
    const { identityRequiredForSecondary = true } = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass);
    if (!AuthenticationSingleton.isMvpdTempPass()) {
        const tempPassResponse = await tempPassRequest(initialRequest);
        if (tempPassResponse.error) {
            if (identityRequiredForSecondary || !initialRequest) {
                if (identityRequiredForSecondary && initialRequest) {
                    return tempPassAuthenticate(request, program, stream, false);
                }
                request.error = tempPassResponse.error;
                throw request;
            }
            else {
                return tempPassAuthenticate(request, program, stream, false);
            }
        }
        request.authType = tempPassResponse.authType;
        request.initialRequest = initialRequest;
    }
    else {
        request.authType = StreamAuthorizationType.TEMP_PASS;
        request.initialRequest = initialRequest;
    }
    return request;
};
const vodAuthenticate = async (request) => {
    const userProfile = getUserProfile();
    if (!userProfile) {
        request.error = unauthenticatedError({});
        throw request;
    }
    return request;
};
export const authenticate = (request, stream, program) => {
    var _a, _b, _c, _d;
    if (((_b = (_a = program === null || program === void 0 ? void 0 : program.offerType) === null || _a === void 0 ? void 0 : _a.toLowerCase) === null || _b === void 0 ? void 0 : _b.call(_a)) === 'free' ||
        ((_d = (_c = program === null || program === void 0 ? void 0 : program.entitlement) === null || _c === void 0 ? void 0 : _c.toLowerCase) === null || _d === void 0 ? void 0 : _d.call(_c)) === 'free') {
        request.authType = StreamAuthorizationType.UNLOCKED;
    }
    else if (AuthenticationSingleton.isAuthenticated()) {
        if (AuthenticationSingleton.isMvpdTempPass()) {
            return tempPassAuthenticate(request, program, stream);
        }
        request.authType = StreamAuthorizationType.MVPD;
    }
    else {
        const linear = (stream === null || stream === void 0 ? void 0 : stream.contentType) === CONTENT_TYPE.LINEAR;
        if (program.programmingType === PROGRAMMING_TYPES.SLE ||
            program.programmingType === PROGRAMMING_TYPES.FER) {
            return tempPassAuthenticate(request, program, stream);
        }
        if (!linear && (program === null || program === void 0 ? void 0 : program.mpxGuid)) {
            return vodAuthenticate(request);
        }
        if (linear) {
            // Don't fail yet, check if geo request has auth kill
            return Promise.resolve(request);
        }
        request.error = unauthenticatedError({});
        return Promise.reject(request);
    }
    return Promise.resolve(request);
};
