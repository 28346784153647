import { IPlayerEvent } from './IPlayerEvent';
export class AdEvent extends IPlayerEvent {
    constructor(adBreak, ad) {
        super();
        this._adBreak = adBreak;
        this._ad = ad;
    }
    get adBreak() {
        return this._adBreak;
    }
    get ad() {
        return this._ad;
    }
}
