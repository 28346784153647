export class StopWatch {
    constructor() {
        this.start = () => {
            this._start = new Date().getTime();
        };
        this.stop = () => {
            this._end = new Date().getTime();
        };
        this.duration = () => {
            if (this._start && this._end) {
                return this._end - this._start;
            }
            else {
                new Error('You must start and stop the StopWatch!');
            }
        };
        this._start = null;
        this._end = null;
    }
}
