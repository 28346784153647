import { CMPGroupIds } from './types';
import { VerticalText } from './VerticalText';
import { AllowForm } from './AllowForm';
export const cmpComponentFactory = (id, item, context) => {
    var _a;
    const response = {
        key: id,
        label: item.groupName,
        component: VerticalText,
        componentProps: {
            title: item.groupName,
            content: item.groupDescription,
        },
    };
    switch (id) {
        case CMPGroupIds.ALLOW_SALE:
            response.component = AllowForm;
            response.componentProps.content = [
                ((_a = context.purposeTree.styling.itemDetailsConsentCheckboxInfo) === null || _a === void 0 ? void 0 : _a.interactionChoiceText) || '',
                item.groupDescription,
            ];
            break;
        default:
            break;
    }
    return response;
};
