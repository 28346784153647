import { Log } from '@lightningjs/sdk';
import { Subject } from 'rxjs';
import version from '../version';
import { getConfigName, getPlatformName, ENV } from '../helpers';
import { DebugControllerSingleton } from '../util/debug/DebugController';
import TVPlatform from '../lib/tv-platform';
import { ErrorType } from '../lib/tv-platform/types';
class AppConfig {
    constructor() {
        this._config = null;
        this.events = new Subject();
    }
    /**
     * Get remote config url in the format {version}/app_{brand}_{environment}.json
     * @return string
     */
    _getRemoteConfigUrl() {
        const env = DebugControllerSingleton.enabled && DebugControllerSingleton.env
            ? DebugControllerSingleton.env
            : NODE_ENV || ENV.PRODUCTION;
        const appVersion = version.indexOf('_') > -1 ? version.split('_')[1] : version;
        const majorVersion = appVersion === null || appVersion === void 0 ? void 0 : appVersion.split('.')[0];
        return `https://platco.nbc.co/config/${majorVersion}.0.0/${getPlatformName()}/app_${getConfigName()}_${env}.json`;
    }
    /**
     * Get remote config when the app loads
     * @return Promise
     */
    async getRemoteConfig() {
        const remoteConfigUrl = this._getRemoteConfigUrl();
        Log.info(AppConfig.TAG, `get remote config ${remoteConfigUrl}`);
        return fetch(remoteConfigUrl)
            .then((response) => response.json())
            .then((json) => {
            this._config = json;
            this.events.next(json);
            return json;
        })
            .catch((e) => {
            TVPlatform.reportError({
                type: ErrorType.OTHER,
                code: AppConfig.TAG,
                description: 'Error while fetching remote config',
                payload: {
                    url: remoteConfigUrl,
                    error: e,
                },
            });
            throw new Error();
        });
    }
    /**
     * Get remote config url in the format {version}/app_{brand}_{environment}.json
     * @return config json
     */
    get config() {
        return this._config;
    }
}
AppConfig.TAG = 'App Config';
export default AppConfig;
