import { Storage } from '@lightningjs/sdk';
import { DebugControllerSingleton } from '../../util/debug/DebugController';
import { createHash } from '../../helpers';
const namespace = 'gql.';
export const getNamespacedId = (id = '') => `${id}`.substring(0, 4) === namespace ? id : namespace + id;
const cacheIndexKey = getNamespacedId('cache');
// #region Cache index
// gql.cache at Storage maintains a list of cache keys and its expiration
let cachedIndex = null;
export const getCacheIndex = () => {
    // Check if we have a cached version in memory
    if (cachedIndex !== null) {
        return cachedIndex;
    }
    // Fetch from Storage only if not available in memory
    const keyValues = Storage.get(cacheIndexKey);
    if (keyValues === null) {
        // If not found in Storage, initialize and save an empty object
        const emptyCacheIndex = {};
        Storage.set(cacheIndexKey, emptyCacheIndex);
        // Update the in-memory cache
        cachedIndex = emptyCacheIndex;
    }
    else {
        // Cache the retrieved value for future use
        cachedIndex = keyValues;
    }
    return cachedIndex;
};
export const updateCacheIndex = (data) => {
    Storage.set(cacheIndexKey, data);
};
export const getCacheExpiration = (id = '') => (id && getCacheIndex()[id]) || 0;
// #endregion
export const setInCache = (id, value) => {
    const namespacedId = getNamespacedId(id);
    const cacheIndex = getCacheIndex();
    Storage.set(namespacedId, value);
    // Bumping up to 90 seconds instead of 30 seconds for testing
    const time = DebugControllerSingleton.enabled ? 90000 : 30 * 60 * 1000;
    const cacheExpiration = Date.now() + time;
    cacheIndex[namespacedId] = cacheExpiration;
    updateCacheIndex(cacheIndex);
};
export const findInCache = (id) => {
    var _a, _b;
    const cacheIndex = getCacheIndex();
    // Iterate over the keys in the cache index to avoid multiple loops
    for (const key in cacheIndex) {
        // eslint-disable-next-line no-prototype-builtins
        if (cacheIndex.hasOwnProperty(key)) {
            const cacheItem = Storage.get(key);
            // Check if the cache item matches the desired ID
            if (((_b = (_a = cacheItem === null || cacheItem === void 0 ? void 0 : cacheItem.data) === null || _a === void 0 ? void 0 : _a.bonanzaPage) === null || _b === void 0 ? void 0 : _b.id) === id) {
                return {
                    key,
                    value: cacheItem,
                };
            }
        }
    }
    // Return an empty object if the item is not found
    return {};
};
export const getQueryHash = (data) => {
    const query = { query: data.query, variables: data.variables };
    return getNamespacedId(createHash(JSON.stringify(query)));
};
export const getCacheFromQuery = (data) => {
    const key = getQueryHash(data);
    const value = Storage.get(key);
    return !value ? {} : { key, value };
};
