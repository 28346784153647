import SelectButton from '../../buttons/SelectButton';
import { COLORS, FONT_FACE } from '../../../constants';
import { getAudioTrackLabel, mapAudioLanguageTag } from '../../../helpers';
import { ClosedCaptionsUtils } from '../../../lib/ClosedCaptions/ClosedCaptionsUtils';
export const getAudioTrackComponent = (item, index, items) => {
    const audioLanguageTag = item.languageTag || item.language;
    return {
        y: index * 72,
        w: 500,
        h: 60,
        type: SelectButton,
        radius: 0,
        fontSize: 30,
        fontFace: FONT_FACE.light,
        focusFontColor: COLORS.black,
        unfocusFontColor: COLORS.white2,
        focusBackGroundColor: COLORS.white,
        unfocusBackgroundColor: COLORS.transparent,
        label: getAudioTrackLabel(mapAudioLanguageTag(audioLanguageTag)),
        padding: 0,
        icon: 'images/settings/selection.png',
        focusSelectedIcon: 'focus_selected.png',
        selected: items.length === 1,
        optionValue: item,
    };
};
export const getLanguageListComponent = (optionValue, index, selectedType) => {
    const selected = optionValue === selectedType;
    return {
        y: index * 72,
        w: 500,
        h: 60,
        type: SelectButton,
        radius: 0,
        fontSize: 30,
        fontFace: FONT_FACE.light,
        focusFontColor: COLORS.black,
        unfocusFontColor: COLORS.white2,
        focusBackGroundColor: COLORS.white,
        unfocusBackgroundColor: COLORS.transparent,
        label: ClosedCaptionsUtils.getCCTypeLabel(optionValue),
        padding: 0,
        icon: selected ? 'images/settings/selected.png' : 'images/settings/selection.png',
        focusSelectedIcon: 'focus_selected.png',
        selected,
        optionValue,
    };
};
