export default class Metadata {
    constructor(data) {
        this._data = data;
    }
    get firstRow() {
        return this._data.textRow1;
    }
    get secondRow() {
        return this._data.textRow2;
    }
}
