import Collection from './Collection';
import Tiles from '../tiles';
import componentTypes from '../../types/componentTypes';
export default class Shelf extends Collection {
    mapItems(items) {
        // Retrieve shelf data associated with the container.
        const shelfData = this.shelfData;
        // Create a set of valid component types for efficient lookup.
        const validComponentTypes = new Set(Object.values(componentTypes));
        // Filter out items that are invalid or do not match
        const filteredItems = items.filter((item) => {
            // Check if item is not null
            if (!item) {
                return false;
            }
            // Check if item.component is a valid component type
            if (!validComponentTypes.has(item.component)) {
                return false;
            }
            // Check if the component exists in Tiles
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (!Tiles[item.component]) {
                return false;
            }
            return true;
        });
        const mapToTiles = (items, shelfData) => 
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        items.map((item, index) => new Tiles[item.component](item, shelfData, index));
        // Map each filtered item to its corresponding component in the Tiles object.
        const mappedItems = mapToTiles(filteredItems, shelfData);
        const isContinueWatchingShelf = (mappedItems) => {
            var _a, _b;
            const relevantShelves = ['continue-watching-shelf', 'Shelf--smartContinueWatchingShelf'];
            return relevantShelves.includes((_b = (_a = mappedItems[0]) === null || _a === void 0 ? void 0 : _a.shelf) === null || _b === void 0 ? void 0 : _b.machineName);
        };
        const mapSmartTileToVideoTile = (items) => items.map((item) => {
            var _a;
            if (((_a = item === null || item === void 0 ? void 0 : item.obj) === null || _a === void 0 ? void 0 : _a.component) === 'SmartTile') {
                const itemData = item.obj.data.tile.data;
                item.obj.component = 'VideoTile';
                item.obj.data = itemData;
            }
            return item;
        });
        // Data mapping only for Continue watching shelf
        return isContinueWatchingShelf(mappedItems) ? mapSmartTileToVideoTile(mappedItems) : mappedItems;
    }
    get shelfData() {
        return {
            playlistMachineName: this.playlistMachineName,
            listTitle: this.listTitle,
            shelfIndex: this.index,
            machineName: this.machineName,
            sponsorName: this.sponsorName,
            shelfType: this.shelfType,
            component: this.component,
        };
    }
    get listTitle() {
        return this.obj.listTitle;
    }
    get listTitleImage() {
        return this.obj.listTitleImage;
    }
    get moreItems() {
        return this.obj.moreItems;
    }
    get sponsorLogo() {
        return this.obj.sponsorLogo;
    }
    get sponsorLogoAltText() {
        return this.obj.sponsorLogoAltText;
    }
    get sponsorName() {
        return this.obj.sponsorName;
    }
    get shelfType() {
        return this.obj.shelfType;
    }
    get component() {
        return this.obj.component;
    }
    get ariaLabel() {
        return this.obj.ariaLabel || this.listTitle;
    }
}
