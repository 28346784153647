import BasePlayer from './BasePlayer';
import VODPlayerControls from '../../components/player/PlayerControls/VODPlayerControls';
import TVRatingGuide from '../../components/player/TVRatingGuide';
import PlayerStoreSingleton from '../../store/PlayerStore/PlayerStore';
import { AdBreakDataEvent, TimeChangeEvent } from '../../player/model/event';
import { setSmooth } from '../../helpers';
import { CONTENT_TYPE, PROGRAMMING_TYPES } from '../../constants';
import { TrickPlayImageEvent } from '../../player/model/event/TrickPlayImageEvent';
import { WithSeeking } from './hoc/WithSeeking';
import { WithEndCardVOD } from './hoc/WithEndCard/WithEndCardVOD';
import { WithSkipButton } from './hoc/WithSkipButton';
import { WithPauseAds } from './hoc/WithPauseAds';
import { VODAnalyticsDelegate } from './delegates/analytics/VODAnalyticsDelegate';
import { CoreVideoLightningMediaPlayerAdapter } from '../../player/CoreVideoPlayer/CoreVideoLightningMediaPlayerAdapter';
import { WatchProgressDelegate } from './delegates/WatchProgressDelegate';
import { PlayerStatus } from '../../player/model/PlayerStatus';
import { isSingleStream } from '../../store/PlayerStore/actions';
import { useProgress, } from '../../components/player/PlayerControls/hooks/useProgress';
class VODCorePlayer extends BasePlayer {
    constructor() {
        super(...arguments);
        this._log_tag = 'VOD Core Player';
        this._stream_type = CONTENT_TYPE.VOD;
    }
    get widgets() {
        var _a;
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        return (_a = this.parent) === null || _a === void 0 ? void 0 : _a.widgets; // Widgets were only accesible from page
    }
    static _template() {
        return super._template({
            Controls: { type: VODPlayerControls },
            TVRating: {
                type: TVRatingGuide,
                x: 25,
                y: 25,
            },
        });
    }
    _init() {
        super._init();
        this._progress = useProgress();
        this._mediaPlayer = new CoreVideoLightningMediaPlayerAdapter();
        this._analyticsDelegate = new VODAnalyticsDelegate(this);
        this._tvGuideRating = this.tag('TVRating');
    }
    // @ts-expect-error TS(2416): Property '_active' in type 'VODCorePlayer' is not ... Remove this comment to see the full error message
    _active() {
        var _a;
        super._active();
        this.fireAncestors('$onAttached');
        setSmooth(this.widgets.loader, 'visible', 0);
        (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.firePageLoad();
        this._load();
    }
    _attach() {
        var _a;
        super._attach();
        (_a = this._tvGuideRating) === null || _a === void 0 ? void 0 : _a._attachPlayer(this._player);
    }
    // @ts-expect-error TS(2416): Property '_detach' in type 'VODCorePlayer' is not ... Remove this comment to see the full error message
    _detach() {
        super._detach();
        if (this._tvGuideRating)
            this._tvGuideRating._detach();
    }
    _playerEventsHandler(event) {
        var _a;
        super._playerEventsHandler(event);
        if (event instanceof TimeChangeEvent) {
            (_a = this._watchProgressDelegate) === null || _a === void 0 ? void 0 : _a.update(event.time);
        }
        if (event instanceof AdBreakDataEvent) {
            this._controls.addAdBreakPoints(event.adBreaks.filter(({ startTime }) => startTime > 0));
        }
        if (event instanceof TrickPlayImageEvent) {
            this._controls.addThumbnailVariants(event);
        }
    }
    _onPlayerStatusChange(status) {
        var _a;
        super._onPlayerStatusChange(status);
        switch (status) {
            case PlayerStatus.PAUSED:
                (_a = this._watchProgressDelegate) === null || _a === void 0 ? void 0 : _a.update(this._progress.value[0], {
                    forceReport: true,
                });
        }
    }
    // @ts-expect-error TS(2425): Class 'BasePlayer' defines instance member propert... Remove this comment to see the full error message
    _onStreamEnd() {
        var _a, _b, _c, _d, _e;
        if (!isSingleStream(PlayerStoreSingleton.stream))
            return;
        (_a = this._watchProgressDelegate) === null || _a === void 0 ? void 0 : _a.update(this._progress.value[0], {
            forceReport: true,
        });
        (_b = this._watchProgressDelegate) === null || _b === void 0 ? void 0 : _b.endSession();
        (_c = this._analyticsDelegate) === null || _c === void 0 ? void 0 : _c.fireContentComplete();
        (_d = this._analyticsDelegate) === null || _d === void 0 ? void 0 : _d.fireSessionEnd({
            watched: this._progress.value[0],
            duration: (_e = PlayerStoreSingleton.stream) === null || _e === void 0 ? void 0 : _e.duration,
        });
    }
    _handleBack(e) {
        this._closeMediaPlayer(true);
        e.preventDefault();
        e.stopPropagation();
    }
    _closeMediaPlayer(forceExit) {
        var _a, _b;
        if (!this._watchProgressDelegate) {
            //example use case: For Clips, we don't have watch progress
            super._closeMediaPlayer(forceExit);
        }
        (_b = (_a = this._watchProgressDelegate) === null || _a === void 0 ? void 0 : _a.update(this._progress.value[0], {
            forceReport: true,
        })) === null || _b === void 0 ? void 0 : _b.then(() => {
            var _a;
            if (this._watchProgressDelegate) {
                (_a = this._watchProgressDelegate) === null || _a === void 0 ? void 0 : _a.setHasWatchedContent().then(() => {
                    super._closeMediaPlayer(forceExit);
                });
            }
            else {
                super._closeMediaPlayer(forceExit);
            }
        }).catch(() => {
            super._closeMediaPlayer(forceExit);
        });
    }
    _setPlayerInActiveTimeout() { }
    _loadWatchProgressDelegate() {
        const { stream } = PlayerStoreSingleton;
        if (!isSingleStream(stream))
            return;
        const shouldReportProgress = (!this._removePersonalization &&
            (stream === null || stream === void 0 ? void 0 : stream.programmingType) === PROGRAMMING_TYPES.FULL_EPISODE) ||
            (stream === null || stream === void 0 ? void 0 : stream.programmingType) === PROGRAMMING_TYPES.MOVIE;
        if (shouldReportProgress) {
            this._watchProgressDelegate = new WatchProgressDelegate(stream === null || stream === void 0 ? void 0 : stream.duration, this._mediaPlayer.watchId);
        }
    }
    _handleMediaPause() {
        super._handleMediaPause();
    }
    async _startStream() {
        const { stream, program } = PlayerStoreSingleton;
        if (!isSingleStream(stream) || !program)
            return;
        await super._startStream();
        this._tvGuideRating.assetInfo = program;
        this._loadWatchProgressDelegate();
    }
}
export default class extends WithPauseAds(WithSkipButton(WithSeeking(WithEndCardVOD(VODCorePlayer)))) {
}
