import { Language, Lightning } from '@lightningjs/sdk';
import version from '../../version';
import { PLAYER_TYPE, PlayerFactorySingleton } from '../../player/core/PlayerFactory';
import { ErrorEvent } from '../../player/model/event/ErrorEvent';
import { FPSChangeEvent } from '../../player/model/event/FPSChangeEvent';
import { TimeChangeEvent } from '../../player/model/event/TimeChangeEvent';
import { LevelChangeEvent } from '../../player/model/event/LevelChangeEvent';
import { LoadSourceEvent } from '../../player/model/event/LoadSourceEvent';
import { AdStartEvent } from '../../player/model/event/AdStartEvent';
import { BufferLengthChangeEvent } from '../../player/model/event/BufferLengthChangeEvent';
import { COLORS, FLEX_DIRECTION, FONT_FACE, SCREEN_SIZE, VERTICAL_ALIGN } from '../../constants';
export default class PlayerQOSPanel extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._setUserAgent = () => {
            var _a;
            this.userAgentLbl.text = `User Agent: ${(_a = navigator.userAgent) !== null && _a !== void 0 ? _a : 'n/a'}`;
        };
        this.clearDebugMessages = () => {
            this.qosDebugMessages.children = [];
        };
        this.addDebugMessage = (message = '') => {
            if (!this.qosDebug.visible)
                this.qosDebug.visible = true;
            this.qosDebugMessages.childList.a({
                text: {
                    fontSize: 32,
                    lineHeight: 36,
                    fontFace: FONT_FACE.regular,
                    text: `[ ${message} ]`,
                },
                y: (this.qosDebugMessages.children.length + 1) * 50,
            });
        };
    }
    static _template() {
        return {
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            rect: true,
            color: COLORS.black8,
            clipping: true,
            CaptionsHolder: {
                x: 90,
                y: 50,
                w: 1740,
                QOSDebug: {
                    visible: false,
                    flex: { direction: FLEX_DIRECTION.column, padding: 20 },
                    y: 8,
                    x: 900,
                    w: 550,
                    h: 650,
                    rect: true,
                    color: COLORS.black8,
                    QOSDebugMessages: {
                        y: 0,
                        textColor: COLORS.white2,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        fontSize: 16,
                        lineHeight: 20,
                        fontFace: FONT_FACE.regular,
                        children: [],
                    },
                },
                QOSMain: {
                    flex: { direction: FLEX_DIRECTION.column, padding: 20 },
                    QOSHeading: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 36,
                            lineHeight: 36,
                            fontFace: FONT_FACE.bold,
                            text: Language.translate('QOS Panel').toUpperCase(),
                        },
                    },
                    AppVersionLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'App Version: {0}',
                        },
                    },
                    PlayerLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Player: {0}',
                        },
                    },
                    PlayerVersionLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Player Version: {0}',
                        },
                    },
                    UserAgentLbl: {
                        w: 870,
                        flexItem: { grow: 1 },
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'User Agent: {0}',
                        },
                    },
                    AssetIdLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: '',
                        },
                    },
                    PerformanceLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Framerate / Bitrate: {0} FPS / {1} kbps',
                        },
                    },
                    BufferHealthLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Latency / Buffer: {0} s / {1} s',
                        },
                    },
                    ConnectionSpeedLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Bandwidth Estimate: ${0} kbps',
                        },
                    },
                    CurrentPositionLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Current Position: {0} s',
                        },
                    },
                    UrlLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Manifest URL:',
                        },
                    },
                    UrlValueLbl: {
                        w: 1740,
                        flexItem: { grow: 1 },
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 16,
                            lineHeight: 22,
                            fontFace: FONT_FACE.regular,
                            text: 'Manifest URL: {0}',
                        },
                    },
                    AdStatusLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Latest Ad - ID / Title / StartTime / Duration: {0} / {1} / {2} / {4}',
                        },
                    },
                    PlayerEventLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Latest Player Event: {0}',
                        },
                    },
                    ErrorsLbl: {
                        flexItem: {},
                        text: {
                            textColor: COLORS.white2,
                            verticalAlign: VERTICAL_ALIGN.middle,
                            fontSize: 32,
                            lineHeight: 36,
                            fontFace: FONT_FACE.regular,
                            text: 'Latest Error Code: {0}',
                        },
                    },
                },
            },
        };
    }
    _init() {
        this.tag('AppVersionLbl').text = this.tag('AppVersionLbl').text = `App Version: ${version}`;
        this.connectionLbl = this.tag('ConnectionSpeedLbl');
        this.assetIdLbl = this.tag('AssetIdLbl');
        this.urlLbl = this.tag('UrlLbl');
        this.urlValueLbl = this.tag('UrlValueLbl');
        this.adStatusLbl = this.tag('AdStatusLbl');
        this.currentPositionLbl = this.tag('CurrentPositionLbl');
        this.userAgentLbl = this.tag('UserAgentLbl');
        this.bufferHealthLbl = this.tag('BufferHealthLbl');
        this.qosDebug = this.tag('QOSDebug');
        this.qosDebugMessages = this.tag('QOSDebugMessages');
        this._subscription = null;
        this._player = PlayerFactorySingleton.player(PLAYER_TYPE.MAIN);
        this._latestBitrate = 0;
        this._latestFPS = 0;
        this._setUserAgent();
    }
    _active() {
        this.tag('PlayerLbl').text.text = `Player: ${this._player.framework}`;
        this.tag('PlayerVersionLbl').text.text = `Player Version: ${this._player.version}`;
        this._subscription = this._player.events.subscribe((event) => {
            var _a, _b;
            if (!(event instanceof FPSChangeEvent) &&
                !(event instanceof TimeChangeEvent) &&
                !(event instanceof BufferLengthChangeEvent)) {
                this.tag('PlayerEventLbl').text.text = `Latest Player Event: ${event.constructor.name}`;
            }
            if (event instanceof ErrorEvent) {
                this.tag('ErrorsLbl').text.text =
                    `Latest Error Code: fatal: ${event.error.fatal} type: ${event.error.type} details: ${event.error.details}`;
            }
            else if (event instanceof LevelChangeEvent) {
                this._latestBitrate = event.bitrate / 1000;
            }
            else if (event instanceof AdStartEvent) {
                this.adStatusLbl.text = `Latest Ad - ID / Title / StartTime / Duration: ${event.ad.id} / ${event.ad.title} / ${event.ad.startTime} / ${event.ad.duration}`;
            }
            else if (event instanceof FPSChangeEvent) {
                this._latestFPS = event.fps;
            }
            else if (event instanceof BufferLengthChangeEvent) {
                this.bufferHealthLbl.text = `Latency / Buffer: ${this._player.liveLatency} s / ${event.length} s`;
            }
            else if (event instanceof LoadSourceEvent) {
                this.urlValueLbl.text.text = (_b = (_a = event.sourceUrl) === null || _a === void 0 ? void 0 : _a.replace) === null || _b === void 0 ? void 0 : _b.call(_a, /(.{180})/g, '$1\n');
                this.assetIdLbl.text.text = (() => {
                    const { pid, mpxGuid, adobeContentType, channelId } = event.metadata;
                    if (adobeContentType === 'Linear')
                        return `CHANNEL ID: ${channelId}`;
                    if (pid)
                        return `PID: ${pid}`;
                    if (mpxGuid)
                        return `MPXGUID: ${mpxGuid}`;
                    return '';
                })();
            }
            else if (event instanceof TimeChangeEvent) {
                this.currentPositionLbl.text.text = `Current Position: ${Math.round(event.time)} s`;
            }
            this.tag('PerformanceLbl').text.text =
                `Framerate / Bitrate: ${this._latestFPS} FPS / ${this._latestBitrate} kbps`;
            this.connectionLbl.text.text = `Bandwidth Estimate: ${Math.round(this._player.bandwidthEstimate / 1000)} kbps`;
        });
    }
    _inactive() {
        if (this._subscription) {
            this._subscription.unsubscribe();
        }
    }
    _handleBackButton() {
        return false;
    }
}
