import { Utils } from '@lightningjs/sdk';
import TextButton from './TextButton';
import { FONT_FACE } from '../../constants';
// @ts-expect-error TS(2417): Class static side 'typeof CCTextButton' incorrectl... Remove this comment to see the full error message
export default class CCTextButton extends TextButton {
    static _template() {
        return {
            ...super._template(),
            Label: {
                x: 20,
                y: 11,
            },
            Icon: {
                mountX: 1,
                x: (w) => w - 20,
                y: 20,
            },
            Description: {
                x: 20,
                y: (h) => h - 9,
                mountY: 1,
            },
        };
    }
    set icon(icon) {
        this.patch({
            Icon: {
                src: Utils.asset(icon),
            },
        });
    }
    set description(v) {
        this.patch({
            Description: {
                text: {
                    text: v,
                    fontFace: FONT_FACE.light,
                    fontSize: 20,
                },
            },
        });
    }
    _focus() {
        super._focus();
        this.tag('Icon').patch({
            color: this._focusFontColor,
        });
        this.tag('Description').patch({
            color: this._focusFontColor,
        });
    }
    _unfocus() {
        super._unfocus();
        this.tag('Icon').patch({
            color: this._unfocusFontColor,
        });
        this.tag('Description').patch({
            color: this._unfocusFontColor,
        });
    }
}
