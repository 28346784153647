import { Storage } from '@lightningjs/sdk';
import isEmpty from 'lodash/isEmpty';
import { EVENTS } from '../../../lib/analytics/types';
import { ENTITY_TYPES, LiveGuideV2States, STORAGE_KEYS } from '../../../constants';
import { setSmooth } from '../../../helpers';
import PlayerStoreSingleton, { PlayerStoreEvents } from '../../../store/PlayerStore/PlayerStore';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { useLiveGuideXOffset } from '../hooks/useLiveGuideXOffset';
import { SubscriptionBuilder, SubscriptionSources } from '../../../util/SubscriptionBuilder';
import isUndefined from 'lodash/isUndefined';
const VERTICAL_SCROLL_DIRECTION = Object.freeze({
    UP: 0,
    DOWN: 1,
});
export const ChannelsStateFactory = (base) => class Channels extends base {
    get streamIndex() {
        return this._streamIndex || 0;
    }
    set streamIndex(value) {
        this._streamIndex = value;
    }
    get programIndex() {
        return this._programIndex || 0;
    }
    set programIndex(value) {
        this._programIndex = value;
    }
    get slotIndex() {
        return this._slotIndex || 0;
    }
    set slotIndex(value) {
        this._slotIndex = value;
    }
    get programStartSlot() {
        return this._programStartSlot || 0;
    }
    set programStartSlot(value) {
        this._programStartSlot = value;
    }
    // Current component reference
    get currentItem() {
        var _a, _b, _c, _d, _e;
        return ((_e = (_d = (_c = (_b = (_a = this._channelsGuide) === null || _a === void 0 ? void 0 : _a.schedules) === null || _b === void 0 ? void 0 : _b.children[this.streamIndex]) === null || _c === void 0 ? void 0 : _c.children) === null || _d === void 0 ? void 0 : _d[this.programIndex]) !== null && _e !== void 0 ? _e : {});
    }
    //Data
    get streams() {
        return PlayerStoreSingleton.state.epg.streams;
    }
    get schedules() {
        return PlayerStoreSingleton.state.epg.schedules;
    }
    get currentStream() {
        return this.streams[this.streamIndex];
    }
    get currentStreamPrograms() {
        var _a, _b;
        return (_b = (_a = this.schedules) === null || _a === void 0 ? void 0 : _a[this.streamIndex]) === null || _b === void 0 ? void 0 : _b.programs;
    }
    get currentStreamProgram() {
        var _a;
        return (_a = this.currentStreamPrograms) === null || _a === void 0 ? void 0 : _a[this.programIndex];
    }
    get slotsCount() {
        var _a, _b, _c;
        if ((_a = this.currentStreamPrograms) === null || _a === void 0 ? void 0 : _a.length) {
            const lastItem = (_b = this.currentStreamPrograms) === null || _b === void 0 ? void 0 : _b[((_c = this.currentStreamPrograms) === null || _c === void 0 ? void 0 : _c.length) - 1];
            if (lastItem === null || lastItem === void 0 ? void 0 : lastItem.endSlot) {
                return lastItem.endSlot;
            }
        }
        return 0;
    }
    get currentProgramStartSlot() {
        return this.currentStreamProgram.startSlot || 0;
    }
    get currentProgramEndSlot() {
        return this.currentStreamProgram.endSlot || 0;
    }
    get currentProgramSlotSpan() {
        var _a;
        return ((_a = this.currentStreamProgram) === null || _a === void 0 ? void 0 : _a.slotSpan) || 0;
    }
    _getFocused() {
        return !isEmpty(this.currentItem) ? this.currentItem : this;
    }
    $enter() {
        var _a;
        const { streamIndex } = PlayerStoreSingleton;
        if (this._filtersGuide)
            this._filtersGuide.visible = false;
        if (this._channelsGuide)
            this._channelsGuide.visible = true;
        this._channelsGuide.changeBrandIndex(this.streamIndex);
        this._xOffset = useLiveGuideXOffset();
        if (isUndefined(this._streamIndex) && ((_a = this._channelsGuide) === null || _a === void 0 ? void 0 : _a.loaded))
            this.updateStreamIndex(streamIndex);
        this._subscription = new SubscriptionBuilder()
            .with({
            type: SubscriptionSources.PLAYER_STORE,
            events: [PlayerStoreEvents.EPG_CHANNEL_UPDATED, PlayerStoreEvents.EPG_OK],
        })
            .subscribe(this._onEpgChannelUpdated.bind(this));
    }
    $exit() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._subscription = undefined;
    }
    _onEpgChannelUpdated({ type, payload, }) {
        if (type === PlayerStoreEvents.EPG_CHANNEL_UPDATED)
            if (isUndefined(this._streamIndex))
                this.updateStreamIndex(payload === null || payload === void 0 ? void 0 : payload.streamIndex);
        if (type === PlayerStoreEvents.EPG_OK)
            if (isUndefined(this._streamIndex))
                this.updateStreamIndex(payload === null || payload === void 0 ? void 0 : payload.index);
    }
    _handleBack(e) {
        var _a;
        if (this.slotIndex > 0) {
            this.updateSlotIndex(0);
            this.programIndex = 0;
            this.programStartSlot = (_a = this.currentStreamProgram) === null || _a === void 0 ? void 0 : _a.startSlot;
            e.preventDefault();
            e.stopPropagation();
        }
        else if (this.streamIndex > 0) {
            this.updateStreamIndex(0, 0);
            this.scrollStreams(0);
            e.preventDefault();
            e.stopPropagation();
        }
        else {
            // Take user back to the previous screen
            this.fireAncestors('$trueBack');
        }
    }
    trackContentClick(program, stream) {
        const referringShelf = {
            tileIndex: this.programIndex,
            shelfIndex: this.streamIndex,
            listTitle: 'On Now',
        };
        const params = {
            entity: {
                program,
                stream,
                entityType: ENTITY_TYPES.STREAM,
                analytics: { ...program, customShelfTitle: 'None' },
            },
            shelf: {
                position: this.programIndex + 1,
                customPosition: this.streamIndex + 1,
            },
        };
        Storage.set(STORAGE_KEYS.REFERRING_SHELF, referringShelf);
        sendMetric(EVENTS.CONTENT_CLICK, params);
    }
    _handleEnter() {
        if (this.programIndex === 0) {
            const stream = this.currentStream;
            const program = this.currentStreamProgram;
            if (!stream || !program)
                return;
            this.trackContentClick(program, stream);
            this.fireAncestors('$streamSelected', stream, program);
        }
    }
    _handleRight() {
        var _a;
        if (this.slotIndex < this.slotsCount - 1) {
            let nextSlot = this.slotIndex + 1;
            if (nextSlot < 3 && this.currentProgramEndSlot > nextSlot) {
                if (this.currentProgramEndSlot > 3) {
                    nextSlot = 3;
                }
                else {
                    nextSlot += 1;
                }
            }
            else if (this.slotIndex > (this.slotsCount - 4)) {
                if (this.currentProgramSlotSpan > 1) {
                    nextSlot = this.slotIndex + this.currentProgramSlotSpan;
                }
            }
            this.updateSlotIndex(nextSlot);
            if (this.currentProgramEndSlot &&
                this.slotIndex + 1 > this.currentProgramEndSlot &&
                ((_a = this.currentStreamPrograms) === null || _a === void 0 ? void 0 : _a.length) &&
                this.currentStreamPrograms.length - 1 > this.programIndex) {
                this.programIndex += 1;
            }
            this.programStartSlot = this.currentProgramStartSlot;
        }
        else {
            return false;
        }
    }
    _handleLeft() {
        var _a;
        if (this.slotIndex > 0) {
            const nextSlotIndex = this.currentProgramSlotSpan > 1 && this.slotIndex > this.slotsCount - 4
                ? this.slotIndex - this.currentProgramSlotSpan
                : this.slotIndex - 1;
            this.updateSlotIndex(nextSlotIndex);
            this.programStartSlot = this.currentProgramStartSlot;
            if (this.currentProgramStartSlot &&
                this.slotIndex < this.currentProgramStartSlot) {
                this.programIndex -= 1;
                this.programStartSlot = this.currentProgramStartSlot;
            }
        }
        else {
            if (((_a = this.currentStreamPrograms) === null || _a === void 0 ? void 0 : _a.length) === 1) {
                this.updateSlotIndex(this.programIndex);
                this.programStartSlot = this.currentProgramStartSlot;
            }
            else if (this.programIndex > 0) {
                this.programIndex -= 1;
            }
            else {
                return false;
            }
        }
    }
    _handleUp() {
        if (this.streamIndex > 0) {
            this.updateStreamIndex(this.streamIndex - 1, VERTICAL_SCROLL_DIRECTION.UP);
        }
        else {
            this._setState(LiveGuideV2States.Tabs);
            this._channelsGuide.changeBrandIndex(-1);
        }
    }
    _handleDown() {
        if (this.streamIndex < this.streams.length - 1) {
            this.updateStreamIndex(this.streamIndex + 1, VERTICAL_SCROLL_DIRECTION.DOWN);
        }
        else {
            return false;
        }
    }
    updateSlotIndex(index) {
        this.slotIndex = index || 0;
        if (this.slotsCount - this.slotIndex > 2) {
            const xValue = this.slotIndex >= 3 ? -(this.slotIndex * (this.itemWidth + this.itemPadding)) : 0;
            this._xOffset.set([xValue, index]);
            setSmooth(this._channelsGuide.schedules, 'x', xValue, {
                duration: 0.3,
                delay: 0,
            });
            this._channelsGuide._slotsHolder.index = index < 3 ? 0 : index;
        }
    }
    updateStreamIndex(index, direction = VERTICAL_SCROLL_DIRECTION.UP) {
        this.streamIndex = index || 0;
        const getNextProgramIndex = () => {
            var _a;
            const comparsionValue = this.slotIndex > 3 ? this.slotIndex : this.programStartSlot;
            return this.streamIndex < this.schedules.length
                ? (_a = this.currentStreamPrograms) === null || _a === void 0 ? void 0 : _a.findIndex(({ startSlot, endSlot, component }) => component === 'EventTile' ||
                    (startSlot <= comparsionValue && endSlot > comparsionValue))
                : 0;
        };
        this.programIndex = getNextProgramIndex();
        const topOffSetIndex = direction === VERTICAL_SCROLL_DIRECTION.UP ? 0 : 1;
        const bottomOffSetIndex = this.tag('AssetInfo').alpha ? 0 : 1;
        if (index > topOffSetIndex && index < this.streams.length - bottomOffSetIndex) {
            const yValue = (index - 1) * -(this.itemHeight + this.itemPadding);
            this.scrollStreams(yValue);
        }
        this._channelsGuide.changeBrandIndex(index);
    }
    scrollStreams(yValue) {
        setSmooth(this._channelsGuide.schedules, 'y', yValue, {
            duration: 0.3,
            delay: 0,
        });
        setSmooth(this._channelsGuide.streams, 'y', yValue, {
            duration: 0.3,
            delay: 0,
        });
    }
};
