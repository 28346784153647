import { Lightning } from '@lightningjs/sdk';
import { COLORS, FONT_FACE } from '../../constants';
export default class AudioLanguageBadge extends Lightning.Component {
    static _template() {
        return {
            AudioLanguageBadge: {
                text: {
                    fontSize: 22,
                    fontFace: FONT_FACE.light,
                    text: this.bindProp('label'),
                    paddingLeft: 8,
                    paddingRight: 4,
                },
            },
        };
    }
    _init() {
        this.tag('AudioLanguageBadge').on('txLoaded', () => {
            this.tag('AudioLanguageBadge').patch({
                Border: {
                    texture: Lightning.Tools.getRoundRect(this.tag('AudioLanguageBadge').renderWidth, 28, 4, 1, COLORS.white, false),
                },
            });
        });
    }
}
