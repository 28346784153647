import getVodAttributes from './getVodAttributes';
import { getEndCardCommonAttributes, getEndCardRecommendationsAttributes, } from './getEndCardGlobalAttributes';
import { setDefaultValue } from '../../../../helpers/analytics';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
const getEndCardImpressionAttributes = (params = {}) => {
    const { shelf, ccLanguage, previousVideo, previousVideoType, video, recommendation, recommendationAlternate1, endCardTime, } = params;
    return setDefaultValue({
        ...getVodAttributes(video, ccLanguage, previousVideo, previousVideoType),
        ...getEndCardRecommendationsAttributes({
            recommendation,
            recommendationAlternate1,
        }),
        ...getEndCardCommonAttributes({ video, endCardTime, shelf }),
    }, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getEndCardImpressionAttributes;
