import { componentsForPlaceholders } from '../../api/requests';
import Variables from '../../graphql/Variables';
import rsnFilter from '../../graphql/filter/rsnFilter';
import componentsForPlaceholdersMapper from '../../graphql/mappers/componentsForPlaceholders';
import { createRequestConfig } from '../../lib/useRequest';
export const EventsWithFiltersRequestConfig = (queryVariables) => createRequestConfig({
    query: componentsForPlaceholders,
    mapper: (data) => rsnFilter(componentsForPlaceholdersMapper(data)),
    variables: Variables.componentsForPlaceholdersVariables(queryVariables),
    reject: [
        (data) => { var _a, _b; return ((_b = (_a = data === null || data === void 0 ? void 0 : data.components) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.component) === 'Message'; },
        (data) => { var _a; return !((_a = data === null || data === void 0 ? void 0 : data.components) === null || _a === void 0 ? void 0 : _a.find((e) => (e === null || e === void 0 ? void 0 : e.component) === 'Grid')); },
    ],
});
