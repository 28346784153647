import { Language } from '@lightningjs/sdk';
import BaseComponent from '../../base';
import TextButton from '../../buttons/TextButton';
import { COLORS, FLEX_DIRECTION, FONT_FACE } from '../../../constants';
import { formatSecondsAsHhMmSs, getRemainingAnnounce } from '../../../helpers';
export default class TempPassCTA extends BaseComponent {
    static _template() {
        return {
            x: 960,
            y: 120,
            Container: {
                flex: { direction: FLEX_DIRECTION.row },
                mount: 0.5,
                TempPassCountdown: {
                    flex: { direction: FLEX_DIRECTION.row },
                    ExpiresIn: {
                        flexItem: { marginRight: 15 },
                        mountY: 0.5,
                        text: {
                            fontFace: FONT_FACE.regular,
                            fontSize: 32,
                            text: Language.translate('tempPass-expires-in'),
                        },
                    },
                    Countdown: {
                        flexItem: { marginRight: 30 },
                        mountY: 0.5,
                        text: {
                            fontFace: FONT_FACE.semiBold,
                            fontSize: 40,
                            text: '30:00',
                        },
                    },
                },
                LinkProviderButton: {
                    type: TextButton,
                    mountY: 0.5,
                    h: 63,
                    radius: 31,
                    fontSize: 32,
                    fontFace: FONT_FACE.regular,
                    focusFontColor: COLORS.black,
                    unfocusFontColor: COLORS.black5,
                    focusBackGroundColor: COLORS.white,
                    unfocusBackgroundColor: COLORS.mediumGray3,
                    autoWidth: true,
                    padding: 22,
                    label: Language.translate('tempPass-mvpd-cta').toUpperCase(),
                },
            },
        };
    }
    _init() {
        this._linkButton = this.tag('LinkProviderButton');
        this._expiresInItem = this.tag('TempPassCountdown.ExpiresIn');
    }
    update(value) {
        this._remaining = value;
        this.tag('Countdown').text.text = formatSecondsAsHhMmSs(value);
    }
    _getFocused() {
        return this._linkButton || this;
    }
    get announce() {
        return [
            this._expiresInItem.text.text,
            getRemainingAnnounce(this._remaining),
            this._linkButton.label,
        ];
    }
    get remaining() {
        return this._remaining;
    }
    _setTranslation() {
        this._linkButton.label = Language.translate('tempPass-mvpd-cta').toUpperCase();
        this._expiresInItem.patch({
            text: { text: Language.translate('tempPass-expires-in') },
        });
    }
}
