import BaseComponent from '../../../base';
import EventTile from '../../../items/EventTile';
import ItemWrapper from '../../../items/ItemWrapper';
import GuideProgram from './GuideProgram';
export default class GuideProgramHolder extends BaseComponent {
    static _template() {
        const template = super._template();
        return {
            ...template,
            x: 0,
            y: 59,
            w: 3368,
            h: 868,
            clipping: true,
            Schedules: {
                boundsMargin: [500, 100, 500, 100],
            },
        };
    }
    _init() {
        this._schedules = this.tag('Schedules');
        this._schedules.children = [];
    }
    set schedules(v) {
        if (!v)
            return;
        if (!this._schedules)
            this._schedules = this.tag('Schedules');
        this._schedules.children = v.map(({ programs }, index) => ({
            ref: `ScheduleItem-${index}`,
            h: this.itemHeight,
            y: index * (this.itemHeight + this.itemPadding),
            children: [
                ...programs.map((program, index) => {
                    const { slotSpan, startSlot } = program;
                    const itemType = program.component === 'EventTile' ? EventTile : GuideProgram;
                    return {
                        w: (slotSpan || 1) * (this.itemWidth + this.itemPadding) - this.itemPadding,
                        h: this.itemHeight,
                        x: (startSlot || 0) * (this.itemWidth + this.itemPadding),
                        type: ItemWrapper,
                        itemType,
                        item: program,
                        index,
                    };
                }),
            ],
        }));
        this._refocus();
    }
    get schedules() {
        return this._schedules;
    }
}
