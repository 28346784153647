import { COLORS } from '../../../../constants';
const AD_BREAKPOINT_WIDTH = 9;
export class AdBreakMarkersDelegate {
    constructor(controls) {
        this._availableWidth = 0;
        this._controls = controls;
    }
    set currentTimeText(time) {
        this._controls.tag('CurrentTime').text.text = time;
    }
    set totalTimeText(time) {
        this._controls.tag('TotalTime').text.text = time;
    }
    get totalWidth() {
        var _a;
        return ((_a = this._controls.tag('TotalBar')) === null || _a === void 0 ? void 0 : _a.w) || 0;
    }
    draw(adbreaks, duration) {
        const component = this._controls.tag('AdBreakPoints');
        this._availableWidth = this.totalWidth;
        if (component && adbreaks.length > 0) {
            component.children = adbreaks.map(({ startTimeOnUi }) => {
                // Adding an offset for the width of each breakpoint
                const x = (startTimeOnUi / duration) * (this._availableWidth - AD_BREAKPOINT_WIDTH);
                return {
                    x,
                    w: AD_BREAKPOINT_WIDTH,
                    h: 8,
                    rect: true,
                    color: COLORS.white,
                };
            });
        }
    }
    getBarWidth(currentTime, duration) {
        if (!this._availableWidth)
            this._availableWidth = this.totalWidth;
        return duration ? (currentTime * this._availableWidth) / duration : 0;
    }
}
