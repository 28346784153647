import { Language, Lightning } from '@lightningjs/sdk';
import { FiltersContentStates } from '../../../../constants';
import MessageContainer from '../../../error/MessageContainer';
import StackGroup from '../../../StackGroup';
export default class Content extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._sections = [];
    }
    static _template() {
        return {
            ErrorContainer: {
                visible: false,
                y: 100,
                w: this.bindProp('w', (ctx) => ctx.w || 1454),
                type: MessageContainer,
                title: Language.translate('liveGuide-null-state-title'),
                message: Language.translate('liveGuide-null-state-subtitle'),
                buttonText: Language.translate('liveGuide-null-state-button'),
            },
            Container: {
                w: 1400,
                h: 900,
                type: StackGroup,
                y: 0,
                x: 0,
                scrollFocus: 0.5,
            },
        };
    }
    _init() {
        this._errorContainer = this.tag('ErrorContainer');
        this._container = this.tag('Container');
    }
    _getFocused() {
        return this.tag('Button') || this;
    }
    set sections(sections) {
        this._container.clear();
        this._sections = sections;
        this._container.add(this._sections);
        this._errorContainer.visible = false;
        this._container.visible = true;
        this._setState(FiltersContentStates.Container);
    }
    get sections() {
        return this._sections;
    }
    set error({ title, message, buttonText, hideButton = false, }) {
        this._errorContainer.title = title || '';
        this._errorContainer.message = message || '';
        this._errorContainer.buttonText =
            buttonText || Language.translate('liveGuide-null-state-button');
        this._errorContainer.hideButton = hideButton;
    }
    showErrorState() {
        if (this._errorContainer.hideButton) {
            this._errorContainer.visible = true;
            this._container.visible = false;
        }
        else
            this._setState(FiltersContentStates.Error);
    }
    static _states() {
        return [
            class Error extends this {
                $enter() {
                    this._errorContainer.visible = true;
                    this._container.visible = false;
                }
                _getFocused() {
                    return this.tag('ErrorContainer') || this;
                }
                _handleEnter() {
                    var _a;
                    (_a = this._nullStateCallback) === null || _a === void 0 ? void 0 : _a.call(this);
                }
            },
            class Container extends this {
                $enter() {
                    this._errorContainer.visible = false;
                    this._container.visible = true;
                }
                _getFocused() {
                    return this.tag('Container') || this;
                }
            },
        ];
    }
}
