import { Language } from '@lightningjs/sdk';
import LongScrollPage from '../LongScrollPage';
import MessageContainer from '../../components/error/MessageContainer';
import { SectionsSpawner } from '../../api/spawners';
import { COLORS, EventsWithFiltersStates } from '../../constants';
import { setSmooth } from '../../helpers';
import { findGridComponent, findLinkSelectableComponent } from '../../store/PlayerStore/actions';
import LabelsList, { LabelsListRenderingType } from '../../components/labelsList/LabelsList';
import { createItems } from '../../components/componentMaps';
import { Container } from '../../api/models';
import { KeyMap } from '../../lib/KeyMap';
import { merge } from 'lodash';
import FilterPosition from '../../util/filterPosition/filterPosition';
import ContentPosition from '../../util/contentPosition';
import { useRequest } from '../../lib/useRequest';
import { PaginatedComponentRequestConfig } from '../../components/LinksSelectableGroup/request';
import { EventsWithFiltersRequestConfig } from './request';
export const GridDelegateTypes = {
    replayTile: 'replayTileOlympicsContentHub',
    videoTile: 'videoTileOlympicsContentHub',
};
var LoadingState;
(function (LoadingState) {
    LoadingState["LOADING"] = "loading";
    LoadingState["SUCCESS"] = "success";
    LoadingState["ERROR"] = "error";
})(LoadingState || (LoadingState = {}));
const resultStyle = { x: 536, y: 200, w: 1454 };
export default class EventsWithFilters extends LongScrollPage {
    constructor() {
        super(...arguments);
        this.label = 'Sports Filter menu';
        this.announceTitle = '';
        this._keyMap = KeyMap(this, [
            [
                [EventsWithFiltersStates.Filters],
                EventsWithFiltersStates.Results,
                [EventsWithFiltersStates.Error],
            ],
        ]);
        this._container = this.tag('Container');
        this._parentRoute = '';
        this._pageTitle = '';
        this._cursors = {};
        this._renderResult = (gridSection) => {
            return ([results, filters] = []) => {
                var _a, _b;
                if (results) {
                    this.results = results;
                    if ((_a = gridSection === null || gridSection === void 0 ? void 0 : gridSection.data) === null || _a === void 0 ? void 0 : _a.moreItems) {
                        this.results.moreItems = (_b = gridSection === null || gridSection === void 0 ? void 0 : gridSection.data) === null || _b === void 0 ? void 0 : _b.moreItems;
                    }
                }
                if (filters)
                    this.filters = filters;
                this.loadingState = LoadingState.SUCCESS;
            };
        };
        this._renderFiltersOnly = (gridSection) => {
            return ([results, filters] = []) => {
                if (filters)
                    this.filters = filters;
            };
        };
        this._renderError = () => {
            this._container.patch({
                Results: undefined,
            });
            this.loadingState = LoadingState.ERROR;
        };
    }
    static _template() {
        return {
            Filters: {
                x: 0,
                y: 200,
                w: 456,
                type: LabelsList,
                sectionSize: 700,
                mode: LabelsListRenderingType.VERTICAL,
            },
            Container: {
                visible: false,
                Results: {},
                Error: {
                    x: 450,
                    y: 400,
                    type: MessageContainer,
                    title: Language.translate('olympics-empty-title'),
                    message: Language.translate('olympics-empty-subtitle'),
                    buttonText: Language.translate('olympics-all-sports-button'),
                },
            },
        };
    }
    // #region Lifecycle
    _init() {
        this.stage.setClearColor(COLORS.dark);
    }
    _active() {
        this.stage.setClearColor(COLORS.dark);
        setSmooth(this.widgets.loader, 'visible', 0);
    }
    _onMounted() {
        this._setTitlePage();
    }
    _disable() {
        this._keyMap.reset();
        this.widgets.menu.expand();
    }
    _detach() {
        var _a;
        super._detach();
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
    }
    // #endregion
    // #region Getters/setters
    set params(params) {
        this._params = params;
    }
    get announce() {
        var _a;
        return ((_a = this._params) === null || _a === void 0 ? void 0 : _a.loadAnnounce) ? `${this.announceTitle}, ${this._params.loadAnnounce}` : '';
    }
    set results(v) {
        if (v) {
            const announce = `In a ${v.lastRowNumber + 1} by ${v.itemsPerRow} grid, press left, right, down for items`;
            v = merge(v, resultStyle, { title: announce });
        }
        this.tag('Container').patch({ Results: v });
    }
    get results() {
        return this._container.tag('Results');
    }
    set filters(filtersContainer) {
        var _a, _b;
        if (filtersContainer && filtersContainer.getDataElement) {
            this._spawnerFilters = (_a = filtersContainer.getDataElement()) === null || _a === void 0 ? void 0 : _a.spawner;
            this.filters.items = (_b = this._spawnerFilters) === null || _b === void 0 ? void 0 : _b.createItemLabels(null, COLORS.yellow2);
            this._setState(EventsWithFiltersStates.Filters);
            //if we are reselecting a previous filter, load the items associated with the filter
            if (FilterPosition.getPositionForCurrentPage() > 0)
                this._getItems();
        }
    }
    get filters() {
        return this.tag('Filters');
    }
    get error() {
        return this.tag('Error');
    }
    set loadingState(state) {
        if (state === LoadingState.LOADING) {
            this._container.visible = false;
            return;
        }
        const isSuccess = state === LoadingState.SUCCESS;
        this._container.patch({
            visible: true,
            Results: {
                visible: isSuccess,
            },
            Error: {
                visible: !isSuccess,
            },
        });
    }
    // #endregion
    // #region Data fetching
    $getMoreItems(data) {
        if (!this._cursors[data.cursor])
            this._cursors[data.cursor] = 0;
        this._cursors[data.cursor]++;
        if (this._cursors[data.cursor] > 1)
            return;
        useRequest(PaginatedComponentRequestConfig(data))
            .fetch()
            .then((result) => {
            const { data = {} } = new Container(result.data);
            this.results.addItems(createItems(data === null || data === void 0 ? void 0 : data.items));
            this.results.moreItems = data === null || data === void 0 ? void 0 : data.moreItems;
        })
            .catch(() => {
            // fail silently
        });
    }
    set apiData(apiData) {
        var _a;
        const gridSection = findGridComponent(apiData.sections);
        const filtersSection = findLinkSelectableComponent(apiData.sections);
        this._parentRoute = (_a = filtersSection === null || filtersSection === void 0 ? void 0 : filtersSection.data.itemLabels[0]) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        this._pageTitle = gridSection === null || gridSection === void 0 ? void 0 : gridSection.data.listTitle;
        this._setTitlePage();
        //if we are loading a previous filter instead of default, just load the filters
        //when the filters are set, we initate _getItems to get the results for the filter
        if (FilterPosition.getPositionForCurrentPage() > 0) {
            SectionsSpawner(this.stage, [gridSection, filtersSection], GridDelegateTypes).then(this._renderFiltersOnly(gridSection));
        }
        else {
            //otherwise, load the default results for the page (all filter)
            SectionsSpawner(this.stage, [gridSection, filtersSection], GridDelegateTypes).then(this._renderResult(gridSection));
        }
    }
    async _fetchPlaceholderData(queryVariables) {
        var _a, _b, _c, _d, _e, _f, _g;
        try {
            const response = await useRequest(EventsWithFiltersRequestConfig(queryVariables)).fetch();
            const gridSection = findGridComponent(response.components);
            if (gridSection) {
                const result = await SectionsSpawner(this.stage, [gridSection], GridDelegateTypes);
                this._renderResult(gridSection)(result);
            }
        }
        catch (e) {
            const message = ((_c = (_b = (_a = e === null || e === void 0 ? void 0 : e.data) === null || _a === void 0 ? void 0 : _a.components) === null || _b === void 0 ? void 0 : _b[0]) === null || _c === void 0 ? void 0 : _c.data) || {};
            if (message) {
                this._container.patch({
                    Error: {
                        title: message.textRow1 ? message.textRow1 : Language.translate('olympics-empty-title'),
                        message: message.textRow2
                            ? message.textRow2
                            : Language.translate('olympics-empty-subtitle'),
                        buttonText: ((_e = (_d = message.cta) === null || _d === void 0 ? void 0 : _d.data) === null || _e === void 0 ? void 0 : _e.text)
                            ? (_g = (_f = message.cta) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.text
                            : Language.translate('olympics-all-sports-button'),
                    },
                });
            }
            this._renderError();
        }
    }
    _getItems() {
        var _a, _b, _c, _d;
        let index = FilterPosition.getPositionForCurrentPage();
        if ((_a = this._spawnerFilters) === null || _a === void 0 ? void 0 : _a.getItemLabelsConfig()[index]) {
            index =
                ((_b = this._spawnerFilters) === null || _b === void 0 ? void 0 : _b.getItemLabelsConfig()[index].index) !== null
                    ? (_c = this._spawnerFilters) === null || _c === void 0 ? void 0 : _c.getItemLabelsConfig()[index].index
                    : index;
        }
        const selectedElement = (_d = this._spawnerFilters) === null || _d === void 0 ? void 0 : _d.getItems()[index];
        this.loadingState = LoadingState.LOADING;
        if (selectedElement)
            this._fetchPlaceholderData(selectedElement.data.queryVariables);
    }
    // #endregion
    // #region UI
    _setTitlePage() {
        this.widgets.menu.collapse({
            parentRoute: this._parentRoute,
            subCategoryItem: this._pageTitle,
            hideProfile: true,
        });
    }
    // #endregion
    _handleLeft() {
        this._keyMap.left();
    }
    _handleRight() {
        this._keyMap.right();
    }
    static _states() {
        return [
            class Filters extends this {
                _getFocused() {
                    return this.filters;
                }
                _handleEnter() {
                    ContentPosition.clearPositionForCurrentPage();
                    FilterPosition.setPositionForCurrentPage(this.filters.activeIndex);
                    //reset cursor map on filter change
                    this._cursors = {};
                    this._getItems();
                }
            },
            class Results extends this {
                _getFocused() {
                    return this.results;
                }
            },
            class Error extends this {
                _getFocused() {
                    return this.error;
                }
                _handleEnter() {
                    var _a, _b;
                    const initialSelection = ((_a = this._spawnerFilters) === null || _a === void 0 ? void 0 : _a.initiallySelected) || 0;
                    (_b = this.filters) === null || _b === void 0 ? void 0 : _b.selectItem(initialSelection);
                    this._setState(EventsWithFiltersStates.Filters);
                    this._keyMap.reset();
                    ContentPosition.clearPositionForCurrentPage();
                    FilterPosition.setPositionForCurrentPage(initialSelection);
                    this._getItems();
                }
            },
        ];
    }
    _getFocused() {
        return this.filters || this;
    }
}
