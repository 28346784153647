import { IPlayerEvent } from './IPlayerEvent';
export class SubtitleEvent extends IPlayerEvent {
    constructor(subtitleData) {
        super();
        this._subtitleData = subtitleData;
    }
    get subtitleData() {
        return this._subtitleData;
    }
}
