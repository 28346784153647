import { COLORS, FLEX_DIRECTION, FONT_FACE, SCREEN_SIZE, TEXT_ALIGN, VERTICAL_ALIGN, } from '../../../../constants';
import ModalButton from '../../../../components/buttons/ModalButton';
import { FastImg, isExternalLinkingAllowed } from '../../../../helpers';
import { EVENTS } from '../../../../lib/analytics/types';
import { sendMetric } from '../../../../lib/analytics/Analytics';
import TVPlatform from '../../../../lib/tv-platform';
import { OLY_IMPOLICY } from '../../../../constants';
import { AuthenticationEvents } from '../../../../authentication/Authentication';
export const WithPeacockModal = (base) => class extends base {
    constructor() {
        super(...arguments);
        this._peacockModalButtonIndex = 0;
    }
    static _template() {
        return {
            // @ts-expect-error TODO: fix super not working on HOCs
            ...super._template(),
            PeacockModal: {
                visible: false,
                alpha: 0.9,
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                rect: true,
                color: COLORS.black,
                Logo: {
                    y: 300,
                    w: 405,
                    x: 960,
                    mountX: 0.5,
                },
                Title: {
                    y: 480,
                    x: 960,
                    mountX: 0.5,
                    text: {
                        fontSize: 54,
                        fontFace: FONT_FACE.light,
                        lineHeight: 70,
                        textColor: COLORS.white,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        maxLines: 2,
                        textAlign: TEXT_ALIGN.center,
                        text: '',
                    },
                },
                Subtitle: {
                    y: 570,
                    x: 960,
                    mountX: 0.5,
                    text: {
                        fontSize: 36,
                        fontFace: FONT_FACE.light,
                        lineHeight: 70,
                        textColor: COLORS.white,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        maxLines: 2,
                        textAlign: TEXT_ALIGN.center,
                        text: 'Go to peacocktv.com to get the full experience.',
                    },
                },
                Buttons: {
                    flex: {
                        direction: FLEX_DIRECTION.row,
                    },
                    x: 960,
                    y: 720,
                    OpenPeacock: {
                        type: ModalButton,
                        label: 'Go to Peacock',
                        visible: false,
                    },
                    Dismiss: {
                        type: ModalButton,
                        label: 'OK',
                    },
                },
            },
        };
    }
    static _states() {
        return [
            // @ts-expect-error TODO: fix super not working on HOCs
            ...super._states(),
            class PeacockModal extends this {
                $enter() {
                    this._clearTimeout();
                    AuthenticationEvents.stopPolling();
                    if (this._peacockModal) {
                        this._peacockModal.visible = true;
                    }
                }
                $exit() {
                    var _a;
                    (_a = this._peacockModal) === null || _a === void 0 ? void 0 : _a.patch({
                        visible: false,
                    });
                }
                _handleEnter(e) {
                    var _a;
                    if ((_a = this._peacockModalOpenButton) === null || _a === void 0 ? void 0 : _a._isFocus) {
                        TVPlatform.exitToPeacock();
                    }
                    else {
                        this._trackPeacockModalDismiss();
                        super._handleBack(e);
                    }
                }
                _handleLeft() {
                    this._peacockModalButtonIndex = 0;
                }
                _handleRight() {
                    this._peacockModalButtonIndex = 1;
                }
                _getFocused() {
                    var _a;
                    return ((((_a = this._peacockModalOpenButton) === null || _a === void 0 ? void 0 : _a.visible) && this._peacockModalButtonIndex === 0
                        ? this._peacockModalOpenButton
                        : this._peacockModalDismissButton) || this);
                }
            },
        ];
    }
    _init() {
        var _a;
        super._init();
        this._peacockModal = this.tag('PeacockModal');
        this._peacockModalOpenButton = this.tag('PeacockModal.OpenPeacock');
        this._peacockModalDismissButton = this.tag('PeacockModal.Dismiss');
        if (TVPlatform.capabilities.externalAppLinking && isExternalLinkingAllowed()) {
            this.tag('PeacockModal.Buttons').x -= (((_a = this._peacockModalOpenButton) === null || _a === void 0 ? void 0 : _a.finalW) || 0) / 2;
            if (this._peacockModalOpenButton)
                this._peacockModalOpenButton.visible = true;
        }
    }
    _disable() {
        super._disable();
        this._setState('NewCode');
    }
    _onCodeSuccess(expires) {
        if (this._getState() === 'PeacockModal') {
            return;
        }
        super._onCodeSuccess(expires);
    }
    _handleBack(e) {
        var _a, _b, _c, _d;
        if (this._program &&
            'notification' in this._program &&
            ((_b = (_a = this._program) === null || _a === void 0 ? void 0 : _a.notification) === null || _b === void 0 ? void 0 : _b.data) &&
            !((_c = this._peacockModal) === null || _c === void 0 ? void 0 : _c.visible)) {
            this._patchPeacockModal();
            this._trackPeacockModalLoad();
            this._setState('PeacockModal');
            return;
        }
        (_d = super._handleBack) === null || _d === void 0 ? void 0 : _d.call(this, e);
    }
    _patchPeacockModal() {
        var _a, _b;
        if (!this._program || !('notification' in this._program))
            return;
        const { cta, headline, logo, message, dismissText } = ((_b = (_a = this._program) === null || _a === void 0 ? void 0 : _a.notification) === null || _b === void 0 ? void 0 : _b.data) || {};
        if (logo) {
            this.tag('PeacockModal.Logo').texture = FastImg(logo).contain(405, 124, OLY_IMPOLICY.RECT_SMEDIUM_640_360);
        }
        if (headline) {
            this.tag('PeacockModal.Title').text.text = headline;
        }
        if (message) {
            this.tag('PeacockModal.Subtitle').text.text = message;
        }
        if (dismissText && this._peacockModalDismissButton) {
            this._peacockModalDismissButton.label = dismissText;
        }
        if (cta && this._peacockModalOpenButton) {
            this._peacockModalOpenButton.label = cta;
        }
    }
    _trackPeacockModalLoad() {
        var _a, _b;
        const message = (this._program &&
            'notification' in this._program &&
            ((_b = (_a = this._program.notification) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.message)) ||
            '';
        sendMetric(EVENTS.MODAL_LOAD, {
            modalName: message,
            modalType: 'Peacock Live Stream Modal',
        });
    }
    _trackPeacockModalDismiss() {
        var _a, _b;
        const { brand, programTitle, seasonNumber } = this._program || {};
        const dismissText = (this._program &&
            'notification' in this._program &&
            ((_b = (_a = this._program.notification) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.dismissText)) ||
            '';
        sendMetric(EVENTS.CLICK, {
            brand: brand === null || brand === void 0 ? void 0 : brand.title,
            show: programTitle,
            season: seasonNumber,
            name: dismissText,
        });
    }
};
