import Tile from './Tile';
export default class GuideStream extends Tile {
    get channelId() {
        return this.data.channelId;
    }
    get resourceId() {
        return this.data.resourceId;
    }
    get whiteBrandLogo() {
        return this.data.whiteBrandLogo;
    }
    get brandDisplayTitle() {
        return this.data.brandDisplayTitle || '';
    }
    get secondaryTitle() {
        return this.data.secondaryTitle || '';
    }
    get stationId() {
        return this.data.stationId;
    }
}
