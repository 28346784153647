import { Lightning, Utils } from '@lightningjs/sdk';
import { COLORS, FLEX_DIRECTION, FONT_FACE, MAX_LINES_SUFFIX } from '../../constants';
export default class EventTile extends Lightning.Component {
    static _template() {
        return {
            w: (w) => w,
            h: (h) => h,
            rect: true,
            color: COLORS.dark3,
            ProgramInfo: {
                x: 30,
                w: (w) => (w > 1620 ? 1620 : w),
                Title: {
                    w: (w) => w - 60,
                    y: 20,
                    text: {
                        fontFace: FONT_FACE.regular,
                        fontSize: 30,
                        lineHeight: 38,
                        wordWrapWidth: (w) => w,
                        maxLinesSuffix: MAX_LINES_SUFFIX,
                        maxLines: 2,
                        textColor: COLORS.white,
                    },
                },
                Description: {
                    w: (w) => w - 60,
                    y: 64,
                    alpha: 0,
                    text: {
                        fontFace: FONT_FACE.regular,
                        fontSize: 25,
                        wordWrapWidth: (w) => w,
                        maxLinesSuffix: MAX_LINES_SUFFIX,
                        maxLines: 1,
                        textColor: COLORS.dark2,
                    },
                },
                RatingInfo: {
                    y: 155,
                    alpha: 0,
                    flex: { direction: FLEX_DIRECTION.row },
                    Rating: {
                        flexItem: {},
                        text: {
                            fontFace: FONT_FACE.light,
                            fontSize: 25,
                            maxLinesSuffix: MAX_LINES_SUFFIX,
                            maxLines: 1,
                            textColor: COLORS.dark2,
                        },
                    },
                    AudioDescription: {
                        flexItem: { marginLeft: 20, marginTop: 3 },
                        alpha: 0,
                        src: Utils.asset('images/audio-description.png'),
                    },
                },
            },
        };
    }
    set item(item) {
        this._activeProgram = false;
        this.tag('Title').on('txLoaded', (obj) => {
            this.tag('Description').y = obj._source.renderInfo.lines.length === 2 ? 107 : 64;
        });
        this.tag('Title').text.text = item.title;
        // this.tag('Description').text.text = item.programDescription
        //this.tag('Rating').text.text = item.ratingWithAdvisories
        //this.tag('AudioDescription').alpha = item.audioDescription
    }
    _focus() {
        this.patch({
            color: COLORS.lightGray1,
            ProgramInfo: {
                Title: {
                    text: {
                        textColor: COLORS.dark2,
                    },
                },
                Description: {
                    alpha: 1,
                },
                RatingInfo: {
                    alpha: 1,
                },
            },
        });
    }
    _unfocus() {
        this.patch({
            color: this._activeProgram ? COLORS.dark9 : COLORS.dark3,
            ProgramInfo: {
                Title: {
                    text: {
                        textColor: COLORS.white,
                    },
                },
                Description: {
                    alpha: 0,
                },
                RatingInfo: {
                    alpha: 0,
                },
            },
        });
    }
    set activeProgram(v) {
        this._activeProgram = v;
        this.color = v ? COLORS.dark9 : COLORS.dark3;
    }
}
