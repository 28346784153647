var _a;
import { Log } from '@lightningjs/sdk';
import mParticle from '@mparticle/web-sdk';
import ParkAPI, { USERS_VERSION } from './ParkApi';
import WatchesLib from './Watches';
import FavoritesLib from './Favorites';
import { setMpid, getMpid } from '../helpers/analytics';
import { sendMetric } from '../lib/analytics/Analytics';
import { EVENTS } from '../lib/analytics/types';
import AppConfigFactorySingleton from '../config/AppConfigFactory';
class MParticleApi {
}
_a = MParticleApi;
MParticleApi.MPARTICLE_API_TAG = 'mParticle API';
MParticleApi.DEFAULT_MPARTICLE_ID = '0';
/**
 * Should Migrate User - check if anonymous mpid has favorites or watches
 * @returns {boolean}
 */
MParticleApi.shouldMigrateUser = async (mpid) => {
    var _b, _c;
    try {
        const favorites = await FavoritesLib.get(mpid);
        const watches = await WatchesLib.get(mpid);
        if (((_b = favorites === null || favorites === void 0 ? void 0 : favorites.meta) === null || _b === void 0 ? void 0 : _b.count) > 0 || ((_c = watches === null || watches === void 0 ? void 0 : watches.meta) === null || _c === void 0 ? void 0 : _c.count) > 0) {
            return false;
        }
    }
    catch (error) {
        return false;
    }
    return true;
};
/**
 * Migrate User - sync anonymous user watches with NBC profile
 * @returns {Promise}
 */
MParticleApi.migrateUser = async (anonymousMpid, mpid) => {
    const url = `users/${anonymousMpid}/migrate/${mpid}`;
    const result = await ParkAPI.post(url, 'POST', {}, { version: USERS_VERSION });
    return result;
};
/**
 * Login identity profile with mParticle
 * @returns {Promise}
 */
MParticleApi.login = (identity) => new Promise((resolve, reject) => {
    if (!identity)
        reject();
    if (identity) {
        sendMetric(EVENTS.IDM_CONVERSION);
        const { mail: email, id: customerid } = identity;
        mParticle.Identity.login({ userIdentities: { email, customerid } }, async (response) => {
            var _b;
            const nbcProfileMPID = response.getUser().getMPID();
            const anonymousMPID = ((_b = response === null || response === void 0 ? void 0 : response.getPreviousUser()) === null || _b === void 0 ? void 0 : _b.getMPID()) || MParticleApi.DEFAULT_MPARTICLE_ID;
            // Update localStorage with new mpid.
            setMpid(nbcProfileMPID);
            const shouldMigrateUser = await MParticleApi.shouldMigrateUser(nbcProfileMPID);
            if (shouldMigrateUser) {
                Log.info(MParticleApi.MPARTICLE_API_TAG, 'NBCUniversal Profile has no existing favorites/watch data. Migrating existing favorites/watches.');
                await MParticleApi.migrateUser(anonymousMPID, nbcProfileMPID);
            }
            else {
                Log.info(MParticleApi.MPARTICLE_API_TAG, 'NBCUniversal Profile has existing favorites/watch data. Favorites/watches were not migrated.');
            }
            resolve();
        });
    }
});
/**
 * Logout identity profile with mParticle
 * @returns {Promise}
 */
MParticleApi.logout = () => new Promise((resolve) => {
    mParticle.Identity.logout({ userIdentities: {} }, (response) => {
        var _b;
        // Reset to previous mpid.
        if (response === null || response === void 0 ? void 0 : response.getPreviousUser) {
            const previousMpid = ((_b = response === null || response === void 0 ? void 0 : response.getPreviousUser()) === null || _b === void 0 ? void 0 : _b.getMPID()) || MParticleApi.DEFAULT_MPARTICLE_ID;
            // Return localStorage to previous mpid or 0.
            setMpid(previousMpid);
        }
        resolve();
    });
});
/**
 * Fetch mParticle audience ids
 * @returns {Promise}
 */
MParticleApi.getAudiences = async () => {
    const url = AppConfigFactorySingleton.get().config.mparticle.audienceSearchUrl;
    const mpid = getMpid();
    try {
        const response = await fetch(`${url}?mpid=${mpid}`);
        const audienceJSON = await response.json();
        const audiences = (audienceJSON === null || audienceJSON === void 0 ? void 0 : audienceJSON.audiences) || [];
        Log.info(MParticleApi.MPARTICLE_API_TAG, 'audience ids found for this user', audiences);
        return audiences;
    }
    catch {
        Log.info(MParticleApi.MPARTICLE_API_TAG, 'no audience ids found for this user');
        return [];
    }
};
MParticleApi.getSessionId = () => { var _b; return ((_b = mParticle === null || mParticle === void 0 ? void 0 : mParticle.sessionManager) === null || _b === void 0 ? void 0 : _b.getSession()) || ''; };
export default MParticleApi;
