import { EPG_VIEWS, LiveGuideV2States } from '../../../constants';
import { EpgGuideController } from '../EpgGuideController';
export const TabsStateFactory = (base) => class Tabs extends base {
    _getFocused() {
        return this._tabs || super._getFocused();
    }
    _handleLeft() {
        var _a;
        (_a = this._tabs) === null || _a === void 0 ? void 0 : _a.changeIndex(-1);
    }
    _handleRight() {
        var _a;
        (_a = this._tabs) === null || _a === void 0 ? void 0 : _a.changeIndex(1);
    }
    _handleEnter() {
        var _a;
        (_a = this._tabs) === null || _a === void 0 ? void 0 : _a.selectCurrent();
    }
    _restoreFocus() {
        var _a;
        if (this._channelsGuide.loaded || ((_a = this._filtersGuide.items) === null || _a === void 0 ? void 0 : _a.length)) {
            const view = EpgGuideController.currentEpgView;
            this._setState(view === EPG_VIEWS.channels ? LiveGuideV2States.Channels : LiveGuideV2States.Filters);
        }
        else {
            this._setState(LiveGuideV2States.Tabs);
        }
    }
    _handleUp() {
        this._restoreFocus();
        this._setState(LiveGuideV2States.AssetInfo);
        this._channelsGuide.changeBrandIndex(-1);
    }
    _handleDown() {
        var _a, _b, _c, _d;
        this._restoreFocus();
        if (EpgGuideController.currentEpgView == EPG_VIEWS.channels) {
            this._channelsGuide.changeBrandIndex(0);
            (_c = (_b = (_a = this._channelsGuide) === null || _a === void 0 ? void 0 : _a.streams) === null || _b === void 0 ? void 0 : _b.children[0]) === null || _c === void 0 ? void 0 : _c.setChildProps({
                focused: true,
                channelId: (_d = this._channelsGuide) === null || _d === void 0 ? void 0 : _d.streams.children[0]._item.channelId,
            });
        }
    }
};
