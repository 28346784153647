import { Language, Lightning, Registry, Router, Utils } from '@lightningjs/sdk';
import TextButton from '../../components/buttons/TextButton';
import { FastImg, pipeSeparate, setSmooth } from '../../helpers';
import { getUserProfile } from '../../api/Identity';
import { ALIGN_ITEMS, COLORS, FONT_FACE, JUSTIFY_CONTENT, OLY_IMPOLICY, ROUTE, ROUTE_ENTITY, SCREEN_SIZE, TEXT_ALIGN, } from '../../constants';
import RouterUtil from '../../util/RouterUtil';
import { reduceEpisodeCount } from '../../api/Api';
import { ACTIVATION_LANDING, ACTIVATION_TYPE } from '../../widgets/Modals/activation/constants';
import ModalManager, { ModalTypes } from '../../lib/ModalManager';
import { AuthenticationEvents } from '../../authentication/Authentication';
import { useRequest } from '../../lib/useRequest';
import { CreditsPageRequestConfig } from './request';
export default class Credits extends Lightning.Component {
    static _template() {
        return {
            w: SCREEN_SIZE.width,
            h: SCREEN_SIZE.height,
            Image: {
                x: 240,
                y: 225,
                w: 790,
                h: 444,
            },
            Badge: {
                x: 240,
                y: 225,
                h: 50,
                rect: true,
                color: COLORS.white1,
                flex: {},
                Label: {
                    flexItem: {
                        marginTop: 10,
                        marginBottom: -4,
                        marginLeft: 20,
                        marginRight: 20,
                    },
                    color: COLORS.dark,
                    text: {
                        textAlign: TEXT_ALIGN.center,
                        fontSize: 24,
                        fontFace: FONT_FACE.regular,
                    },
                },
            },
            KeyImage: {
                x: 950,
                y: 225,
                w: 80,
                h: 50,
                rect: true,
                color: COLORS.black4,
                alpha: 1,
                ImageLabel: {
                    x: (w) => w / 2,
                    y: (h) => h / 2,
                    mount: 0.5,
                    w: 40,
                    h: 21,
                    color: COLORS.white1,
                    texture: Lightning.Tools.getSvgTexture(Utils.asset('images/key.svg'), 40, 21),
                },
            },
            Title: {
                x: 240,
                y: 695,
                w: 730,
                h: 50,
                text: {
                    fontSize: 40,
                    fontFace: FONT_FACE.semiBold,
                    textColor: COLORS.white,
                },
            },
            SecondaryTitle: {
                x: 240,
                y: 754,
                w: 730,
                h: 38,
                text: {
                    fontSize: 30,
                    fontFace: FONT_FACE.regular,
                    textColor: COLORS.white,
                },
            },
            LogoContainer: {
                x: 970,
                y: 695,
                h: 97,
                w: 60,
                rect: true,
                color: COLORS.transparent,
                alpha: 0.7,
                flex: {
                    alignItems: ALIGN_ITEMS.center,
                    justifyContent: JUSTIFY_CONTENT.center,
                },
                Logo: {
                    rect: true,
                },
            },
            CreditsTitle: {
                x: 1140,
                y: 215,
                w: 574,
                h: 258,
                text: {
                    fontSize: 60,
                    fontFace: FONT_FACE.light,
                    textColor: COLORS.white,
                    lineHeight: 64,
                },
            },
            CreditNum: {
                x: 1140,
                y: 443,
                color: COLORS.white,
                texture: Lightning.Tools.getRoundRect(60, 60, 30),
                NumLabel: {
                    x: 32,
                    y: 33,
                    mount: 0.5,
                    text: {
                        fontSize: 52,
                        textAlign: TEXT_ALIGN.center,
                        fontFace: FONT_FACE.regular,
                        textColor: COLORS.black,
                    },
                },
            },
            CreditMessage: {
                x: 1220,
                y: 447,
                w: 470,
                h: 52,
                text: {
                    fontSize: 48,
                    lineHeight: 52,
                    fontFace: FONT_FACE.regular,
                    textColor: COLORS.white,
                },
            },
            WatchNowBtn: {
                x: 1140,
                y: 613,
                w: 528,
                h: 72,
                type: TextButton,
                radius: 36,
                fontSize: 37,
                fontFace: FONT_FACE.regular,
                focusFontColor: COLORS.dark,
                unfocusFontColor: COLORS.white,
                focusBackGroundColor: COLORS.lightGray3,
                unfocusBackgroundColor: COLORS.black3,
                autoWidth: false,
                padding: 0,
            },
            LaterButton: {
                x: 1140,
                y: 715,
                w: 528,
                h: 72,
                type: TextButton,
                radius: 36,
                fontSize: 37,
                fontFace: FONT_FACE.regular,
                focusFontColor: COLORS.dark,
                unfocusFontColor: COLORS.white,
                focusBackGroundColor: COLORS.lightGray3,
                unfocusBackgroundColor: COLORS.black3,
                autoWidth: false,
                padding: 0,
            },
        };
    }
    set params(params) {
        this._videoId = params.videoId;
    }
    _init() {
        this.stage.setClearColor(COLORS.dark);
        if (ModalManager.getActiveModal())
            ModalManager.close();
        this._authnSubscription = AuthenticationEvents.subscribe((type) => {
            if (type === ACTIVATION_TYPE.MVPD)
                this._tvProviderLinked();
        });
    }
    _detach() {
        this._authnSubscription.unsubscribe();
    }
    async load() {
        this.apiData = await useRequest(CreditsPageRequestConfig(this._videoId)).fetch();
    }
    set apiData(v) {
        Registry.setTimeout(() => {
            setSmooth(this.widgets.loader, 'visible', 0);
            this.videoData = v.metadata;
            this._userProfile = getUserProfile();
            this._setUserProfile();
            this._setState('WatchNowBtn');
        }, 100);
    }
    set videoData(data) {
        if (!data)
            return;
        let title, secondaryTitle;
        if (data.programmingType === 'Movie') {
            title = data.title;
            secondaryTitle = pipeSeparate([data.rating, data.secondaryTitle]);
        }
        else {
            title = data.secondaryTitle;
            secondaryTitle = data.title;
        }
        this.patch({
            Image: {
                texture: FastImg(data.image).contain(790, 444),
            },
            Badge: {
                alpha: data.labelBadge ? 1 : 0,
                Label: {
                    text: { text: data.labelBadge },
                },
            },
            KeyImage: {
                alpha: data.locked,
            },
            Title: {
                text: { text: title },
            },
            SecondaryTitle: {
                text: { text: secondaryTitle },
            },
            LogoContainer: {
                Logo: {
                    texture: FastImg(data.whiteBrandLogo).contain(60, 60, OLY_IMPOLICY.SQUARE),
                },
            },
        });
    }
    _setUserProfile() {
        const episodeCount = this._userProfile.episodeCount;
        this.patch({
            CreditsTitle: {
                text: {
                    text: episodeCount
                        ? Language.translate('ready_to_watch')
                        : Language.translate('link_tv_provider_to_watch'),
                },
            },
            CreditNum: {
                alpha: episodeCount,
                NumLabel: {
                    text: { text: episodeCount },
                },
            },
            CreditMessage: {
                x: episodeCount ? 1220 : 1140,
                text: {
                    text: episodeCount
                        ? Language.translate('credits_Available')
                        : Language.translate('no_credits'),
                },
            },
            WatchNowBtn: {
                label: (episodeCount
                    ? Language.translate('watch_now')
                    : Language.translate('link_tv_provider')).toUpperCase(),
            },
            LaterButton: {
                label: (episodeCount
                    ? Language.translate('maybe_later')
                    : Language.translate('cancel')).toUpperCase(),
            },
        });
    }
    _tvProviderLinked() {
        if (!this._userProfile.episodeCount)
            RouterUtil.navigateToRoute(ROUTE.video, {
                entity: ROUTE_ENTITY.videoId,
                value: this._videoId,
            });
    }
    static _states() {
        return [
            class WatchNowBtn extends this {
                _getFocused() {
                    return this.tag('WatchNowBtn') || this;
                }
                _handleDown() {
                    this._setState('LaterButton');
                }
                _handleEnter() {
                    if (this._userProfile.episodeCount) {
                        reduceEpisodeCount(this._videoId);
                        RouterUtil.navigateToRoute(ROUTE.video, {
                            entity: ROUTE_ENTITY.videoId,
                            value: this._videoId,
                        }, { allowToPlay: true });
                    }
                    else {
                        ModalManager.open(ModalTypes.ACTIVATION, {
                            landing: ACTIVATION_LANDING.MVPD,
                            videoId: this._videoId,
                        });
                    }
                }
            },
            class LaterButton extends this {
                _getFocused() {
                    return this.tag('LaterButton') || this;
                }
                _handleUp() {
                    this._setState('WatchNowBtn');
                }
                _handleEnter() {
                    Router.back();
                }
            },
        ];
    }
}
