import { LabelListRenderingStrategy } from '.';
import { SCREEN_SIZE } from '../../../constants';
import { setSmooth } from '../../../helpers';
import LabelItem from '../partials/LabelItem';
const SECTION_WIDTH = SCREEN_SIZE.width * 0.75;
const GAP = 47;
export default class LabelsListHorizontal extends LabelListRenderingStrategy {
    constructor() {
        super(...arguments);
        this._mapItem = (item, index) => {
            return {
                type: LabelItem,
                // We have to set focusFontFace before item
                // so font is available during item creation
                focusFontFace: this._fontFace,
                item,
                index,
            };
        };
        // #endregion
    }
    get isScrollable() {
        // Longer than default screen size means scrollable.
        return this._ctx.w >= SCREEN_SIZE.width;
    }
    // #region Lifecycle
    init() {
        this._ctx.patch({
            h: 72,
            Holder: {
                y: -10,
                Focus: {
                    x: 15,
                },
                Items: {
                    h: 72,
                },
            },
        });
    }
    enter() {
        this._setFocus(true);
    }
    exit() {
        this._setFocus(false);
    }
    // #endregion
    // #region Labels
    get isScrolled() {
        return this._items.x !== 0;
    }
    positionItems() {
        const xCoordinates = this._items.children.reduce((acc, { width }) => [...acc, acc.slice(-1)[0] + width + GAP], [0]);
        this._ctx.w = xCoordinates.slice(-1)[0];
        this._items.children.forEach((child, i) => {
            child.alpha = 1;
            child.x = xCoordinates[i];
            child.scrollable = this.isScrollable;
        });
        this._items.w = this._ctx.w;
    }
    // #endregion
    // #region Events
    handleLeft() {
        if (this._index > 0) {
            this.moveWithDelta(-1);
        }
    }
    handleRight() {
        if (this._index < this._items.children.length - 1) {
            this.moveWithDelta(1);
        }
        else {
            this.announceEndOfRow();
        }
    }
    _setFocus(listInFocus) {
        if (!this.isScrollable) {
            this._ctx.tag('Focus').setSmooth('alpha', listInFocus ? 1 : 0);
        }
    }
    // #endregion
    // #region Scrolling
    _scrollTo(index, duration = 0) {
        var _a;
        super._scrollTo(index, duration);
        // Hide title season if index > 6
        (_a = this._ctx) === null || _a === void 0 ? void 0 : _a.fireAncestors('$toggleTitleSeason', index >= 6 ? 0 : 1);
    }
    _patchFocusElement(duration = 0) {
        var _a;
        this._ctx.patch({
            Holder: {
                Focus: {
                    smooth: {
                        y: [((_a = this.focusItem) === null || _a === void 0 ? void 0 : _a.y) || 0, { duration }],
                    },
                },
            },
        });
    }
    _changeListOffsetToFocused() {
        var _a;
        let scrolledDistance = 0;
        const currentX = this._items.x || 0;
        const focusItemX = ((_a = this.focusItem) === null || _a === void 0 ? void 0 : _a.x) || 0;
        const sectionWidth = this._sectionSize || SECTION_WIDTH;
        if (focusItemX === 0) {
            // Jump to start of the list
            setSmooth(this._items, 'x', 0, { duration: 0.3, delay: 0 });
        }
        else {
            // Calculate list scroll
            const absoluteCurrentX = Math.abs(currentX);
            const lesserThanCurrentX = focusItemX < absoluteCurrentX;
            const greaterThanCurrentX = focusItemX > absoluteCurrentX + sectionWidth;
            if (lesserThanCurrentX || greaterThanCurrentX) {
                const distance = lesserThanCurrentX
                    ? currentX + sectionWidth
                    : greaterThanCurrentX
                        ? currentX - sectionWidth
                        : currentX;
                scrolledDistance = distance;
                this._ctx.signal('changeScrollState', this.isScrolled);
                setSmooth(this._items, 'x', distance, { duration: 0.3, delay: 0 });
            }
            if (Math.abs(scrolledDistance) + sectionWidth > this._items.finalW) {
                this.onLabelsPageEnd();
            }
        }
    }
}
