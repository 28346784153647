import { Storage } from '@lightningjs/sdk';
import { findInCache, getCacheFromQuery, getCacheIndex, getNamespacedId, setInCache, updateCacheIndex, } from './cache';
import { getHash, storeHash } from '../../api/requests/bffQuery';
import { getBff, getExtensions, getHeaders, getQueryName, makeGetUrl, persistedQueryUnavailable, postFallback, } from './helpers';
import { PlaceholderRequestController } from './placeholder';
class GraphQl {
    constructor() {
        this.uri = '';
        this.placeholder = new PlaceholderRequestController(this);
        this.cache = {
            clear() {
                const cache = getCacheIndex();
                for (const key in cache) {
                    const isInStorage = Storage.get(key);
                    if (isInStorage) {
                        Storage.remove(key);
                    }
                }
                const cacheKey = getNamespacedId('cache');
                Storage.set(cacheKey, {});
            },
            gc() {
                const now = Date.now();
                const cache = getCacheIndex();
                const startCacheLength = Object.keys(cache).length;
                for (const key in cache) {
                    const expired = cache[key] < now;
                    const isInStorage = Storage.get(key);
                    if (!isInStorage) {
                        delete cache[key];
                    }
                    else if (expired) {
                        if (isInStorage) {
                            Storage.remove(key);
                        }
                        delete cache[key];
                    }
                }
                if (Object.keys(cache).length !== startCacheLength) {
                    updateCacheIndex(cache);
                }
            },
            evict(id) {
                Storage.remove(getNamespacedId(id));
                this.gc();
            },
            modify(data) {
                var _a;
                const { key, value } = findInCache(data.id);
                if (value) {
                    const newValue = (_a = data.fields) === null || _a === void 0 ? void 0 : _a.call(data, value);
                    setInCache(key, newValue);
                }
            },
        };
    }
    async setBffUrl() {
        try {
            const bff = await getBff();
            this.uri = `${bff.url}${bff.version}${bff.endpoint}`;
        }
        catch (e) {
            return Promise.reject();
        }
    }
    handleResponse(options, data, hash) {
        return (result) => {
            var _a;
            if (options === null || options === void 0 ? void 0 : options.middleware) {
                (_a = options === null || options === void 0 ? void 0 : options.middleware) === null || _a === void 0 ? void 0 : _a.forEach((middleware) => middleware(result));
            }
            // Only store the hash if the query is successful
            storeHash(data.query, hash);
            return result;
        };
    }
    async query(data, options) {
        if (!this.uri)
            await this.setBffUrl();
        if (data.variables.componentConfigs) {
            return this.placeholder.fetch(data);
        }
        return this.handleQuery(data, options);
    }
    handleQuery(data, options) {
        const { query, variables } = data;
        const hash = getHash(query);
        const headers = getHeaders();
        const extensions = getExtensions(hash);
        variables.queryName = getQueryName(query, variables);
        // Make GET call for persisted query.
        return fetch(makeGetUrl(this.uri, variables, extensions), {
            method: 'GET',
            headers,
        })
            .then((response) => response.json())
            .then((json) => {
            // If PersistedQueryNotFound, POST it and return response.
            if (persistedQueryUnavailable(json)) {
                return postFallback(this.uri, headers, query, variables, extensions);
            }
            return json;
        })
            .then(this.handleResponse(options, data, hash));
    }
    readQuery(data) {
        var _a, _b;
        return (_b = (_a = getCacheFromQuery(data)) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.data;
    }
}
export const GraphQlClient = new GraphQl();
