import { Lightning, Utils } from '@lightningjs/sdk';
import { COLORS } from '../../constants';
export default class MedalBadge extends Lightning.Component {
    static _template() {
        return {
            alpha: 1,
            mountY: 0,
            rect: true,
            color: COLORS.transparent,
            MedalImage: {
                w: 26,
                h: 26,
                x: -5,
                texture: Lightning.Tools.getSvgTexture(Utils.asset('images/olympic-medal.svg'), 26, 26),
            },
        };
    }
}
