import { Lightning } from '@lightningjs/sdk';
import { COLORS, FONT_FACE } from '../../../constants';
export default (tile) => {
    if (tile.showClipData) {
        tile.patch({
            SubTitle: {
                y: 73,
                text: {
                    maxLines: 1,
                },
            },
            TertiaryTitle: {
                y: 118,
                text: {
                    text: '',
                },
                Clip: {
                    flex: {},
                    Icon: {
                        flexItem: { marginRight: 8, marginTop: 3 },
                        texture: Lightning.Tools.getRoundRect(56, 28, 0, 1, COLORS.mediumGray4, true, COLORS.transparent),
                        Label: {
                            mount: 0.5,
                            x: 29,
                            y: 16,
                            text: { text: 'CLIP', fontFace: FONT_FACE.light, fontSize: 22 },
                        },
                    },
                    Date: {
                        flexItem: { marginTop: -3 },
                        text: {
                            text: tile.tertiaryTitle,
                            fontFace: FONT_FACE.light,
                            fontSize: 30,
                        },
                    },
                },
            },
            Description: {
                text: {
                    text: tile.description,
                },
            },
        });
    }
};
