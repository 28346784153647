import { Language, Router } from '@lightningjs/sdk';
import { setSmooth } from '../../helpers';
import { COLORS, FONT_FACE, TEXT_ALIGN, VERTICAL_ALIGN } from '../../constants';
export const WithRequestErrorState = 'RequestError';
export const WithRequestError = (component) => class extends component {
    // @ts-expect-error todo
    static _template() {
        return {
            // @ts-expect-error todo
            ...super._template(),
            RequestError: {
                alpha: 0,
                x: 960,
                mountX: 0.5,
                y: 650,
                text: {
                    textColor: COLORS.white,
                    verticalAlign: VERTICAL_ALIGN.middle,
                    textAlign: TEXT_ALIGN.center,
                    fontSize: 48,
                    fontFace: FONT_FACE.light,
                    text: Language.translate('error_filters_title'),
                },
            },
        };
    }
    // @ts-expect-error this member cannot have an 'override' modifier because it is not declared in the base class 'Component<any, RouterPage>'
    static _states() {
        return [
            // @ts-expect-error super doesn't work with static methods
            ...(super._states() || []),
            class RequestError extends this {
                $enter() {
                    setSmooth(this.widgets.loader, 'visible', 0);
                    setSmooth(this.tag('RequestError'), 'alpha', 1);
                }
                _getFocused() {
                    return this;
                }
                _handleUp() {
                    Router.focusWidget('Menu');
                }
            },
        ];
    }
};
