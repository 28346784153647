import get from 'lodash/get';
import moment from 'moment-timezone';
// @ts-expect-error TS(7016): Could not find a declaration file for module 'json... Remove this comment to see the full error message
import { getRelationship } from 'jsonapi-relate';
import AppConfigFactorySingleton from '../config/AppConfigFactory';
import ParkApi from '../api/ParkApi';
import { collectionQuery } from '../api/ParkUtils';
import Watches from '../api/Watches';
import { getMpid } from './analytics';
const DEFAULT_TIMEZONE = 'America/New_York';
const COLLECTION_NAME = 'samsung-eden-preview-collection-of-collections';
const PREVIEW_PARK_VERSION = '3.13.0';
const hasVideoExpired = (expiration) => {
    const currentDate = moment().format('YYYY-MM-DD');
    return !!expiration && moment(expiration).isBefore(currentDate);
};
const formatDate = (datestring, isFullYear = false) => {
    const format = isFullYear ? 'MM/DD/YYYY' : 'MM/DD/YY';
    return moment(datestring).tz(DEFAULT_TIMEZONE).format(format);
};
function formatVideoSubTitle(entity) {
    const date = formatDate(entity.attributes.airdate);
    const seasonNumber = get(entity, 'attributes.seasonNumber');
    const episodeNumber = get(entity, 'attributes.episodeNumber');
    const seasonEpisode = [seasonNumber && `S${seasonNumber}`, episodeNumber && `E${episodeNumber}`]
        .filter((exists) => exists)
        .join(' ');
    const meta = entity.attributes.type === 'Full Episode'
        ? formatDate(entity.attributes.airdate)
        : `${entity.attributes.type} | ${date} | ${formatRunTime(get(entity, 'attributes.runTime', 0))}`;
    return `${seasonEpisode && `${seasonEpisode} | `}${meta}`;
}
const formatRunTime = (seconds) => {
    return `${Math.max(1, Math.round(seconds / 60))} min`;
};
const getImagePath = (payload, entity) => {
    const relation = getRelationship(payload, entity, 'image');
    const path = get(relation, 'attributes.path');
    return path ? `https://img.nbc.com${path}?impolicy=nbc_com&imwidth=480` : '';
};
const typeMap = {
    videos: (payload, entity) => ({
        title: entity.attributes.type === 'Full Episode'
            ? get(getRelationship(payload, entity, 'show'), 'attributes.shortTitle', '')
            : entity.attributes.title,
        subtitle: formatVideoSubTitle(entity),
        display_until: Math.floor(new Date(entity.attributes.expiration).getTime() / 1000),
        image_url: getImagePath(payload, entity),
        image_ratio: '16by9',
        is_playable: true,
        action_data: `video/${entity.attributes.guid}`,
    }),
    shows: (payload, entity) => ({
        image_url: getImagePath(payload, entity),
        image_ratio: '16by9',
        is_playable: false,
        action_data: `show/${entity.attributes.urlAlias}`,
    }),
};
// Filtering to be applied to collections.
const filterMap = {
    videos: (entity) => entity.attributes.published && !hasVideoExpired(entity.attributes.expiration),
    shows: (entity) => entity.attributes.frontends.indexOf('tv') !== -1,
};
export const getPersonalPreview = async (deviceId) => {
    var _a, _b;
    // Init app config if app have not loaded yet
    if (!AppConfigFactorySingleton.config)
        await AppConfigFactorySingleton.get().getRemoteConfig();
    const accessVodEnabled = (_a = AppConfigFactorySingleton.config.access_vod) === null || _a === void 0 ? void 0 : _a.enabled;
    const payload = await ParkApi.get('collections', {
        ...collectionQuery,
        filter: {
            name: COLLECTION_NAME,
        },
        dervatives: 'landscape.widescreen.size640.x1',
    }, { version: PREVIEW_PARK_VERSION });
    if (!get(payload, 'data.length')) {
        return Promise.reject('Collection cannot be found.');
    }
    const relationship = getRelationship(payload, payload.data[0], 'collections');
    const collections = relationship.map((collection) => {
        var _a, _b, _c;
        return ({
            title: collection.attributes.title,
            tiles: ((_c = (_b = (_a = getRelationship(payload, collection, collection.attributes.type)) === null || _a === void 0 ? void 0 : _a.filter((entity) => filterMap[collection.attributes.type](entity) && entity)) === null || _b === void 0 ? void 0 : _b.filter((entity) => entity.attributes.uplynkStatus === 'VIDEO_SLICED_OTT' || accessVodEnabled)) === null || _c === void 0 ? void 0 : _c.map((entity) => typeMap[collection.attributes.type](payload, entity))) || [],
        });
    });
    const watchesResponse = await Watches.get(getMpid(true, false) || deviceId);
    const watches = (_b = watchesResponse.data) === null || _b === void 0 ? void 0 : _b.map((entity) => getRelationship(payload, entity, 'videos')).filter((entity) => filterMap.videos(entity) && entity).filter((entity) => entity.attributes.uplynkStatus === 'VIDEO_SLICED_OTT' || accessVodEnabled).map((entity) => typeMap.videos(payload, entity));
    if (watches === null || watches === void 0 ? void 0 : watches.length) {
        collections.unshift({
            title: 'Continue Watching',
            tiles: watches,
        });
    }
    return JSON.stringify({ sections: collections });
};
