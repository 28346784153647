import { Lightning, Language } from '@lightningjs/sdk';
import { COLORS, FONT_FACE } from '../../constants';
class InputField extends Lightning.Component {
    static _template() {
        return {
            h: (h) => h,
            w: (w) => w,
            Background: {
                h: (h) => h,
                w: (w) => w,
            },
            QueryString: {
                y: 30,
                x: 47,
                color: COLORS.warmGray4,
                text: {
                    fontSize: 36,
                    fontFace: FONT_FACE.light,
                },
            },
        };
    }
    set maxCharacters(v) {
        InputField.MAX_CHARS = v;
    }
    set value(v) {
        const screenValue = this.inspectValue(v);
        this.tag('QueryString').patch({
            text: { text: this._limitTextLength(screenValue, InputField.MAX_CHARS) },
        });
        this._value = screenValue;
    }
    get value() {
        return this._value;
    }
    _limitTextLength(value, max) {
        if (value.length <= max) {
            return value;
        }
        const len = value.length;
        const offset = len - max;
        return value.substring(offset, len);
    }
    inspectValue(value) {
        this._hasInput = false;
        if (value.length > 0) {
            this._hasInput = true;
            this.tag('QueryString').patch({ color: COLORS.black });
            return value;
        }
        this.tag('QueryString').patch({ color: COLORS.mediumGray });
        return this.emptyInputString;
    }
    get emptyInputString() {
        return Language.translate('searchPage-keyboard-lookingFor');
    }
    get hasInput() {
        return this._hasInput;
    }
    reset() {
        this.value = '';
    }
    _init() {
        this._hasInput = false;
        this.patch({
            Background: {
                texture: Lightning.Tools.getRoundRect(this.w, this.h, 5, 0, COLORS.warmGray3, true, COLORS.warmGray3),
            },
        });
        this.reset();
    }
}
InputField.MAX_CHARS = 33;
export default InputField;
