import { Lightning, Log } from '@lightningjs/sdk';
import { throttle } from 'lodash';
import { setSmooth } from '../../helpers/template';
import { CCEdgeStyles, CCFonts, ClosedCaptionsUtils, } from '../../lib/ClosedCaptions/ClosedCaptionsUtils';
import Preferences from '../../lib/Preferences';
import { FLEX_DIRECTION, FONT_FACE } from '../../constants';
const PLAYER_CAPTIONS_TAG = 'PlayerCaptions';
class PlayerCaptions extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._cleanUp = throttle((time) => {
            this._container.children.forEach((item) => item.endTime <= time && this._container.childList.remove(item));
        }, 1000, { leading: false });
    }
    static _template() {
        return {
            Window: {
                x: -25,
                y: -25,
                rect: true,
                color: this.bindProp('windowColor'),
                w: this.bindProp('windowWidth'),
                h: this.bindProp('windowHeight'),
            },
            CaptionsContainer: {
                flex: { direction: FLEX_DIRECTION.column, wrap: false },
            },
        };
    }
    _reset() {
        this._container.childList.clear();
    }
    _init() {
        this._container = this.tag('CaptionsContainer');
        this.updateUI();
    }
    syncCCSettings(deviceSettings) {
        if (deviceSettings)
            Object.entries(deviceSettings).forEach(([key, value]) => Preferences.store(key, value));
        this.updateUI(deviceSettings);
    }
    get ccStyle() {
        return {
            fontStyle: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_FONT_STYLE),
            fontOpacity: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_FONT_OPACITY),
            fontSize: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_FONT_SIZE),
            fontColor: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_FONT_COLOR),
            backgroundOpacity: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_BACKGROUND_OPACITY),
            backgroundColor: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_BACKGROUND_COLOR),
            edgeStyle: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_EDGE_STYLE),
            edgeColor: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_EDGE_COLOR),
            edgeOpacity: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_EDGE_OPACITY),
            windowColor: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_WINDOW_COLOR),
            windowOpacity: ClosedCaptionsUtils.getValue(Preferences.CLOSED_CAPTION_WINDOW_OPACITY),
        };
    }
    _setActiveCues(activeCues) {
        if (ClosedCaptionsUtils.getCCType() === 'off' || activeCues.length === 0) {
            setSmooth(this, 'alpha', 0);
        }
        else {
            setSmooth(this, 'alpha', 1);
            // Cues are returned in reverse order.
            this._container.children = [].map
                .call(activeCues, (activeCue) => {
                return {
                    flexItem: {},
                    type: CaptionItem,
                    settings: this.ccStyle,
                    captionText: activeCue.text,
                    endTime: activeCue.endTime,
                };
            })
                .reverse();
        }
    }
    _ccLanguageUpdated(value) {
        ClosedCaptionsUtils.setCCType(value);
        setSmooth(this, 'alpha', value === 'off' ? 0 : 1);
    }
    _ccSettingUpdated(key, value) {
        if (ClosedCaptionsUtils.setValue(key, value))
            this.updateUI();
    }
    updateUI(style = this.ccStyle) {
        var _a, _b;
        const _player = (_a = this.parent) === null || _a === void 0 ? void 0 : _a._player;
        const interfacePlayer = _player;
        (_b = interfacePlayer === null || interfacePlayer === void 0 ? void 0 : interfacePlayer.setCCStyle) === null || _b === void 0 ? void 0 : _b.call(interfacePlayer, style);
        this._container.children.forEach((child) => {
            if (child)
                child.settings = style;
        });
        this.windowColor = ClosedCaptionsUtils.getColorHex(style.windowColor, style.windowOpacity);
    }
    $onWidth(w) {
        this.windowWidth = w + 50;
    }
    $onHeight(w) {
        this.windowHeight = w + 50;
    }
}
PlayerCaptions.CAPTION_ITEM_MARGIN_LEFT = 14;
PlayerCaptions.CAPTION_ITEM_MARGIN_RIGHT = 48;
export default PlayerCaptions;
class CaptionItem extends Lightning.Component {
    static _template() {
        const onChange = (prop) => (ctx) => {
            Log.info(PLAYER_CAPTIONS_TAG, `Caption item color: ${ctx.color}, fontColor: ${ctx.fontColor}, fontFace: ${ctx.fontFace}, fontSize: ${ctx.fontSize}`);
            return ctx[prop];
        };
        return {
            flex: {},
            rect: true,
            color: this.bindProp('backgroundColor', onChange('backgroundColor')),
            CaptionsItem: {
                x: PlayerCaptions.CAPTION_ITEM_MARGIN_LEFT,
                text: {
                    textColor: this.bindProp('fontColor', onChange('fontColor')),
                    fontFace: this.bindProp('fontFace', onChange('fontFace')),
                    fontSize: this.bindProp('fontSize', onChange('fontSize')),
                    shadow: this.bindProp('shadow', onChange('shadow')),
                    shadowBlur: this.bindProp('shadowBlur', onChange('shadowBlur')),
                    shadowColor: this.bindProp('shadowColor', onChange('shadowColor')),
                    shadowOffsetX: this.bindProp('shadowOffsetX', onChange('shadowOffsetX')),
                    shadowOffsetY: this.bindProp('shadowOffsetY', onChange('shadowOffsetY')),
                },
            },
        };
    }
    _init() {
        this.tag('CaptionsItem').on('txLoaded', () => {
            const { renderWidth, renderHeight } = this.tag('CaptionsItem');
            const w = renderWidth +
                PlayerCaptions.CAPTION_ITEM_MARGIN_LEFT +
                PlayerCaptions.CAPTION_ITEM_MARGIN_RIGHT;
            this.w = w;
            this.fireAncestors('$onWidth', w);
            this.fireAncestors('$onHeight', renderHeight);
        });
    }
    get endTime() {
        return this._endTime;
    }
    set endTime(endTime) {
        this._endTime = endTime;
    }
    set captionText(v) {
        this.tag('CaptionsItem').text.text = v;
    }
    set settings({ fontStyle, fontOpacity, fontSize, fontColor, backgroundOpacity, backgroundColor, edgeStyle, edgeColor, edgeOpacity, }) {
        this.backgroundColor = ClosedCaptionsUtils.getColorHex(backgroundColor, backgroundOpacity);
        this.fontColor = ClosedCaptionsUtils.getColorHex(fontColor, fontOpacity);
        this.fontSize = fontSize;
        this.fontFace = fontStyle === CCFonts.default ? FONT_FACE.courier : fontStyle;
        this.shadow = edgeStyle !== CCEdgeStyles.none;
        this.shadowColor = ClosedCaptionsUtils.getColorHex(edgeColor, edgeOpacity);
        const edgeStyles = {
            [CCEdgeStyles.none]: [0, 0],
            [CCEdgeStyles.depressed]: [0, -3],
            [CCEdgeStyles.raised]: [0, 3],
            [CCEdgeStyles.shadow]: [3, 3],
            [CCEdgeStyles.uniform]: [0, 0],
        };
        const currentEdgeStyle = edgeStyles[edgeStyle];
        this.shadowOffsetX = currentEdgeStyle[0];
        this.shadowOffsetY = currentEdgeStyle[1];
        this.shadowBlur = [CCEdgeStyles.shadow, CCEdgeStyles.uniform].includes(edgeStyle) ? 4 : 0;
        Log.info(PLAYER_CAPTIONS_TAG, 'Settings set in caption item');
    }
}
