export var MAPPED_CHANNELS;
(function (MAPPED_CHANNELS) {
    MAPPED_CHANNELS["sports"] = "nbcsn";
    MAPPED_CHANNELS["nbcsports"] = "nbcsn";
    MAPPED_CHANNELS["nbcsbayarea"] = "nbcsn";
    MAPPED_CHANNELS["nbcsboston"] = "nbcsn";
    MAPPED_CHANNELS["nbcscalifornia"] = "nbcsn";
    MAPPED_CHANNELS["nbcschicago"] = "nbcsn";
    MAPPED_CHANNELS["nbcsphiladelphia"] = "nbcsn";
    MAPPED_CHANNELS["nbcswashington"] = "nbcsn";
})(MAPPED_CHANNELS || (MAPPED_CHANNELS = {}));
export const CONTENT_TYPES = {
    linear: 'live',
    live: 'live',
    'Single Live Event': 'sle',
    'Full Event Replay': 'fer',
    Highlights: 'vod',
};
export const TOKEN_VERIFIER_URLS = {
    sle: {
        hostKey: 'tokenVerifier',
        endpoint: 'tokenverifier/entitlement/verifier',
    },
    linear: {
        hostKey: 'tokenVerifier',
        endpoint: 'tokenverifier/entitlement/verifier',
    },
};
export const TEMP_PASS_ERROR = Object.freeze({
    UNKNOWN: 'Unknown error',
    ATTEMPTS_EXCEEDED: 'TempPass creation attempts exceeded',
    TEMP_PASS_ACTIVE: 'TempPass already active',
    SESSION_EXPIRED: 'Session Expired',
    LAUNCH_DARKLY_ERROR: 'No initial TempPass name / TempPass disabled from LaunchDarkly / Error fetching LaunchDarkly data',
});
