export class PlayerEventEmitter {
    attach(player, events) {
        this._player = player;
        this._normalizedPlayerEvents = events;
    }
    detach() {
        this._player = null;
        this._normalizedPlayerEvents = null;
    }
}
