import { Registry } from '@lightningjs/sdk';
import { Subject } from 'rxjs';
export class TempPass {
    initSession(expiration) {
        this.expiration = expiration;
        this.expired = false;
        this.events = new Subject();
        this._interval = Registry.setInterval(this._tick.bind(this), 1000);
    }
    _tick() {
        var _a;
        if (this.expired || !this.expiration)
            return;
        const delta = this.getDelta();
        if (delta <= 0) {
            this.expired = true;
        }
        (_a = this.events) === null || _a === void 0 ? void 0 : _a.next(delta);
    }
    subscribe(callback) {
        var _a;
        return (_a = this.events) === null || _a === void 0 ? void 0 : _a.subscribe(callback);
    }
    endSession() {
        var _a;
        (_a = this.events) === null || _a === void 0 ? void 0 : _a.complete();
        this.events = null;
        if (this._interval)
            Registry.clearInterval(this._interval);
        this._interval = null;
        this.expiration = null;
        this.expired = false;
    }
    getDelta() {
        const now = Date.now();
        return Math.floor((this.expiration - now) / 1000);
    }
}
export const TempPassSingleton = new TempPass();
