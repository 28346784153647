import { Log } from '@lightningjs/sdk';
import { sendMetric } from '../../../lib/analytics/Analytics';
import AppConfigFactorySingleton from '../../../config/AppConfigFactory';
import { DebugControllerSingleton } from '../../../util/debug/DebugController';
import { getAdParams, getAdPodParams } from '../../CoreVideoPlayer/normalizeAds';
import { AdBreakDataEvent } from '../event/AdBreakDataEvent';
import { reduceAdBreaks } from '../../../api/models/AdBreakTiming';
import { PROGRAMMING_TYPES } from '../../../constants';
import { AdBreakStartEvent } from '../event/AdBreakStartEvent';
import { AdBreakEndEvent } from '../event/AdBreakEndEvent';
import { AdStartEvent } from '../event/AdStartEvent';
import { AdEndEvent } from '../event/AdEndEvent';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { isVod } from '../../../components/player/helpers/metadata';
import { PlayerEventEmitter } from './PlayerEventEmitter';
import { EVENTS } from '../../../lib/analytics/types';
import { NonLinearAdEvent } from '../event/NonLinearAdEvent';
const TAG = 'CoreVideoAdReporter';
export class CoreVideoAdReporter extends PlayerEventEmitter {
    constructor() {
        super(...arguments);
        this._onAdBreakDataReceived = (adbreaks) => {
            var _a;
            const mappedAdBreaks = reduceAdBreaks(adbreaks);
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new AdBreakDataEvent(mappedAdBreaks));
        };
        this._onAdBreakStarted = (adBreak) => {
            var _a;
            this._adBreakIsPlaying = true;
            this._currentAdBreak = getAdPodParams(adBreak);
            Log.info(`${TAG} ad break started`, this._currentAdBreak);
            sendMetric(EVENTS.AD_BREAK_START, this._currentAdBreak);
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new AdBreakStartEvent(adBreak));
        };
        this._onAdBreakFinished = () => {
            var _a;
            this._adBreakIsPlaying = false;
            Log.info(`${TAG} ad break finished`);
            sendMetric(EVENTS.AD_BREAK_END, this._currentAdBreak);
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new AdBreakEndEvent({}));
        };
        this._onAdStarted = async (ad) => {
            var _a;
            this._isAdPlaying = true;
            this._currentAd = await getAdParams(ad);
            const index = ad.adBreak.ads.findIndex((ad) => { var _a; return ad.id === ((_a = this._currentAd) === null || _a === void 0 ? void 0 : _a.id); });
            Log.info(`${TAG} ad started`, this._currentAd);
            sendMetric(EVENTS.AD_START, this._currentAd);
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new AdStartEvent(ad === null || ad === void 0 ? void 0 : ad.adBreak, { ...this._currentAd, index }));
        };
        this._onAdFinished = () => {
            var _a;
            this._isAdPlaying = false;
            Log.info(`${TAG} ad finished`);
            sendMetric(EVENTS.AD_END, this._currentAd);
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new AdEndEvent(this._currentAdBreak, this._currentAd));
        };
        this._onPauseAd = (ad) => {
            var _a;
            Log.info(`${TAG} onPauseAd`);
            (_a = this._normalizedPlayerEvents) === null || _a === void 0 ? void 0 : _a.publish(new NonLinearAdEvent(ad));
        };
    }
    get isAdPlaying() {
        return this._isAdPlaying;
    }
    attach(player, eventStream) {
        var _a;
        super.attach(player, eventStream);
        const { program } = PlayerStoreSingleton;
        // Ad event callbacks
        // Adbreaks (only for FERs since ad breaks aren't known on live)
        if (program &&
            'programmingType' in program &&
            ((program === null || program === void 0 ? void 0 : program.programmingType) === PROGRAMMING_TYPES.FER || isVod(program === null || program === void 0 ? void 0 : program.programmingType))) {
            player === null || player === void 0 ? void 0 : player.onAdBreakDataReceived(this._onAdBreakDataReceived);
        }
        // User entering/exiting ad break
        player === null || player === void 0 ? void 0 : player.onAdBreakStarted(this._onAdBreakStarted);
        player === null || player === void 0 ? void 0 : player.onAdBreakFinished(this._onAdBreakFinished);
        // User entering/exiting one ad within the ad break
        player === null || player === void 0 ? void 0 : player.onAdStarted(this._onAdStarted);
        player === null || player === void 0 ? void 0 : player.onAdFinished(this._onAdFinished);
        (_a = player === null || player === void 0 ? void 0 : player.onPauseAd) === null || _a === void 0 ? void 0 : _a.call(player, this._onPauseAd);
        // Disable ads through the config if needed
        // Long term I'd like to make a debug screen where we could toggle this on and off
        DebugControllerSingleton.adsDisabled = AppConfigFactorySingleton.config.adsDisabled;
    }
}
