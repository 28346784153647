import { Lightning } from '@lightningjs/sdk';
export default class Bullet extends Lightning.Component {
    static _template() {
        return {
            texture: Lightning.Tools.getRoundRect(18, 18, 9),
        };
    }
    setFocus(v) {
        this.patch({
            smooth: { scale: v ? 1 : 0.66, alpha: v ? 1 : 0.4 },
        });
    }
}
