import { Lightning, Utils } from '@lightningjs/sdk';
import { FONT_FACE, httpsRegExp, svgRegExp } from '../../constants';
import { FastImg, getSvgTexture } from '../../helpers';
export default class MenuItem extends Lightning.Component {
    static _template() {
        return {
            flexItem: {
                marginLeft: 20,
                marginRight: 20,
            },
            shader: {
                type: Lightning.shaders.Inversion,
                amount: 0,
            },
        };
    }
    _init() {
        this.on('txError', (texture) => {
            if (texture instanceof Lightning.textures.StaticCanvasTexture && this.item.label)
                this._iconLoadError = true;
            this._setTextTexture(this.item.label);
        });
        if (this.item.label && !this.item.icon)
            this._setTextTexture(this.item.label);
        if (this.item.icon)
            this._setImageTexture();
        if (this.item.ariaLabel)
            this.announce = this.item.ariaLabel;
    }
    set current(v) {
        if (v === this._current)
            return;
        this._current = v;
        if (this.texture) {
            if (this.texture instanceof Lightning.textures.TextTexture) {
                this.patch({ text: { fontFace: v ? 'Bold' : 'Regular' } });
            }
            else if (this.texture instanceof Lightning.textures.ImageTexture) {
                this._setImageTexture();
            }
        }
    }
    get width() {
        return this.core.w;
    }
    get height() {
        return this.core.h;
    }
    get coreX() {
        return this.core.x;
    }
    set index(v) {
        this._index = v;
    }
    get index() {
        return this._index;
    }
    get hasRoundedFocus() {
        return this.height === this.width && this.item.focused;
    }
    _focus() {
        if (!this._iconLoadError && (this.item.focused || this.item.icon))
            this._setImageTexture(true);
        else
            this._setShaderAmount(1);
    }
    _unfocus() {
        if (!this._iconLoadError && this.item.icon)
            this._setImageTexture();
        else
            this._setShaderAmount(0);
    }
    _setImageTexture(focused = false) {
        const url = focused && this.item.focused
            ? this.item.focused
            : this._current && this.item.active
                ? this.item.active
                : this.item.icon;
        if (httpsRegExp.test(url)) {
            this.patch({
                h: 53,
                texture: svgRegExp.test(url) ? getSvgTexture(url, { h: 53 }) : FastImg(url).portrait(53),
            });
        }
        else {
            this.patch({
                src: Utils.asset(`images/${url}`),
            });
        }
    }
    _setTextTexture(text) {
        this.patch({ text: { text, fontSize: 35, fontFace: FONT_FACE.regular } });
    }
    _setShaderAmount(amount) {
        this.setSmooth('shader.amount', amount);
    }
}
