import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
import getLinearAttributes, { getLinearDuration } from './getLinearAttributes';
const getLinearEndAttributes = (params = {}) => ({
    ...getLinearAttributes(params),
    // @ts-expect-error TS(2339): Property 'programOrder' does not exist on type '{}... Remove this comment to see the full error message
    'Program Order': params.programOrder || MPARTICLE_DEFAULT_ATTR_VALUE,
    // @ts-expect-error TS(2339): Property 'duration' does not exist on type '{}'.
    'Linear Duration': getLinearDuration(params.duration),
});
export default getLinearEndAttributes;
