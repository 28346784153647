import { createRequestConfig } from '../lib/useRequest';
import { componentsForPlaceholders, endCardRequest, mainRequest, mixEditorialShelfRequest, onAirNowShelfRequest, } from '../api/requests';
import Variables from '../graphql/Variables';
import rsnFilter from '../graphql/filter/rsnFilter';
import componentsForPlaceholdersMapper from '../graphql/mappers/componentsForPlaceholders';
import onAirNowShelfMapper from '../graphql/mappers/onAirNowShelf';
import mixedEditorialShelf from '../graphql/mappers/mixedEditorialShelf';
import endCardMapper from '../graphql/mappers/endCard';
export const SlePageRequestConfig = (pid) => createRequestConfig({
    query: mainRequest,
    variables: Variables.slePageVariables(pid),
    reject: [(data) => !(data === null || data === void 0 ? void 0 : data.sections)],
});
export const LiveScheduleRequestConfig = () => createRequestConfig({
    query: mainRequest,
    variables: Variables.liveSchedulePageVariables(),
    reject: [(data) => !(data === null || data === void 0 ? void 0 : data.sections)],
});
export const StreamRequestConfig = (callSign, isGlobalNavigation = false) => createRequestConfig({
    query: mainRequest,
    variables: Variables.streamPageVariables(isGlobalNavigation ? 'live' : callSign, callSign),
    reject: [(data) => !(data === null || data === void 0 ? void 0 : data.sections)],
});
export const OlympicsStreamRequestConfig = (videoId) => createRequestConfig({
    query: mainRequest,
    variables: Variables.olympicsVideoPageVariables(videoId),
    reject: [(data) => !(data === null || data === void 0 ? void 0 : data.sections)],
});
export const VideoPageRequestConfig = (videoId) => createRequestConfig({
    query: mainRequest,
    variables: Variables.videoPageVariables(videoId),
    reject: [(data) => !(data === null || data === void 0 ? void 0 : data.sections)],
});
export const ComponentsForPlaceholdersConfig = (variables) => createRequestConfig({
    query: componentsForPlaceholders,
    mapper: (data) => rsnFilter(componentsForPlaceholdersMapper(data)),
    variables: Variables.componentsForPlaceholdersVariables(variables),
});
export const onAirNowShelfConfig = (variables) => createRequestConfig({
    query: onAirNowShelfRequest,
    mapper: (data) => rsnFilter(onAirNowShelfMapper(data)),
    variables: Variables.componentsForPlaceholdersVariables(variables),
});
export const EndCardConfig = (type, videoId) => createRequestConfig({
    query: endCardRequest,
    variables: Variables.endCardVariables(type, videoId),
    mapper: (data) => endCardMapper(data, ''),
});
export const EndCardSleConfig = (type, videoId, source) => createRequestConfig({
    query: endCardRequest,
    variables: Variables.endCardSLEVariables(type, videoId),
    mapper: (data) => endCardMapper(data, source),
});
export const MixedEditorialConfig = (variables) => createRequestConfig({
    query: mixEditorialShelfRequest,
    variables: Variables.mixedEditorialShelfVariables(variables),
    mapper: (data) => rsnFilter(mixedEditorialShelf(data)),
});
