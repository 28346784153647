export const ScrollerStateFactory = (base) => class Scroller extends base {
    $enter() {
        this.tag('Featured').setInactive(false);
        this.tag('Scroller').setSmooth('y', 250);
        this.tag('SubNav').setSmooth('x', 3000);
    }
    _handleRight() {
        if (this.tag('SubNav').getSmooth('x') === 1250) {
            this._setState('ItemMenu');
        }
        else {
            return false;
        }
    }
    _handleBack() {
        this._setState(this._initialState);
    }
    _handleUp() {
        this._setState(this._initialState);
    }
    _getFocused() {
        return this.tag('Scroller') || this;
    }
    $indexChanged({ index }) {
        if (this.tag('Scroller').itemsLength - 1 === index) {
            this.tag('BackReturn').setSmooth('alpha', 1);
        }
        else {
            this.tag('BackReturn').setSmooth('alpha', 0);
        }
    }
};
